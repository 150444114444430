import React, { Component } from "react";
import SignatureCanvas from "react-signature-canvas";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardTitle,
  CardText,
  Row,
  Col
} from "reactstrap";
import classnames from "classnames";
import Select from "react-select";
import htmlToImage from "html-to-image";
import notie from "notie";
import $ from "jquery";
import axios from "axios";
import { replaceAgreementContent } from "../../helper/util";
import styles from "../styles.module.css";

class MandatoryPDF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      Disable_Button: "",
      User_ID: props.User_ID,
      Agreement_Content: "",
      Agreement_Title: "",
      setisMandatoryCompeleted: props.setisMandatoryCompeleted,
      User_Agreement_ID: props.User_Agreement_ID,
      User_Comp_Name: props.User_Comp_Name,
      User_Name: props.User_Name,
      txtSignName: props.User_Name,
      User_Title: props.User_Title,
      txtSignTitle: props.User_Title,
      User_Sign: "",
      Sign_Name: "",
      Sign_Titile: "",
      addSigneeName: "",
      addSigneeEmail: "",
      addSigneeTitle: "",
      editSignatureFile: "",
      editSign: "",
      editSignCheck: false,
      loading: false,
      activeTab: "1",
      FontName: "Mistral",
      SelectedFontName: {
        value: "Mistral",
        label: props.User_Name
      },
      signOptions: [
        {
          value: "Mistral",
          label: props.User_Name
        },
        {
          value: "Rage",
          label: props.User_Name
        },
        {
          value: "SignatureOne",
          label: props.User_Name
        },
        {
          value: "SignatureTwo",
          label: props.User_Name
        },
        {
          value: "SignatureThree",
          label: props.User_Name
        },
        {
          value: "SignatureFour",
          label: props.User_Name
        },
        {
          value: "SignatureFive",
          label: props.User_Name
        },
        {
          value: "SignatureSix",
          label: props.User_Name
        },
        {
          value: "SignatureSeven",
          label: props.User_Name
        },
        {
          value: "SignatureEight",
          label: props.User_Name
        }
      ]
    };

    this.savePDF = this.savePDF.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.GetAgreementContent = this.GetAgreementContent.bind(this);
    this.onChangeeditUploadSign = this.onChangeeditUploadSign.bind(this);
    this.handleeditUpload = this.handleeditUpload.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }
  componentWillMount() {
    this.GetAgreementContent(this.state.User_Agreement_ID);
  }

  GetAgreementContent(_User_Agreement_ID) {
    try {
      this.setState({
        loading: true,
        Disable_Button: "disabled"
      });
      const api = `${process.env.REACT_APP_BASE_URL}user_agreements/api/getAgreementUser?user_agreement_id=${_User_Agreement_ID}&user_id=${this.state.User_ID}`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("tcc_user_token")}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = "/client_login");
          }
          if (res.data.success === false) {
            this.setState({
              loading: false,
              Disable_Button: ""
            });
            return notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          this.setState({
            Agreement_Content: "",
            Agreement_Title: ""
          });
          let objUser = {
            client_title: res.data[0].client_title,
            client_name: res.data[0].client_name,
            client_comp_name: res.data[0].client_comp_name,
            client_email: res.data[0].client_email,
            client_address: res.data[0].client_address,
            client_phone_no: res.data[0].client_phone_no,
            client_fax: res.data[0].client_fax,
            vendor_signor_name: res.data[0].vendor_signor_name,
            vendor_signor_comp_name: res.data[0].vendor_signor_comp_name,
            vendor_signor_title: res.data[0].vendor_signor_title,
            vendor_signor_address: res.data[0].vendor_signor_address,
            vendor_signor_email: res.data[0].vendor_signor_email,
            vendor_signor_phone_no: res.data[0].vendor_signor_phone_no,
            vendor_signor_fax: res.data[0].vendor_signor_fax,
            user_comp_name: res.data[0].user_comp_name,
            user_comp_name_short: res.data[0].user_comp_name_short,
            user_address: res.data[0].user_address
          };
          this.setState({
            Agreement_Title: res.data[0].agreement_title,
            Agreement_Content: replaceAgreementContent(
              res.data[0].agreement_content,
              objUser
            ),
            User_FullName: res.data[0].client_name,
            User_Comp_Name: res.data[0].client_comp_name,
            loading: false,
            Disable_Button: ""
          });
        });
    } catch (error) {}
  }

  handleClear() {
    this.signaturePad.clear();
    this.setState({ User_Sign: "" });
    $("#divImgSig").hide();
    $(".clsimgSig").attr("src", "");
    $(".clsimgSig").hide();
  }

  handleSave() {
    if ($("#txtSignName").val() === "") {
      $("#txtSignName").focus();
      return notie.alert({
        type: "info", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: "Please Enter Name",
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: "top" // optional, default = 'top', enum: ['top', 'bottom']
      });
    } else if ($("#txtSignTitle").val() === "") {
      $("#txtSignTitle").focus();
      return notie.alert({
        type: "info", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: "Please Enter Title",
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: "top" // optional, default = 'top', enum: ['top', 'bottom']
      });
    } else if (
      this.state.activeTab === "1" &&
      this.signaturePad.isEmpty() &&
      $("#editSignatureFile").get(0).files.length === 0
    ) {
      // eslint-disable-next-line no-alert
      return notie.alert({
        type: "info", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: "Please Upload or Sign Signature",
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: "top" // optional, default = 'top', enum: ['top', 'bottom']
      });
    }

    if ($("#chkeditUpload").is(":checked")) {
      if (this.state.activeTab === "1") {
        var data = this.signaturePad.getTrimmedCanvas().toDataURL("image/png");
        this.setState({ User_Sign: data });

        $("#divSig").show();
        $("#divImgSig").show();
        $(".clsimgSig").show();
        $(".clsimgSig").attr("src", data);

        $("#btnSignAgreementSmall").show();
        $("#btnSignAgreementLarge").hide();

        $("#btnSaveAgreementSmall").show();
        $("#btnSaveAgreementLarge").show();

        $("#sign_agreement").hide();

        $(".lblClientTitle").text($("#txtSignTitle").val());
        $(".lblClientName").text($("#txtSignName").val());
      } else {
        var node = document.getElementById("divSignature");
        console.log("node", node);
        this.setState({
          loading: true,
          Disable_Button: "disabled"
        });
        const it = this;
        htmlToImage
          .toPng(node)
          .then(function(dataUrl) {
            console.log("dataUrl", dataUrl);

            $("#divSig").show();
            $("#divImgSig").show();
            $(".clsimgSig").show();
            $(".clsimgSig").attr("src", dataUrl);

            $("#btnSignAgreementSmall").show();
            $("#btnSignAgreementLarge").hide();

            $("#btnSaveAgreementSmall").show();
            $("#btnSaveAgreementLarge").show();

            $("#sign_agreement").hide();

            $(".lblClientTitle").text($("#txtSignTitle").val());
            $(".lblClientName").text($("#txtSignName").val());

            it.setState({
              loading: false,
              Disable_Button: ""
            });
          })
          .catch(function(error) {
            console.error("oops, something went wrong!", error);
          });
      }
    } else {
      var reader = new FileReader();
      reader.onload = function(e) {
        $(".clsimgSig").attr("src", e.target.result);
      };
      reader.readAsDataURL($("#editSignatureFile").get(0).files[0]);

      $("#divSig").show();
      $("#divImgSig").show();
      $(".clsimgSig").show();

      $("#btnSignAgreementSmall").show();
      $("#btnSignAgreementLarge").hide();

      $("#btnSaveAgreementSmall").show();
      $("#btnSaveAgreementLarge").show();

      $("#sign_agreement").hide();

      $(".lblClientTitle").text($("#txtSignTitle").val());
      $(".lblClientName").text($("#txtSignName").val());
    }
  }

  savePDF() {
    if (
      ($(".clsimgSig").attr("src") === undefined ||
        $(".clsimgSig").attr("src") === "") &&
      $("#editSignatureFile").get(0).files.length === 0
    ) {
      notie.alert({
        type: "warning", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: "Agreement is not Signed",
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: "top" // optional, default = 'top', enum: ['top', 'bottom']
      });
    } else {
      try {
        this.setState({
          loading: true,
          Disable_Button: "disabled"
        });

        var body = new FormData();

        body.append("user_agreement_id", this.state.User_Agreement_ID);
        body.append("user_sign", $("#imgSig").attr("src"));
        body.append("sign_name", this.state.txtSignName);
        body.append("sign_title", this.state.txtSignTitle);

        body.append("siganture_type", this.state.editSignCheck);
        body.append("user_signature", $("#imgSig").attr("src"));
        body.append("user_signature_file", this.state.editSignatureFile);

        axios({
          method: "post",
          url: `${process.env.REACT_APP_BASE_URL}user_agreements/api/signMandatoryAgreement`,
          data: body,

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tcc_user_token")}`
          }
        })
          .then(res => {
            if (res.data.logout === true) {
              return (window.location = "/client_login");
            }
            if (res.data.success === false) {
              this.setState({
                loading: false,
                Disable_Button: ""
              });
              return notie.alert({
                type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: res.data.message,
                stay: true, // optional, default = false
                position: "top" // optional, default = 'top', enum: ['top', 'bottom']
              });
            }
            let _data = res.data;
            if (res.data.success === true) {
              notie.alert({
                type: "success", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: _data.message,
                stay: false, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: "top" // optional, default = 'top', enum: ['top', 'bottom']
              });

              this.setState({
                loading: false,
                Disable_Button: ""
              });

              this.handleClear();
              this.state.setisMandatoryCompeleted(1);
            } else {
              notie.alert({
                type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: _data.message,
                stay: false, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: "top" // optional, default = 'top', enum: ['top', 'bottom']
              });
            }
            this.setState({ loading: false });
          })
          .catch(function(response) {
            //handle error
            notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: response.message,
              stay: false, // optional, default = false
              //time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          });
      } catch (error) {
        notie.alert({
          type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: "ERROR : " + error,
          stay: false, // optional, default = false
          time: 30, // optional, default = 3, minimum = 1,
          position: "top" // optional, default = 'top', enum: ['top', 'bottom']
        });
      }
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleeditUpload = () => {
    this.setState({ editSignCheck: !this.state.editSignCheck });
    if (this.state.editSignCheck) {
      $("#diveditUploadSign").show();
      $(".diveditSign").css("visibility", "hidden");
    } else {
      $("#diveditUploadSign").hide();
      $(".diveditSign").css("visibility", "visible");
    }
  };

  onChangeeditUploadSign = event => {
    //getting file object
    let file = event.target.files[0];

    const types = ["image/png", "image/jpeg"];
    let imgSize = 70000;
    // let imgWidth = 200;
    // let imgHeight = 200;

    let err = "";
    // list allow mime type
    if (file === undefined) {
      return false;
    }
    if (types.every(type => file.type !== type)) {
      // create error message and assign to container
      err += file.type + " is Not a Supported Format. Please Try PNG or JGPEG ";

      notie.alert({
        type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: err,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: "top" // optional, default = 'top', enum: ['top', 'bottom']
      });
    }

    if (err !== "") {
      // if message not same old that mean has error
      event.target.value = null; // discard selected file
      console.log(err);
      return false;
    }

    if (file.size > imgSize) {
      err += file.type + " is Too Large. Please Pick a MAX 70KB File";
      notie.alert({
        type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: err,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: "top" // optional, default = 'top', enum: ['top', 'bottom']
      });
    }

    if (err !== "") {
      event.target.value = null;
      console.log(err);
      return false;
    }

    this.setState({
      editSignatureFile: event.target.files[0]
    });

    console.log(this.state.editSignatureFile);
  };

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }

    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 1);
  };

  onChangeSignStyle = selectedSignOption => {
    this.setState({ FontName: selectedSignOption.value });
    this.setState({ SelectedFontName: selectedSignOption });
    console.log("this.state.FontName", this.state.FontName);
  };

  renderSignaturePad() {
    const signStyles = {
      control: styles => ({
        ...styles,
        fontFamily: this.state.FontName,
        fontSize: "x-large",
        width: "275px",
        heigth: "150px"
      }),
      option: (styles, { data, isSelected }) => {
        return {
          ...styles,
          fontFamily: data.value,
          fontSize: "x-large"
        };
      }
    };
    return (
      <div style={{ height: "auto", width: "100%" }}>
        <div className="container-fluid">
          <div className="form-group row">
            <label htmlFor="txtSignName" className="col-md-3">
              Full Name:
            </label>
            <input
              type="text"
              id="txtSignName"
              className="form-control col-md-7"
              name="txtSignName"
              value={this.state.txtSignName}
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="form-group row">
            <label htmlFor="txtSignTitle" className="col-md-3">
              Title:
            </label>
            <input
              type="text"
              id="txtSignTitle"
              className="form-control col-md-7"
              name="txtSignTitle"
              value={this.state.txtSignTitle}
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="form-group row">
            <label className="col-md-3" htmlFor="chkeditUpload">
              Manual Signature: (check box to add signature)
            </label>
            <div className="isnotified:0,">
              <input
                type="checkbox"
                id="chkeditUpload"
                checked={this.state.editSignCheck}
                onChange={this.handleeditUpload}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-md-3">or</label>
          </div>
          <div id="diveditUploadSign" className="form-group row">
            <label htmlFor="editSignatureFile" className="col-md-3">
              Upload Signature:
            </label>
            <input
              type="file"
              id="editSignatureFile"
              className="col-md-6"
              name="editSignatureFile"
              onChange={this.onChangeeditUploadSign}
              style={{ paddingLeft: 0 }}
              accept="image/*"
            />
          </div>
          <div
            className="diveditSign"
            style={{
              visibility: "hidden"
            }}
          >
            <Nav tabs className="col-md-12">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "1"
                  })}
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  DRAW
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "2"
                  })}
                  onClick={() => {
                    this.toggle("2");
                  }}
                >
                  CHOOSE
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    <div
                      className="row sigPadRow"
                      style={{
                        padding: "10px 10px 10px 10px",
                        height: "200px"
                      }}
                    >
                      <label htmlFor="">Sign Here:</label>

                      <div className={styles.container}>
                        <div className={styles.sigContainer}>
                          <SignatureCanvas
                            clearOnResize={true}
                            canvasProps={{ className: styles.sigPad }}
                            velocityFilterWeight={0.7}
                            throttle={50}
                            ref={ref => {
                              this.signaturePad = ref;
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col lg="3">
                    <Card body>
                      <CardTitle></CardTitle>
                      <CardText>
                        <Select
                          id="ddlSignatureStyle"
                          onChange={this.onChangeSignStyle}
                          options={this.state.signOptions}
                          value={this.state.SelectedFontName}
                          styles={signStyles}
                        />
                      </CardText>
                    </Card>
                  </Col>
                  <Col lg="6">
                    <Card body>
                      <CardTitle></CardTitle>

                      <CardText>
                        <div
                          id="divSignature"
                          style={{
                            color: "black",
                            backgroundColor: "white",
                            fontFamily: this.state.FontName,
                            fontSize: "80px",
                            width: "500px",
                            height: "150px",
                            lineHeight: "150px"
                          }}
                        >
                          {this.state.txtSignName}
                        </div>
                      </CardText>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { loading } = this.state;
    return (
      <div style={{ height: "auto", width: "100%" }}>
        {loading ? <div className="loadingDiv" /> : ""}

        <div
          id="pDataMandatory"
          style={{
            width: "100%",
            padding: "50px",
            backgroundColor: "white",
            boxShadow: "0px 0px 1px black",
            margin: "auto",
            overflow: "auto",
            color: "black"
          }}
        >
          <div style={{ width: "95%", position: "fixed" }}>
            {" "}
            <button
              id="btnSaveAgreementSmall"
              className="btn btn-success"
              onClick={this.savePDF}
              style={{ display: "none", float: "right" }}
              disabled={this.state.Disable_Button}
            >
              Submit Agreement
            </button>
          </div>
          <div className="form-group row">
            <h2 style={{ marginLeft: "auto", marginRight: "auto" }}>
              {this.state.Agreement_Title}
            </h2>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: this.state.Agreement_Content
            }}
          />
        </div>
        <div className="container-fluid" style={{ paddingTop: "10px" }}>
          <div className="row">
            <div className="col-6">
              {" "}
              <div id="divImgSig" style={{ display: "none" }} className="row">
                Signature :{" "}
                <img
                  id="imgSig"
                  className="clsimgSig"
                  width="100px"
                  alt="Signature"
                />
              </div>
            </div>
            <div className="col-6">
              {" "}
              <button
                id="btnSignAgreementSmall"
                className="btn btn-primary"
                onClick={() => {
                  $("#sign_agreement").show();
                  setTimeout(() => {
                    window.dispatchEvent(new Event("resize"));
                  }, 1);
                }}
                style={{ display: "none", float: "right" }}
              >
                Sign Again
              </button>
            </div>
          </div>
          <div className="form-group row">
            <button
              id="btnSignAgreementLarge"
              className="btn-lg btn-block btn-primary"
              onClick={() => {
                $("#sign_agreement").show();
                setTimeout(() => {
                  window.dispatchEvent(new Event("resize"));
                }, 1);
              }}
              style={{ display: "block" }}
            >
              Sign Agreement
            </button>
            <button
              id="btnSaveAgreementLarge"
              className="btn-lg btn-block btn-success"
              onClick={this.savePDF}
              style={{ display: "none" }}
              disabled={this.state.Disable_Button}
            >
              Submit Agreement
            </button>
          </div>
        </div>

        {/** SIGNATURE MODAL */}
        <div
          className="modal myModal"
          id="sign_agreement"
          style={{ display: "none", overflow: "auto" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">{this.state.User_Comp_Name}</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => $("#sign_agreement").hide()}
                >
                  ×
                </button>
              </div>

              <div className="modal-body">
                {this.renderSignaturePad()}

                <div className="modal-footer" style={{ paddingTop: "35px" }}>
                  {/**  <button
                      type='button'
                      className='btn btn-danger'
                      onClick={() => $('#sign_agreement').hide()}
                    >
                      Cancel
                   </button> */}
                  <button
                    className="btn btn-warning"
                    onClick={this.handleClear.bind(this)}
                  >
                    Clear
                  </button>
                  <button
                    className="btn btn-success"
                    onClick={this.handleSave.bind(this)}
                  >
                    Sign Agreement
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MandatoryPDF;
