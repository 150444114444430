import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import lifecycle from 'react-pure-lifecycle';
import { AuthConsumer } from '../../context/AuthContext';

const methods = {
  componentDidMount(props) {
    document.body.classList.add('page-login-v2', 'layout-full', 'page-dark');
    document.body.classList.remove('site-navbar-small');
  }
};

const AdminLoginLayout = ({ Comp_Name, Comp_Logo_Dashboard, children }) => (
  <React.Fragment>
    <div
      className='page'
      data-animsition-in='fade-in'
      data-animsition-out='fade-out'
    >
      <div className='page-content'>
        <div className='page-brand-info'>
          <div className='brand'>
            {Comp_Logo_Dashboard === '' ? (
              ''
            ) : (
              <img className='brand-img' src={Comp_Logo_Dashboard} alt='...' />
            )}
          </div>
        </div>

        <div className='page-login-main-admin animation-slide-right animation-duration-1'>
          {children}
        </div>
      </div>
      <div className='modal myMode ' id='myMode'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h4 id='heading' className='modal-title ml-40'>
                Forgot Password?
              </h4>
              <button type='button' className='close' data-dismiss='modal'>
                &times;
              </button>
            </div>

            <div className='modal-body'>
              <div className='container-fluid'>
                <div className='form-group row '>
                  <label htmlFor='email' className='col-md-2 label-clr mt-2'>
                    Email:
                  </label>
                  <input
                    type='text'
                    id='email'
                    className='form-control col-md-8'
                    name='email'
                    placeholder='Enter Email Address'
                    required
                  />
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-warning'
                data-dismiss='modal'
              >
                Cancel
              </button>
              <button
                type='button'
                className='btn btn-success'
                data-dismiss='modal'
                name='notie-success'
                data-plugin='notie'
                data-type='success'
                data-text='Saved !'
                data-time='1.5'
              >
                Save{' '}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
);

const AdminLoginLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <AuthConsumer>
      {({ isAuth, Comp_Name, Comp_Logo_Dashboard }) => (
        <Route
          render={matchProps =>
            isAuth ? (
              <Redirect to='/dashboard' />
            ) : (
              <AdminLoginLayout
                Comp_Name={Comp_Name}
                Comp_Logo_Dashboard={Comp_Logo_Dashboard}
              >
                <Component {...matchProps} />
              </AdminLoginLayout>
            )
          }
          {...rest}
        />
      )}
    </AuthConsumer>
  );
};

export default lifecycle(methods)(AdminLoginLayoutRoute);
