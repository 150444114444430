import React, { Component } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import axios from 'axios';

import notie from 'notie';
import queryString from 'query-string';
import { replaceAgreementContent } from '../../helper/util';
import $ from 'jquery';
class SigneeSignature extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      Content: '',
      User_Agreement_ID: '',
      Name: '',
      User_Sign: ''
    };
    this.Signee_Sign = this.Signee_Sign.bind(this);
    this.viewAgreementData = this.viewAgreementData.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentWillMount = () => {};

  componentDidMount = () => {
    document.body.classList.remove('page-login-v2', 'layout-full', 'page-dark');
    document.body.classList.add('animsition');
    this.viewAgreementData();
  };

  viewAgreementData(_User_Agreement_ID) {
    try {
      const values = queryString.parse(this.props.location.search);
      let user_agreement_id = values.uai;
      const api = `${
        process.env.REACT_APP_BASE_URL
      }user_agreements/api/signeeAgreement?user_agreement_id=${user_agreement_id}
      }`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${values.token}`
          }
        })
        .then(res => {
          if (res.data.error) {
            notie.alert({
              type: 2, // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              position: 'top'
            });
          } else {
            if (res.data.success) {
              let _data = res.data.data[0];

              var objUser = {
                name: values.name,
                comp_name: _data.comp_name,
                address: _data.address,
                title: _data.title
              };
              this.setState({
                Content: replaceAgreementContent(
                  _data.agreement_content,
                  objUser
                ),
                User_Agreement_ID: _data.user_agreement_id,
                User_FullName: values.name
              });
            } else {
              notie.alert({
                type: 2, // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: res.data.message,
                position: 'top',
                stay: true
              });
              $('#divMain').hide();
            }
          }
        });
    } catch (error) {}
  }

  Signee_Sign() {
    try {
      const values = queryString.parse(this.props.location.search);
      let objUserAgreement = {
        signee_name: values.name,
        signee_email: values.email,
        signee_title: values.title,
        user_agreement_id: values.uai,
        user_sign: this.state.User_Sign
      };
      axios({
        method: 'post',
        url: `${
          process.env.REACT_APP_BASE_URL
        }user_agreements/api/signeeAgreement`,
        data: objUserAgreement,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${values.token}`
        }
      }).then(res => {
        if (res.data.success === false) {
          notie.alert({
            type: 2, // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            position: 'top',
            stay: true
          });
          $('#divMain').hide();
        } else {
          notie.alert({
            type: 'success',
            text: res.data.message,
            position: 'top'
          });
          $('#divMain').hide();
        }
      });
    } catch (error) {
      notie.alert({
        type: 2, // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        position: 'top'
      });
      $('#divMain').hide();
    }
  }

  renderSignaturePad() {
    return (
      <div style={{ border: '1px solid black' }}>
        <SignatureCanvas
          penColor='black'
          canvasProps={{ width: 300, height: 90, className: 'sigCanvas' }}
          ref={ref => (this.signaturePad = ref)}
        />

        <div className='modal-footer'>
          <button className='btn btn-warning' onClick={this.handleClear}>
            Clear Signature
          </button>
          <button className='btn btn-success' onClick={this.handleSave}>
            Sign the Agreement
          </button>
        </div>
      </div>
    );
  }

  handleClear() {
    this.signaturePad.clear();
    this.setState({ User_Sign: '' });
    $('#imgSig').attr('src', '');
    $('#imgSig').hide();
  }

  handleSave() {
    if (this.signaturePad.isEmpty()) {
      // eslint-disable-next-line no-alert
      notie.alert({
        type: 'info', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: 'Please Provide a Signature First',
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    } else {
      var data = this.signaturePad.toDataURL('image/png');
      this.setState({ User_Sign: data });
      $('#imgSig').show();
      $('#imgSig').attr('src', data);
    }
  }

  render() {
    return (
      <div style={{ height: 'auto', width: '100%' }}>
        <div id='divMain'>
          <div
            id='pData'
            style={{
              width: '100%',
              padding: 'none',
              backgroundColor: 'white',
              boxShadow: '5px 5px 5px black',
              margin: 'auto',
              overflow: 'auto'
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.Content
              }}
            />
          </div>

          <section className='section' style={{ width: '50%' }}>
            <div className='container'>
              {this.renderSignaturePad()}
              <br />
              <button className='btn btn-primary' onClick={this.Signee_Sign}>
                Save the Agreement
              </button>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default SigneeSignature;
