import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import axios from 'axios';
import $ from 'jquery';
import notie from 'notie';
import GeneratePDF from '../../components/GeneratePDF';
import { replaceAgreementContent } from '../../helper/util';
import './UserAgreements.css';

const { SearchBar } = Search;
class UserAgreements extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      User_ID: props.location.state.User_ID,
      User_Agreement_ID: '',
      User_FullName: '',
      User_Comp_Name: '',
      User_Title: '',
      Agreement_Title: '',
      SignatureFile: '',
      Decline_Reason: '',
      previewAgreement: '',
      agreements: [],
      archiveAgreements: [],
      agreementsVendorPush: [],
      selectedAgreements: [],
      loading: true,
      columns: [
        {
          dataField: 'agreement_PDF',
          text: 'View',
          sort: false,
          formatter: this.signagreementPreviewFormatter,
          headerAttrs: { width: 50 }
        },
        {
          dataField: 'preview',
          text: 'Sign Agreement',
          sort: false,
          formatter: this.agreementPreviewFormatter,
          headerAttrs: { width: 100 },
          attrs: { width: 100, className: '' }
        },
        {
          dataField: 'agreement_title',
          text: 'Agreement Title',
          sort: true,
          headerAttrs: { width: 200 },
          attrs: { width: 200, className: '' }
        },
        {
          dataField: 'signee_name',
          text: 'Signee Name',
          sort: true,
          headerAttrs: { width: 120 },
          attrs: { width: 120, className: '' }
        },
        {
          dataField: 'signee_title',
          text: 'Signee Title',
          sort: true,
          headerAttrs: { width: 100 },
          attrs: { width: 100, className: '' }
        },
        {
          dataField: 'agreement_status',
          text: 'Status',
          sort: true,
          formatter: this.agreementStatusPreviewFormatter,
          headerAttrs: { width: 90 }
        },
        // {
        //   dataField: 'ispushtovendor',
        //   text: 'Push to Vendor',
        //   sort: true,
        //   headerAttrs: { width: 100 },
        //   attrs: { width: 100, className: '' }
        // },
        {
          dataField: 'signature_date',
          text: 'Signature Date',
          sort: true,
          headerAttrs: { width: 100 }
        },
        {
          dataField: 'Archived',
          text: 'Archived',
          sort: false,
          formatter: this.archiveAgreementFormatter,
          headerAttrs: { width: 50, style: { textAlign: 'center' } }
        }
      ],
      columnsVendorPush: [
        {
          dataField: 'serial_number',
          text: 'Sr #',
          sort: true
        },
        {
          dataField: 'agreement_title',
          text: 'Agreement Title',
          sort: true
        },
        {
          dataField: 'signee_name',
          text: 'Signee Name',
          sort: true
        },
        {
          dataField: 'signee_title',
          text: 'Signee Title',
          sort: true
        },
        {
          dataField: 'signature_date',
          text: 'Signature Date',
          sort: true
        }
      ],
      columnsArchiveAgreement: [
        {
          dataField: 'serial_number',
          text: 'Sr #',
          sort: true,
          headerAttrs: { width: 50 }
        },
        {
          dataField: 'created_date',
          text: 'Archive Date',
          sort: true,
          headerAttrs: { width: 100 }
        },
        {
          dataField: 'agreement_PDF',
          text: 'View',
          sort: false,
          formatter: this.signagreementPreviewFormatter,
          headerAttrs: { width: 35, style: { textAlign: 'center' } }
        }
      ]
    };

    this.handleChange = this.handleChange.bind(this);
    this.agreementPreviewFormatter = this.agreementPreviewFormatter.bind(this);
    this.signagreementPreviewFormatter = this.signagreementPreviewFormatter.bind(
      this
    );
    this.agreementStatusPreviewFormatter = this.agreementStatusPreviewFormatter.bind(
      this
    );

    this.viewAgreementData = this.viewAgreementData.bind(this);
    this.showPDFGenerate = this.showPDFGenerate.bind(this);
    this.GetUserAgreements = this.GetUserAgreements.bind(this);
    this.GetVendorPushAgreements = this.GetVendorPushAgreements.bind(this);
    this.onRowSelect = this.onRowSelect.bind(this);
    this.onSelectAll = this.onSelectAll.bind(this);
    this.handlePushVendor = this.handlePushVendor.bind(this);
    this.GetUserAgreementArchive = this.GetUserAgreementArchive.bind(this);
    this.signagreementPreviewFormatter = this.signagreementPreviewFormatter.bind(
      this
    );
  }

  GetUserAgreements() {
    const api = `${process.env.REACT_APP_BASE_URL}user_agreements/api`;
    this.setState({
      loading: true
    });
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_user_token')}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = '/client_login');
        }
        if (res.data.success === false) {
          this.setState({
            loading: false
          });
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        this.setState({
          agreements: res.data,
          loading: false
        });
      });
  }

  GetVendorPushAgreements() {
    const api = `${process.env.REACT_APP_BASE_URL}user_agreements/api/PushToVendor`;
    this.setState({
      loading: true
    });
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_user_token')}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = '/client_login');
        }
        if (res.data.success === false) {
          this.setState({
            loading: false
          });
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        this.setState({ selectedAgreements: [] });
        this.setState({
          agreementsVendorPush: res.data,
          loading: false
        });
        $('#push_to_vendor').show();
      });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  agreementPreviewFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        {row.issigned === 'No' && row.issigneesigned === 'No' ? (
          <span
            className='fa fa-pen'
            onClick={() => this.viewAgreementData(row.user_agreement_id)}
          />
        ) : (
          ''
        )}
      </div>
    );
  };

  signagreementPreviewFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        {row.issigned === 'Yes' || row.issigneesigned === 'Yes' ? (
          <a
            className='fa fa-eye'
            style={{ textDecoration: 'none' }}
            target='_blank'
            href={process.env.REACT_APP_BASE_URL + row.agreement_PDF}
          />
        ) : (
          ''
        )}
        {row.agreement_status === 'Rejected' ? (
          <a
            className='fa fa-eye'
            style={{ color: 'red', textDecoration: 'none' }}
            href='javascript:void()'
            onClick={() => {
              this.setState({ Decline_Reason: row.agreement_decline_reason });
              $('#preview_decline_reason').show();
            }}
          />
        ) : (
          ''
        )}
      </div>
    );
  };

  agreementStatusPreviewFormatter = (cell, row) => {
    return (
      <div>
        {row.agreement_status === 'Pending' ||
        row.agreement_status === 'Approved' ? (
          row.agreement_status
        ) : (
          <div>{row.agreement_status}</div>
        )}
      </div>
    );
  };

  viewAgreementData(_User_Agreement_ID) {
    try {
      this.setState({
        loading: true
      });
      const api = `${process.env.REACT_APP_BASE_URL}user_agreements/api/getAgreementUser?user_agreement_id=${_User_Agreement_ID}`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_user_token')}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/client_login');
          }
          if (res.data.success === false) {
             this.setState({
               loading: false
             });
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          this.setState({
            previewAgreement: ''
          });
          let objUser = {
            client_title: res.data[0].client_title,
            client_name: res.data[0].client_name,
            client_comp_name: res.data[0].client_comp_name,
            client_email: res.data[0].client_email,
            client_address: res.data[0].client_address,
            client_phone_no: res.data[0].client_phone_no,
            client_fax: res.data[0].client_fax,
            vendor_signor_name: res.data[0].vendor_signor_name,
            vendor_signor_comp_name: res.data[0].vendor_signor_comp_name,
            vendor_signor_title: res.data[0].vendor_signor_title,
            vendor_signor_address: res.data[0].vendor_signor_address,
            vendor_signor_email: res.data[0].vendor_signor_email,
            vendor_signor_phone_no: res.data[0].vendor_signor_phone_no,
            vendor_signor_fax: res.data[0].vendor_signor_fax,
            user_comp_name: res.data[0].user_comp_name,
            user_comp_name_short: res.data[0].user_comp_name_short,
            user_address: res.data[0].user_address
          };
          this.setState({
            previewAgreement: replaceAgreementContent(
              res.data[0].agreement_content,
              objUser
            ),
            User_Agreement_ID: res.data[0].user_agreement_id,
            User_FullName: res.data[0].client_name,
            User_Comp_Name: res.data[0].client_comp_name,
            User_Title: res.data[0].client_title,
            SignatureFile: res.data[0].user_signature_file
          });
          this.setState({
            loading: false
          });
          $('#preview_agreement').show();
        });
    } catch (error) {
       this.setState({
         loading: false
       });
      return notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: true, // optional, default = false
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  showPDFGenerate() {
    if (this.state.previewAgreement !== '') {
      return (
        <GeneratePDF
          Content={this.state.previewAgreement}
          SignatureFile={this.state.SignatureFile}
          User_Agreement_ID={this.state.User_Agreement_ID}
          User_FullName={this.state.User_FullName}
          User_Comp_Name={this.state.User_Comp_Name}
          User_Title={this.state.User_Title}
          Callback={this.GetUserAgreements}
          ModalID='preview_agreement'
        />
      );
    }
  }

  onRowSelect(row, isSelected, e) {
    let newArray = isSelected // if isChecked is true
      ? [...this.state.selectedAgreements, row.user_agreement_id] // add element
      : [...this.state.selectedAgreements].filter(
          e => e !== row.user_agreement_id
        ); // remove the elements that are equal to newStatus
    this.setState({ selectedAgreements: newArray });
    //console.log('newArray', newArray);
  }

  onSelectAll(isSelected, rows) {
    let newArray = [];
    if (isSelected) {
      for (let i = 0; i < rows.length; i++) {
        newArray.push(rows[i].user_agreement_id);
      }
    }

    this.setState({ selectedAgreements: newArray });
    //console.log('newArray', newArray);
  }

  handlePushVendor(e) {
    e.preventDefault();
    try {
      if (this.state.selectedAgreements.length === 0) {
        notie.alert({
          type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: 'Please Select Agreement to Send to Vendor',
          stay: false, // optional, default = false
          time: 3, // optional, default = 3, minimum = 1,
          position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
        });
        return false;
      }

      let objAgreement = {
        agreements: this.state.selectedAgreements
      };
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}user_agreements/api/PushToVendor`,
        data: objAgreement,

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('tcc_user_token')}`
        }
      })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/client_login');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }

          if (res.data.success === true) {
            notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            $('#push_to_vendor').hide();

            this.GetUserAgreements();

            this.setState({
              selectedAgreements: []
            });
          } else {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  archiveAgreementFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <span
          className='fa fa-archive'
          onClick={() => {
            this.GetUserAgreementArchive(row.agreement_id);
            this.setState({ Agreement_Title: row.agreement_title });
          }}
        />
      </div>
    );
  };

  GetUserAgreementArchive(_Agreement_ID) {
    try {
      this.setState({ archiveAgreements: [] });
      const api = `${process.env.REACT_APP_BASE_URL}user_agreement_archive/api/GetArchiveAgreementByAgreementID?agreement_id=${_Agreement_ID}`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_user_token')}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/client_login');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          this.setState({
            archiveAgreements: res.data,
            loading: false
          });

          $('#archive_agreements').show();
        });
    } catch (error) {
       this.setState({
         loading: false
       });
      return notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: true, // optional, default = false
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  signagreementPreviewFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        {row.issigned === 'Yes' || row.issigneesigned === 'Yes' ? (
          <a
            className='fa fa-eye'
            target='_blank'
            href={process.env.REACT_APP_BASE_URL + row.agreement_PDF}
          />
        ) : (
          ''
        )}
      </div>
    );
  };
  componentWillMount = () => {
    //console.log('UserAgreements will mount');
  };

  componentDidMount = () => {
    this.GetUserAgreements();
    $('.active_left_menu').removeClass('active_left_menu');
    $('#lnkagreement').addClass('active_left_menu');
  };

  arrayRemove(arr, value) {
    return arr.filter(function(ele) {
      return ele !== value;
    });
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    const { loading } = this.state;

    const selectRowProp = {
      mode: 'checkbox',
      clickToSelect: true, // enable click to select
      columnWidth: '20px',
      bgColor: 'lightgray',
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll
    };
    return (
      <div>
        <div className='page-header'>
          <h1 className='page-title'>AGREEMENTS</h1>
        </div>

        <div className='page-content'>
          <div className='panel-body'>
            <div className='row'>
              <div className='col-md-12 '>
                <div className='row'>
                  <div
                    className='col-md-9 col-sm-4 '
                    style={{ paddingLeft: 0 }}
                  >
                    <button
                      style={{ display: 'none' }}
                      type='button'
                      className='btn btn-warning'
                      onClick={() => this.GetVendorPushAgreements()}
                    >
                      Push to Vendor
                    </button>
                    <br />
                    <br />
                  </div>
                </div>
              </div>
              <div className='col-md-12 card'>
                <div className='container-fluid' style={{ padding: 0 }}>
                  <div className='col-lg-12' style={{ padding: 0 }}>
                    <div className='example-wrap'>
                      <div className='example'>
                        {loading ? (
                          <div style={{ textAlign: 'center' }}>
                            <img
                              src='../assets/images/tcc_loading.gif'
                              style={{ width: '128px' }}
                            />
                          </div>
                        ) : (
                          <div>
                            <ToolkitProvider
                              keyField='user_agreement_id'
                              data={this.state.agreements}
                              columns={this.state.columns}
                              search
                              columnToggle
                            >
                              {props => (
                                <div>
                                  {/*<ToggleList {...props.columnToggleProps} />
                              <hr />**/}

                                  <SearchBar
                                    {...props.searchProps}
                                    className='custome-search-field'
                                    style={{ color: '#000' }}
                                    delay={1000}
                                    placeholder='Search ...'
                                  />
                                  <hr />
                                  <BootstrapTable
                                    wrapperClasses='table-responsive'
                                    {...props.baseProps}
                                    filter={filterFactory()}
                                    pagination={paginationFactory({
                                      sizePerPage: 10,
                                      nextPageText: 'Next',
                                      prePageText: 'Previous',
                                      sizePerPageList: [
                                        {
                                          text: '10 Per Page',
                                          value: 10
                                        },
                                        {
                                          text: '20 Per Page',
                                          value: 20
                                        },
                                        {
                                          text: 'All Record',
                                          value: 100
                                        }
                                      ]
                                    })}
                                    striped
                                    hover
                                  />
                                </div>
                              )}
                            </ToolkitProvider>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/** PREWVIEW AGREEMENT*/}
            <div
              className='modal preview_agreement myModal'
              id='preview_agreement'
              style={{ display: 'none', overflow: 'auto' }}
            >
              <div className='modal-dialog modal-dialog-centered modal-lg'>
                {this.showPDFGenerate()}
              </div>
            </div>
          </div>
        </div>
        <div
          className='modal myModal PushtoVendor '
          id='push_to_vendor'
          style={{ display: 'none', paddingLeft: '15px', overflow: 'auto' }}
        >
          <div className='modal-dialog modal-dialog-centered modal-lg'>
            <form
              id='frmVendorPush'
              onSubmit={this.handlePushVendor}
              style={{ width: '100%' }}
            >
              <div className='modal-content'>
                <div className='modal-header'>
                  <h4 className='modal-title'>Push to Vendor</h4>
                  <button
                    type='button'
                    className='close'
                    onClick={() => $('#push_to_vendor').hide()}
                  >
                    ×
                  </button>
                </div>

                <div className='modal-body'>
                  <div className='container-fluid'>
                    <div className='col-lg-12'>
                      <div className='example-wrap'>
                        <div className='example'>
                          {loading ? (
                            <div style={{ textAlign: 'center' }}>
                              <img
                                src='../assets/images/tcc_loading.gif'
                                style={{ width: '128px' }}
                                alt='...'
                              />
                            </div>
                          ) : (
                            <div>
                              <ToolkitProvider
                                keyField='user_agreement_id'
                                data={this.state.agreementsVendorPush}
                                columns={this.state.columnsVendorPush}
                                search
                                columnToggle
                              >
                                {props => (
                                  <div>
                                    {/*<ToggleList {...props.columnToggleProps} />
                              <hr />**/}

                                    <SearchBar
                                      {...props.searchProps}
                                      className='custome-search-field'
                                      style={{ color: '#000' }}
                                      delay={1000}
                                      placeholder='Search ...'
                                    />
                                    <hr />
                                    <BootstrapTable
                                      selectRow={selectRowProp}
                                      wrapperClasses='table-responsive'
                                      {...props.baseProps}
                                      filter={filterFactory()}
                                      pagination={paginationFactory({
                                        sizePerPage: 5,
                                        nextPageText: 'Next',
                                        prePageText: 'Previous',
                                        sizePerPageList: [
                                          {
                                            text: '5 Per Page',
                                            value: 5
                                          },
                                          {
                                            text: '10 Per Page',
                                            value: 10
                                          },
                                          {
                                            text: '20 Per Page',
                                            value: 20
                                          },
                                          {
                                            text: 'All Record',
                                            value: 100
                                          }
                                        ]
                                      })}
                                      striped
                                      hover
                                    />
                                  </div>
                                )}
                              </ToolkitProvider>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-warning'
                    onClick={() => $('#push_to_vendor').hide()}
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    id='btnSendToVendor'
                    className='btn btn-success'
                  >
                    Send{' '}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div
          className='modal myModal'
          id='preview_decline_reason'
          style={{ display: 'none', paddingLeft: '15px', overflow: 'auto' }}
        >
          <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h4 className='modal-title'>Agreement Rejection Reason</h4>
                <button
                  type='button'
                  className='close'
                  onClick={() => $('#preview_decline_reason').hide()}
                >
                  ×
                </button>
              </div>

              <div className='modal-body'>
                <div className='container-fluid'>
                  <div className='form-group row'>
                    <h5 className='col-md-12'>{this.state.Decline_Reason}</h5>
                  </div>
                </div>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-warning'
                  onClick={() => $('#preview_decline_reason').hide()}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        {/** SHOW ARCHIVED AGREEMENT */}

        <div
          className='modal myModal ProductFiles '
          id='archive_agreements'
          style={{ display: 'none', paddingLeft: '15px', overflow: 'auto' }}
        >
          <form id='frmArchivedAgreement'>
            <div className='modal-dialog modal-dialog-centered  modal-lg'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h4 className='modal-title'>
                    {this.state.Agreement_Title} Archived Agreements
                  </h4>
                  <button
                    type='button'
                    className='close'
                    onClick={() => $('#archive_agreements').hide()}
                  >
                    ×
                  </button>
                </div>

                <div className='modal-body'>
                  <div className='container-fluid'>
                    <div className='col-lg-12'>
                      <div className='example-wrap'>
                        <div className='example'>
                          {loading ? (
                            <div style={{ textAlign: 'center' }}>
                              <img
                                src='../assets/images/tcc_loading.gif'
                                style={{ width: '128px' }}
                                alt='...'
                              />
                            </div>
                          ) : (
                            <div>
                              <ToolkitProvider
                                keyField='serial_number'
                                data={this.state.archiveAgreements}
                                columns={this.state.columnsArchiveAgreement}
                                search
                                columnToggle
                              >
                                {props => (
                                  <div>
                                    {/*<ToggleList {...props.columnToggleProps} />
                              <hr />**/}

                                    <SearchBar
                                      {...props.searchProps}
                                      className='custome-search-field'
                                      style={{ color: '#000' }}
                                      delay={1000}
                                      placeholder='Search ...'
                                    />
                                    <hr />
                                    <BootstrapTable
                                      wrapperClasses='table-responsive'
                                      {...props.baseProps}
                                      filter={filterFactory()}
                                      pagination={paginationFactory({
                                        sizePerPage: 10,
                                        nextPageText: 'Next',
                                        prePageText: 'Previous',
                                        sizePerPageList: [
                                          {
                                            text: '10 Per Page',
                                            value: 10
                                          },
                                          {
                                            text: '20 Per Page',
                                            value: 20
                                          },
                                          {
                                            text: 'All Record',
                                            value: 100
                                          }
                                        ]
                                      })}
                                      striped
                                      hover
                                    />
                                  </div>
                                )}
                              </ToolkitProvider>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default UserAgreements;
