import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import axios from 'axios';
import notie from 'notie';
import $ from 'jquery';
import InputMask from 'react-input-mask';
import './ManageVendorContact.css';
const { SearchBar } = Search;

class ManageVendorContact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      addVendorContactCompName: '',
      addVendorContactName: '',
      addVendorContactTitle: '',
      addVendorContactEmail: '',
      addVendorContactPhoneNo: '',
      addVendorContactFax: '',
      addVendorContactAddress: '',
      addisActive: 0,
      editVendorContactCompName: '',
      editVendorContactName: '',
      editVendorContactTitle: '',
      editVendorContactEmail: '',
      editVendorContactPhoneNo: '',
      editVendorContactFax: '',
      editVendorContactAddress: '',
      editisActive: 0,
      Vendor_Contact_ID: '',
      Vendor_Contacts: [],
      loading: true,
      columns: [
        {
          dataField: 'serial_number',
          text: 'Sr #',
          sort: true
        },
        {
          dataField: 'vendor_contact_name',
          text: 'Vendor Name',
          sort: true
        },
        {
          dataField: 'vendor_contact_email',
          text: 'Vendor Email',
          sort: true
        },
        {
          dataField: 'vendor_contact_title',
          text: 'Vendor Title',
          sort: true
        },

        {
          dataField: 'isactive',
          text: 'Active',
          sort: true
        },
        {
          dataField: 'edit',
          text: 'Edit',
          sort: false,
          formatter: this.vendorFormatter,
          headerAttrs: { width: 75, style: { textAlign: 'center' } },
          attrs: { width: 75, style: { textAlign: 'center' } }
        },
        {
          dataField: 'delete',
          text: 'Delete',
          sort: false,
          formatter: this.vendorDeleteFormatter,
          headerAttrs: { width: 75, style: { textAlign: 'center' } },
          attrs: { width: 75, style: { textAlign: 'center' } }
        }
      ]
    };

    this.handleAdd = this.handleAdd.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getVendorContactData = this.getVendorContactData.bind(this);
  }

  GetAllVendorContact() {
    const api = `${process.env.REACT_APP_BASE_URL}vendor_contact/api`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = '/tcaccess');
        }
        if (res.data.success === false) {
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        this.setState({
          Vendor_Contacts: res.data,
          loading: false
        });
      });
  }

  handleChange(event) {
    const target = event.target;
    const value =
      target.type === 'checkbox'
        ? target.checked === true
          ? 1
          : 0
        : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleAdd(e) {
    e.preventDefault();

    try {
      let objVendorContact = {
        vendor_contact_comp_name: this.state.addVendorContactCompName,
        vendor_contact_name: this.state.addVendorContactName,
        vendor_contact_title: this.state.addVendorContactTitle,
        vendor_contact_email: this.state.addVendorContactEmail,
        vendor_contact_phone_no: this.state.addVendorContactPhoneNo,
        vendor_contact_fax: this.state.addVendorContactFax,
        vendor_contact_address: this.state.addVendorContactAddress,
        isactive: this.state.addisActive
      };

      //console.log('this.state.addSignCheck', this.state.addSignCheck);

      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}vendor_contact/api/`,
        data: objVendorContact,

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
        }
      })
        .then(res => {
          if (res.data.success === false) {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            return false;
          }
          let _data = res.data;
          if (_data.affectedRows === 1) {
            notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: 'Vendor Contact Added Successfully',
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            $('#add_vendor_contact').hide();
            //$('.modal-backdrop').remove();
            this.GetAllVendorContact();

            this.setState({
              addVendorContactCompName: '',
              addVendorContactName: '',
              addVendorContactTitle: '',
              addVendorContactEmail: '',
              addVendorContactPhoneNo: '',
              addVendorContactFax: '',
              addVendorContactAddress: '',
              addisActive: 1
            });
          } else {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: _data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  vendorFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <span
          className='fa fa-edit'
          onClick={() => this.getVendorContactData(row.vendor_contact_id)}
        />
      </div>
    );
  };

  vendorDeleteFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <span
          className='fa fa-trash'
          onClick={() =>
            notie.confirm({
              text: 'Are you sure to delete this record ?',
              submitText: 'Yes', // optional, default = 'Yes'
              cancelText: 'No', // optional, default = 'Cancel'
              position: 'top', // optional, default = 'top', enum: ['top', 'bottom']
              submitCallback: () =>
                this.deleteVendorContactData(row.vendor_contact_id) // optional
            })
          }
        />
      </div>
    );
  };

  handleEdit(e) {
    e.preventDefault();
    try {
      let objUdpateVendorContact = {
        vendor_contact_id: this.state.Vendor_Contact_ID,

        vendor_contact_comp_name: this.state.editVendorContactCompName,
        vendor_contact_name: this.state.editVendorContactName,
        vendor_contact_title: this.state.editVendorContactTitle,

        vendor_contact_phone_no: this.state.editVendorContactPhoneNo,
        vendor_contact_fax: this.state.editVendorContactFax,
        vendor_contact_email: this.state.editVendorContactEmail,

        vendor_contact_address: this.state.editVendorContactAddress,
        isactive: this.state.editisActive
      };

      axios({
        method: 'put',
        url: `${process.env.REACT_APP_BASE_URL}vendor_contact/api/`,
        data: objUdpateVendorContact,

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
        }
      })
        .then(res => {
          if (res.data.success === false) {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            return false;
          }
          let _data = res.data;
          if (_data.affectedRows === 1) {
            notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: 'Vendor Contact Updated Successfully',
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            $('#edit_vendor_contact').hide();

            this.GetAllVendorContact();

            this.setState({
              editVendorContactCompName: '',
              editVendorContactName: '',
              editVendorContactTitle: '',
              editVendorContactEmail: '',
              editVendorContactPhoneNo: '',
              editVendorContactFax: '',
              editVendorContactAddress: '',
              editVendorContactSignature: '',
              editVendorContactSignatureFile: null,
              editSignCheck: false,
              editisActive: 0
            });
          } else {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: _data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  getVendorContactData(_Vendor_Contact_ID) {
    try {
      this.setState({
        editVendorContactCompName: '',
        editVendorContactName: '',
        editVendorContactTitle: '',
        editVendorContactEmail: '',
        editVendorContactPhoneNo: '',
        editVendorContactFax: '',
        editVendorContactAddress: '',
        editVendorContactSignature: '',
        editVendorContactSignatureFile: null,
        editSignCheck: false,
        editisActive: 0
      });

      this.setState({ Vendor_Contact_ID: _Vendor_Contact_ID });
      const api = `${process.env.REACT_APP_BASE_URL}vendor_contact/api/getVendorContact?vendor_contact_id=${_Vendor_Contact_ID}`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/tcaccess');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          $('#edit_vendor_contact').show();
          this.setState({
            editVendorContactCompName: res.data[0].vendor_contact_comp_name,
            editVendorContactName: res.data[0].vendor_contact_name,
            editVendorContactTitle: res.data[0].vendor_contact_title,

            editVendorContactEmail: res.data[0].vendor_contact_email,
            editVendorContactPhoneNo: res.data[0].vendor_contact_phone_no,
            editVendorContactFax: res.data[0].vendor_contact_fax,
            editVendorContactAddress: res.data[0].vendor_contact_address,

            editisActive: res.data[0].isactive
          });
        });
    } catch (error) {}
  }

  deleteVendorContactData(_Vendor_Contact_ID) {
    try {
      const api = `${process.env.REACT_APP_BASE_URL}vendor_contact/api/?vendor_contact_id=${_Vendor_Contact_ID}`;
      axios
        .delete(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/tcaccess');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          let _data = res.data;
          if (_data.affectedRows === 1) {
            notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: 'Vendor Contact Deleted Successfully',
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            this.GetAllVendorContact();
          } else {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: 'Some problem in deleting record.',
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        });
    } catch (error) {}
  }

  componentWillMount = () => {};

  componentDidMount = () => {
    this.GetAllVendorContact();
  };

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    const { loading } = this.state;
    return (
      <div>
        <div className='row'>
          <div className='col-md-12 card '>
            <div className='row'>
              <div className='col-md-9'>
                <h4 className='ml-3' id='heading'>
                  Manage Vendor Contact
                </h4>
              </div>
              <div className='col-md-3 col-sm-5 text-right text-sm-left '>
                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={() => $('#add_vendor_contact').show()}
                >
                  Add Vendor Contact
                </button>
              </div>
            </div>
            <hr />
            <div className='container-fluid'>
              <div className='col-lg-12'>
                <div className='example-wrap'>
                  <div className='example'>
                    {loading ? (
                      <div style={{ textAlign: 'center' }}>
                        <img
                          src='assets/images/tcc_loading.gif'
                          style={{ width: '128px' }}
                        />
                      </div>
                    ) : (
                      <div>
                        <ToolkitProvider
                          keyField='vendor_contact_id'
                          data={this.state.Vendor_Contacts}
                          columns={this.state.columns}
                          search
                        >
                          {props => (
                            <div>
                              <SearchBar
                                {...props.searchProps}
                                className='custome-search-field'
                                style={{ color: '#000' }}
                                delay={1000}
                                placeholder='Search ...'
                              />
                              <hr />
                              <BootstrapTable
                                {...props.baseProps}
                                filter={filterFactory()}
                                pagination={paginationFactory()}
                                striped
                                hover
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={this.handleAdd}>
          <div
            className='modal myModal AddVendorContact'
            id='add_vendor_contact'
            style={{
              display: 'none',
              paddingLeft: '15px',
              overflow: 'auto'
            }}
          >
            <div className='modal-dialog modal-dialog-centered'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h4 className='modal-title'>Add Vendor Contact</h4>
                  <button
                    type='button'
                    className='close'
                    onClick={() => $('#add_vendor_contact').hide()}
                  >
                    ×
                  </button>
                </div>

                <div className='modal-body'>
                  <div className='container-fluid'>
                    <div className='form-group row'>
                      <label
                        htmlFor='addVendorContactCompName'
                        className='col-md-4'
                      >
                        Vendor Company:
                      </label>
                      <input
                        type='text'
                        id='addVendorContactCompName'
                        className='form-control col-md-6'
                        name='addVendorContactCompName'
                        value={this.state.addVendorContactCompName}
                        onChange={this.handleChange}
                        maxLength='150'
                        required
                      />
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='addVendorContactName'
                        className='col-md-4'
                      >
                        Vendor Name:
                      </label>
                      <input
                        type='text'
                        id='addVendorContactName'
                        className='form-control col-md-6'
                        name='addVendorContactName'
                        value={this.state.addVendorContactName}
                        onChange={this.handleChange}
                        maxLength='150'
                        required
                      />
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='addVendorContactTitle'
                        className='col-md-4'
                      >
                        Vendor Title:
                      </label>
                      <input
                        type='text'
                        id='addVendorContactTitle'
                        className='form-control col-md-6'
                        name='addVendorContactTitle'
                        value={this.state.addVendorContactTitle}
                        onChange={this.handleChange}
                        required
                        maxLength='100'
                      />
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='addVendorContactEmail'
                        className='col-md-4'
                      >
                        Email Address:
                      </label>
                      <input
                        type='email'
                        id='addVendorContactEmail'
                        className='form-control col-md-6'
                        name='addVendorContactEmail'
                        value={this.state.addVendorContactEmail}
                        onChange={this.handleChange}
                        maxLength='200'
                        required
                      />
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='addVendorContactPhoneNo'
                        className='col-md-4'
                      >
                        Contact Number:
                      </label>

                      <InputMask
                        mask='99999999999'
                        pattern='.{10,}'
                        maskChar={null}
                        id='addVendorContactPhoneNo'
                        className='form-control col-md-6'
                        name='addVendorContactPhoneNo'
                        placeholder='Contact Number 2134567890'
                        title='Contact Number 2134567890'
                        value={this.state.addVendorContactPhoneNo}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className='form-group row'>
                      <label htmlFor='addVendorContactFax' className='col-md-4'>
                        Fax Number:
                      </label>

                      <InputMask
                        mask='99999999999'
                        pattern='.{10,}'
                        maskChar={null}
                        id='addVendorContactFax'
                        className='form-control col-md-6'
                        name='addVendorContactFax'
                        placeholder='Fax Number 2134567890'
                        title='Fax Number 2134567890'
                        value={this.state.addVendorContactFax}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='addVendorContactAddress'
                        className='col-md-4'
                      >
                        Address :
                      </label>
                      <textarea
                        id='addVendorContactAddress'
                        className='form-control col-md-6'
                        name='addVendorContactAddress'
                        placeholder='Address'
                        value={this.state.addVendorContactAddress}
                        onChange={this.handleChange}
                        maxLength='250'
                        required
                      />
                    </div>

                    <div className='form-group row'>
                      <label htmlFor='addisActive' className='col-md-4'>
                        Active:
                      </label>
                      <div className='isnotified:0,'>
                        <input
                          type='checkbox'
                          id='addisActive'
                          name='addisActive'
                          checked={this.state.addisActive}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-danger'
                    onClick={() => $('#add_vendor_contact').hide()}
                  >
                    Cancel
                  </button>
                  <button type='submit' className='btn btn-success'>
                    Save{' '}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        {/** EDIT FORM */}
        <form onSubmit={this.handleEdit}>
          <div
            className='modal myModal edit_vendor_contact'
            id='edit_vendor_contact'
            style={{
              display: 'none',
              paddingLeft: '15px',
              overflow: 'auto'
            }}
          >
            <div className='modal-dialog modal-dialog-centered'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h4 className='modal-title'>Edit Vendor Contact</h4>
                  <button
                    type='button'
                    className='close'
                    onClick={() => $('#edit_vendor_contact').hide()}
                  >
                    ×
                  </button>
                </div>

                <div className='modal-body'>
                  <div className='container-fluid'>
                    <div className='form-group row'>
                      <label
                        htmlFor='editVendorContactCompName'
                        className='col-md-4'
                      >
                        Vendor Company:
                      </label>
                      <input
                        type='text'
                        id='editVendorContactCompName'
                        className='form-control col-md-6'
                        name='editVendorContactCompName'
                        value={this.state.editVendorContactCompName}
                        onChange={this.handleChange}
                        required
                        maxLength='150'
                      />
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='editVendorContactName'
                        className='col-md-4'
                      >
                        Vendor Name:
                      </label>
                      <input
                        type='text'
                        id='editVendorContactName'
                        className='form-control col-md-6'
                        name='editVendorContactName'
                        value={this.state.editVendorContactName}
                        onChange={this.handleChange}
                        required
                        maxLength='150'
                      />
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='editVendorContactTitle'
                        className='col-md-4'
                      >
                        Vendor Title:
                      </label>
                      <input
                        type='text'
                        id='editVendorContactTitle'
                        className='form-control col-md-6'
                        name='editVendorContactTitle'
                        value={this.state.editVendorContactTitle}
                        onChange={this.handleChange}
                        required
                        maxLength='100'
                      />
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='editVendorContactEmail'
                        className='col-md-4'
                      >
                        Email Address:
                      </label>
                      <input
                        type='email'
                        id='editVendorContactEmail'
                        className='form-control col-md-6'
                        name='editVendorContactEmail'
                        value={this.state.editVendorContactEmail}
                        onChange={this.handleChange}
                        required
                        maxLength='250'
                      />
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='editVendorContactPhoneNo'
                        className='col-md-4'
                      >
                        Contact Number:
                      </label>
                      <InputMask
                        mask='99999999999'
                        pattern='.{10,}'
                        maskChar={null}
                        id='editVendorContactPhoneNo'
                        className='form-control col-md-6'
                        name='editVendorContactPhoneNo'
                        placeholder='Contact Number 2134567890'
                        title='Contact Number 2134567890'
                        value={this.state.editVendorContactPhoneNo}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='editVendorContactFax'
                        className='col-md-4'
                      >
                        Fax Number:
                      </label>

                      <InputMask
                        mask='99999999999'
                        pattern='.{10,}'
                        maskChar={null}
                        id='editVendorContactFax'
                        className='form-control col-md-6'
                        name='editVendorContactFax'
                        placeholder='Fax Number 12134567890'
                        title='Fax Number 12134567890'
                        value={this.state.editVendorContactFax}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='editVendorContactAddress'
                        className='col-md-4'
                      >
                        Address :
                      </label>
                      <textarea
                        id='editVendorContactAddress'
                        className='form-control col-md-6'
                        name='editVendorContactAddress'
                        placeholder='Address'
                        value={this.state.editVendorContactAddress}
                        onChange={this.handleChange}
                        required
                        maxLength='250'
                      />
                    </div>

                    <div className='form-group row'>
                      <label htmlFor='editisActive' className='col-md-4'>
                        Active:
                      </label>
                      <div className='isnotified:0,'>
                        <input
                          type='checkbox'
                          id='editisActive'
                          name='editisActive'
                          checked={this.state.editisActive}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-danger'
                    onClick={() => $('#edit_vendor_contact').hide()}
                  >
                    Cancel
                  </button>
                  <button type='submit' className='btn btn-success'>
                    Save{' '}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default ManageVendorContact;
