import React, { Component } from 'react';
import { AuthConsumer } from '../../context/AuthContext';
import queryString from 'query-string';
import $ from 'jquery';
class VerifyUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false
    };
  }

  componentWillMount = () => {};

  componentDidMount = () => {
    $('.page-login-main').css('visibility', 'hidden');
    $('#target').click();
  };

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    const values = queryString.parse(this.props.location.search);
    return (
      <AuthConsumer>
        {({ Verify_User, Comp_Name }) => (
          <div>
            <button id='target' onClick={() => Verify_User(values.token)} />
          </div>
        )}
      </AuthConsumer>
    );
  }
}

export default VerifyUser;
