import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import axios from 'axios';
import notie from 'notie';
import $ from 'jquery';
//import InputMask from 'react-input-mask';
import './ManageSettings.css';
const { SearchBar } = Search;

class ManageSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,

      editSettingCompName: '',
      editSettingSupportEmail: '',
      editSettingClientMarketing: '',
      editSettingDashboardContent: '',
      editSettingMetaTags: '',
      editSettingSmallLogo: '',
      editSettingLargeLogo: '',
      editSettingCompPhoneNo: '',
      editSettingCompAddress: '',
      Setting_ID: '',
      Settings: [],
      loading: true,
      columns: [
        {
          dataField: 'setting_id',
          text: 'Sr#',
          attrs: { width: 50, style: { verticalAlign: 'middle' } }
        },
        {
          dataField: 'setting_comp_name',
          text: 'Company Name',
          sort: true,

          attrs: { width: 150, style: { verticalAlign: 'middle' } }
        },
        {
          dataField: 'setting_support_email',
          text: 'Support Email',
          sort: true,
          attrs: { width: 150, style: { verticalAlign: 'middle' } }
        },
        {
          dataField: 'setting_comp_small_logo',
          text: 'Small Logo',
          sort: false,
          formatter: this.settingViewSmallLogoFormatter,
          headerAttrs: { style: { textAlign: 'center' } },
          attrs: { width: 150, className: 'EditRow' }
        },

        {
          dataField: 'setting_comp_large_logo',
          text: 'Large Logo',
          sort: false,
          formatter: this.settingViewLargeLogoFormatter,
          headerAttrs: { style: { textAlign: 'center' } },
          attrs: { width: 150, className: 'EditRow' }
        },
        {
          dataField: 'edit',
          text: '',
          sort: false,
          formatter: this.settingViewFormatter,
          headerAttrs: { width: 150 },
          attrs: { width: 150, style: { verticalAlign: 'middle' } }
        }
      ]
    };

    this.handleEdit = this.handleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getSettingData = this.getSettingData.bind(this);
    this.onChangeeditUploadSmallLogo = this.onChangeeditUploadSmallLogo.bind(
      this
    );
    this.onChangeeditUploadLargeLogo = this.onChangeeditUploadLargeLogo.bind(
      this
    );
  }

  GetAllSetting() {
    const api = `${process.env.REACT_APP_BASE_URL}settings/api`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = '/tcaccess');
        }
        if (res.data.success === false) {
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }

        $('.navbar-brand-logo').attr(
          'src',
          res.data[0].setting_comp_small_logo
        );
        $('#spComp_Name').text(res.data[0].setting_comp_name);
        this.setState({
          Settings: res.data,
          loading: false
        });
      });
  }

  handleeditUpload = () => {
    this.setState({ editSignCheck: !this.state.editSignCheck });
    if (this.state.editSignCheck) {
      $('#diveditUploadSign').show();
      $('#diveditSign').hide();
    } else {
      $('#diveditUploadSign').hide();
      $('#diveditSign').show();
    }
  };

  handleChange(event) {
    const target = event.target;
    const value =
      target.type === 'checkbox'
        ? target.checked === true
          ? 1
          : 0
        : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  settingViewFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <span
          className='fa fa-edit'
          onClick={() => this.getSettingData(row.setting_id)}
        />
      </div>
    );
  };

  settingViewSmallLogoFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal',
          backgroundColor: 'whitesmoke'
        }}
      >
        <img
          alt='...'
          src={row.setting_comp_small_logo}
          style={{ height: '40px' }}
        />
      </div>
    );
  };

  settingViewLargeLogoFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal',
          backgroundColor: 'whitesmoke'
        }}
      >
        <img
          alt='...'
          src={row.setting_comp_large_logo}
          style={{ height: '40px' }}
        />
      </div>
    );
  };

  handleEdit(e) {
    e.preventDefault();
    try {
      var body = new FormData();
      body.append('setting_id', this.state.Setting_ID);
      body.append('setting_comp_name', this.state.editSettingCompName);
      body.append('setting_comp_phone_no', this.state.editSettingCompPhoneNo);
      body.append('setting_comp_address', this.state.editSettingCompAddress);
      body.append('setting_support_email', this.state.editSettingSupportEmail);
      body.append(
        'setting_client_marketing_content',
        this.state.editSettingClientMarketing
      );
      body.append(
        'setting_dashboard_content',
        this.state.editSettingDashboardContent
      );
      body.append('setting_meta_tags', this.state.editSettingMetaTags);
      body.append('setting_comp_large_logo', this.state.editSettingLargeLogo);
      body.append('setting_comp_small_logo', this.state.editSettingSmallLogo);

      axios({
        method: 'put',
        url: `${process.env.REACT_APP_BASE_URL}settings/api/`,
        data: body,

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
        }
      })
        .then(res => {
          if (res.data.success === false) {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            return false;
          }
          let _data = res.data;
          if (_data.affectedRows === 1) {
            notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: 'Settings Updated Successfully',
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            $('#edit_settings').hide();

            this.GetAllSetting();
            $('#editSettingSmallLogo').val('');
            $('#editSettingLargeLogo').val('');

            this.setState({
              editSettingCompName: '',
              editSettingCompPhoneNo: '',
              editSettingCompAddress: '',
              editSettingSupportEmail: '',
              editSettingClientMarketing: '',
              editSettingDashboardContent: '',
              editSettingMetaTags: '',
              editSettingSmallLogo: '',
              editSettingLargeLogo: ''
            });
          } else {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: _data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  getSettingData(_Setting_ID) {
    try {
      this.setState({
        editSettingCompName: '',
        editSettingCompPhoneNo: '',
        editSettingCompAddress: '',
        editSettingSupportEmail: '',
        editSettingClientMarketing: '',
        editSettingDashboardContent: '',
        editSettingMetaTags: '',
        editSettingSmallLogo: '',
        editSettingLargeLogo: ''
      });

      this.setState({ Setting_ID: _Setting_ID });
      const api = `${process.env.REACT_APP_BASE_URL}settings/api/getSetting?setting_id=${_Setting_ID}`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/tcaccess');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          $('#edit_settings').show();
          this.setState({
            editSettingCompName: res.data[0].setting_comp_name,
            editSettingCompPhoneNo: res.data[0].setting_comp_phone_no,
            editSettingCompAddress: res.data[0].setting_comp_address,
            editSettingSupportEmail: res.data[0].setting_support_email,
            editSettingClientMarketing:
              res.data[0].setting_client_marketing_content,
            editSettingDashboardContent: res.data[0].setting_dashboard_content,
            editSettingMetaTags: res.data[0].setting_meta_tags
          });
        });
    } catch (error) {}
  }

  onChangeeditUploadSmallLogo = event => {
    //getting file object
    //console.log('event.target.files[0]', event.target.files[0]);
    let file = event.target.files[0];

    const types = ['image/png', 'image/jpeg'];
    let imgSize = 70000;
    // let imgWidth = 200;
    // let imgHeight = 200;

    let err = '';
    // list allow mime type
    if (file === undefined) {
      return false;
    }
    if (types.every(type => file.type !== type)) {
      // create error message and assign to container
      err += file.type + ' is Not a Supported Format. Please Try PNG or JGPEG ';

      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: err,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }

    if (err !== '') {
      // if message not same old that mean has error
      event.target.value = null; // discard selected file
      console.log(err);
      return false;
    }

    if (file.size > imgSize) {
      err += file.type + ' is Too Large. Please Pick a MAX 70KB File';
      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: err,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }

    if (err !== '') {
      event.target.value = null;
      console.log(err);
      return false;
    }

    // if (file.width > imgWidth || file.height > imgHeight) {
    //   err += file.type + ' is too large, please pick a smaller file';
    //   notie.alert({
    //     type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
    //     text: err,
    //     stay: false, // optional, default = false
    //     time: 3, // optional, default = 3, minimum = 1,
    //     position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
    //   });
    // }

    // if (err !== '') {
    //   event.target.value = null;
    //   console.log(err);
    //   return false;
    // }

    this.setState({
      editSettingSmallLogo: event.target.files[0]
    });

    //console.log('this.state.editSettingSmallLogo', event.target.files[0]);
  };

  onChangeeditUploadLargeLogo = event => {
    //getting file object
    let file = event.target.files[0];

    const types = ['image/png', 'image/jpeg'];
    let imgSize = 70000;
    // let imgWidth = 200;
    // let imgHeight = 200;

    let err = '';
    // list allow mime type
    if (file === undefined) {
      return false;
    }
    if (types.every(type => file.type !== type)) {
      // create error message and assign to container
      err += file.type + ' is Not a Supported Format. Please Try PNG or JGPEG ';

      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: err,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }

    if (err !== '') {
      // if message not same old that mean has error
      event.target.value = null; // discard selected file
      console.log(err);
      return false;
    }

    if (file.size > imgSize) {
      err += file.type + ' is Too Large. Please Pick a MAX 70KB File';
      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: err,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }

    if (err !== '') {
      event.target.value = null;
      console.log(err);
      return false;
    }

    // if (file.width > imgWidth || file.height > imgHeight) {
    //   err += file.type + ' is too large, please pick a smaller file';
    //   notie.alert({
    //     type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
    //     text: err,
    //     stay: false, // optional, default = false
    //     time: 3, // optional, default = 3, minimum = 1,
    //     position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
    //   });
    // }

    // if (err !== '') {
    //   event.target.value = null;
    //   console.log(err);
    //   return false;
    // }

    this.setState({
      editSettingLargeLogo: event.target.files[0]
    });

    //console.log(this.state.editSettingLargeLogo);
  };

  componentWillMount = () => {};

  componentDidMount = () => {
    this.GetAllSetting();
  };

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    const { loading } = this.state;
    return (
      <div>
        <div className='row'>
          <div className='col-md-12 card '>
            <div className='row'>
              <div className='col-md-9'>
                <h4 className='ml-3' id='heading'>
                  Manage Settings
                </h4>
              </div>
              <div className='col-md-3 col-sm-5 text-right text-sm-left ' />
            </div>
            <hr />
            <div className='container-fluid'>
              <div className='col-lg-12'>
                <div className='example-wrap'>
                  <div className='example'>
                    {loading ? (
                      <div style={{ textAlign: 'center' }}>
                        <img
                          src='assets/images/tcc_loading.gif'
                          style={{ width: '128px' }}
                        />
                      </div>
                    ) : (
                      <div>
                        <ToolkitProvider
                          keyField='setting_id'
                          data={this.state.Settings}
                          columns={this.state.columns}
                          search
                        >
                          {props => (
                            <div>
                              <SearchBar
                                {...props.searchProps}
                                className='custome-search-field'
                                style={{ color: '#000' }}
                                delay={1000}
                                placeholder='Search ...'
                              />
                              <hr />
                              <BootstrapTable
                                {...props.baseProps}
                                filter={filterFactory()}
                                pagination={paginationFactory()}
                                striped
                                hover
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/** EDIT FORM */}
        <form onSubmit={this.handleEdit}>
          <div
            className='modal myModal edit_settings'
            id='edit_settings'
            style={{
              display: 'none',
              paddingLeft: '15px',
              overflow: 'auto'
            }}
          >
            <div className='modal-dialog modal-dialog-centered'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h4 className='modal-title'>Edit Setting</h4>
                  <button
                    type='button'
                    className='close'
                    onClick={() => $('#edit_settings').hide()}
                  >
                    ×
                  </button>
                </div>

                <div className='modal-body'>
                  <div className='container-fluid'>
                    <div className='form-group row'>
                      <label htmlFor='editSettingCompName' className='col-md-4'>
                        Company Name:
                      </label>
                      <input
                        type='text'
                        id='editSettingCompName'
                        className='form-control col-md-6'
                        name='editSettingCompName'
                        value={this.state.editSettingCompName}
                        onChange={this.handleChange}
                        required
                        maxLength='150'
                      />
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='editSettingSupportEmail'
                        className='col-md-4'
                      >
                        Support Email:
                      </label>
                      <input
                        type='email'
                        id='editSettingSupportEmail'
                        className='form-control col-md-6'
                        name='editSettingSupportEmail'
                        value={this.state.editSettingSupportEmail}
                        onChange={this.handleChange}
                        required
                        maxLength='150'
                      />
                    </div>
                    {/**  <div className='form-group row'>
                      <label
                        htmlFor='editSettingCompPhoneNo'
                        className='col-md-4'
                      >
                        Contact Number:
                      </label>

                      <InputMask
                        mask='(999) 999-9999'
                        pattern='.{14,}'
                        maskChar={null}
                        id='editSettingCompPhoneNo'
                        className='form-control col-md-6'
                        name='editSettingCompPhoneNo'
                        placeholder='Phone Number (213) 456-7890'
                        title='Phone Number (213) 456-7890'
                        value={this.state.editSettingCompPhoneNo}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='editSettingCompAddress'
                        className='col-md-4'
                      >
                        Address:
                      </label>
                      <textarea
                        id='editSettingCompAddress'
                        className='form-control col-md-6'
                        name='editSettingCompAddress'
                        value={this.state.editSettingCompAddress}
                        onChange={this.handleChange}
                        required
                      />
                    </div>*/}

                    <div className='form-group row'>
                      <label htmlFor='editSettingMetaTags' className='col-md-4'>
                        Meta Tags:
                      </label>
                      <textarea
                        id='editSettingMetaTags'
                        className='form-control col-md-6'
                        name='editSettingMetaTags'
                        value={this.state.editSettingMetaTags}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='editSettingSmallLogo'
                        className='col-md-4'
                      >
                        Small Logo (100 x 35):
                      </label>
                      <input
                        type='file'
                        id='editSettingSmallLogo'
                        className='col-md-6'
                        name='editSettingSmallLogo'
                        onChange={this.onChangeeditUploadSmallLogo}
                        style={{ paddingLeft: 0 }}
                        accept='image/*'
                      />
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='editSettingLargeLogo'
                        className='col-md-4'
                      >
                        Large Logo (250 x 250):
                      </label>
                      <input
                        type='file'
                        id='editSettingLargeLogo'
                        className='col-md-6'
                        name='editSettingLargeLogo'
                        onChange={this.onChangeeditUploadLargeLogo}
                        style={{ paddingLeft: 0 }}
                        accept='image/*'
                      />
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-danger'
                    onClick={() => $('#edit_settings').hide()}
                  >
                    Cancel
                  </button>
                  <button type='submit' className='btn btn-success'>
                    Save{' '}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default ManageSettings;
