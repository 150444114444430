import React, { Component } from "react";
import "./User.css";
import { AuthConsumer } from "../../context/AuthContext";
import SignUpAgreements from "./SignUpAgreements";
import $ from "jquery";
import InputMask from "react-input-mask";
import queryString from "query-string";
import notie from "notie";
import axios from "axios";
import CookieConsent, { Cookies } from "react-cookie-consent";

class UserLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      txtCampaignID: "",
      txtAmbassadorID: "",
      txtCompName: "",
      txtEmail: "",
      txtTitle: "",
      txtFirstName: "",
      txtPassword: "",
      txtContactNo: "",
      txtAddress: "",
      chkAccept: "",
      txtForgotEmail: "",
      inputEmail: "",
      inputPassword: "",
      email: "",
      password: "",
      isSignUp: false,
      SignUpRequest: 0,
      ForgotEmail: "",
      Cookie_Consent_Content: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSignUp = this.handleChangeSignUp.bind();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.signupUser = this.signupUser.bind(this);
    this.userForgotPassword = this.userForgotPassword.bind(this);
    this.resendVerifyEmail = this.resendVerifyEmail.bind(this);
    this.GetCookieConsentContent = this.GetCookieConsentContent.bind(this);
  }

  signupUser(e) {
    e.preventDefault();
    console.log("signupUser");
    try {
      let objUser = {
        campaign_id: this.state.txtCampaignID,
        ambassador_id: this.state.txtAmbassadorID,
        comp_name: this.state.txtCompName,
        first_name: this.state.txtFirstName,
        email: this.state.txtEmail,
        password: this.state.txtPassword,
        phone_number: this.state.txtContactNo,
        title: this.state.txtTitle,
        address: this.state.txtAddress,
        status: 1
      };
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}users/api/usersignup`,
        data: objUser,

        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(res => {
          let _data = res.data;

          if (_data.success) {
            notie.alert({
              type: "success", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: _data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });

            $("#chkAccept").prop("checked", false);
            this.setState({ isSignUp: true });
          } else {
            notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: _data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
          return false;
        });
    } catch (error) {
      notie.alert({
        type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: "top" // optional, default = 'top', enum: ['top', 'bottom']
      });
      return false;
    }
  }

  userForgotPassword(e) {
    e.preventDefault();

    try {
      let objforgotPassword = {
        email: this.state.txtForgotEmail
      };
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}canned_email/api/userForgotPassword`,
        data: objforgotPassword,

        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(res => {
          let _data = res.data;
          if (_data.success === false) {
            return notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: _data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          if (_data.success) {
            // notie.alert({
            //   type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            //   text: _data.message,
            //   stay: false, // optional, default = false
            //   time: 3, // optional, default = 3, minimum = 1,
            //   position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            // });
            this.setState({ ForgotEmail: this.state.txtForgotEmail });
            $("#forgot_password").hide();
            $("#forgot_password_message").show();
            this.setState({ txtForgotEmail: "" });
          } else {
            notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: _data.command,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      notie.alert({
        type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: "top" // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  handleChange(e) {
    let target = e.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    let name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleChangeSignUp = e => {
    let target = e.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    let name = target.name;
    if (name !== undefined) {
      this.setState({
        [name]: value
      });
    }
  };

  handleSubmit(e) {
    return true;
    // console.log('The form was submitted with the following data:');
    // console.log(this.state.email);
    // const { history } = this.props;
    // history.push('/dashboard');
  }

  resendVerifyEmail(e) {
    e.preventDefault();
    if (this.state.SignUpRequest > 2) {
      return notie.alert({
        type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: "Too Many Email Request.",
        stay: true, // optional, default = false
        position: "top" // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
    try {
      let objUser = {
        email: this.state.txtEmail,
        full_name: this.state.txtFirstName
      };

      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}users/api/ResendVerifiedEmail`,
        data: objUser,

        headers: {
          "Content-Type": "application/json"
        }
      }).then(res => {
        if (res.data.logout === true) {
          return (window.location = "/client_login");
        }
        if (res.data.success === false) {
          return notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        } else {
          this.setState({ SignUpRequest: this.state.SignUpRequest + 1 });
          notie.alert({
            type: "success", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
      });
    } catch (error) {}
  }
  GetCookieConsentContent() {
    const api = `${process.env.REACT_APP_BASE_URL}cms/api/getCMS?cms_id=1`;
    axios.get(api).then(res => {
      if (res.data.logout === true) {
        return (window.location = "/client_login");
      }
      if (res.data.success === false) {
        return notie.alert({
          type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: res.data.message,
          stay: true, // optional, default = false
          position: "top" // optional, default = 'top', enum: ['top', 'bottom']
        });
      }

      this.setState({
        Cookie_Consent_Content: res.data[0].cms_template
      });
    });
  }
  componentDidMount = () => {
    const values = queryString.parse(this.props.location.search);

    if (values.campaignid !== "" && values.campaignid !== undefined) {
      this.setState({ txtCampaignID: values.campaignid });
    }

    if (values.mbsy !== "" && values.mbsy !== undefined) {
      this.setState({ txtAmbassadorID: values.mbsy });
    }

    this.GetCookieConsentContent();
  };

  render() {
    return (
      <React.Fragment>
        <AuthConsumer>
          {({ isAuthUser, userlogin, logout, Comp_Name, Support_Email }) => (
            <React.Fragment>
              {isAuthUser === false ? (
                <React.Fragment>
                  <div className="col-md-12">
                    <div className="example-wrap">
                      <div className="example">
                        <h4 style={{ textAlign: "center" }}>{Comp_Name}</h4>
                        <div
                          className="nav-tabs-horizontal nav-tabs-inverse"
                          data-plugin="tabs"
                        >
                          <ul
                            className="nav nav-tabs nav-tabs-solid"
                            role="tablist"
                          >
                            <li className="nav-item" role="presentation">
                              <a
                                className="nav-link active show"
                                data-toggle="tab"
                                href="#exampleIconifiedTabsTwo"
                                aria-controls="exampleIconifiedTabsTwo"
                                role="tab"
                                aria-selected="false"
                              >
                                <i
                                  className="icon fas fa-user-plus"
                                  aria-hidden="true"
                                />
                                Sign Up
                              </a>
                            </li>
                            <li className="nav-item" role="presentation">
                              <a
                                className="nav-link"
                                data-toggle="tab"
                                href="#exampleIconifiedTabsOne"
                                aria-controls="exampleIconifiedTabsOne"
                                role="tab"
                                aria-selected="true"
                              >
                                <i
                                  className="icon fas fa-sign-in-alt"
                                  aria-hidden="true"
                                />
                                Sign in
                              </a>
                            </li>
                          </ul>
                          <div className="tab-content pt-15">
                            <div
                              className="tab-pane "
                              id="exampleIconifiedTabsOne"
                              role="tabpanel"
                            >
                              {/*<h3 className='font-size-24'>User Sign In</h3>*/}
                              <form
                                method="post"
                                onSubmit={e =>
                                  userlogin(
                                    e,
                                    this.state.inputEmail,
                                    this.state.inputPassword,
                                    false
                                  )
                                }
                              >
                                <div className="form-group">
                                  <label
                                    className="sr-only"
                                    htmlFor="inputEmail"
                                  >
                                    Email
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="inputEmail"
                                    name="inputEmail"
                                    placeholder="Email"
                                    onChange={this.handleChange}
                                    value={this.state.inputEmail}
                                    required
                                  />
                                </div>
                                <div className="form-group">
                                  <label
                                    className="sr-only"
                                    htmlFor="inputPassword"
                                  >
                                    Password
                                  </label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="inputPassword"
                                    name="inputPassword"
                                    placeholder="Password"
                                    onChange={this.handleChange}
                                    value={this.state.inputPassword}
                                    required
                                  />
                                </div>
                                <div className="form-group clearfix">
                                  {/** <div className='checkbox-custom checkbox-inline checkbox-primary float-left'>
                              <input
                                type='checkbox'
                                id='remember'
                                name='rememberMe'
                              />
                              <label htmlFor='rememberMe'>Remember me</label>
                        </div>*/}
                                  <a
                                    href="javascrip:void(0)"
                                    style={{ cursor: "pointer" }}
                                    className="float-right"
                                    onClick={() => $("#forgot_password").show()}
                                  >
                                    Forgot password?
                                  </a>
                                  <a
                                    href="/tcagent"
                                    style={{ cursor: "pointer" }}
                                    className="float-right"
                                  >
                                    Goto Agent Login
                                  </a>
                                </div>
                                <button
                                  type="submit"
                                  className="btn btn-primary btn-block"
                                >
                                  Sign in
                                </button>
                              </form>
                            </div>

                            <div
                              className="tab-pane active show"
                              id="exampleIconifiedTabsTwo"
                              role="tabpanel"
                            >
                              {/*<h3 className='font-size-24'>User Sign Up</h3>*/}
                              {this.state.isSignUp === false ? (
                                <form onSubmit={this.signupUser}>
                                  <div className="form-group">
                                    <label
                                      className="sr-only"
                                      htmlFor="txtCompName"
                                    >
                                      Company Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="txtCompName"
                                      name="txtCompName"
                                      placeholder="Company Name"
                                      onChange={this.handleChangeSignUp}
                                      value={this.state.txtCompName}
                                      required
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label
                                      className="sr-only"
                                      htmlFor="txtEmail"
                                    >
                                      Email
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      id="txtEmail"
                                      name="txtEmail"
                                      placeholder="Email"
                                      onChange={this.handleChangeSignUp}
                                      value={this.state.txtEmail}
                                      required
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label
                                      className="sr-only"
                                      htmlFor="txtTitle"
                                    >
                                      Title:
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="txtTitle"
                                      name="txtTitle"
                                      placeholder="Title"
                                      required
                                      onChange={this.handleChangeSignUp}
                                      value={this.state.txtTitle}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label
                                      className="sr-only"
                                      htmlFor="txtFirstName"
                                    >
                                      Full Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="txtFirstName"
                                      name="txtFirstName"
                                      placeholder="Full Name"
                                      required
                                      onChange={this.handleChangeSignUp}
                                      value={this.state.txtFirstName}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label
                                      className="sr-only"
                                      htmlFor="txtPassword"
                                    >
                                      Password
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      id="txtPassword"
                                      name="txtPassword"
                                      placeholder="Password"
                                      pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                      title="Min 8 Chars  1 Number, 1 Lowercase & 1 Uppercase"
                                      required
                                      onChange={this.handleChangeSignUp}
                                      value={this.state.txtPassword}
                                    />
                                    <span
                                      style={{
                                        fontSize: "10px",
                                        marginLeft: "5px"
                                      }}
                                    >
                                      8+ characters. Mixed Case, Numbers and
                                      Symbols
                                    </span>
                                  </div>
                                  <div className="form-group">
                                    <label
                                      htmlFor="txtContactNo"
                                      className="sr-only"
                                    >
                                      Contact Number:
                                    </label>
                                    <InputMask
                                      mask="99999999999"
                                      pattern=".{10,}"
                                      maskChar={null}
                                      id="txtContactNo"
                                      className="form-control "
                                      name="txtContactNo"
                                      placeholder="Contact Number 2134567890"
                                      title="Contact Number 2134567890"
                                      value={this.state.txtContactNo}
                                      onChange={this.handleChangeSignUp}
                                      required
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label
                                      htmlFor="txtAddress"
                                      className="sr-only"
                                    >
                                      Address :
                                    </label>
                                    <textarea
                                      id="txtAddress"
                                      className="form-control"
                                      name="txtAddress"
                                      placeholder="Service Address: City, State, Zip"
                                      required
                                      onChange={this.handleChangeSignUp}
                                      value={this.state.txtAddress}
                                    />
                                  </div>

                                  {this.state.txtCampaignID === "" ? (
                                    ""
                                  ) : (
                                    <div className="form-group">
                                      <label
                                        className="col-md-12 row"
                                        htmlFor="txtCampaignID"
                                      >
                                        Campaign ID : &nbsp;
                                        <b> {this.state.txtCampaignID} </b>
                                      </label>
                                    </div>
                                  )}
                                  <SignUpAgreements />
                                  <button
                                    type="submit"
                                    className="btn btn-success btn-block"
                                  >
                                    Sign up
                                  </button>
                                </form>
                              ) : (
                                <form onSubmit={this.resendVerifyEmail}>
                                  <div className="form-group">
                                    <br />A Verification email has been sent to
                                    your Email Address.Resend Verification Code
                                    if you did not receive the email.
                                  </div>
                                  <div className="form-group">
                                    <button
                                      type="submit"
                                      className="btn btn-success btn-block"
                                    >
                                      Resend Email
                                    </button>
                                  </div>
                                </form>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div style={{ textAlign: "center" }}>
                      All Rights Reserved. Licensed{" "}
                      <a
                        target="_blank"
                        href="https://onboardsoft.com"
                        style={{ display: "inline" }}
                      >
                        Onboardsoft
                      </a>{" "}
                      2015-2020.
                      <br /> Official Sales Channel for {Comp_Name}{" "}
                    </div>
                  </div>

                  <form
                    id="frmChangePassword"
                    onSubmit={this.userForgotPassword}
                  >
                    <div className="modal myMode" id="forgot_password">
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 id="heading" className="modal-title">
                              Forgot Password?
                            </h4>
                            <button
                              type="button"
                              className="close"
                              onClick={() => $("#forgot_password").hide()}
                            >
                              ×
                            </button>
                          </div>

                          <div className="modal-body">
                            <div className="container-fluid">
                              <div className="form-group row ">
                                <label
                                  htmlFor="txtForgotEmail"
                                  className="col-md-2 label-clr mt-2"
                                >
                                  Email:
                                </label>
                                <input
                                  type="email"
                                  id="txtForgotEmail"
                                  className="form-control col-md-8"
                                  name="txtForgotEmail"
                                  placeholder="Enter Email Address"
                                  required
                                  onChange={this.handleChange}
                                  value={this.state.txtForgotEmail}
                                />
                              </div>
                              <div className="form-group">
                                Enter the email used to register your account
                                Trouble with Email Address ? contact &nbsp;
                                <a
                                  style={{ margin: "0" }}
                                  href={"mailto:" + Support_Email}
                                >
                                  {Support_Email}
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-warning"
                              onClick={() => $("#forgot_password").hide()}
                            >
                              Cancel
                            </button>
                            <button type="submit" className="btn btn-success">
                              Send{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>

                  <div className="modal myMode" id="forgot_password_message">
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 id="heading" className="modal-title ml-10">
                            Check Your Email
                          </h4>
                          <button
                            type="button"
                            className="close"
                            onClick={() => $("#forgot_password_message").hide()}
                          >
                            ×
                          </button>
                        </div>

                        <div className="modal-body">
                          <div className="container-fluid">
                            <div className="form-group">
                              Instructions on changing your password were sent
                              to you at:
                              <br />
                              <br />
                              <strong>{this.state.ForgotEmail}</strong>
                              <br />
                              <br />
                              When you receive this email, click on Reset
                              Password link which will return you to {
                                Comp_Name
                              }{" "}
                              website.
                            </div>
                          </div>
                        </div>
                        <div
                          className="modal-footer"
                          style={{ paddingRight: "15px" }}
                        >
                          <button
                            type="button"
                            className="btn btn-warning"
                            onClick={() => $("#forgot_password_message").hide()}
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            className="btn btn-info"
                            style={{ display: "none" }}
                          >
                            I don't have access to this email address
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                ""
              )}
            </React.Fragment>
          )}
        </AuthConsumer>
        <CookieConsent
          cookieName="CookieConsentWest"
          onAccept={() => {
            Cookies.set("CookieConsentWest", "true");
          }}
          debug={false}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: this.state.Cookie_Consent_Content
            }}
          />
        </CookieConsent>
      </React.Fragment>
    );
  }
}

export default UserLogin;
