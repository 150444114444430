import React from "react";
import { NavLink } from "react-router-dom";
import Sidebar from "react-sidebar";
import $ from "jquery";
import EditUserForm from "./EditUserForm";
const mql = window.matchMedia(`(min-width: 800px)`);

const styles = {
  contentHeaderMenuLink: {
    textDecoration: "none",
    color: "white",
    padding: 8
  },
  content: {
    padding: "16px"
  }
};
class UserLeftMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Comp_Logo: props.Comp_Logo,
      docked: mql.matches,
      open: false,
      User_ID: props.User_ID,
      logout: props.logout,
      showEditForm: false,
      loading: false
    };

    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.onSetOpen = this.onSetOpen.bind(this);
    this.hideEditForm = this.hideEditForm.bind(this);
  }
  hideEditForm() {
    this.setState({
      showEditForm: false
    });
  }

  componentWillMount() {
    mql.addListener(this.mediaQueryChanged);
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  onSetOpen(open) {
    this.setState({ open });
  }

  toggleOpen(ev) {
    if (ev) {
      //ev.preventDefault();
    }
    this.setState({ open: !this.state.open });
  }

  mediaQueryChanged() {
    this.setState({
      docked: mql.matches,
      open: false
    });
  }

  render() {
    const { loading, showEditForm } = this.state;
    return (
      <React.Fragment>
        {loading ? <div className="loadingDiv" /> : ""}
        <nav className="site-navbar navbar navbar-default navbar-fixed-top navbar-mega">
          <div className="navbar-header">
            {!this.state.docked && (
              <button
                type="button"
                className="navbar-toggler hamburger hamburger-close navbar-toggler-left hided"
                onClick={this.toggleOpen}
                style={styles.contentHeaderMenuLink}
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="hamburger-bar" />
              </button>
            )}
            <button
              type="button"
              className="navbar-toggler collapsed"
              data-target="#site-navbar-collapse"
              data-toggle="collapse"
            >
              <i className="icon wb-more-horizontal" aria-hidden="true" />
            </button>
            <div
              className="navbar-brand navbar-brand-center site-gridmenu-toggle"
              data-toggle="gridmenu"
            >
              <img
                className="navbar-brand-logo"
                src={this.state.Comp_Logo}
                alt="..."
              />
            </div>
          </div>

          <div className="navbar-container container-fluid">
            <div
              className="collapse navbar-collapse navbar-collapse-toolbar"
              id="site-navbar-collapse"
            >
              <ul className="nav navbar-toolbar navbar-right navbar-toolbar-right">
                <li className="nav-item dropdown">
                  <span
                    style={{ cursor: "pointer" }}
                    className="nav-link navbar-avatar"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    data-animation="scale-up"
                    role="button"
                  >
                    <i className="icon wb-user-circle" aria-hidden="true" />
                  </span>
                  <div className="dropdown-menu" role="menu">
                    <span
                      style={{ cursor: "pointer" }}
                      className="dropdown-item"
                      role="menuitem"
                      onClick={() => this.setState({ showEditForm: true })}
                    >
                      <i className="icon wb-user" aria-hidden="true" /> Edit
                      Profile
                    </span>
                    <span
                      style={{ cursor: "pointer" }}
                      className="dropdown-item"
                      role="menuitem"
                      onClick={() => $("#change_password").show()}
                    >
                      <i className="icon fa fa-key" aria-hidden="true" /> Change
                      Password
                    </span>
                    <div className="dropdown-divider" role="presentation" />
                    <span
                      style={{ cursor: "pointer" }}
                      className="dropdown-item"
                      role="menuitem"
                      onClick={() => this.state.logout()}
                    >
                      <i className="icon wb-power" aria-hidden="true" /> Log out
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <Sidebar
          children=""
          sidebar={
            <div
              style={{
                height: "100%",
                backgroundColor: "#224B70"
              }}
            >
              <div className="site-menubar-user">
                <NavLink
                  id="lnkdashboard"
                  to="client_dashboard"
                  onClick={this.toggleOpen}
                  style={{ color: "rgba(163, 175, 183, .9)" }}
                  className="nav-link"
                  role="button"
                >
                  {" "}
                  <i
                    className="site-menu-icon wb-dashboard"
                    aria-hidden="true"
                  />
                  <span className="site-menu-title">DASHBOARD</span>
                </NavLink>
                <NavLink
                  id="lnkservice"
                  style={{ color: "rgba(163, 175, 183, .9)" }}
                  to={{
                    pathname: "client_services",
                    state: { User_ID: this.state.User_ID }
                  }}
                  onClick={this.toggleOpen}
                  className="nav-link"
                  role="button"
                >
                  {" "}
                  <i
                    className="site-menu-icon  wb-library"
                    aria-hidden="true"
                  />
                  <span className="site-menu-title">SERVICES</span>
                </NavLink>
                <NavLink
                  id="lnkagreement"
                  style={{ color: "rgba(163, 175, 183, .9)" }}
                  to={{
                    pathname: "client_agreements",
                    state: { User_ID: this.state.User_ID }
                  }}
                  onClick={this.toggleOpen}
                  className="nav-link"
                  role="button"
                >
                  {" "}
                  <i className="site-menu-icon wb-file" aria-hidden="true" />
                  <span className="site-menu-title">AGREEMENTS</span>
                </NavLink>
                {this.props.Service === "Prepay" ? (
                  ""
                ) : (
                  <NavLink
                    id="lnkcredit"
                    style={{ color: "rgba(163, 175, 183, .9)" }}
                    to={{
                      pathname: "client_credit"
                    }}
                    onClick={this.toggleOpen}
                    className="nav-link"
                    role="button"
                  >
                    {" "}
                    <i
                      className="site-menu-icon  wb-contract"
                      aria-hidden="true"
                    />
                    <span className="site-menu-title"> CREDIT APP </span>
                  </NavLink>
                )}

                <NavLink
                  id="lnkorder"
                  style={{ color: "rgba(163, 175, 183, .9)", display: "" }}
                  to={{
                    pathname: "client_order"
                  }}
                  onClick={this.toggleOpen}
                  className="nav-link"
                  role="button"
                >
                  {" "}
                  <i className="site-menu-icon  wb-order" aria-hidden="true" />
                  <span className="site-menu-title"> ORDER FORM </span>
                </NavLink>
                <span
                  className="nav-link"
                  style={{ color: "rgba(163, 175, 183, .9)" }}
                  onClick={() => this.state.logout()}
                >
                  <i className="site-menu-icon wb-power" aria-hidden="true" />
                  <span className="site-menu-title">LOG OUT</span>
                </span>
              </div>
            </div>
          }
          open={this.state.open}
          docked={this.state.docked}
          onSetOpen={this.onSetOpen}
        />
        {showEditForm === true ? (
          <EditUserForm
            User_ID={this.props.User_ID}
            hideEditForm={this.hideEditForm}
            User_Name={this.props.User_Name}
          />
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

export default UserLeftMenu;
