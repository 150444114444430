import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import lifecycle from "react-pure-lifecycle";
import { AuthConsumer } from "../../context/AuthContext";
import $ from "jquery";
import notie from "notie";
import axios from "axios";
import InputMask from "react-input-mask";
import UserLogin from "../../pages/UserLogin";
import "./UserMainLayout.css";
import UserLeftMenu from "./UserLeftMenu";
import MandatoryPDF from "./MandatoryPDF";

const methods = {
  componentDidMount(props) {
    document.body.classList.remove("page-login-v2", "layout-full", "page-dark");
    document.body.classList.add("animsition", "site-menubar-unfold");
  }
};

const UserMainLayout = ({ children, ...rest }) => {
  let [_txtOldPassword, setOldPassword] = useState("");
  let [_txtNewPassword, setNewPassword] = useState("");
  let [_txtConfirmPassword, setConfirmPassword] = useState("");

  const [updateKey, setUpdateKey] = useState(1);
  const refreshControl = () => setUpdateKey(updateKey + 1);

  const [values, setValues] = useState({
    comp_name: "",
    first_name: "",
    phone_number: "",
    email: "",
    title: "",
    address: ""
  });

  function changePassword(event) {
    event.preventDefault();
    const matches = _txtNewPassword === _txtConfirmPassword;
    matches
      ? console.log("matched")
      : notie.alert({
          type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: "New Password & Confirm Password Not Same",
          stay: false, // optional, default = false
          time: 3, // optional, default = 3, minimum = 1,
          position: "top" // optional, default = 'top', enum: ['top', 'bottom']
        });

    if (matches) {
      try {
        let objAgreement = {
          old_password: _txtOldPassword,
          new_password: _txtNewPassword
        };
        axios({
          method: "post",
          url: `${process.env.REACT_APP_BASE_URL}users/api/changeUserPassword`,
          data: objAgreement,

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tcc_user_token")}`
          }
        })
          .then(res => {
            if (res.data.success === false) {
              return <Redirect to="/client_login" />;
            }
            let _data = res.data;
            if (_data.error === true) {
              return notie.alert({
                type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: _data.message,
                stay: false, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: "top" // optional, default = 'top', enum: ['top', 'bottom']
              });
            }
            if (_data.affectedRows === 1) {
              notie.alert({
                type: "success", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: "Password Changed Successfully",
                stay: false, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: "top" // optional, default = 'top', enum: ['top', 'bottom']
              });
              $("#change_password").hide();
            } else {
              notie.alert({
                type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: _data.message,
                stay: false, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: "top" // optional, default = 'top', enum: ['top', 'bottom']
              });
            }
          })
          .catch(function(response) {
            //handle error
            notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: response.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          });
      } catch (error) {
        notie.alert({
          type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: error,
          stay: false, // optional, default = false
          time: 3, // optional, default = 3, minimum = 1,
          position: "top" // optional, default = 'top', enum: ['top', 'bottom']
        });
      }
    }
  }

  function inputPassword(event) {
    if (event.target.name === "txtOldPassword") {
      setOldPassword((_txtOldPassword = event.target.value));
    } else if (event.target.name === "txtNewPassword") {
      setNewPassword((_txtNewPassword = event.target.value));
    } else if (event.target.name === "txtConfirmPassword") {
      setConfirmPassword((_txtConfirmPassword = event.target.value));
    }
  }

  const handleInputChange = e => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  function getUserData(_User_ID) {
    try {
      const api = `${process.env.REACT_APP_BASE_URL}users/api/getUserByUserID`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("tcc_user_token")}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = "/client_login");
          }
          if (res.data.success === false) {
            return notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          }

          setValues({
            ...values,
            comp_name: res.data[0].comp_name,
            first_name: res.data[0].first_name,
            phone_number: res.data[0].phone_number,
            email: res.data[0].email,
            title: res.data[0].title,
            address: res.data[0].address
          });

          $("#edit_user").show();
        });
    } catch (error) {}
  }

  return (
    <AuthConsumer>
      {({
        isAuthUser,
        userlogin,
        logout,
        User_ID,
        User_Name,
        User_Title,
        Comp_Name,
        Comp_Logo,
        isMandatoryCompeleted,
        setisMandatoryCompeleted,
        User_Agreement_ID,
        Service
      }) => (
        <React.Fragment>
          {isMandatoryCompeleted === 0 ? (
            <React.Fragment>
              <nav className="site-navbar navbar navbar-default navbar-fixed-top navbar-mega">
                <div className="navbar-header">
                  <button
                    type="button"
                    className="navbar-toggler collapsed"
                    data-target="#site-navbar-collapse"
                    data-toggle="collapse"
                  >
                    <i className="icon wb-more-horizontal" aria-hidden="true" />
                  </button>
                  <div
                    className="navbar-brand navbar-brand-center site-gridmenu-toggle"
                    data-toggle="gridmenu"
                  >
                    <img
                      className="navbar-brand-logo"
                      src={Comp_Logo}
                      alt="..."
                    />
                  </div>
                </div>

                <div className="navbar-container container-fluid">
                  <div
                    className="collapse navbar-collapse navbar-collapse-toolbar"
                    id="site-navbar-collapse"
                  >
                    <ul className="nav navbar-toolbar navbar-right navbar-toolbar-right">
                      <li className="nav-item dropdown">
                        <a
                          className="nav-link navbar-avatar"
                          data-toggle="dropdown"
                          href="#"
                          aria-expanded="false"
                          data-animation="scale-up"
                          role="button"
                        >
                          <i
                            className="icon wb-user-circle"
                            aria-hidden="true"
                          />
                        </a>
                        <div className="dropdown-menu" role="menu">
                          <a
                            className="dropdown-item"
                            href="javascript:void(0)"
                            role="menuitem"
                            onClick={() => getUserData(User_ID)}
                          >
                            <i className="icon wb-user" aria-hidden="true" />{" "}
                            Edit Profile
                          </a>
                          <div
                            className="dropdown-divider"
                            role="presentation"
                          />
                          <a
                            className="dropdown-item"
                            role="menuitem"
                            onClick={() => logout()}
                          >
                            <i className="icon wb-power" aria-hidden="true" />{" "}
                            Log out
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
              <div className="page" style={{ marginLeft: "0" }}>
                <MandatoryPDF
                  key={updateKey}
                  setisMandatoryCompeleted={setisMandatoryCompeleted}
                  Comp_Name={Comp_Name}
                  User_Agreement_ID={User_Agreement_ID}
                  User_ID={User_ID}
                  User_Name={
                    values.first_name === "" ? User_Name : values.first_name
                  }
                  User_Title={values.title === "" ? User_Title : values.title}
                />
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <UserLeftMenu
                User_ID={User_ID}
                logout={logout}
                Comp_Logo={Comp_Logo}
                Comp_Name={Comp_Name}
                Service={Service}
                User_Name={User_Name}
              />
              <div className="page">{children}</div>
              <form id="frmChangePassword" onSubmit={changePassword}>
                <div className="modal myModal" id="change_password">
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 id="heading" className="modal-title">
                          Change Password
                        </h4>
                        <button
                          type="button"
                          className="close"
                          onClick={() => $("#change_password").hide()}
                        >
                          &times;
                        </button>
                      </div>

                      <div className="modal-body">
                        <div className="container-fluid">
                          <div className="form-group row ">
                            <label
                              htmlFor="email"
                              className="col-md-4 label-clr mt-2"
                            >
                              Old Password:
                            </label>
                            <input
                              type="password"
                              id="txtOldPassword"
                              className="form-control col-md-6"
                              name="txtOldPassword"
                              placeholder="Enter Old Password"
                              onChange={inputPassword}
                              required
                            />
                          </div>
                          <div className="form-group row ">
                            <label
                              htmlFor="email"
                              className="col-md-4 label-clr mt-2"
                            >
                              New Password:
                            </label>
                            <input
                              type="password"
                              id="txtNewPassword"
                              className="form-control col-md-6"
                              name="txtNewPassword"
                              placeholder="Enter New Password"
                              onChange={inputPassword}
                              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                              title="Min 8 Chars must contain 1 Number, 1 Lowercase & 1 Uppercase"
                              required
                            />
                            <label className="col-md-4 label-clr mt-2" />
                            <span
                              style={{
                                fontSize: "10px",
                                marginLeft: "5px"
                              }}
                            >
                              8+ characters. Mixed Case, Numbers and Symbols
                            </span>
                          </div>
                          <div className="form-group row ">
                            <label
                              htmlFor="email"
                              className="col-md-4 label-clr mt-2"
                            >
                              Confirm Password:
                            </label>
                            <input
                              type="password"
                              id="txtConfirmPassword"
                              className="form-control col-md-6"
                              name="txtConfirmPassword"
                              placeholder="Re-Enter New Password"
                              onChange={inputPassword}
                              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                              title="Min 8 Chars must contain 1 Number, 1 Lowercase & 1 Uppercase"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={() => $("#change_password").hide()}
                        >
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-success">
                          Save{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <footer
                className="site-footer"
                style={{ marginLeft: "45px !important" }}
              >
                <div className="site-footer-legal">
                  All Rights Reserved. Licensed{" "}
                  <a
                    target="_blank"
                    href="https://onboardsoft.com"
                    style={{ display: "inline" }}
                    rel="noopener noreferrer"
                  >
                    Onboardsoft
                  </a>{" "}
                  2015-2020. Official Sales Channel for {Comp_Name}{" "}
                </div>
              </footer>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </AuthConsumer>
  );
};

const UserMainLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <AuthConsumer>
      {({
        isAuthUser,
        User_ID,
        Comp_Name,
        Comp_Phone_No,
        Comp_Address,
        Dashboard_Content,
        Service
      }) => (
        <Route
          render={matchProps =>
            isAuthUser ? (
              <UserMainLayout>
                <Component
                  {...matchProps}
                  User_ID={User_ID}
                  Comp_Name={Comp_Name}
                  Comp_Phone_No={Comp_Phone_No}
                  Comp_Address={Comp_Address}
                  Dashboard_Content={Dashboard_Content}
                  Service={Service}
                />
              </UserMainLayout>
            ) : (
              <Redirect to="/client_login" component={UserLogin} />
            )
          }
          {...rest}
        />
      )}
    </AuthConsumer>
  );
};

export default lifecycle(methods)(UserMainLayoutRoute);
