// import React from 'react';
// import './index.css';
// import App from './App';
// import { hydrate, render } from 'react-dom';
// import * as serviceWorker from './serviceWorker';

// const rootElement = document.getElementById('root');
// if (rootElement.hasChildNodes()) {
//   render(<App />, rootElement);
// } else {
//   render(<App />, rootElement);
// }
// serviceWorker.unregister();

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const rootElement = document.getElementById('root');

ReactDOM.render(<App />, rootElement);
// console.log('window.hasRestoredState', window.hasRestoredState);
// if (window.hasRestoredState) {
//   ReactDOM.hydrate(<App />, rootElement);
// } else {
//   ReactDOM.render(<App />, rootElement);
// }
