import React from 'react';
import axios from 'axios';

const AuthContext = React.createContext();

class AuthProviderEmail extends React.Component {
  constructor() {
    super();

    this.state = {
      Comp_Name: '',
      Comp_Logo: '',
      Comp_Logo_Dashboard: '',
      Client_Marketing_Content: '',
      Meta_Tags: '',
      Comp_Phone_No: '',
      Comp_Address: ''
    };
    const api = `${process.env.REACT_APP_BASE_URL}settings/api/`;
    axios
      .get(api, {
        config: { headers: { 'Content-Type': 'application/json' } }
      })
      .then(res => {
        this.setState({
          Comp_Name: res.data[0].setting_comp_name,
          Support_Email: res.data[0].setting_support_email,
          Comp_Phone_No: res.data[0].setting_comp_phone_no,
          Comp_Address: res.data[0].setting_comp_address,
          Comp_Logo: res.data[0].setting_comp_small_logo,
          Comp_Logo_Dashboard: res.data[0].setting_comp_large_logo,
          Client_Marketing_Content:
            res.data[0].setting_client_marketing_content,
          Meta_Tags: res.data[0].setting_meta_tags
        });
      })
      .catch(function(response) {
        //handle error
      });
  }

  render() {
    return (
      <AuthContext.Provider
        value={{
          Comp_Name: this.state.Comp_Name,
          Support_Email: this.state.Support_Email,
          Comp_Phone_No: this.state.Comp_Phone_No,
          Comp_Address: this.state.Comp_Address,
          Comp_Logo: this.state.Comp_Logo,
          Comp_Logo_Dashboard: this.state.Comp_Logo_Dashboard,
          Client_Marketing_Content: this.state.Client_Marketing_Content,
          Meta_Tags: this.state.Meta_Tags
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

const AuthConsumer = AuthContext.Consumer;

export { AuthProviderEmail, AuthConsumer };
