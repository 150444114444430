import React, { Component } from 'react';
import { AuthConsumer } from '../../../context/AuthContextAgent';
import notie from 'notie';
import queryString from 'query-string';
import axios from 'axios';
import $ from 'jquery';

class AgentResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      isValid: true,
      token: '',
      addPassword: '',
      addConfirmPassword: '',
      txtForgotEmail: '',
      ForgotEmail: '',
      PageLoad: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.agentForgotPassword = this.agentForgotPassword.bind(this);
  }
  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  verifyLink(_Token) {
    const api = `${process.env.REACT_APP_BASE_URL}agents/api/reset_password`;
    try {
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${_Token}`
          }
        })
        .then(res => {
          //console.log(res.data);

          if (res.data.success) {
            this.setState({
              isValid: true
            });
          } else {
            this.setState({
              isValid: false
            });
          }
          this.setState({
            PageLoad: true
          });
        });
    } catch (error) {}
  }
  componentWillMount = () => {};

  componentDidMount = () => {
    const values = queryString.parse(this.props.location.search);

    if (values.token !== '' && values.token !== undefined) {
      this.setState({ token: values.token });
      this.verifyLink(values.token);
    } else {
      this.setState({
        isValid: false,
        PageLoad: true
      });
    }
  };

  agentForgotPassword(e) {
    e.preventDefault();

    try {
      let objforgotPassword = {
        agent_email: this.state.txtForgotEmail
      };
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}canned_email/api/agentForgotPassword`,
        data: objforgotPassword,

        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          let _data = res.data;
          if (_data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: _data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          if (_data.success) {
            this.setState({ ForgotEmail: this.state.txtForgotEmail });
            $('#forgot_password').hide();
            $('#forgot_password_message').show();
            this.setState({ txtForgotEmail: '' });
          } else {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: _data.command,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return (
      <AuthConsumer>
        {({ isAuthAgent, Reset_Password, Comp_Name, Support_Email }) => (
          <div>
            {this.state.PageLoad === true ? (
              <React.Fragment>
                <div className='col-md-12'>
                  <div className='example-wrap'>
                    <div className='example'>
                      <div
                        className='nav-tabs-horizontal nav-tabs-inverse'
                        data-plugin='tabs'
                      >
                        <ul
                          className='nav nav-tabs nav-tabs-solid'
                          role='tablist'
                        >
                          <li className='nav-item' role='presentation'>
                            <a
                              className='nav-link active show'
                              data-toggle='tab'
                              href='#exampleIconifiedTabsOne'
                              aria-controls='exampleIconifiedTabsOne'
                              role='tab'
                              aria-selected='true'
                            >
                              <i
                                className='icon fas fa-key'
                                aria-hidden='true'
                              />
                              Change Password
                            </a>
                          </li>
                        </ul>
                        <div className='tab-content pt-15'>
                          <div
                            className='tab-pane active show'
                            id='exampleIconifiedTabsOne'
                            role='tabpanel'
                          >
                            {/*<h3 className='font-size-24'>User Sign In</h3>*/}
                            {this.state.isValid === true ? (
                              <form
                                onSubmit={e =>
                                  Reset_Password(
                                    e,
                                    this.state.addPassword,
                                    this.state.addConfirmPassword,
                                    this.state.token
                                  )
                                }
                              >
                                <div className='form-group'>
                                  Please enter your new password below.
                                </div>
                                <div className='form-group'>
                                  <label
                                    className='sr-only'
                                    htmlFor='txtPassword'
                                  >
                                    Password
                                  </label>
                                  <input
                                    type='password'
                                    className='form-control'
                                    id='addPassword'
                                    name='addPassword'
                                    placeholder='Password'
                                    pattern='(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}'
                                    title='Min 8 Chars must contain 1 Number, 1 Lowercase & 1 Uppercase'
                                    required
                                    onChange={this.handleChange}
                                    value={this.state.addPassword}
                                  />
                                  <span
                                    style={{
                                      fontSize: '10px',
                                      marginLeft: '5px'
                                    }}
                                  >
                                    8+ characters. Mixed Case, Numbers and
                                    Symbols
                                  </span>
                                </div>
                                <div className='form-group'>
                                  <label
                                    className='sr-only'
                                    htmlFor='txtPassword'
                                  >
                                    Confirm Password
                                  </label>
                                  <input
                                    type='password'
                                    className='form-control'
                                    id='addConfirmPassword'
                                    name='addConfirmPassword'
                                    placeholder='Confirm Password'
                                    required
                                    onChange={this.handleChange}
                                    value={this.state.addConfirmPassword}
                                  />
                                </div>
                                <button
                                  type='submit'
                                  className='btn btn-success btn-block'
                                >
                                  Continue
                                </button>
                              </form>
                            ) : (
                              <form>
                                <div className='form-group'>
                                  Sorry! this link has been expired. If you are
                                  looking to recover your password then do the
                                  Forgot Password again.
                                </div>

                                <button
                                  type='button'
                                  className='btn btn-success btn-block'
                                  onClick={() => $('#forgot_password').show()}
                                >
                                  Forgot Password
                                </button>
                              </form>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-12'>
                  <div style={{ textAlign: 'center' }}>
                    All Rights Reserved. Copyright. Telecom Carrier 2019.
                    <br /> Official Channel Partner of {Comp_Name}.
                  </div>
                </div>
                <form
                  id='frmChangePassword'
                  onSubmit={this.agentForgotPassword}
                >
                  <div className='modal myMode' id='forgot_password'>
                    <div className='modal-dialog modal-dialog-centered'>
                      <div className='modal-content'>
                        <div className='modal-header'>
                          <h4 id='heading' className='modal-title'>
                            Forgot Password?
                          </h4>
                          <button
                            type='button'
                            className='close'
                            onClick={() => $('#forgot_password').hide()}
                          >
                            ×
                          </button>
                        </div>

                        <div className='modal-body'>
                          <div className='container-fluid'>
                            <div className='form-group row '>
                              <label
                                htmlFor='txtForgotEmail'
                                className='col-md-2 label-clr mt-2'
                              >
                                Email:
                              </label>
                              <input
                                type='email'
                                id='txtForgotEmail'
                                className='form-control col-md-8'
                                name='txtForgotEmail'
                                placeholder='Enter Email Address'
                                required
                                onChange={this.handleChange}
                                value={this.state.txtForgotEmail}
                              />
                            </div>
                            <div className='form-group'>
                              Enter the email used to register your account
                              Trouble with Email Address ? contact &nbsp;
                              <a
                                style={{ margin: '0' }}
                                href={'mailto:' + Support_Email}
                              >
                                {Support_Email}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className='modal-footer'>
                          <button
                            type='button'
                            className='btn btn-warning'
                            onClick={() => $('#forgot_password').hide()}
                          >
                            Cancel
                          </button>
                          <button type='submit' className='btn btn-success'>
                            Send{' '}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className='modal myMode' id='forgot_password_message'>
                  <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                      <div className='modal-header'>
                        <h4 id='heading' className='modal-title ml-10'>
                          Check Your Email
                        </h4>
                        <button
                          type='button'
                          className='close'
                          onClick={() => $('#forgot_password_message').hide()}
                        >
                          ×
                        </button>
                      </div>

                      <div className='modal-body'>
                        <div className='container-fluid'>
                          <div className='form-group'>
                            Instructions on changing your password were sent to
                            you at:
                            <br />
                            <br />
                            <strong>{this.state.ForgotEmail}</strong>
                            <br />
                            <br />
                            When you receive this email, click on Reset Password
                            link which will return you to {Comp_Name} website.
                          </div>
                        </div>
                      </div>
                      <div
                        className='modal-footer'
                        style={{ paddingRight: '15px' }}
                      >
                        <button
                          type='button'
                          className='btn btn-warning'
                          onClick={() => $('#forgot_password_message').hide()}
                        >
                          Cancel
                        </button>
                        <button
                          type='button'
                          className='btn btn-info'
                          style={{ display: 'none' }}
                        >
                          I don't have access to this email address
                        </button>
                      </div>
                    </div>
                  </div>
                </div>{' '}
              </React.Fragment>
            ) : (
              ''
            )}
          </div>
        )}
      </AuthConsumer>
    );
  }
}

export default AgentResetPassword;
