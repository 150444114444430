import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import axios from "axios";
import notie from "notie";
import $ from "jquery";
import SignatureCanvas from "react-signature-canvas";

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardTitle,
  CardText,
  Row,
  Col
} from "reactstrap";
import classnames from "classnames";
import Select from "react-select";
import htmlToImage from "html-to-image";

import InputMask from "react-input-mask";
import styles from "./styles.module.css";
const { SearchBar } = Search;

class ManageVendorSignor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      addVendorSignorCompName: "",
      addVendorSignorName: "",
      addVendorSignorTitle: "",
      addVendorSignorEmail: "",
      addVendorSignorPhoneNo: "",
      addVendorSignorFax: "",
      addVendorSignorAddress: "",
      addVendorSignorSignature: "",
      addVendorSignorSignatureFile: null,
      addVendorSign: "",
      addSignCheck: false,
      addisActive: 0,
      editVendorSignorCompName: "",
      editVendorSignorName: "",
      editVendorSignorTitle: "",
      editVendorSignorEmail: "",
      editVendorSignorPhoneNo: "",
      editVendorSignorFax: "",
      editVendorSignorAddress: "",
      editVendorSignorSignature: "",
      editVendorSignorSignatureFile: "",
      editVendorSign: "",
      editisActive: 0,
      Vendor_Signor_ID: "",
      Vendor_Signor: [],
      loading: true,
      columns: [
        {
          dataField: "serial_number",
          text: "Sr #",
          sort: true
        },
        {
          dataField: "vendor_signor_name",
          text: "Vendor Signor Name",
          sort: true
        },
        {
          dataField: "vendor_signor_title",
          text: "Vendor Signor Title",
          sort: true
        },

        {
          dataField: "view",
          text: "Signature",
          sort: false,
          formatter: this.vendorSignatureFormatter,
          headerAttrs: { width: 250 },
          attrs: { width: 250, className: "EditRow" }
        },
        {
          dataField: "isactive",
          text: "Active",
          sort: true
        },
        {
          dataField: "edit",
          text: "Edit",
          sort: false,
          formatter: this.vendorFormatter,
          headerAttrs: { width: 75, style: { textAlign: "center" } },
          attrs: { width: 75, style: { textAlign: "center" } }
        },
        {
          dataField: "delete",
          text: "Delete",
          sort: false,
          formatter: this.vendorDeleteFormatter,
          headerAttrs: { width: 75, style: { textAlign: "center" } },
          attrs: { width: 75, style: { textAlign: "center" } }
        }
      ],
      addActiveTab: "1",
      editActiveTab: "3",
      FontName: "Mistral",
      SelectedFontName: {
        value: "Mistral",
        label: "Signature Style One"
      },
      signOptions: [
        {
          value: "Mistral",
          label: "Signature Style One"
        },
        {
          value: "Rage",
          label: "Signature Style Two"
        },
        {
          value: "SignatureOne",
          label: "Signature Style Three"
        },
        {
          value: "SignatureTwo",
          label: "Signature Style Four"
        },
        {
          value: "SignatureThree",
          label: "Signature Style Five"
        },
        {
          value: "SignatureFour",
          label: "Signature Style Six"
        },
        {
          value: "SignatureFive",
          label: "Signature Style Seven"
        },
        {
          value: "SignatureSix",
          label: "Signature Style Eight"
        },
        {
          value: "SignatureSeven",
          label: "Signature Style Nine"
        },
        {
          value: "SignatureEight",
          label: "Signature Style Ten"
        }
      ]
    };

    this.handleAdd = this.handleAdd.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getVendorSignorData = this.getVendorSignorData.bind(this);
    this.onChangeaddUploadSign = this.onChangeaddUploadSign.bind(this);
    this.onChangeeditUploadSign = this.onChangeeditUploadSign.bind(this);
  }

  GetAllVendorSignor() {
    const api = `${process.env.REACT_APP_BASE_URL}vendor_signor/api`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = "/tcaccess");
        }
        if (res.data.success === false) {
          return notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        this.setState({
          Vendor_Signor: res.data,
          loading: false
        });
      });
  }

  handleaddUpload = () => {
    this.setState({ addSignCheck: !this.state.addSignCheck });
    if (this.state.addSignCheck) {
      $("#divaddUploadSign").show();
      $("#divaddSign").hide();
    } else {
      $("#divaddUploadSign").hide();
      $("#divaddSign").show();
    }

    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 1);
  };

  handleeditUpload = () => {
    this.setState({ editSignCheck: !this.state.editSignCheck });
    if (this.state.editSignCheck) {
      $("#diveditUploadSign").show();
      $("#diveditSign").hide();
    } else {
      $("#diveditUploadSign").hide();
      $("#diveditSign").show();
    }
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 1);
  };

  handleChange(event) {
    const target = event.target;
    const value =
      target.type === "checkbox"
        ? target.checked === true
          ? 1
          : 0
        : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  async handleAdd(e) {
    e.preventDefault();

    try {
      var body = new FormData();

      body.append(
        "vendor_signor_comp_name",
        this.state.addVendorSignorCompName
      );
      body.append("vendor_signor_name", this.state.addVendorSignorName);
      body.append("vendor_signor_title", this.state.addVendorSignorTitle);
      body.append("vendor_signor_email", this.state.addVendorSignorEmail);
      body.append("vendor_signor_phone_no", this.state.addVendorSignorPhoneNo);
      body.append("vendor_signor_fax", this.state.addVendorSignorFax);
      body.append("vendor_signor_address", this.state.addVendorSignorAddress);
      if (this.state.addActiveTab === "1") {
        body.append(
          "vendor_signor_signature",
          this.addVendorSignaturePad.toDataURL("image/png")
        );
      } else {
        var node = document.getElementById("divSignature");
        await htmlToImage
          .toPng(node)
          .then(function(dataUrl) {
            console.log("dataUrl", dataUrl);
            body.append("vendor_signor_signature", dataUrl);
          })
          .catch(function(error) {
            console.error("oops, something went wrong!", error);
          });
      }
      body.append(
        "vendor_signor_signature_file",
        this.state.addVendorSignorSignatureFile
      );
      body.append("siganture_type", this.state.addSignCheck);
      body.append("isactive", this.state.addisActive);
      //console.log('this.state.addSignCheck', this.state.addSignCheck);
      if (
        this.state.addActiveTab === "1" &&
        this.state.addSignCheck === true &&
        this.addVendorSignaturePad.isEmpty()
      ) {
        notie.alert({
          type: "info", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: "Please Provide a Signature First",
          stay: false, // optional, default = false
          time: 3, // optional, default = 3, minimum = 1,
          position: "top" // optional, default = 'top', enum: ['top', 'bottom']
        });
        return false;
      } else if (
        this.state.addSignCheck === false &&
        this.state.addVendorSignorSignatureFile === null
      ) {
        // eslint-disable-next-line no-alert
        notie.alert({
          type: "info", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: "Please Upload Signature File",
          stay: false, // optional, default = false
          time: 3, // optional, default = 3, minimum = 1,
          position: "top" // optional, default = 'top', enum: ['top', 'bottom']
        });
        return false;
      }

      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}vendor_signor/api/`,
        data: body,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
        }
      })
        .then(res => {
          if (res.data.success === false) {
            notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
            return false;
          }
          let _data = res.data;
          if (_data.affectedRows === 1) {
            notie.alert({
              type: "success", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: "Vendor Signor Added Successfully",
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
            $("#add_vendor_signor").hide();
            $("#addVendorSignorSignatureFile").val("");
            this.addVendorSignaturePad.clear();
            $("#divaddUploadSign").show();
            $("#divaddSign").hide();

            this.GetAllVendorSignor();

            this.setState({
              addVendorSignorCompName: "",
              addVendorSignorName: "",
              addVendorSignorTitle: "",
              addVendorSignorEmail: "",
              addVendorSignorPhoneNo: "",
              addVendorSignorFax: "",
              addVendorSignorAddress: "",
              addVendorSignorSignature: "",
              addVendorSignorSignatureFile: null,
              addSignCheck: false,
              addisActive: 1
            });
          } else {
            notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: _data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      notie.alert({
        type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: "top" // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  vendorFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal"
        }}
      >
        <span
          className="fa fa-edit"
          onClick={() => this.getVendorSignorData(row.vendor_signor_id)}
        />
      </div>
    );
  };

  vendorDeleteFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal"
        }}
      >
        <span
          className="fa fa-trash"
          onClick={() =>
            notie.confirm({
              text: "Are you sure to delete this record ?",
              submitText: "Yes", // optional, default = 'Yes'
              cancelText: "No", // optional, default = 'Cancel'
              position: "top", // optional, default = 'top', enum: ['top', 'bottom']
              submitCallback: () =>
                this.deleteVendorSignorData(row.vendor_signor_id) // optional
            })
          }
        />
      </div>
    );
  };

  vendorSignatureFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: "center",
          lineHeight: "normal"
        }}
      >
        <img
          style={{ height: "30px" }}
          alt="Signature"
          src={`${process.env.REACT_APP_BASE_URL}${row.vendor_signor_signature_file}`}
        />
      </div>
    );
  };

  handleAddClear(e) {
    e.preventDefault();
    this.addVendorSignaturePad.clear();
    this.setState({ addVendorSign: "" });
  }

  handleEditClear(e) {
    e.preventDefault();
    this.editVendorSignaturePad.clear();
    this.setState({ editVendorSign: "" });
  }

  async handleEdit(e) {
    e.preventDefault();
    try {
      var body = new FormData();
      body.append("vendor_signor_id", this.state.Vendor_Signor_ID);
      body.append(
        "vendor_signor_comp_name",
        this.state.editVendorSignorCompName
      );
      body.append("vendor_signor_name", this.state.editVendorSignorName);
      body.append("vendor_signor_title", this.state.editVendorSignorTitle);
      body.append("vendor_signor_phone_no", this.state.editVendorSignorPhoneNo);
      body.append("vendor_signor_fax", this.state.editVendorSignorFax);
      body.append("vendor_signor_email", this.state.editVendorSignorEmail);
      body.append("vendor_signor_address", this.state.editVendorSignorAddress);
      if (this.state.editActiveTab === "3") {
        body.append(
          "vendor_signor_signature",
          this.editVendorSignaturePad.toDataURL("image/png")
        );
      } else {
        var node = document.getElementById("divEditSignature");
        await htmlToImage
          .toPng(node)
          .then(function(dataUrl) {
            console.log("dataUrl", dataUrl);
            body.append("vendor_signor_signature", dataUrl);
          })
          .catch(function(error) {
            console.error("oops, something went wrong!", error);
          });
      }
      body.append(
        "vendor_signor_signature_file",
        this.state.editVendorSignorSignatureFile
      );
      body.append("siganture_type", this.state.editSignCheck);
      body.append("isactive", this.state.editisActive);
      //console.log('this.state.addSignCheck', this.state.addSignCheck);

      axios({
        method: "put",
        url: `${process.env.REACT_APP_BASE_URL}vendor_signor/api/`,
        data: body,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
        }
      })
        .then(res => {
          if (res.data.success === false) {
            notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
            return false;
          }
          let _data = res.data;
          if (_data.affectedRows === 1) {
            notie.alert({
              type: "success", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: "Vendor Signor Updated Successfully",
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
            $("#edit_vendor_signor").hide();
            $("#editVendorSignorSignatureFile").val("");
            this.editVendorSignaturePad.clear();
            $("#diveditUploadSign").show();
            $("#diveditSign").hide();

            this.GetAllVendorSignor();

            this.setState({
              editVendorSignorCompName: "",
              editVendorSignorName: "",
              editVendorSignorTitle: "",
              editVendorSignorEmail: "",
              editVendorSignorPhoneNo: "",
              editVendorSignorFax: "",
              editVendorSignorAddress: "",
              editVendorSignorSignature: "",
              editVendorSignorSignatureFile: null,
              editSignCheck: false,
              editisActive: 0
            });
          } else {
            notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: _data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      notie.alert({
        type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: "top" // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  getVendorSignorData(_Vendor_Signor_ID) {
    try {
      this.setState({
        editVendorSignorCompName: "",
        editVendorSignorName: "",
        editVendorSignorTitle: "",
        editVendorSignorEmail: "",
        editVendorSignorPhoneNo: "",
        editVendorSignorFax: "",
        editVendorSignorAddress: "",
        editVendorSignorSignature: "",
        editVendorSignorSignatureFile: null,
        editSignCheck: false,
        editisActive: 0
      });

      this.setState({ Vendor_Signor_ID: _Vendor_Signor_ID });
      const api = `${process.env.REACT_APP_BASE_URL}vendor_signor/api/getVendorSignor?vendor_signor_id=${_Vendor_Signor_ID}`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = "/tcaccess");
          }
          if (res.data.success === false) {
            return notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          $("#edit_vendor_signor").show();
          this.setState({
            editVendorSignorCompName: res.data[0].vendor_signor_comp_name,
            editVendorSignorName: res.data[0].vendor_signor_name,
            editVendorSignorTitle: res.data[0].vendor_signor_title,

            editVendorSignorEmail: res.data[0].vendor_signor_email,
            editVendorSignorPhoneNo: res.data[0].vendor_signor_phone_no,
            editVendorSignorFax: res.data[0].vendor_signor_fax,
            editVendorSignorAddress: res.data[0].vendor_signor_address,
            // editVendorSignorSignature: res.data[0].vendor_signor_signature,
            // editVendorSignorSignatureFile:
            //   res.data[0].vendor_signor_signature_file,
            editisActive: res.data[0].isactive
          });
        });
    } catch (error) {}
  }

  deleteVendorSignorData(_Vendor_Signor_ID) {
    try {
      const api = `${process.env.REACT_APP_BASE_URL}vendor_signor/api/?vendor_signor_id=${_Vendor_Signor_ID}`;
      axios
        .delete(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = "/tcaccess");
          }
          if (res.data.success === false) {
            return notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          let _data = res.data;
          if (_data.affectedRows === 1) {
            notie.alert({
              type: "success", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: "Vendor Signor Deleted Successfully",
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
            this.GetAllVendorSignor();
          } else {
            notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: "Some problem in deleting record.",
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        });
    } catch (error) {}
  }

  onChangeaddUploadSign = event => {
    //getting file object
    let file = event.target.files[0];

    const types = ["image/png", "image/jpeg"];
    let imgSize = 70000;
    // let imgWidth = 200;
    // let imgHeight = 200;

    let err = "";
    // list allow mime type
    if (file === undefined) {
      return false;
    }
    if (types.every(type => file.type !== type)) {
      // create error message and assign to container
      err += file.type + " is Not a Supported Format. Please Try PNG or JGPEG ";

      notie.alert({
        type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: err,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: "top" // optional, default = 'top', enum: ['top', 'bottom']
      });
    }

    if (err !== "") {
      // if message not same old that mean has error
      event.target.value = null; // discard selected file
      console.log(err);
      return false;
    }

    if (file.size > imgSize) {
      err += file.type + " is Too Large. Please Pick a MAX 70KB File";
      notie.alert({
        type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: err,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: "top" // optional, default = 'top', enum: ['top', 'bottom']
      });
    }

    if (err !== "") {
      event.target.value = null;
      console.log(err);
      return false;
    }

    // if (file.width > imgWidth || file.height > imgHeight) {
    //   err += file.type + ' is too large, please pick a smaller file';
    //   notie.alert({
    //     type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
    //     text: err,
    //     stay: false, // optional, default = false
    //     time: 3, // optional, default = 3, minimum = 1,
    //     position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
    //   });
    // }

    // if (err !== '') {
    //   event.target.value = null;
    //   console.log(err);
    //   return false;
    // }

    this.setState({
      addVendorSignorSignatureFile: event.target.files[0]
    });
  };

  onChangeeditUploadSign = event => {
    //getting file object
    let file = event.target.files[0];

    const types = ["image/png", "image/jpeg"];
    let imgSize = 70000;
    let imgWidth = 200;
    let imgHeight = 200;

    let err = "";
    // list allow mime type
    if (file === undefined) {
      return false;
    }
    if (types.every(type => file.type !== type)) {
      // create error message and assign to container
      err += file.type + " is Not a Supported Format. Please Try PNG or JGPEG ";

      notie.alert({
        type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: err,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: "top" // optional, default = 'top', enum: ['top', 'bottom']
      });
    }

    if (err !== "") {
      // if message not same old that mean has error
      event.target.value = null; // discard selected file
      console.log(err);
      return false;
    }

    if (file.size > imgSize) {
      err += file.type + " is Too Large. Please Pick a MAX 70KB File";
      notie.alert({
        type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: err,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: "top" // optional, default = 'top', enum: ['top', 'bottom']
      });
    }

    if (err !== "") {
      event.target.value = null;
      console.log(err);
      return false;
    }

    // if (file.width > imgWidth || file.height > imgHeight) {
    //   err += file.type + ' is too large, please pick a smaller file';
    //   notie.alert({
    //     type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
    //     text: err,
    //     stay: false, // optional, default = false
    //     time: 3, // optional, default = 3, minimum = 1,
    //     position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
    //   });
    // }

    // if (err !== '') {
    //   event.target.value = null;
    //   console.log(err);
    //   return false;
    // }

    this.setState({
      editVendorSignorSignatureFile: event.target.files[0]
    });

    console.log(this.state.editVendorSignorSignatureFile);
  };

  componentWillMount = () => {};

  componentDidMount = () => {
    this.GetAllVendorSignor();
  };

  addToggle = tab => {
    if (this.state.addActiveTab !== tab) {
      this.setState({ addActiveTab: tab });
    }
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 1);
  };

  editToggle = tab => {
    if (this.state.editActiveTab !== tab) {
      this.setState({ editActiveTab: tab });
    }
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 1);
  };

  onChangeSignStyle = selectedSignOption => {
    this.setState({ FontName: selectedSignOption.value });
    this.setState({ SelectedFontName: selectedSignOption });
    console.log("this.state.FontName", this.state.FontName);
  };

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    const signStyles = {
      control: styles => ({
        ...styles,
        fontFamily: this.state.FontName,
        fontSize: "x-large",
        width: "275px",
        heigth: "150px"
      }),
      option: (styles, { data, isSelected }) => {
        return {
          ...styles,
          fontFamily: data.value,
          fontSize: "x-large"
        };
      }
    };
    const { loading } = this.state;
    return (
      <div>
        <div className="row">
          <div className="col-md-12 card ">
            <div className="row">
              <div className="col-md-9">
                <h4 className="ml-3" id="heading">
                  Manage Vendor Signor
                </h4>
              </div>
              <div className="col-md-3 col-sm-5 text-right text-sm-left ">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => $("#add_vendor_signor").show()}
                >
                  Add Vendor Signor
                </button>
              </div>
            </div>
            <hr />
            <div className="container-fluid">
              <div className="col-lg-12">
                <div className="example-wrap">
                  <div className="example">
                    {loading ? (
                      <div style={{ textAlign: "center" }}>
                        <img
                          src="assets/images/tcc_loading.gif"
                          style={{ width: "128px" }}
                          alt="loading"
                        />
                      </div>
                    ) : (
                      <div>
                        <ToolkitProvider
                          keyField="vendor_signor_id"
                          data={this.state.Vendor_Signor}
                          columns={this.state.columns}
                          search
                        >
                          {props => (
                            <div>
                              <SearchBar
                                {...props.searchProps}
                                className="custome-search-field"
                                style={{ color: "#000" }}
                                delay={1000}
                                placeholder="Search ..."
                              />
                              <hr />
                              <BootstrapTable
                                {...props.baseProps}
                                filter={filterFactory()}
                                pagination={paginationFactory()}
                                striped
                                hover
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={this.handleAdd}>
          <div
            className="modal myModal AddVendorSignor"
            id="add_vendor_signor"
            style={{
              display: "none",
              paddingLeft: "15px",
              overflow: "auto"
            }}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Add Vendor Signor</h4>
                  <button
                    type="button"
                    className="close"
                    onClick={() => $("#add_vendor_signor").hide()}
                  >
                    ×
                  </button>
                </div>

                <div className="modal-body">
                  <div className="container-fluid">
                    <div className="form-group row">
                      <label
                        htmlFor="addVendorSignorCompName"
                        className="col-md-4"
                      >
                        Vendor Signor Company:
                      </label>
                      <input
                        type="text"
                        id="addVendorSignorCompName"
                        className="form-control col-md-6"
                        name="addVendorSignorCompName"
                        value={this.state.addVendorSignorCompName}
                        onChange={this.handleChange}
                        maxLength="150"
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label htmlFor="addVendorSignorName" className="col-md-4">
                        Vendor Signor Name:
                      </label>
                      <input
                        type="text"
                        id="addVendorSignorName"
                        className="form-control col-md-6"
                        name="addVendorSignorName"
                        value={this.state.addVendorSignorName}
                        onChange={this.handleChange}
                        maxLength="150"
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="addVendorSignorTitle"
                        className="col-md-4"
                      >
                        Vendor Signor Title:
                      </label>
                      <input
                        type="text"
                        id="addVendorSignorTitle"
                        className="form-control col-md-6"
                        name="addVendorSignorTitle"
                        value={this.state.addVendorSignorTitle}
                        onChange={this.handleChange}
                        required
                        maxLength="100"
                      />
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="addVendorSignorEmail"
                        className="col-md-4"
                      >
                        Email Address:
                      </label>
                      <input
                        type="email"
                        id="addVendorSignorEmail"
                        className="form-control col-md-6"
                        name="addVendorSignorEmail"
                        value={this.state.addVendorSignorEmail}
                        onChange={this.handleChange}
                        maxLength="200"
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="addVendorSignorPhoneNo"
                        className="col-md-4"
                      >
                        Contact Number:
                      </label>

                      <InputMask
                        mask="99999999999"
                        pattern=".{10,}"
                        maskChar={null}
                        id="addVendorSignorPhoneNo"
                        className="form-control col-md-6"
                        name="addVendorSignorPhoneNo"
                        placeholder="Contact Number 2134567890"
                        title="Contact Number 2134567890"
                        value={this.state.addVendorSignorPhoneNo}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label htmlFor="addVendorSignorFax" className="col-md-4">
                        Fax Number:
                      </label>

                      <InputMask
                        mask="99999999999"
                        pattern=".{10,}"
                        maskChar={null}
                        id="addVendorSignorFax"
                        className="form-control col-md-6"
                        name="addVendorSignorFax"
                        placeholder="Fax Number 2134567890"
                        title="Fax Number 2134567890"
                        value={this.state.addVendorSignorFax}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="addVendorSignorAddress"
                        className="col-md-4"
                      >
                        Address :
                      </label>
                      <textarea
                        id="addVendorSignorAddress"
                        className="form-control col-md-6"
                        name="addVendorSignorAddress"
                        placeholder="Address"
                        value={this.state.addVendorSignorAddress}
                        onChange={this.handleChange}
                        maxLength="250"
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label className="col-md-4">or</label>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-4" htmlFor="chkaddUpload">
                        Manual Signature: (check box to add signature)
                      </label>
                      <div className="isnotified:0,">
                        <input
                          type="checkbox"
                          id="chkaddUpload"
                          checked={this.state.addSignCheck}
                          onChange={this.handleaddUpload}
                        />
                      </div>
                    </div>
                    <div id="divaddUploadSign" className="form-group row">
                      <label
                        htmlFor="addVendorSignorSignatureFile"
                        className="col-md-4"
                      >
                        Upload Signature:
                      </label>
                      <input
                        type="file"
                        id="addVendorSignorSignatureFile"
                        className="col-md-6"
                        name="addVendorSignorSignatureFile"
                        onChange={this.f}
                        style={{ paddingLeft: 0 }}
                        accept="image/*"
                      />
                    </div>
                    <div
                      id="divaddSign"
                      style={{
                        display: "none"
                      }}
                    >
                      <Nav tabs className="col-md-12">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.addActiveTab === "1"
                            })}
                            onClick={() => {
                              this.addToggle("1");
                            }}
                          >
                            DRAW
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.addActiveTab === "2"
                            })}
                            onClick={() => {
                              this.addToggle("2");
                            }}
                          >
                            CHOOSE
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={this.state.addActiveTab}>
                        <TabPane tabId="1">
                          <Row>
                            <Col sm="12">
                              <div
                                className="row"
                                style={{
                                  padding: "10px 10px 10px 10px",
                                  height: "200px"
                                }}
                              >
                                <label htmlFor="">Sign Here:</label>

                                <div className={styles.container}>
                                  <div className={styles.sigContainer}>
                                    <SignatureCanvas
                                      clearOnResize={true}
                                      canvasProps={{ className: styles.sigPad }}
                                      velocityFilterWeight={0.7}
                                      throttle={50}
                                      ref={ref => {
                                        this.addVendorSignaturePad = ref;
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="2">
                          <Row>
                            <Col lg="12">
                              <Card body>
                                <CardTitle></CardTitle>
                                <CardText>
                                  <Select
                                    id="ddlSignatureStyle"
                                    onChange={this.onChangeSignStyle}
                                    options={this.state.signOptions}
                                    value={this.state.SelectedFontName}
                                    styles={signStyles}
                                  />
                                </CardText>
                              </Card>
                            </Col>
                            <Col lg="12">
                              <Card body>
                                <CardTitle></CardTitle>

                                <CardText>
                                  <div
                                    id="divSignature"
                                    style={{
                                      color: "black",
                                      backgroundColor: "white",
                                      fontFamily: this.state.FontName,
                                      fontSize: "80px",
                                      width: "500px",
                                      height: "150px",
                                      lineHeight: "150px"
                                    }}
                                  >
                                    {this.state.addVendorSignorName}
                                  </div>
                                </CardText>
                              </Card>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </div>

                    <div
                      className="form-group row"
                      style={{ paddingTop: "25px" }}
                    >
                      <label htmlFor="addisActive" className="col-md-4">
                        Active:
                      </label>
                      <div className="col-md-6">
                        <input
                          type="checkbox"
                          id="addisActive"
                          name="addisActive"
                          checked={this.state.addisActive}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-warning"
                    onClick={this.handleAddClear.bind(this)}
                  >
                    Clear Signature
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => $("#add_vendor_signor").hide()}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-success">
                    Save{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        {/** EDIT FORM */}
        <form onSubmit={this.handleEdit}>
          <div
            className="modal myModal edit_vendor_signor"
            id="edit_vendor_signor"
            style={{
              display: "none",
              paddingLeft: "15px",
              overflow: "auto"
            }}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Edit Vendor Signor</h4>
                  <button
                    type="button"
                    className="close"
                    onClick={() => $("#edit_vendor_signor").hide()}
                  >
                    ×
                  </button>
                </div>

                <div className="modal-body">
                  <div className="container-fluid">
                    <div className="form-group row">
                      <label
                        htmlFor="editVendorSignorCompName"
                        className="col-md-4"
                      >
                        Vendor Signor Company:
                      </label>
                      <input
                        type="text"
                        id="editVendorSignorCompName"
                        className="form-control col-md-6"
                        name="editVendorSignorCompName"
                        value={this.state.editVendorSignorCompName}
                        onChange={this.handleChange}
                        required
                        maxLength="150"
                      />
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="editVendorSignorName"
                        className="col-md-4"
                      >
                        Vendor Signor Name:
                      </label>
                      <input
                        type="text"
                        id="editVendorSignorName"
                        className="form-control col-md-6"
                        name="editVendorSignorName"
                        value={this.state.editVendorSignorName}
                        onChange={this.handleChange}
                        required
                        maxLength="150"
                      />
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="editVendorSignorTitle"
                        className="col-md-4"
                      >
                        Vendor Signor Title:
                      </label>
                      <input
                        type="text"
                        id="editVendorSignorTitle"
                        className="form-control col-md-6"
                        name="editVendorSignorTitle"
                        value={this.state.editVendorSignorTitle}
                        onChange={this.handleChange}
                        required
                        maxLength="100"
                      />
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="editVendorSignorEmail"
                        className="col-md-4"
                      >
                        Email Address:
                      </label>
                      <input
                        type="email"
                        id="editVendorSignorEmail"
                        className="form-control col-md-6"
                        name="editVendorSignorEmail"
                        value={this.state.editVendorSignorEmail}
                        onChange={this.handleChange}
                        required
                        maxLength="250"
                      />
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="editVendorSignorPhoneNo"
                        className="col-md-4"
                      >
                        Contact Number:
                      </label>
                      <InputMask
                        mask="99999999999"
                        pattern=".{10,}"
                        maskChar={null}
                        id="editVendorSignorPhoneNo"
                        className="form-control col-md-6"
                        name="editVendorSignorPhoneNo"
                        placeholder="Contact Number 2134567890"
                        title="Contact Number 2134567890"
                        value={this.state.editVendorSignorPhoneNo}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label htmlFor="editVendorSignorFax" className="col-md-4">
                        Fax Number:
                      </label>

                      <InputMask
                        mask="99999999999"
                        pattern=".{10,}"
                        maskChar={null}
                        id="editVendorSignorFax"
                        className="form-control col-md-6"
                        name="editVendorSignorFax"
                        placeholder="Fax Number 2134567890"
                        title="Fax Number 2134567890"
                        value={this.state.editVendorSignorFax}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="editVendorSignorAddress"
                        className="col-md-4"
                      >
                        Address :
                      </label>
                      <textarea
                        id="editVendorSignorAddress"
                        className="form-control col-md-6"
                        name="editVendorSignorAddress"
                        placeholder="Address"
                        value={this.state.editVendorSignorAddress}
                        onChange={this.handleChange}
                        required
                        maxLength="250"
                      />
                    </div>
                    <div className="form-group row">
                      <label className="col-md-4" htmlFor="chkeditUpload">
                        Manual Signature: (check box to add signature)
                      </label>
                      <div className="isnotified:0,">
                        <input
                          type="checkbox"
                          id="chkeditUpload"
                          checked={this.state.editSignCheck}
                          onChange={this.handleeditUpload}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-4">or</label>
                    </div>
                    <div id="diveditUploadSign" className="form-group row">
                      <label
                        htmlFor="editVendorSignorSignatureFile"
                        className="col-md-4"
                      >
                        Upload Signature:
                      </label>
                      <input
                        type="file"
                        id="editVendorSignorSignatureFile"
                        className="col-md-6"
                        name="editVendorSignorSignatureFile"
                        onChange={this.onChangeeditUploadSign}
                        style={{ paddingLeft: 0 }}
                        accept="image/*"
                      />
                    </div>
                    <div
                      id="diveditSign"
                      style={{
                        display: "none"
                      }}
                    >
                      <Nav tabs className="col-md-12">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.editActiveTab === "3"
                            })}
                            onClick={() => {
                              this.editToggle("3");
                            }}
                          >
                            DRAW
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.editActiveTab === "4"
                            })}
                            onClick={() => {
                              this.editToggle("4");
                            }}
                          >
                            CHOOSE
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={this.state.editActiveTab}>
                        <TabPane tabId="3">
                          <Row>
                            <Col sm="12">
                              <div
                                className="row"
                                style={{
                                  padding: "10px 10px 10px 10px",
                                  height: "200px"
                                }}
                              >
                                <label htmlFor="">Sign Here:</label>

                                <div className={styles.container}>
                                  <div className={styles.sigContainer}>
                                    <SignatureCanvas
                                      clearOnResize={true}
                                      canvasProps={{ className: styles.sigPad }}
                                      velocityFilterWeight={0.7}
                                      throttle={50}
                                      ref={ref => {
                                        this.editVendorSignaturePad = ref;
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="4">
                          <Row>
                            <Col lg="12">
                              <Card body>
                                <CardTitle></CardTitle>
                                <CardText>
                                  <Select
                                    id="ddlSignatureStyle"
                                    onChange={this.onChangeSignStyle}
                                    options={this.state.signOptions}
                                    value={this.state.SelectedFontName}
                                    styles={signStyles}
                                  />
                                </CardText>
                              </Card>
                            </Col>
                            <Col lg="12">
                              <Card body>
                                <CardTitle></CardTitle>

                                <CardText>
                                  <div
                                    id="divEditSignature"
                                    style={{
                                      color: "black",
                                      backgroundColor: "white",
                                      fontFamily: this.state.FontName,
                                      fontSize: "80px",
                                      width: "500px",
                                      height: "150px",
                                      lineHeight: "150px"
                                    }}
                                  >
                                    {this.state.editVendorSignorName}
                                  </div>
                                </CardText>
                              </Card>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </div>
                    <div
                      className="form-group row"
                      style={{ paddingTop: "25px" }}
                    >
                      <label htmlFor="editisActive" className="col-md-4">
                        Active:
                      </label>
                      <div className="isnotified:0,">
                        <input
                          type="checkbox"
                          id="editisActive"
                          name="editisActive"
                          checked={this.state.editisActive}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-warning"
                    onClick={this.handleEditClear.bind(this)}
                  >
                    Clear Signature
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => $("#edit_vendor_signor").hide()}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-success">
                    Save{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default ManageVendorSignor;
