import React from 'react';

import notie from 'notie';
import axios from 'axios';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import SignatureCanvas from 'react-signature-canvas';
import 'react-datepicker/dist/react-datepicker.css';
import './UserCreditForm.css';
import moment from 'moment';
import $ from 'jquery';
var Hide_Div = [];
class UserCreditForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      loading: true,
      Comp_Name: this.props.Comp_Name,
      Comp_Phone_No: this.props.Comp_Phone_No,
      Comp_Address: this.props.Comp_Address,
      Current_Date: moment().format('MM-DD-YYYY'),

      hdproduct_1: '',
      hdproduct_title_1: '',
      mou_1: '',
      required_sessions_1: '',
      hdcredit_assessment_1: '',
      credit_assessment_1: 0,

      hdproduct_2: '',
      hdproduct_title_2: '',
      mou_2: '',
      required_sessions_2: '',
      hdcredit_assessment_2: 0,
      credit_assessment_2: 0,

      hdproduct_3: '',
      hdproduct_title_3: '',
      mou_3: '',
      required_sessions_3: '',
      hdcredit_assessment_3: 0,
      credit_assessment_3: 0,

      hdproduct_4: '',
      hdproduct_title_4: '',
      mou_4: '',
      required_sessions_4: '',
      hdcredit_assessment_4: 0,
      credit_assessment_4: 0,

      hdproduct_5: '',
      hdproduct_title_5: '',
      mou_5: '',
      required_sessions_5: '',
      hdcredit_assessment_5: 0,
      credit_assessment_5: 0,

      hdproduct_6: '',
      hdproduct_title_6: '',
      mou_6: '',
      required_sessions_6: '',
      hdcredit_assessment_6: 0,
      credit_assessment_6: 0,

      hdproduct_7: '',
      hdproduct_title_7: '',
      mou_7: '',
      required_sessions_7: '',
      hdcredit_assessment_7: 0,
      credit_assessment_7: 0,

      rdoFormofBussiness: '',
      chkExpempt: '',
      client_fax: '',
      client_phone: '',
      bank_phone_number: '',
      trade_phone_1: '',
      trade_fax_1: '',
      trade_phone_2: '',
      trade_fax_2: '',

      Show_Product: '1',
      Products: [],
      addProduct_1: '',
      addProduct_1_Selected: '',
      addProduct_2: '',
      addProduct_2_Selected: '',
      addProduct_3: '',
      addProduct_3_Selected: '',
      addProduct_4: '',
      addProduct_4_Selected: '',
      addProduct_5: '',
      addProduct_5_Selected: '',
      addProduct_6: '',
      addProduct_6_Selected: '',
      addProduct_7: '',
      addProduct_7_Selected: '',
      Auth_Sign: '',
      auth_name: '',
      auth_title: '',
      hdauth_signature: '',
      hdCalled: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);

    this.handleAuthDate = this.handleAuthDate.bind(this);
    this.Product_Show = this.Product_Show.bind(this);
    this.Product_Hide = this.Product_Hide.bind(this);
    this.GetAllProducts = this.GetAllProducts.bind(this);
    this.GetCreditFormData = this.GetCreditFormData.bind(this);

    this.addProduct1SetValues = this.addProduct1SetValues.bind(this);
    this.addProduct2SetValues = this.addProduct2SetValues.bind(this);
    this.addProduct3SetValues = this.addProduct3SetValues.bind(this);
    this.addProduct4SetValues = this.addProduct4SetValues.bind(this);
    this.addProduct5SetValues = this.addProduct5SetValues.bind(this);
    this.addProduct6SetValues = this.addProduct6SetValues.bind(this);
    this.addProduct7SetValues = this.addProduct7SetValues.bind(this);
  }
  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    localStorage.setItem(target.name, value);
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleAuthDate(date) {
    this.setState({
      auth_date: date
    });
  }

  handleOptionChange = changeEvent => {
    const target = changeEvent.target;

    const value = target.type === 'radio' ? target.value : target.checked;
    localStorage.setItem(target.name, value);
    const name = target.name;
    console.log('target.name', target.name, 'target.value', value);
    this.setState({
      [name]: value
    });
  };

  Product_Show() {
    if (Hide_Div.length > 0) {
      let divID = '#divProduct_' + Hide_Div.shift();
      $(divID).show();
    } else {
      if (parseInt(localStorage.getItem('Show_Product')) < 7) {
        localStorage.setItem(
          'Show_Product',
          parseInt(localStorage.getItem('Show_Product')) + 1
        );
        this.setState({
          Show_Product: localStorage.getItem('Show_Product')
        });
      }
    }
  }

  Product_Hide(index) {
    let divID = '#divProduct_' + index;
    Hide_Div.push(index);
    $(divID).hide();

    // localStorage.setItem(
    //   'Show_Product',
    //   parseInt(localStorage.getItem('Show_Product')) - 1
    // );

    localStorage.removeItem(`addProduct_${index}`);
    localStorage.removeItem(`hdproduct_title_${index}`);
    let addProduct_Selected = `addProduct_${index}_Selected`;
    localStorage.removeItem(addProduct_Selected);
    localStorage.removeItem(`hdcredit_assessment_${index}`);
    localStorage.removeItem(`mou_${index}`);
    localStorage.removeItem(`required_sessions_${index}`);
    localStorage.removeItem(`hdproduct_${index}`);
    this.setState({
      Show_Product: parseInt(localStorage.getItem('Show_Product'))
    });
    let hdproduct_title = `hdproduct_title_${index}`;
    let mou = `mou_${index}`;
    let required_sessions = `required_sessions_${index}`;
    let hdcredit_assessment = `hdcredit_assessment_${index}`;
    let hdproduct = `hdproduct_${index}`;
    let credit_assessment = `#credit_assessment_${index}`;
    $(credit_assessment).text('$0');
    this.setState({
      [addProduct_Selected]: '',
      [hdproduct_title]: '',
      [mou]: '',
      [required_sessions]: '',
      [hdcredit_assessment]: '',
      [hdproduct]: ''
    });
    console.info('this.state.Show_Product', this.state.Show_Product);
  }

  GetAllProducts() {
    const api = `${process.env.REACT_APP_BASE_URL}products/api/DDProduct`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_user_token')}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = '/client_login');
        }
        if (res.data.success === false) {
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        //console.log(res.data);

        this.setState({
          Products: res.data
        });

        this.GetCreditFormData();
      });
  }

  GetCreditFormData() {
    Object.keys(localStorage).forEach(function(key) {
      if (!/^tcc_/.test(key)) {
        localStorage.removeItem(key);
      }
    });

    localStorage.setItem('Show_Product', 1);
    try {
      const api = `${process.env.REACT_APP_BASE_URL}credit_form/api`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_user_token')}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/client_login');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          if (res.data[0] !== undefined) {
            console.log(res.data[0].product_1);

            let _addProduct_1_Selected =
              res.data[0].product_title_1 !== ''
                ? JSON.parse(
                    `{"label" : "${res.data[0].product_title_1}","value":"${res.data[0].product_selected_1}"}`
                  )
                : '';

            let _addProduct_2_Selected =
              res.data[0].product_title_2 !== ''
                ? JSON.parse(
                    `{"label" : "${res.data[0].product_title_2}","value":"${res.data[0].product_selected_2}"}`
                  )
                : '';
            if (res.data[0].product_title_2 !== '') {
              localStorage.setItem(
                'Show_Product',
                parseInt(localStorage.getItem('Show_Product')) + 1
              );
            }

            let _addProduct_3_Selected =
              res.data[0].product_title_3 !== ''
                ? JSON.parse(
                    `{"label" : "${res.data[0].product_title_3}","value":"${res.data[0].product_selected_3}"}`
                  )
                : '';

            if (res.data[0].product_title_3 !== '') {
              localStorage.setItem(
                'Show_Product',
                parseInt(localStorage.getItem('Show_Product')) + 1
              );
            }

            let _addProduct_4_Selected =
              res.data[0].product_title_4 !== ''
                ? JSON.parse(
                    `{"label" : "${res.data[0].product_title_4}","value":"${res.data[0].product_selected_4}"}`
                  )
                : '';

            if (res.data[0].product_title_4 !== '') {
              localStorage.setItem(
                'Show_Product',
                parseInt(localStorage.getItem('Show_Product')) + 1
              );
            }

            let _addProduct_5_Selected =
              res.data[0].product_title_5 !== ''
                ? `{"label" : "${res.data[0].product_title_5}","value":"${res.data[0].product_selected_5}"}`
                : '';

            if (res.data[0].product_title_5 !== '') {
              localStorage.setItem(
                'Show_Product',
                parseInt(localStorage.getItem('Show_Product')) + 1
              );
            }

            let _addProduct_6_Selected =
              res.data[0].product_title_6 !== ''
                ? JSON.parse(
                    `{"label" : "${res.data[0].product_title_6}","value":"${res.data[0].product_selected_6}"}`
                  )
                : '';

            if (res.data[0].product_title_6 !== '') {
              localStorage.setItem(
                'Show_Product',
                parseInt(localStorage.getItem('Show_Product')) + 1
              );
            }

            let _addProduct_7_Selected =
              res.data[0].product_title_7 !== ''
                ? JSON.parse(
                    `{"label" : "${res.data[0].product_title_7}","value":"${res.data[0].product_selected_7}"}`
                  )
                : '';

            if (res.data[0].product_title_7 !== '') {
              localStorage.setItem(
                'Show_Product',
                parseInt(localStorage.getItem('Show_Product')) + 1
              );
            }

            // console.log('_addProduct_1_Selected', _addProduct_1_Selected);
            this.setState({
              loading: true,
              hdproduct_title_1: res.data[0].product_title_1,
              hdproduct_1: res.data[0].product_selected_1,
              addProduct_1_Selected: _addProduct_1_Selected,
              mou_1: res.data[0].mou_1,
              hdcredit_assessment_1: res.data[0].credit_assessment_1,
              credit_assessment_1: res.data[0].credit_assessment_1,
              required_sessions_1: res.data[0].required_sessions_1,

              hdproduct_title_2: res.data[0].product_title_2,
              hdproduct_2: res.data[0].product_selected_2,
              addProduct_2_Selected: _addProduct_2_Selected,
              mou_2: res.data[0].mou_2,
              hdcredit_assessment_2: res.data[0].credit_assessment_2,
              credit_assessment_2: res.data[0].credit_assessment_2,
              required_sessions_2: res.data[0].required_sessions_2,

              hdproduct_title_3: res.data[0].product_title_3,
              hdproduct_3: res.data[0].product_selected_3,
              addProduct_3_Selected: _addProduct_3_Selected,
              mou_3: res.data[0].mou_3,
              hdcredit_assessment_3: res.data[0].credit_assessment_3,
              credit_assessment_3: res.data[0].credit_assessment_3,
              required_sessions_3: res.data[0].required_sessions_3,

              hdproduct_title_4: res.data[0].product_title_4,
              hdproduct_4: res.data[0].product_selected_4,
              addProduct_4_Selected: _addProduct_4_Selected,
              mou_4: res.data[0].mou_4,
              hdcredit_assessment_4: res.data[0].credit_assessment_4,
              credit_assessment_4: res.data[0].credit_assessment_4,
              required_sessions_4: res.data[0].required_sessions_4,

              hdproduct_title_5: res.data[0].product_title_5,
              hdproduct_5: res.data[0].product_selected_5,
              addProduct_5_Selected: _addProduct_5_Selected,
              mou_5: res.data[0].mou_5,
              hdcredit_assessment_5: res.data[0].credit_assessment_5,
              credit_assessment_5: res.data[0].credit_assessment_5,
              required_sessions_5: res.data[0].required_sessions_5,

              hdproduct_title_6: res.data[0].product_title_6,
              hdproduct_6: res.data[0].product_selected_6,
              addProduct_6_Selected: _addProduct_6_Selected,
              mou_6: res.data[0].mou_6,
              hdcredit_assessment_6: res.data[0].credit_assessment_6,
              credit_assessment_6: res.data[0].credit_assessment_6,
              required_sessions_6: res.data[0].required_sessions_6,

              hdproduct_title_7: res.data[0].product_title_7,
              hdproduct_7: res.data[0].product_selected_7,
              addProduct_7_Selected: _addProduct_7_Selected,
              mou_7: res.data[0].mou_7,
              hdcredit_assessment_7: res.data[0].credit_assessment_7,
              credit_assessment_7: res.data[0].credit_assessment_7,
              required_sessions_7: res.data[0].required_sessions_7,

              client_fax: res.data[0].client_fax,
              client_phone: res.data[0].client_phone,

              bank_phone_number: res.data[0].bank_phone_number,

              rdoFormofBussiness: res.data[0].rdoFormofBussiness,
              chkExpempt: res.data[0].chkExpempt,

              trade_phone_1: res.data[0].trade_phone_1,
              trade_fax_1: res.data[0].trade_fax_1,
              trade_phone_2: res.data[0].trade_phone_2,
              trade_fax_2: res.data[0].trade_fax_2
            });

            localStorage.setItem(
              'required_sessions_1',
              res.data[0].required_sessions_1
            );

            localStorage.setItem(
              'required_sessions_2',
              res.data[0].required_sessions_2
            );
            localStorage.setItem(
              'required_sessions_3',
              res.data[0].required_sessions_3
            );
            localStorage.setItem(
              'required_sessions_4',
              res.data[0].required_sessions_4
            );
            localStorage.setItem(
              'required_sessions_5',
              res.data[0].required_sessions_5
            );
            localStorage.setItem(
              'required_sessions_6',
              res.data[0].required_sessions_6
            );
            localStorage.setItem(
              'required_sessions_7',
              res.data[0].required_sessions_7
            );

            $('#credit_assessment_1').text(res.data[0].credit_assessment_1);
            $('#credit_assessment_2').text(res.data[0].credit_assessment_2);
            $('#credit_assessment_3').text(res.data[0].credit_assessment_3);
            $('#credit_assessment_4').text(res.data[0].credit_assessment_4);
            $('#credit_assessment_5').text(res.data[0].credit_assessment_5);
            $('#credit_assessment_6').text(res.data[0].credit_assessment_6);
            $('#credit_assessment_7').text(res.data[0].credit_assessment_7);

            $('#client_name').val(res.data[0].client_name);

            $('#client_address').val(res.data[0].client_address);
            $('#client_city').val(res.data[0].client_city);
            $('#client_state').val(res.data[0].client_state);
            $('#client_zip').val(res.data[0].client_zip);
            $('#client_bill_address').val(res.data[0].client_bill_address);
            $('#client_bill_city').val(res.data[0].client_bill_city);
            $('#client_bill_state').val(res.data[0].client_bill_state);
            $('#client_bill_zip').val(res.data[0].client_bill_zip);

            $('#bank_name').val(res.data[0].bank_name);
            $('#bank_contact').val(res.data[0].bank_contact);
            $('#bank_acc_number').val(res.data[0].bank_acc_number);
            $('#bank_loan_acct_number').val(res.data[0].bank_loan_acct_number);
            $('#bank_address').val(res.data[0].bank_address);
            $('#bank_city').val(res.data[0].bank_city);
            $('#bank_state').val(res.data[0].bank_state);
            $('#bank_zip').val(res.data[0].bank_zip);

            $('#bank_email').val(res.data[0].bank_email);

            $('#credit_principal_1').val(res.data[0].credit_principal_1);
            $('#credit_principal_2').val(res.data[0].credit_principal_2);
            $('#credit_person').val(res.data[0].credit_person);
            $('#credit_email').val(res.data[0].credit_email);
            $('#credit_duns').val(res.data[0].credit_duns);
            $('#credit_subsidairy').val(res.data[0].credit_subsidairy);
            $('#credit_sic').val(res.data[0].credit_sic);
            $('#credit_taxpayer').val(res.data[0].credit_taxpayer);
            $('#credit_employees').val(res.data[0].credit_employees);
            $('#credit_sales').val(res.data[0].credit_sales);
            $('#credit_business').val(res.data[0].credit_business);
            $('#credit_locations').val(res.data[0].credit_locations);
            $('#credit_description').val(res.data[0].credit_description);

            $('#trade_ref_number_1').val(res.data[0].trade_ref_number_1);
            $('#trade_contact_1').val(res.data[0].trade_contact_1);
            $('#trade_address_1').val(res.data[0].trade_address_1);
            $('#trade_city_1').val(res.data[0].trade_city_1);
            $('#trade_state_1').val(res.data[0].trade_state_1);
            $('#trade_zip_1').val(res.data[0].trade_zip_1);
            $('#trade_email_1').val(res.data[0].trade_email_1);
            $('#trade_ref_number_2').val(res.data[0].trade_ref_number_2);
            $('#trade_contact_2').val(res.data[0].trade_contact_2);
            $('#trade_address_2').val(res.data[0].trade_address_2);
            $('#trade_city_2').val(res.data[0].trade_city_2);
            $('#trade_state_2').val(res.data[0].trade_state_2);
            $('#trade_state_2').val(res.data[0].trade_state_2);
            $('#trade_email_2').val(res.data[0].trade_email_2);

            $('#auth_name').val(res.data[0].auth_name);
            $('#auth_title').val(res.data[0].auth_title);
          }

          this.setState({
            loading: false
          });
        });
    } catch (err) {
      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: err,
        stay: true, // optional, default = false
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  addProduct1SetValues = selectedOption => {
    let flag = true;
    $("input[class='ddlProduct']").map(function() {
      if (
        'hdproduct_title_1' !== $(this).attr('id') &&
        selectedOption.label === $(this).val()
      ) {
        // alert('Select Different Value');
        flag = false;
        return;
      }
    });
    if (flag) {
      this.setState({
        addProduct_1_Selected: selectedOption,
        addProduct_1: selectedOption.value,
        hdproduct_1: selectedOption.value,
        hdproduct_title_1: selectedOption.label
      });

      localStorage.setItem(
        'addProduct_1_Selected',
        JSON.stringify(selectedOption)
      );
      localStorage.setItem('Show_Product', this.state.Show_Product);
      localStorage.setItem('addProduct_1', selectedOption.value);
      localStorage.setItem('hdproduct_1', selectedOption.value);
      localStorage.setItem('hdproduct_title_1', selectedOption.label);

      $('#hdproduct_1').val(selectedOption.value);
      $('#hdproduct_title_1').val(selectedOption.label);
      this.CalculateAssessment(1);
    }
  };

  addProduct2SetValues = selectedOption => {
    let flag = true;
    $("input[class='ddlProduct']").map(function() {
      if (
        'hdproduct_title_2' !== $(this).attr('id') &&
        selectedOption.label === $(this).val()
      ) {
        // alert('Select Different Value');
        flag = false;
        return;
      }
    });
    if (flag) {
      this.setState({
        addProduct_2_Selected: selectedOption,
        addProduct_2: selectedOption.value,
        hdproduct_2: selectedOption.value,
        hdproduct_title_2: selectedOption.label
      });

      localStorage.setItem(
        'addProduct_2_Selected',
        JSON.stringify(selectedOption)
      );
      localStorage.setItem('Show_Product', this.state.Show_Product);
      localStorage.setItem('addProduct_2', selectedOption.value);
      localStorage.setItem('hdproduct_2', selectedOption.value);
      localStorage.setItem('hdproduct_title_2', selectedOption.label);

      $('#hdproduct_2').val(selectedOption.value);
      $('#hdproduct_title_2').val(selectedOption.label);
      this.CalculateAssessment(2);
    }
  };

  addProduct3SetValues = selectedOption => {
    let flag = true;
    $("input[class='ddlProduct']").map(function() {
      if (
        'hdproduct_title_3' !== $(this).attr('id') &&
        selectedOption.label === $(this).val()
      ) {
        // alert('Select Different Value');
        flag = false;
        return;
      }
    });
    if (flag) {
      this.setState({
        addProduct_3_Selected: selectedOption,
        addProduct_3: selectedOption.value,
        hdproduct_3: selectedOption.value,
        hdproduct_title_3: selectedOption.label
      });

      localStorage.setItem(
        'addProduct_3_Selected',
        JSON.stringify(selectedOption)
      );
      localStorage.setItem('Show_Product', this.state.Show_Product);
      localStorage.setItem('addProduct_3', selectedOption.value);
      localStorage.setItem('hdproduct_3', selectedOption.value);
      localStorage.setItem('hdproduct_title_3', selectedOption.label);

      $('#hdproduct_3').val(selectedOption.value);
      $('#hdproduct_title_3').val(selectedOption.label);
      this.CalculateAssessment(3);
    }
  };

  addProduct4SetValues = selectedOption => {
    let flag = true;
    $("input[class='ddlProduct']").map(function() {
      if (
        'hdproduct_title_4' !== $(this).attr('id') &&
        selectedOption.label === $(this).val()
      ) {
        // alert('Select Different Value');
        flag = false;
        return;
      }
    });
    if (flag) {
      this.setState({
        addProduct_4_Selected: selectedOption,
        addProduct_4: selectedOption.value,
        hdproduct_4: selectedOption.value,
        hdproduct_title_4: selectedOption.label
      });

      localStorage.setItem(
        'addProduct_4_Selected',
        JSON.stringify(selectedOption)
      );
      localStorage.setItem('Show_Product', this.state.Show_Product);
      localStorage.setItem('addProduct_4', selectedOption.value);
      localStorage.setItem('hdproduct_4', selectedOption.value);
      localStorage.setItem('hdproduct_title_4', selectedOption.label);

      $('#hdproduct_4').val(selectedOption.value);
      $('#hdproduct_title_4').val(selectedOption.label);
      this.CalculateAssessment(4);
    }
  };

  addProduct5SetValues = selectedOption => {
    let flag = true;
    $("input[class='ddlProduct']").map(function() {
      if (
        'hdproduct_title_5' !== $(this).attr('id') &&
        selectedOption.label === $(this).val()
      ) {
        // alert('Select Different Value');
        flag = false;
        return;
      }
    });
    if (flag) {
      this.setState({
        addProduct_5_Selected: selectedOption,
        addProduct_5: selectedOption.value,
        hdproduct_5: selectedOption.value,
        hdproduct_title_5: selectedOption.label
      });
      localStorage.setItem(
        'addProduct_5_Selected',
        JSON.stringify(selectedOption)
      );
      localStorage.setItem('Show_Product', this.state.Show_Product);
      localStorage.setItem('addProduct_5', selectedOption.value);
      localStorage.setItem('hdproduct_5', selectedOption.value);
      localStorage.setItem('hdproduct_title_5', selectedOption.label);

      $('#hdproduct_5').val(selectedOption.value);
      $('#hdproduct_title_5').val(selectedOption.label);
      this.CalculateAssessment(5);
    }
  };

  addProduct6SetValues = selectedOption => {
    let flag = true;
    $("input[class='ddlProduct']").map(function() {
      if (
        'hdproduct_title_6' !== $(this).attr('id') &&
        selectedOption.label === $(this).val()
      ) {
        // alert('Select Different Value');
        flag = false;
        return;
      }
    });
    if (flag) {
      this.setState({
        addProduct_6_Selected: selectedOption,
        addProduct_6: selectedOption.value,
        hdproduct_6: selectedOption.value,
        hdproduct_title_6: selectedOption.label
      });

      localStorage.setItem(
        'addProduct_6_Selected',
        JSON.stringify(selectedOption)
      );
      localStorage.setItem('Show_Product', this.state.Show_Product);
      localStorage.setItem('addProduct_6', selectedOption.value);
      localStorage.setItem('hdproduct_6', selectedOption.value);
      localStorage.setItem('hdproduct_title_6', selectedOption.label);

      $('#hdproduct_6').val(selectedOption.value);
      $('#hdproduct_title_6').val(selectedOption.label);
      this.CalculateAssessment(6);
    }
  };

  addProduct7SetValues = selectedOption => {
    let flag = true;
    $("input[class='ddlProduct']").map(function() {
      if (
        'hdproduct_title_7' !== $(this).attr('id') &&
        selectedOption.label === $(this).val()
      ) {
        // alert('Select Different Value');
        flag = false;
        return;
      }
    });
    if (flag) {
      this.setState({
        addProduct_7_Selected: selectedOption,
        addProduct_7: selectedOption.value,
        hdproduct_7: selectedOption.value,
        hdproduct_title_7: selectedOption.label
      });

      localStorage.setItem(
        'addProduct_7_Selected',
        JSON.stringify(selectedOption)
      );
      localStorage.setItem('Show_Product', this.state.Show_Product);
      localStorage.setItem('addProduct_7', selectedOption.value);
      localStorage.setItem('hdproduct_7', selectedOption.value);
      localStorage.setItem('hdproduct_title_7', selectedOption.label);

      $('#hdproduct_7').val(selectedOption.value);
      $('#hdproduct_title_7').val(selectedOption.label);
      this.CalculateAssessment(7);
    }
  };

  CalculateAssessment(ProductType) {
    try {
      //console.log('ProductType', ProductType);
      if (ProductType === 1) {
        if ($('#mou_1').val() !== '') {
          if (parseInt($('#mou_1').val()) < 10000) {
            $('#required_sessions_1').val(0);
            this.setState({ required_sessions_1: 0 });
            localStorage.setItem('required_sessions_1', 0);
            $('#credit_assessment_1').text('$0');
            $('#hdcredit_assessment_1').val(0);
            return notie.alert({
              type: 'info', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: 'Estimated MOU must be greater than 10K',
              stay: false, // optional, default = false
              time: 6, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          let required_sessions_1 = parseInt(
            parseInt($('#mou_1').val()) / 10000
          );
          $('#required_sessions_1').val(required_sessions_1);
          this.setState({
            required_sessions_1: required_sessions_1
          });
          localStorage.setItem('required_sessions_1', required_sessions_1);

          let value = localStorage.getItem('addProduct_1').split('|');
          let ProductRate = value[1];

          let Total_Assessment = (ProductRate * $('#mou_1').val() * 2).toFixed(
            2
          );

          if (!isNaN(parseInt(Total_Assessment))) {
            $('#credit_assessment_1').text('$' + Total_Assessment);
            $('#hdcredit_assessment_1').val(Total_Assessment);
            this.setState({
              hdcredit_assessment_1: Total_Assessment
            });
            localStorage.setItem('hdcredit_assessment_1', Total_Assessment);
          } else {
            this.setState({ hdcredit_assessment_1: 0 });
            localStorage.setItem('hdcredit_assessment_1', 0);
          }
        } else {
          $('#required_sessions_1').val(0);
          this.setState({ required_sessions_1: 0 });
          localStorage.setItem('required_sessions_1', 0);
          $('#credit_assessment_1').text('$0');
          $('#hdcredit_assessment_1').val(0);
        }
      } else if (ProductType === 2) {
        if ($('#mou_2').val() !== '') {
          if (parseInt($('#mou_2').val()) < 10000) {
            $('#required_sessions_2').val(0);
            this.setState({
              required_sessions_2: 0
            });
            $('#credit_assessment_2').text('$0');
            $('#hdcredit_assessment_2').val(0);
            return notie.alert({
              type: 'info', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: 'Estimated MOU must be greater than 10K',
              stay: false, // optional, default = false
              time: 6, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }

          let required_sessions_2 = parseInt(
            parseInt($('#mou_2').val()) / 10000
          );
          $('#required_sessions_2').val(required_sessions_2);
          this.setState({
            required_sessions_2: required_sessions_2
          });
          let value = localStorage.getItem('addProduct_2').split('|');
          let ProductRate = value[1];

          let Total_Assessment = (ProductRate * $('#mou_2').val() * 2).toFixed(
            2
          );

          if (!isNaN(parseInt(Total_Assessment))) {
            $('#credit_assessment_2').text('$' + Total_Assessment);
            $('#hdcredit_assessment_2').val(Total_Assessment);
            this.setState({
              hdcredit_assessment_2: Total_Assessment
            });
          } else {
            this.setState({
              hdcredit_assessment_2: 0
            });
          }
        } else {
          $('#required_sessions_2').val(0);
          this.setState({ required_sessions_2: 0 });
          localStorage.setItem('required_sessions_2', 0);
          $('#credit_assessment_2').text('$0');
          $('#hdcredit_assessment_2').val(0);
        }
      } else if (ProductType === 3) {
        if ($('#mou_3').val() !== '') {
          if (parseInt($('#mou_3').val()) < 10000) {
            $('#required_sessions_3').val(0);
            this.setState({ required_sessions_3: 0 });
            $('#credit_assessment_3').text('$0');
            $('#hdcredit_assessment_3').val(0);
            return notie.alert({
              type: 'info', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: 'Estimated MOU must be greater than 10K',
              stay: false, // optional, default = false
              time: 6, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          let required_sessions_3 = parseInt(
            parseInt($('#mou_3').val()) / 10000
          );
          $('#required_sessions_3').val(required_sessions_3);
          this.setState({ required_sessions_3: required_sessions_3 });
          let value = localStorage.getItem('addProduct_3').split('|');
          let ProductRate = value[1];

          let Total_Assessment = (ProductRate * $('#mou_3').val() * 2).toFixed(
            2
          );

          if (!isNaN(parseInt(Total_Assessment))) {
            $('#credit_assessment_3').text('$' + Total_Assessment);
            $('#hdcredit_assessment_3').val(Total_Assessment);
            this.setState({ hdcredit_assessment_3: Total_Assessment });
          } else {
            this.setState({ hdcredit_assessment_3: 0 });
          }
        } else {
          $('#required_sessions_3').val(0);
          this.setState({ required_sessions_3: 0 });
          localStorage.setItem('required_sessions_3', 0);
          $('#credit_assessment_3').text('$0');
          $('#hdcredit_assessment_3').val(0);
        }
      } else if (ProductType === 4) {
        if ($('#mou_4').val() !== '') {
          if (parseInt($('#mou_4').val()) < 10000) {
            $('#required_sessions_4').val(0);
            this.setState({ required_sessions_4: 0 });
            $('#credit_assessment_4').text('$0');
            $('#hdcredit_assessment_4').val(0);
            return notie.alert({
              type: 'info', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: 'Estimated MOU must be greater than 10K',
              stay: false, // optional, default = false
              time: 6, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          let required_sessions_4 = parseInt(
            parseInt($('#mou_4').val()) / 10000
          );
          $('#required_sessions_4').val(required_sessions_4);
          this.setState({ required_sessions_4: required_sessions_4 });
          let value = localStorage.getItem('addProduct_4').split('|');
          let ProductRate = value[1];

          let Total_Assessment = (ProductRate * $('#mou_4').val() * 2).toFixed(
            2
          );
          if (!isNaN(parseInt(Total_Assessment))) {
            $('#credit_assessment_4').text('$' + Total_Assessment);
            $('#hdcredit_assessment_4').val(Total_Assessment);
            this.setState({ hdcredit_assessment_4: Total_Assessment });
          } else {
            this.setState({ hdcredit_assessment_4: 0 });
          }
        } else {
          $('#required_sessions_4').val(0);
          this.setState({ required_sessions_4: 0 });
          localStorage.setItem('required_sessions_4', 0);
          $('#credit_assessment_4').text('$0');
          $('#hdcredit_assessment_4').val(0);
        }
      } else if (ProductType === 5) {
        if ($('#mou_5').val() !== '') {
          if (parseInt($('#mou_5').val()) < 10000) {
            $('#required_sessions_5').val(0);
            this.setState({ required_sessions_5: 0 });
            $('#credit_assessment_5').text('$0');
            $('#hdcredit_assessment_5').val(0);
            return notie.alert({
              type: 'info', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: 'Estimated MOU must be greater than 10K',
              stay: false, // optional, default = false
              time: 6, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          let required_sessions_5 = parseInt(
            parseInt($('#mou_5').val()) / 10000
          );
          $('#required_sessions_5').val(required_sessions_5);
          this.setState({ required_sessions_5: required_sessions_5 });
          let value = localStorage.getItem('addProduct_5').split('|');
          let ProductRate = value[1];

          let Total_Assessment = (ProductRate * $('#mou_5').val() * 2).toFixed(
            2
          );
          if (!isNaN(parseInt(Total_Assessment))) {
            $('#credit_assessment_5').text('$' + Total_Assessment);
            $('#hdcredit_assessment_5').val(Total_Assessment);
            this.setState({ hdcredit_assessment_5: Total_Assessment });
          } else {
            this.setState({ hdcredit_assessment_5: 0 });
          }
        } else {
          $('#required_sessions_5').val(0);
          this.setState({ required_sessions_5: 0 });
          localStorage.setItem('required_sessions_5', 0);
          $('#credit_assessment_5').text('$0');
          $('#hdcredit_assessment_5').val(0);
        }
      } else if (ProductType === 6) {
        if ($('#mou_6').val() !== '') {
          if (parseInt($('#mou_6').val()) < 10000) {
            $('#required_sessions_6').val(0);
            this.setState({ required_sessions_6: 0 });
            $('#credit_assessment_6').text('$0');
            $('#hdcredit_assessment_6').val(0);
            return notie.alert({
              type: 'info', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: 'Estimated MOU must be greater than 10K',
              stay: false, // optional, default = false
              time: 6, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          let required_sessions_6 = parseInt(
            parseInt($('#mou_6').val()) / 10000
          );
          $('#required_sessions_6').val(required_sessions_6);
          this.setState({ required_sessions_6: required_sessions_6 });
          let value = localStorage.getItem('addProduct_6').split('|');
          let ProductRate = value[1];

          let Total_Assessment = (ProductRate * $('#mou_6').val() * 2).toFixed(
            2
          );
          if (!isNaN(parseInt(Total_Assessment))) {
            $('#credit_assessment_6').text('$' + Total_Assessment);
            $('#hdcredit_assessment_6').val(Total_Assessment);
            this.setState({
              hdcredit_assessment_6: Total_Assessment
            });
          } else {
            this.setState({ hdcredit_assessment_6: 0 });
          }
        } else {
          $('#required_sessions_6').val(0);
          this.setState({ required_sessions_6: 0 });
          localStorage.setItem('required_sessions_6', 0);
          $('#credit_assessment_6').text('$0');
          $('#hdcredit_assessment_6').val(0);
        }
      } else if (ProductType === 7) {
        if ($('#mou_7').val() !== '') {
          if (parseInt($('#mou_7').val()) < 10000) {
            $('#required_sessions_7').val(0);
            this.setState({ required_sessions_7: 0 });
            $('#credit_assessment_7').text('$0');
            $('#hdcredit_assessment_7').val(0);
            return notie.alert({
              type: 'info', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: 'Estimated MOU must be greater than 10K',
              stay: false, // optional, default = false
              time: 6, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          let required_sessions_7 = parseInt(
            parseInt($('#mou_7').val()) / 10000
          );
          $('#required_sessions_7').val(required_sessions_7);
          this.setState({ required_sessions_7: required_sessions_7 });
          let value = localStorage.getItem('addProduct_7').split('|');
          let ProductRate = value[1];

          let Total_Assessment = (ProductRate * $('#mou_7').val() * 2).toFixed(
            2
          );

          if (!isNaN(parseInt(Total_Assessment))) {
            $('#credit_assessment_7').text('$' + Total_Assessment);
            $('#hdcredit_assessment_7').val(Total_Assessment);
            this.setState({ hdcredit_assessment_7: Total_Assessment });
          } else {
            this.setState({ hdcredit_assessment_7: 0 });
          }
        } else {
          $('#required_sessions_7').val(0);
          this.setState({ required_sessions_7: 0 });
          localStorage.setItem('required_sessions_7', 0);
          $('#credit_assessment_7').text('$0');
          $('#hdcredit_assessment_7').val(0);
        }
      }
    } catch (error) {}
  }

  handleClear() {
    this.signaturePad.clear();
    this.setState({ Auth_Sign: '' });

    $('#hdauth_signature').val('');

    $('#divImgSig').hide();
    $('.clsimgSig').attr('src', '');
    $('.clsimgSig').hide();
    $('#divSig').hide();
  }

  handleSave() {
    if (this.signaturePad.isEmpty()) {
      // eslint-disable-next-line no-alert
      notie.alert({
        type: 'info', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: 'Please Provide a Signature First',
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    } else {
      var data = this.signaturePad.toDataURL('image/png');
      this.setState({ Auth_Sign: data });
      $('#hdauth_signature').val(data);
      $('#divSig').show();
      $('#divImgSig').show();
      $('.clsimgSig').show();
      $('.clsimgSig').attr('src', this.signaturePad.toDataURL('image/png'));
    }
  }

  checkProduct(e, ControlID) {
    //alert(e.label);
    $("input[class='ddlProduct']").map(function() {
      console.log('$(this).attr("id")', $(this).attr('id'), ControlID);
      if (ControlID !== $(this).attr('id') && e.label === $(this).val()) {
        alert('Select Different Value');
        return false;
      }
      return true;
    });
  }

  componentWillMount = () => {
    //console.log('UserCreditForm will mount');
  };

  componentDidMount = () => {
    let script = document.createElement('script');
    script.src = 'assets/examples/js/forms/wizard.js';
    document.body.appendChild(script);

    $('.active_left_menu').removeClass('active_left_menu');
    $('#lnkcredit').addClass('active_left_menu');

    this.GetAllProducts();
  };

  componentWillReceiveProps = nextProps => {
    //console.log('UserCreditForm will receive props', nextProps);
  };

  componentWillUpdate = (nextProps, nextState) => {
    //console.log('UserCreditForm will update', nextProps, nextState);
  };

  componentDidUpdate = () => {
    //console.log('UserCreditForm did update');
  };

  componentWillUnmount = () => {
    //console.log('UserCreditForm will unmount');
  };

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return (
      <div>
        <div className='loadingDiv' style={{ display: 'none' }} />
        <div className='page-header'>
          <h1 className='page-title'>CLIENT CREDIT FORM</h1>
        </div>
        <input
          type='hidden'
          id='hdCalled'
          name='hdCalled'
          onChange={this.handleChange}
        />
        <div className='page-content'>
          <div className='col-lg-12'>
            <div className='panel' id='exampleWizardForm'>
              <div className='panel-heading' />
              <div className='panel-body'>
                <div className='row'>
                  <div
                    className='steps steps-sm row seven-cols'
                    data-plugin='matchHeight'
                    data-by-row='true'
                    role='tablist'
                  >
                    <div
                      className='step col-lg-2 current'
                      data-target='#client'
                      role='tab'
                    >
                      <span className='step-number'>1</span>
                      <div className='step-desc'>
                        <span className='step-title'>Client</span>
                      </div>
                    </div>

                    <div
                      className='step col-lg-2'
                      data-target='#bank'
                      role='tab'
                    >
                      <span className='step-number'>2</span>
                      <div className='step-desc'>
                        <span className='step-title'>Bank</span>
                      </div>
                    </div>

                    <div
                      className='step col-lg-2'
                      data-target='#credit'
                      role='tab'
                    >
                      <span className='step-number'>3</span>
                      <div className='step-desc'>
                        <span className='step-title'>Credit</span>
                      </div>
                    </div>

                    <div
                      className='step col-lg-2'
                      data-target='#trade'
                      role='tab'
                    >
                      <span className='step-number'>4</span>
                      <div className='step-desc'>
                        <span className='step-title'>Trade</span>
                      </div>
                    </div>

                    <div
                      className='step col-lg-2'
                      data-target='#Signature'
                      role='tab'
                    >
                      <span className='step-number'>5</span>
                      <div className='step-desc'>
                        <span className='step-title'>Signature</span>
                      </div>
                    </div>
                  </div>

                  <div className='wizard-content' style={{ width: '100%' }}>
                    <div
                      className='wizard-pane active'
                      id='client'
                      role='tabpanel'
                    >
                      <h4>Product Selection</h4>
                      <form id='productform'>
                        <div className='row' id='divProduct_1'>
                          <div className='form-group col-md-3'>
                            <label
                              className='form-control-label'
                              htmlFor='product'
                              style={{ paddingLeft: '15px' }}
                            >
                              Product
                            </label>
                            <Select
                              required
                              aria-required='true'
                              id='product_1'
                              className='col-md-12'
                              onChange={this.addProduct1SetValues}
                              placeholder=''
                              options={this.state.Products}
                              value={this.state.addProduct_1_Selected}
                            />
                            <input
                              type='hidden'
                              id='hdproduct_1'
                              name='hdproduct_1'
                              value={this.state.hdproduct_1}
                              onChange={this.handleChange}
                            />
                            <input
                              type='hidden'
                              id='hdproduct_title_1'
                              name='hdproduct_title_1'
                              value={this.state.hdproduct_title_1}
                              onChange={this.handleChange}
                              className='ddlProduct'
                            />
                          </div>
                          <div className='form-group col-md-3'>
                            <label
                              className='form-control-label'
                              htmlFor='mou_1'
                            >
                              Estimated MOU
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='mou_1'
                              name='mou_1'
                              required='required'
                              pattern='/^\d+$/'
                              onBlur={() => this.CalculateAssessment(1)}
                              value={this.state.mou_1}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className='form-group col-md-3'>
                            <label
                              className='form-control-label'
                              htmlFor='required_sessions'
                            >
                              Required Sessions
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='required_sessions_1'
                              name='required_sessions_1'
                              readOnly
                              pattern='/^\d+$/'
                              value={this.state.required_sessions_1}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className='form-group col-md-3'>
                            <label className='form-control-label'>
                              Net30 / Credit Risk Assessment
                            </label>
                            <div className='row'>
                              <div className='col-6'>
                                <h4 id='credit_assessment_1'>
                                  $
                                  {localStorage.getItem(
                                    'hdcredit_assessment_1'
                                  ) === null
                                    ? '0'
                                    : localStorage.getItem(
                                        'hdcredit_assessment_1'
                                      )}
                                </h4>
                                <input
                                  type='hidden'
                                  id='hdcredit_assessment_1'
                                  name='hdcredit_assessment_1'
                                  value={this.state.hdcredit_assessment_1}
                                  onChange={this.handleChange}
                                />
                              </div>
                              <div className='col-6'>
                                <span
                                  onClick={() => this.Product_Show()}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <span className='add-icon' />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        {parseInt(localStorage.getItem('Show_Product')) > 1 ||
                        this.state.addProduct_2_Selected !== '' ? (
                          <div className='row' id='divProduct_2'>
                            <div className='form-group col-md-3'>
                              <label
                                className='form-control-label'
                                htmlFor='product_2'
                                style={{ paddingLeft: '15px' }}
                              >
                                Product
                              </label>
                              <Select
                                required
                                aria-required='true'
                                id='product_2'
                                className='col-md-12'
                                onChange={this.addProduct2SetValues}
                                placeholder=''
                                options={this.state.Products}
                                value={this.state.addProduct_2_Selected}
                              />
                              <input
                                type='hidden'
                                id='hdproduct_2'
                                name='hdproduct_2'
                                value={this.state.hdproduct_2}
                                onChange={this.handleChange}
                              />
                              <input
                                type='hidden'
                                id='hdproduct_title_2'
                                name='hdproduct_title_2'
                                value={this.state.hdproduct_title_2}
                                onChange={this.handleChange}
                                className='ddlProduct'
                              />
                            </div>
                            <div className='form-group col-md-3'>
                              <label
                                className='form-control-label'
                                htmlFor='mou_2'
                              >
                                Estimated MOU
                              </label>
                              <input
                                type='text'
                                className='form-control'
                                id='mou_2'
                                name='mou_2'
                                required='required'
                                pattern='/^\d+$/'
                                onBlur={() => this.CalculateAssessment(2)}
                                value={this.state.mou_2}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className='form-group col-md-3'>
                              <label
                                className='form-control-label'
                                htmlFor='required_sessions_2'
                              >
                                Required Sessions
                              </label>
                              <input
                                type='text'
                                className='form-control'
                                id='required_sessions_2'
                                name='required_sessions_2'
                                readOnly
                                pattern='/^\d+$/'
                                onKeyUp={() => this.CalculateAssessment(2)}
                                value={this.state.required_sessions_2}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className='form-group col-md-3'>
                              <label className='form-control-label'>
                                Net30 / Credit Risk Assessment
                              </label>

                              <div className='row'>
                                <div className='col-6'>
                                  <h4 id='credit_assessment_2'>
                                    $ {this.state.hdcredit_assessment_2}
                                  </h4>
                                  <input
                                    type='hidden'
                                    id='hdcredit_assessment_2'
                                    name='hdcredit_assessment_2'
                                    value={this.state.hdcredit_assessment_2}
                                    onChange={this.handleChange}
                                  />
                                </div>
                                <div className='col-6'>
                                  <span
                                    onClick={() => this.Product_Hide(2)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <span className='trash-icon' />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                        {parseInt(localStorage.getItem('Show_Product')) > 2 ||
                        this.state.addProduct_3_Selected !== '' ? (
                          <div className='row' id='divProduct_3'>
                            <div className='form-group col-md-3'>
                              <label
                                className='form-control-label'
                                htmlFor='product_3'
                                style={{ paddingLeft: '15px' }}
                              >
                                Product
                              </label>
                              <Select
                                required
                                aria-required='true'
                                id='product_3'
                                className='col-md-12'
                                onChange={this.addProduct3SetValues}
                                placeholder=''
                                options={this.state.Products}
                                value={this.state.addProduct_3_Selected}
                              />
                              <input
                                type='hidden'
                                id='hdproduct_3'
                                name='hdproduct_3'
                                value={this.state.hdproduct_3}
                                onChange={this.handleChange}
                              />
                              <input
                                type='hidden'
                                id='hdproduct_title_3'
                                name='hdproduct_title_3'
                                value={this.state.hdproduct_title_3}
                                onChange={this.handleChange}
                                className='ddlProduct'
                              />
                            </div>
                            <div className='form-group col-md-3'>
                              <label
                                className='form-control-label'
                                htmlFor='mou_3'
                              >
                                Estimated MOU
                              </label>
                              <input
                                type='text'
                                className='form-control'
                                id='mou_3'
                                name='mou_3'
                                required='required'
                                pattern='/^\d+$/'
                                onBlur={() => this.CalculateAssessment(3)}
                                value={this.state.mou_3}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className='form-group col-md-3'>
                              <label
                                className='form-control-label'
                                htmlFor='required_sessions_3'
                              >
                                Required Sessions
                              </label>
                              <input
                                type='text'
                                className='form-control'
                                id='required_sessions_3'
                                name='required_sessions_3'
                                readOnly
                                pattern='/^\d+$/'
                                onKeyUp={() => this.CalculateAssessment(3)}
                                value={this.state.required_sessions_3}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className='form-group col-md-3'>
                              <label className='form-control-label'>
                                Net30 / Credit Risk Assessment
                              </label>

                              <div className='row'>
                                <div className='col-6'>
                                  <h4 id='credit_assessment_3'>
                                    $ {this.state.credit_assessment_3}
                                  </h4>
                                  <input
                                    type='hidden'
                                    id='hdcredit_assessment_3'
                                    name='hdcredit_assessment_3'
                                    value={this.state.hdcredit_assessment_3}
                                    onChange={this.handleChange}
                                  />
                                </div>
                                <div className='col-6'>
                                  <span
                                    onClick={() => this.Product_Hide(3)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <i
                                      className='trash-icon'
                                      aria-hidden='true'
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                        {parseInt(localStorage.getItem('Show_Product')) > 3 ||
                        this.state.addProduct_4_Selected !== '' ? (
                          <div className='row' id='divProduct_4'>
                            <div className='form-group col-md-3'>
                              <label
                                className='form-control-label'
                                htmlFor='product_4'
                                style={{ paddingLeft: '15px' }}
                              >
                                Product
                              </label>
                              <Select
                                required
                                aria-required='true'
                                id='product_4'
                                className='col-md-12'
                                onChange={this.addProduct4SetValues}
                                placeholder=''
                                options={this.state.Products}
                                value={this.state.addProduct_4_Selected}
                              />
                              <input
                                type='hidden'
                                id='hdproduct_4'
                                name='hdproduct_4'
                                value={this.state.hdproduct_4}
                                onChange={this.handleChange}
                              />
                              <input
                                type='hidden'
                                id='hdproduct_title_4'
                                name='hdproduct_title_4'
                                value={this.state.hdproduct_title_4}
                                onChange={this.handleChange}
                                className='ddlProduct'
                              />
                            </div>
                            <div className='form-group col-md-3'>
                              <label
                                className='form-control-label'
                                htmlFor='mou_1'
                              >
                                Estimated MOU
                              </label>
                              <input
                                type='text'
                                className='form-control'
                                id='mou_4'
                                name='mou_4'
                                required='required'
                                pattern='/^\d+$/'
                                onBlur={() => this.CalculateAssessment(4)}
                                value={this.state.mou_4}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className='form-group col-md-3'>
                              <label
                                className='form-control-label'
                                htmlFor='required_sessions_4'
                              >
                                Required Sessions
                              </label>
                              <input
                                type='text'
                                className='form-control'
                                id='required_sessions_4'
                                name='required_sessions_4'
                                readOnly
                                pattern='/^\d+$/'
                                onKeyUp={() => this.CalculateAssessment(4)}
                                value={this.state.required_sessions_4}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className='form-group col-md-3'>
                              <label className='form-control-label'>
                                Net30 / Credit Risk Assessment
                              </label>

                              <div className='row'>
                                <div className='col-6'>
                                  <h4 id='credit_assessment_4'>
                                    $ {this.state.hdcredit_assessment_4}
                                  </h4>
                                  <input
                                    type='hidden'
                                    id='hdcredit_assessment_4'
                                    name='hdcredit_assessment_4'
                                    value={this.state.hdcredit_assessment_4}
                                    onChange={this.handleChange}
                                  />
                                </div>
                                <div className='col-6'>
                                  <span
                                    onClick={() => this.Product_Hide(4)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <i
                                      className='trash-icon'
                                      aria-hidden='true'
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                        {parseInt(localStorage.getItem('Show_Product')) > 4 ||
                        this.state.addProduct_5_Selected !== '' ? (
                          <div className='row' id='divProduct_5'>
                            <div className='form-group col-md-3'>
                              <label
                                className='form-control-label'
                                htmlFor='product_5'
                                style={{ paddingLeft: '15px' }}
                              >
                                Product
                              </label>
                              <Select
                                required
                                aria-required='true'
                                id='product_5'
                                className='col-md-12'
                                onChange={this.addProduct5SetValues}
                                placeholder=''
                                options={this.state.Products}
                                value={this.state.addProduct_5_Selected}
                              />
                              <input
                                type='hidden'
                                id='hdproduct_5'
                                name='hdproduct_5'
                                value={this.state.hdproduct_5}
                                onChange={this.handleChange}
                              />
                              <input
                                type='hidden'
                                id='hdproduct_title_5'
                                name='hdproduct_title_5'
                                value={this.state.hdproduct_title_5}
                                onChange={this.handleChange}
                                className='ddlProduct'
                              />
                            </div>
                            <div className='form-group col-md-3'>
                              <label
                                className='form-control-label'
                                htmlFor='mou_5'
                              >
                                Estimated MOU
                              </label>
                              <input
                                type='text'
                                className='form-control'
                                id='mou_5'
                                name='mou_5'
                                required='required'
                                pattern='/^\d+$/'
                                onBlur={() => this.CalculateAssessment(5)}
                                value={this.state.mou_5}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className='form-group col-md-3'>
                              <label
                                className='form-control-label'
                                htmlFor='required_sessions_5'
                              >
                                Required Sessions
                              </label>
                              <input
                                type='text'
                                className='form-control'
                                id='required_sessions_5'
                                name='required_sessions_5'
                                readOnly
                                pattern='/^\d+$/'
                                onKeyUp={() => this.CalculateAssessment(5)}
                                value={this.state.required_sessions_5}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className='form-group col-md-3'>
                              <label className='form-control-label'>
                                Net30 / Credit Risk Assessment
                              </label>

                              <div className='row'>
                                <div className='col-6'>
                                  <h4 id='credit_assessment_5'>
                                    $ {this.state.hdcredit_assessment_5}
                                  </h4>
                                  <input
                                    type='hidden'
                                    id='hdcredit_assessment_5'
                                    name='hdcredit_assessment_5'
                                    value={this.state.hdcredit_assessment_5}
                                    onChange={this.handleChange}
                                  />
                                </div>
                                <div className='col-6'>
                                  <span
                                    onClick={() => this.Product_Hide(5)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <i
                                      className='trash-icon'
                                      aria-hidden='true'
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                        {parseInt(localStorage.getItem('Show_Product')) > 5 ||
                        this.state.addProduct_6_Selected !== '' ? (
                          <div className='row' id='divProduct_6'>
                            <div className='form-group col-md-3'>
                              <label
                                className='form-control-label'
                                htmlFor='product_6'
                                style={{ paddingLeft: '15px' }}
                              >
                                Product
                              </label>
                              <Select
                                required
                                aria-required='true'
                                id='product_6'
                                className='col-md-12'
                                onChange={this.addProduct6SetValues}
                                placeholder=''
                                options={this.state.Products}
                                value={this.state.addProduct_6_Selected}
                              />
                              <input
                                type='hidden'
                                id='hdproduct_6'
                                name='hdproduct_6'
                                value={this.state.hdproduct_6}
                                onChange={this.handleChange}
                              />
                              <input
                                type='hidden'
                                id='hdproduct_title_6'
                                name='hdproduct_title_6'
                                value={this.state.hdproduct_title_6}
                                onChange={this.handleChange}
                                className='ddlProduct'
                              />
                            </div>
                            <div className='form-group col-md-3'>
                              <label
                                className='form-control-label'
                                htmlFor='mou_6'
                              >
                                Estimated MOU
                              </label>
                              <input
                                type='text'
                                className='form-control'
                                id='mou_6'
                                name='mou_6'
                                required='required'
                                pattern='/^\d+$/'
                                onBlur={() => this.CalculateAssessment(6)}
                                value={this.state.mou_6}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className='form-group col-md-3'>
                              <label
                                className='form-control-label'
                                htmlFor='required_sessions_6'
                              >
                                Required Sessions
                              </label>
                              <input
                                type='text'
                                className='form-control'
                                id='required_sessions_6'
                                name='required_sessions_6'
                                readOnly
                                pattern='/^\d+$/'
                                onKeyUp={() => this.CalculateAssessment(6)}
                                value={this.state.required_sessions_6}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className='form-group col-md-3'>
                              <label className='form-control-label'>
                                Net30 / Credit Risk Assessment
                              </label>

                              <div className='row'>
                                <div className='col-6'>
                                  <h4 id='credit_assessment_6'>
                                    $ {this.state.hdcredit_assessment_6}
                                  </h4>
                                  <input
                                    type='hidden'
                                    id='hdcredit_assessment_6'
                                    name='hdcredit_assessment_6'
                                    value={this.state.hdcredit_assessment_6}
                                    onChange={this.handleChange}
                                  />
                                </div>
                                <div className='col-6'>
                                  <span
                                    onClick={() => this.Product_Hide(6)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <i
                                      className='trash-icon'
                                      aria-hidden='true'
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                        {parseInt(localStorage.getItem('Show_Product')) > 6 ||
                        this.state.addProduct_7_Selected !== '' ? (
                          <div className='row' id='divProduct_7'>
                            <div className='form-group col-md-3'>
                              <label
                                className='form-control-label'
                                htmlFor='product_7'
                                style={{ paddingLeft: '15px' }}
                              >
                                Product
                              </label>
                              <Select
                                required
                                aria-required='true'
                                id='product_7'
                                className='col-md-12'
                                onChange={this.addProduct7SetValues}
                                placeholder=''
                                options={this.state.Products}
                                value={this.state.addProduct_7_Selected}
                              />
                              <input
                                type='hidden'
                                id='hdproduct_7'
                                name='hdproduct_7'
                                value={this.state.hdproduct_7}
                                onChange={this.handleChange}
                              />
                              <input
                                type='hidden'
                                id='hdproduct_title_7'
                                name='hdproduct_title_7'
                                value={this.state.hdproduct_title_7}
                                onChange={this.handleChange}
                                className='ddlProduct'
                              />
                            </div>
                            <div className='form-group col-md-3'>
                              <label
                                className='form-control-label'
                                htmlFor='mou_7'
                              >
                                Net30 / Credit Risk Assessment
                              </label>
                              <input
                                type='text'
                                className='form-control'
                                id='mou_7'
                                name='mou_7'
                                required='required'
                                pattern='/^\d+$/'
                                onBlur={() => this.CalculateAssessment(7)}
                                value={this.state.mou_7}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className='form-group col-md-3'>
                              <label
                                className='form-control-label'
                                htmlFor='required_sessions_7'
                              >
                                Required Sessions
                              </label>
                              <input
                                type='text'
                                className='form-control'
                                id='required_sessions_7'
                                name='required_sessions_7'
                                readOnly
                                pattern='/^\d+$/'
                                onKeyUp={() => this.CalculateAssessment(7)}
                                value={this.state.required_sessions_7}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className='form-group col-md-3'>
                              <label className='form-control-label'>
                                Net30 / Credit Risk Assessment
                              </label>

                              <div className='row'>
                                <div className='col-6'>
                                  <h4 id='credit_assessment_7'>
                                    $ {this.state.hdcredit_assessment_7}
                                  </h4>
                                  <input
                                    type='hidden'
                                    id='hdcredit_assessment_7'
                                    name='hdcredit_assessment_7'
                                    value={this.state.hdcredit_assessment_7}
                                    onChange={this.handleChange}
                                  />
                                </div>
                                <div className='col-6'>
                                  <span
                                    onClick={() => this.Product_Hide(7)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <i
                                      className='trash-icon'
                                      aria-hidden='true'
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </form>
                      <h4>Client Information</h4>
                      <form id='clientform' autoComplete='off'>
                        <div className='form-group'>
                          <label
                            className='form-control-label'
                            htmlFor='client_name'
                          >
                            Full Name of Applicant &nbsp;*
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            id='client_name'
                            name='client_name'
                          />
                        </div>
                        <div className='row'>
                          <div className='form-group col-md-6'>
                            <label
                              className='form-control-label'
                              htmlFor='client_fax'
                            >
                              Business FAX
                            </label>

                            <InputMask
                              mask='99999999999'
                              pattern='.{10,}'
                              maskChar={null}
                              id='client_fax'
                              className='form-control col-md-7'
                              name='client_fax'
                              placeholder='Business FAX 2134567890'
                              title='Business FAX 2134567890'
                              value={this.state.client_fax}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className='form-group col-md-6'>
                            <label
                              className='form-control-label'
                              htmlFor='client_phone'
                            >
                              Business Phone&nbsp;*
                            </label>

                            <InputMask
                              mask='99999999999'
                              pattern='.{10,}'
                              maskChar={null}
                              id='client_phone'
                              className='form-control col-md-7'
                              name='client_phone'
                              placeholder='Business Phone 2134567890'
                              title='Business Phone 2134567890'
                              value={this.state.client_phone}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className='form-group'>
                          <label
                            className='form-control-label'
                            htmlFor='client_address'
                          >
                            Address(Service Location)&nbsp;*
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            id='client_address'
                            name='client_address'
                          />
                        </div>
                        <div className='row'>
                          <div className='form-group col-md-4'>
                            <label
                              className='form-control-label'
                              htmlFor='client_city'
                            >
                              City&nbsp;*
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='client_city'
                              name='client_city'
                            />
                          </div>
                          <div className='form-group col-md-4'>
                            <label
                              className='form-control-label'
                              htmlFor='client_state'
                            >
                              State&nbsp;*
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='client_state'
                              name='client_state'
                            />
                          </div>
                          <div className='form-group col-md-4'>
                            <label
                              className='form-control-label'
                              htmlFor='client_zip'
                            >
                              Zip&nbsp;*
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='client_zip'
                              name='client_zip'
                            />
                          </div>
                        </div>
                        <div className='form-group'>
                          <label
                            className='form-control-label'
                            htmlFor='client_bill_address'
                          >
                            Billing Address if different from above
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            id='client_bill_address'
                            name='client_bill_address'
                          />
                        </div>
                        <div className='row'>
                          <div className='form-group col-md-4'>
                            <label
                              className='form-control-label'
                              htmlFor='client_bill_city'
                            >
                              City
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='client_bill_city'
                              name='client_bill_city'
                            />
                          </div>
                          <div className='form-group col-md-4'>
                            <label
                              className='form-control-label'
                              htmlFor='client_bill_state'
                            >
                              State
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='client_bill_state'
                              name='client_bill_state'
                            />
                          </div>
                          <div className='form-group col-md-4'>
                            <label
                              className='form-control-label'
                              htmlFor='client_bill_zip'
                            >
                              Zip
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='client_bill_zip'
                              name='client_bill_zip'
                            />
                          </div>
                        </div>
                      </form>
                    </div>

                    <div className='wizard-pane' id='bank' role='tabpanel'>
                      <h4>Bank Reference</h4>
                      <form id='bankform' autoComplete='off'>
                        <div className='row'>
                          <div className='form-group col-md-6'>
                            <label
                              className='form-control-label'
                              htmlFor='bank_name'
                            >
                              Bank Name&nbsp;*
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='bank_name'
                              name='bank_name'
                            />
                          </div>
                          <div className='form-group col-md-6'>
                            <label
                              className='form-control-label'
                              htmlFor='bank_contact'
                            >
                              Contact
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='bank_contact'
                              name='bank_contact'
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='form-group col-md-6'>
                            <label
                              className='form-control-label'
                              htmlFor='bank_acc_number'
                            >
                              Checking Acct Number&nbsp;*
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='bank_acc_number'
                              name='bank_acc_number'
                            />
                          </div>
                          <div className='form-group col-md-6'>
                            <label
                              className='form-control-label'
                              htmlFor='bank_loan_acct_number'
                            >
                              Loan Acct Number&nbsp;*
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='bank_loan_acct_number'
                              name='bank_loan_acct_number'
                            />
                          </div>
                        </div>
                        <div className='form-group'>
                          <label
                            className='form-control-label'
                            htmlFor='bank_address'
                          >
                            Bank Address&nbsp;*
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            id='bank_address'
                            name='bank_address'
                          />
                        </div>
                        <div className='row'>
                          <div className='form-group col-md-4'>
                            <label
                              className='form-control-label'
                              htmlFor='bank_city'
                            >
                              City&nbsp;*
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='bank_city'
                              name='bank_city'
                            />
                          </div>
                          <div className='form-group col-md-4'>
                            <label
                              className='form-control-label'
                              htmlFor='bank_state'
                            >
                              State&nbsp;*
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='bank_state'
                              name='bank_state'
                            />
                          </div>
                          <div className='form-group col-md-4'>
                            <label
                              className='form-control-label'
                              htmlFor='bank_zip'
                            >
                              Zip&nbsp;*
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='bank_zip'
                              name='bank_zip'
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='form-group col-md-6'>
                            <label
                              className='form-control-label'
                              htmlFor='bank_phone_number'
                            >
                              Phone Number
                            </label>

                            <InputMask
                              mask='99999999999'
                              pattern='.{10,}'
                              maskChar={null}
                              id='bank_phone_number'
                              className='form-control col-md-7'
                              name='bank_phone_number'
                              placeholder='Phone Number 2134567890'
                              title='Phone Number 2134567890'
                              value={this.state.bank_phone_number}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className='form-group col-md-6'>
                            <label
                              className='form-control-label'
                              htmlFor='bank_email'
                            >
                              Email
                            </label>
                            <input
                              type='email'
                              className='form-control'
                              id='bank_email'
                              name='bank_email'
                            />
                          </div>
                        </div>
                      </form>
                    </div>

                    <div className='wizard-pane' id='credit' role='tabpanel'>
                      <h4>Business Credit Information</h4>
                      <form id='creditform' autoComplete='off'>
                        <div className='form-group'>
                          <label
                            className='form-control-label'
                            htmlFor='credit_principal_1'
                          >
                            Principal(s) / Authorized Officers(s) with
                            Title&nbsp;*
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            id='credit_principal_1'
                            name='credit_principal_1'
                          />
                        </div>
                        <div className='form-group'>
                          <label
                            className='form-control-label'
                            htmlFor='credit_principal_2'
                          >
                            Principal(s) / Authorized Officers(s) with Title
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            id='credit_principal_2'
                            name='credit_principal_2'
                          />
                        </div>
                        <div className='form-group'>
                          <label
                            className='form-control-label'
                            htmlFor='credit_person'
                          >
                            Person to Contact Regarding the Account&nbsp;*
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            id='credit_person'
                            name='credit_person'
                          />
                        </div>
                        <div className='row'>
                          <div className='form-group col-md-6'>
                            <label
                              className='form-control-label'
                              htmlFor='credit_email'
                            >
                              Email&nbsp;*
                            </label>
                            <input
                              type='email'
                              className='form-control'
                              id='credit_email'
                              name='credit_email'
                            />
                          </div>
                          <div className='form-group col-md-6'>
                            <label
                              className='form-control-label'
                              htmlFor='credit_duns'
                            >
                              DUNS Number&nbsp;*
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='credit_duns'
                              name='credit_duns'
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='form-group col-md-6'>
                            <label
                              className='form-control-label'
                              htmlFor='credit_subsidairy'
                            >
                              If SUBSIDAIRY, Name of Parent Company
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='credit_subsidairy'
                              name='credit_subsidairy'
                            />
                          </div>
                          <div className='form-group col-md-6'>
                            <label
                              className='form-control-label'
                              htmlFor='credit_sic'
                            >
                              SIC CODE&nbsp;*
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='credit_sic'
                              name='credit_sic'
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='form-group col-md-6'>
                            <label
                              className='form-control-label'
                              htmlFor='credit_taxpayer'
                            >
                              TAXPAYER ID Number&nbsp;*
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='credit_taxpayer'
                              name='credit_taxpayer'
                            />
                          </div>
                          <div className='form-group col-md-6'>
                            <label
                              className='form-control-label'
                              htmlFor='credit_employees'
                            >
                              Number of Employees
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='credit_employees'
                              name='credit_employees'
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='form-group col-md-4'>
                            <label
                              className='form-control-label'
                              htmlFor='credit_sales'
                            >
                              Annual Sales
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='credit_sales'
                              name='credit_sales'
                            />
                          </div>
                          <div className='form-group col-md-4'>
                            <label
                              className='form-control-label'
                              htmlFor='credit_business'
                            >
                              In Business Since
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='credit_business'
                              name='credit_business'
                            />
                          </div>
                          <div className='form-group col-md-4'>
                            <label
                              className='form-control-label'
                              htmlFor='credit_locations'
                            >
                              Number of Locations
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='credit_locations'
                              name='credit_locations'
                            />
                          </div>
                        </div>

                        <h4>FORM OF BUSINESS(Check One)</h4>
                        <div className='row'>
                          <div className='col-md-6'>
                            <div className='radio-custom radio-primary'>
                              <input
                                type='radio'
                                value='Corporation'
                                name='rdoFormofBussiness'
                                checked={
                                  this.state.rdoFormofBussiness ===
                                  'Corporation'
                                }
                                onChange={this.handleOptionChange}
                              />

                              <label>Corporation</label>
                            </div>
                            <div className='radio-custom radio-primary'>
                              <input
                                type='radio'
                                value='S Corporation'
                                name='rdoFormofBussiness'
                                checked={
                                  this.state.rdoFormofBussiness ===
                                  'S Corporation'
                                }
                                onChange={this.handleOptionChange}
                              />
                              <label>S Corporation</label>
                            </div>
                            <div className='radio-custom radio-primary'>
                              <input
                                type='radio'
                                value='Limited Liability Company'
                                name='rdoFormofBussiness'
                                checked={
                                  this.state.rdoFormofBussiness ===
                                  'Limited Liability Company'
                                }
                                onChange={this.handleOptionChange}
                              />
                              <label>Limited Liability Company</label>
                            </div>

                            <div className='radio-custom radio-primary'>
                              <input
                                type='radio'
                                value='Not For Profit'
                                name='rdoFormofBussiness'
                                checked={
                                  this.state.rdoFormofBussiness ===
                                  'Not For Profit'
                                }
                                onChange={this.handleOptionChange}
                              />
                              <label>Not For Profit</label>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div className='radio-custom radio-primary'>
                              <input
                                type='radio'
                                value='General Partnership'
                                name='rdoFormofBussiness'
                                checked={
                                  this.state.rdoFormofBussiness ===
                                  'General Partnership'
                                }
                                onChange={this.handleOptionChange}
                              />
                              <label>General Partnership</label>
                            </div>
                            <div className='radio-custom radio-primary'>
                              <input
                                type='radio'
                                value='Limited Partnership'
                                name='rdoFormofBussiness'
                                checked={
                                  this.state.rdoFormofBussiness ===
                                  'Limited Partnership'
                                }
                                onChange={this.handleOptionChange}
                              />
                              <label>Limited Partnership</label>
                            </div>
                            <div className='radio-custom radio-primary'>
                              <input
                                type='radio'
                                value='Sole Proprietorship'
                                name='rdoFormofBussiness'
                                checked={
                                  this.state.rdoFormofBussiness ===
                                  'Sole Proprietorship'
                                }
                                onChange={this.handleOptionChange}
                              />
                              <label>Sole Proprietorship</label>
                            </div>
                            {/** <div className='radio-custom radio-primary'>
                              <input type='radio' name='rdoFormofBussiness' />
                              <label>________________</label>
                            </div>*/}
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-md-6'>
                            <p>Is your organization TAX EXEMPT?</p>
                          </div>
                          <div className='col-md-6'>
                            <div className='checkbox-custom checkbox-primary'>
                              <input
                                type='checkbox'
                                name='chkExpempt'
                                id='chkExpempt'
                                checked={this.state.chkExpempt}
                                onChange={this.handleChange}
                              />

                              <label>
                                If YES attach tax exemption certificates.
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className='form-group'>
                          <label
                            className='form-control-label'
                            htmlFor='credit_description'
                          >
                            BRIEF DESCRIPTION OF BUSINESS ACTIVITIES:
                          </label>
                          <textarea
                            className='form-control'
                            id='credit_description'
                            name='credit_description'
                          />
                        </div>
                      </form>
                    </div>

                    <div className='wizard-pane' id='trade' role='tabpanel'>
                      <h4>Trade References 1</h4>
                      <form id='tradeform' autoComplete='off'>
                        <div className='row'>
                          <div className='form-group col-md-6'>
                            <label
                              className='form-control-label'
                              htmlFor='trade_ref_number_1'
                            >
                              Trade Reference Name&nbsp;*
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='trade_ref_number_1'
                              name='trade_ref_number_1'
                            />
                          </div>
                          <div className='form-group col-md-6'>
                            <label
                              className='form-control-label'
                              htmlFor='trade_contact_1'
                            >
                              Contact&nbsp;*
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='trade_contact_1'
                              name='trade_contact_1'
                            />
                          </div>
                        </div>
                        <div className='form-group'>
                          <label
                            className='form-control-label'
                            htmlFor='trade_address_1'
                          >
                            Address&nbsp;*
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            id='trade_address_1'
                            name='trade_address_1'
                          />
                        </div>
                        <div className='row'>
                          <div className='form-group col-md-4'>
                            <label
                              className='form-control-label'
                              htmlFor='trade_city_1'
                            >
                              City&nbsp;*
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='trade_city_1'
                              name='trade_city_1'
                            />
                          </div>
                          <div className='form-group col-md-4'>
                            <label
                              className='form-control-label'
                              htmlFor='trade_state_1'
                            >
                              State&nbsp;*
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='trade_state_1'
                              name='trade_state_1'
                            />
                          </div>
                          <div className='form-group col-md-4'>
                            <label
                              className='form-control-label'
                              htmlFor='trade_zip_1'
                            >
                              Zip&nbsp;*
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='trade_zip_1'
                              name='trade_zip_1'
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='form-group col-md-6'>
                            <label
                              className='form-control-label'
                              htmlFor='trade_phone_1'
                            >
                              Phone Number&nbsp;*
                            </label>

                            <InputMask
                              mask='99999999999'
                              pattern='.{10,}'
                              maskChar={null}
                              id='trade_phone_1'
                              className='form-control col-md-7'
                              name='trade_phone_1'
                              placeholder='Phone Number 2134567890'
                              title='Phone Number 2134567890'
                              value={this.state.trade_phone_1}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className='form-group col-md-6'>
                            <label
                              className='form-control-label'
                              htmlFor='trade_fax_1'
                            >
                              Fax Number
                            </label>

                            <InputMask
                              mask='99999999999'
                              pattern='.{10,}'
                              maskChar={null}
                              id='trade_fax_1'
                              className='form-control col-md-7'
                              name='trade_fax_1'
                              placeholder='Fax Number 2134567890'
                              title='Fax Number 2134567890'
                              value={this.state.trade_fax_1}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className='form-group'>
                          <label
                            className='form-control-label'
                            htmlFor='trade_email_1'
                          >
                            Email
                          </label>
                          <input
                            type='email'
                            className='form-control'
                            id='trade_email_1'
                            name='trade_email_1'
                          />
                        </div>
                        <div className='row'>
                          {' '}
                          <h4 style={{ paddingLeft: '15px' }}>
                            Trade References 2
                          </h4>
                        </div>
                        <div className='row'>
                          <div className='form-group col-md-6'>
                            <label
                              className='form-control-label'
                              htmlFor='trade_ref_number_2'
                            >
                              Trade Reference Name
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='trade_ref_number_2'
                              name='trade_ref_number_2'
                            />
                          </div>
                          <div className='form-group col-md-6'>
                            <label
                              className='form-control-label'
                              htmlFor='trade_contact_2'
                            >
                              Contact
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='trade_contact_2'
                              name='trade_contact_2'
                            />
                          </div>
                        </div>
                        <div className='form-group'>
                          <label
                            className='form-control-label'
                            htmlFor='trade_address_2'
                          >
                            Address
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            id='trade_address_2'
                            name='trade_address_2'
                          />
                        </div>
                        <div className='row'>
                          <div className='form-group col-md-4'>
                            <label
                              className='form-control-label'
                              htmlFor='trade_city_2'
                            >
                              City
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='trade_city_2'
                              name='trade_city_2'
                            />
                          </div>
                          <div className='form-group col-md-4'>
                            <label
                              className='form-control-label'
                              htmlFor='trade_state_2'
                            >
                              State
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='trade_state_2'
                              name='trade_state_2'
                            />
                          </div>
                          <div className='form-group col-md-4'>
                            <label
                              className='form-control-label'
                              htmlFor='trade_zip_2'
                            >
                              Zip
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='trade_zip_2'
                              name='trade_zip_2'
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='form-group col-md-6'>
                            <label
                              className='form-control-label'
                              htmlFor='trade_phone_2'
                            >
                              Phone Number
                            </label>

                            <InputMask
                              mask='99999999999'
                              pattern='.{10,}'
                              maskChar={null}
                              id='trade_phone_2'
                              className='form-control col-md-7'
                              name='trade_phone_2'
                              placeholder='Phone Number 2134567890'
                              title='Phone Number 2134567890'
                              value={this.state.trade_phone_2}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className='form-group col-md-6'>
                            <label
                              className='form-control-label'
                              htmlFor='trade_fax_2'
                            >
                              Fax Number
                            </label>

                            <InputMask
                              mask='99999999999'
                              pattern='.{10,}'
                              maskChar={null}
                              id='trade_fax_2'
                              className='form-control col-md-7'
                              name='trade_fax_2'
                              placeholder='Fax Number 2134567890'
                              title='Fax Number 2134567890'
                              value={this.state.trade_fax_2}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className='form-group'>
                          <label
                            className='form-control-label'
                            htmlFor='trade_email_2'
                          >
                            Email
                          </label>
                          <input
                            type='email'
                            className='form-control'
                            id='trade_email_2'
                            name='trade_email_2'
                          />
                        </div>
                      </form>
                      <p>
                        <b>Notice:</b>Financial statements may be as a condition
                        of credit approval.
                      </p>
                    </div>

                    <div
                      className='wizard-pane '
                      id='Signature'
                      role='tabpanel'
                    >
                      <h4>Equal Credit Opportunity Act Notice:</h4>
                      <p>
                        If your application for business credit is denied, you
                        have the right to a written statement of the specific
                        reasons for the denial. To obtain the statement, please
                        write to Telecom Carrier, Attention: 9093 N Timbershores
                        Dr, Pentwater, MI, 49449 Business Manager, or contact
                        Telecom Carrier by telephone at (721) 344-4222 within 60
                        days from the date you are notified of our decision. We
                        will send you a written statement of reasons for the
                        denial within 30 days of receiving your request. The
                        Federal Equal Credit Opportunity Act prohibits creditors
                        from discriminating against credit applicants on the
                        basis of race, color, religion, national origin, sex,
                        marital status, age (provided the applicant has the
                        capacity to enter into a binding contract); because all
                        or part of the applicant's income derives from any
                        public assistance program; or because the applicant has
                        in good faith exercised any right under the Consumer
                        Credit Protection Act. The federal agency that
                        administers compliance with this law is the Federal
                        Trade Commission, Equal Credit Opportunity, Washington,
                        D.C. 20580.
                      </p>

                      <h4>Signature :</h4>
                      <p>
                        The information in this application is provided for the
                        purpose of obtaining commercial credit and is warranted
                        to be accurate and complete. Applicant authorizes the
                        parties listed herein as Bank and Trade References to
                        disclose to Telecom Carrier information relative to the
                        Applicant's credit history and financial responsibility.
                        Applicant authorizes to provide to the parties listed
                        herein as Bank and Trade References a copy of this
                        application to evidence such authorization Applicant
                        acknowledges that it has been provided notice of its
                        rights under the Equal Credit Opportunity Act. The
                        Applicant hereby certifies that the undersigned has been
                        duly authorized to execute this application by all
                        necessary action on the part of Applicant.
                      </p>

                      <form id='Signatureform' autoComplete='off'>
                        <div className='row'>
                          <div className='form-group col-md-6'>
                            <label
                              className='form-control-label'
                              htmlFor='auth_signature'
                            >
                              Authorized Signature&nbsp;*
                            </label>
                            <input
                              type='hidden'
                              val={this.state.hdauth_signature}
                              id='hdauth_signature'
                              name='hdauth_signature'
                              onChange={this.handleChange}
                            />
                            <SignatureCanvas
                              penColor='black'
                              canvasProps={{
                                width: 300,
                                height: 90,
                                className: 'sigCanvasAuth'
                              }}
                              ref={ref => (this.signaturePad = ref)}
                            />
                          </div>
                          <div className='col-md-6'>
                            <button
                              type='button'
                              className='btn btn-warning'
                              onClick={this.handleClear.bind(this)}
                            >
                              Clear
                            </button>
                            &nbsp;
                            <button
                              type='button'
                              className='btn btn-success'
                              onClick={this.handleSave.bind(this)}
                            >
                              Sign Form
                            </button>
                          </div>
                          <div className='col-md-6'>
                            {' '}
                            <div
                              id='divImgSig'
                              style={{ display: 'none' }}
                              className='row'
                            >
                              Signature :{' '}
                              <img
                                id='imgSig'
                                className='clsimgSig'
                                width='100px'
                                alt='Signature'
                              />
                            </div>
                          </div>

                          <div className='form-group col-md-6'>
                            <label
                              className='form-control-label'
                              htmlFor='auth_date'
                            >
                              Date&nbsp;*
                            </label>

                            <input
                              type='text'
                              id='auth_date'
                              name='auth_date'
                              className='form-control col-md-6'
                              value={this.state.Current_Date}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='form-group col-md-6'>
                            <label
                              className='form-control-label'
                              htmlFor='auth_name'
                            >
                              Name&nbsp;*
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='auth_name'
                              name='auth_name'
                            />
                          </div>
                          <div className='form-group col-md-6'>
                            <label
                              className='form-control-label'
                              htmlFor='auth_title'
                            >
                              Title&nbsp;*
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='auth_title'
                              name='auth_title'
                            />
                          </div>
                        </div>
                        <input
                          type='button'
                          id='btnSuccess'
                          name='btnSuccess'
                          onClick={() => {
                            notie.alert({
                              type: 'info',
                              text: $('#hdCalled').val(),
                              time: 3,
                              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                            });
                            this.props.history.push(`/client_dashboard`);
                            //this.history.push("/client_dashboard")
                          }}
                          style={{ visibility: 'hidden' }}
                        />
                        <input
                          type='button'
                          id='btnWarning'
                          name='btnWarning'
                          onClick={() => {
                            notie.alert({
                              type: 'warning',
                              text: 'Please Provide a Signature First.',
                              time: 3,
                              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                            });
                          }}
                          style={{ visibility: 'hidden' }}
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserCreditForm;
