import React, { Component } from 'react';
import notie from 'notie';
import $ from 'jquery';
import axios from 'axios';

class ProductAgreements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      loading: true,
      Select_Product_Agreements: [],
      Product_ID: props.Product_ID
    };
    this.getProductAgreements = this.getProductAgreements.bind(this);
  }

  getProductAgreements(_Product_ID) {
    try {
      this.setState({
        Product_ID: _Product_ID
      });
      const api = `${
        process.env.REACT_APP_BASE_URL
      }products/api/getProductAgreements?product_id=${_Product_ID}`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_user_token')}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/client_login');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          this.setState({
            Select_Product_Agreements: res.data,
            loading: false
          });
        });
    } catch (error) {}
  }
  componentWillMount() {
    this.getProductAgreements(this.state.Product_ID);
  }

  render() {
    const { loading } = this.state;
    return (
      <React.Fragment>
        {loading ? (
          <div style={{ textAlign: 'center' }}>
            <img
              src='../assets/images/tcc_loading.gif'
              alt='...'
              style={{ width: '40px' }}
            />
          </div>
        ) : (
          <React.Fragment>
            {this.state.Select_Product_Agreements === [] ? (
              ''
            ) : (
              <React.Fragment>
                {this.state.Select_Product_Agreements.map((data, index) => (
                  <React.Fragment key={data.product_agreement_id}>
                    {data.issigned ? (
                      <a
                        className='fa fa-file'
                        style={{ textDecoration: 'none' }}
                        target='_blank'
                        title={data.agreement_title}
                        href={
                          process.env.REACT_APP_BASE_URL + data.agreement_PDF
                        }
                      />
                    ) : (
                      <span
                        className='fa fa-pen'
                        style={{ cursor: 'pointer' }}
                        title={data.agreement_title}
                        onClick={() =>
                          this.props.handlerviewAgreementData(
                            data.user_agreement_id
                          )
                        }
                      />
                    )}
                  </React.Fragment>
                ))}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default ProductAgreements;
