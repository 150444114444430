import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import axios from 'axios';
import notie from 'notie';
import $ from 'jquery';
import BaseSelect from 'react-select';
import './AdminUserAgreementArchive.css';
import FixRequiredSelect from '../../components/FixRequiredSelect';
const { SearchBar } = Search;

const Select = props => (
  <FixRequiredSelect {...props} SelectComponent={BaseSelect} />
);

class AdminUserAgreementArchive extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      Disable_Button: '',
      User_ID: this.props.location.state.User_ID,
      User_Agreement_ID: '',
      User_FullName: this.props.location.state.User_FullName,
      Comp_Name: this.props.location.state.Comp_Name,

      addAgreementID: '',
      addAgreementTitle: '',
      addAgreementFile: '',
      addVendorContactID: '',
      agreements: [],
      addAgreements: [],
      Vendor_Contacts: [],
      loading: true,
      columns: [
        {
          dataField: 'serial_number',
          text: 'Sr #',
          sort: true,
          headerAttrs: { width: 50 }
        },
        {
          dataField: 'vendor_contact_name',
          text: 'Vendor Contact',
          sort: true,
          headerAttrs: { width: 150 }
        },
        {
          dataField: 'agreement_title',
          text: 'Agreement Title',
          sort: true,
          headerAttrs: { width: 190 }
        },
        {
          dataField: 'signee_name',
          text: 'Signee Name',
          sort: true,
          headerAttrs: { width: 120 }
        },
        {
          dataField: 'signee_title',
          text: 'Signee Title',
          sort: true,
          headerAttrs: { width: 100 }
        },
        {
          dataField: 'agreement_status',
          text: 'Status',
          sort: true,
          headerAttrs: { width: 60 }
        },
        {
          dataField: 'signature_date',
          text: 'Signature Date',
          sort: true,
          headerAttrs: { width: 130 }
        },

        {
          dataField: 'ispushtovendor',
          text: 'Send to Vendor',
          sort: true,
          attrs: { width: 75, style: { textAlign: 'center' } },
          headerAttrs: { width: 75, style: { textAlign: 'center' } }
        },
        {
          dataField: 'created_date',
          text: 'Archive Date',
          sort: true,
          headerAttrs: { width: 100 }
        },
        {
          dataField: 'agreement_PDF',
          text: 'View',
          sort: false,
          formatter: this.signagreementPreviewFormatter,
          headerAttrs: { width: 35, style: { textAlign: 'center' } }
        }
      ]
    };

    this.signagreementPreviewFormatter = this.signagreementPreviewFormatter.bind(
      this
    );

    this.GetAdminUserAgreementArchive = this.GetAdminUserAgreementArchive.bind(
      this
    );
    this.addAgreementSetValues = this.addAgreementSetValues.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.addVendorSetValues = this.addVendorSetValues.bind(this);
    this.GetAgreements = this.GetAgreements.bind(this);
    this.GetAllVendorContact = this.GetAllVendorContact.bind(this);
  }

  GetAdminUserAgreementArchive() {
    const api = `${process.env.REACT_APP_BASE_URL}user_agreement_archive/api/?user_id=${this.state.User_ID}`;
    this.setState({ loading: true });
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = '/tcaccess');
        }
        if (res.data.success === false) {
          this.setState({ loading: false });
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        this.setState({
          agreements: res.data,
          loading: false
        });
      });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  signagreementPreviewFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        {row.issigned === 'Yes' || row.issigneesigned === 'Yes' ? (
          <a
            className='fa fa-eye'
            target='_blank'
            href={process.env.REACT_APP_BASE_URL + row.agreement_PDF}
          />
        ) : (
          ''
        )}
      </div>
    );
  };

  addAgreementSetValues = selectedOption => {
    this.setState({
      addAgreementID: selectedOption,
      addAgreementTitle: selectedOption.label
    });
  };

  handleAdd(e) {
    e.preventDefault();

    try {
      var objAgreement = new FormData();

      objAgreement.append('agreement_id', this.state.addAgreementID.value);
      objAgreement.append('agreement_title', this.state.addAgreementTitle);
      objAgreement.append(
        'vendor_contact_id',
        this.state.addVendorContactID.value
      );
      objAgreement.append('user_id', this.state.User_ID);
      objAgreement.append('agreement_file', this.state.addAgreementFile);

      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}user_agreement_archive/api/`,
        data: objAgreement,

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
        }
      })
        .then(res => {
          if (res.data.success === false) {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            return false;
          }

          if (res.data.success === true) {
            notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            $('#add_agreement').hide();
            //$('.modal-backdrop').remove();
            this.GetAdminUserAgreementArchive();
            $('#addAgreementFile').val('');
            this.setState({
              addAgreementFile: '',
              addAgreementID: '',
              addAgreementTitle: '',
              addVendorContactID: ''
            });
          } else {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  onChangeaddUpload = event => {
    const types = ['application/pdf'];
    var files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      // get item
      let file = files[i];
      let err = '';
      if (types.every(type => file.type !== type)) {
        // create error message and assign to container
        err += file.type + ' is Not a Supported Format.';

        notie.alert({
          type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: err,
          stay: false, // optional, default = false
          time: 10, // optional, default = 3, minimum = 1,
          position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
        });
        return false;
      }
    }
    this.setState({
      addAgreementFile: event.target.files[0]
    });
  };

  GetAllVendorContact() {
    this.setState({ loading: true });
    const api = `${process.env.REACT_APP_BASE_URL}vendor_contact/api/DDVendorContact`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = '/tcaccess');
        }
        if (res.data.success === false) {
          this.setState({ loading: false });
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        //console.log(res.data);
        this.setState({
          Vendor_Contacts: res.data,
          loading: false
        });
      });
  }

  addVendorSetValues = selectedAddOption => {
    console.log(`Option selected:`, selectedAddOption.value);
    this.setState({ addVendorContactID: selectedAddOption });
  };

  GetAgreements() {
    this.setState({
      addAgreements: [],
      loading: true
    });
    const api = `${process.env.REACT_APP_BASE_URL}agreements/api/getConnectedAgreements`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = '/tcaccess');
        }
        if (res.data.success === false) {
          this.setState({ loading: false });
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        //console.log(res.data);
        this.setState({
          addAgreements: res.data,
          loading: false
        });
      });
  }

  componentWillMount = () => {
    //console.log('AdminUserAgreements will mount');
  };

  componentDidMount = () => {
    this.GetAdminUserAgreementArchive();
    this.GetAgreements();
    this.GetAllVendorContact();
  };

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    const { loading } = this.state;
    return (
      <div>
        <div className='row'>
          <div className='col-md-12 card '>
            <div className='row'>
              <div className='col-md-9'>
                <h4 className='ml-3' id='heading'>
                  {this.state.Comp_Name} Archived Agreements
                </h4>
              </div>
              <div className='col-md-3 col-sm-5 text-right text-sm-left '>
                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={() => {
                    $('#add_agreement').show();
                  }}
                >
                  Add Archived Agreement
                </button>
              </div>
            </div>

            <hr />
            <div className='container-fluid'>
              <div className='col-lg-12'>
                <div className='example-wrap'>
                  <div className='example'>
                    {loading ? (
                      <div style={{ textAlign: 'center' }}>
                        <img
                          src='assets/images/tcc_loading.gif'
                          style={{ width: '128px' }}
                        />
                      </div>
                    ) : (
                      <div>
                        <ToolkitProvider
                          keyField='serial_number'
                          data={this.state.agreements}
                          columns={this.state.columns}
                          search
                        >
                          {props => (
                            <div>
                              <SearchBar
                                {...props.searchProps}
                                className='custome-search-field'
                                style={{ color: '#000' }}
                                delay={1000}
                                placeholder='Search ...'
                              />
                              <hr />
                              <BootstrapTable
                                {...props.baseProps}
                                filter={filterFactory()}
                                pagination={paginationFactory()}
                                striped
                                hover
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/** ADD ARCHIVED AGREEMENT */}
        <form onSubmit={this.handleAdd} encType='multipart/form-data'>
          <div
            className='modal myModal AddAgreement'
            id='add_agreement'
            style={{
              display: 'none',
              paddingLeft: '15px',
              overflow: 'auto'
            }}
          >
            <div className='modal-dialog modal-dialog-centered modal-lg'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h4 className='modal-title'>Add Archived Agreement</h4>
                  <button
                    type='button'
                    className='close'
                    onClick={() => $('#add_agreement').hide()}
                  >
                    ×
                  </button>
                </div>

                <div className='modal-body'>
                  <div className='container-fluid'>
                    <div className='form-group row'>
                      <label
                        htmlFor='ddladdAgreement'
                        className='col-sm-2 control-labels'
                      >
                        Select Agreement:
                      </label>
                      <Select
                        id='ddladdAgreement'
                        style={{ paddingLeft: 0 }}
                        className='col-md-9'
                        onChange={this.addAgreementSetValues}
                        placeholder='Select Agreement'
                        options={this.state.addAgreements}
                        isSearchable
                        value={this.state.addAgreementID}
                        required
                      />
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='ddladdVendorContact'
                        className='col-sm-2 control-labels'
                      >
                        Select Vendor Contact:
                      </label>
                      <Select
                        required
                        aria-required='true'
                        id='ddladdVendorContact'
                        style={{ width: '200px' }}
                        onChange={this.addVendorSetValues}
                        placeholder='Select Vendor Contact'
                        options={this.state.Vendor_Contacts}
                        value={this.state.addVendorContactID}
                        isSearchable
                        required
                      />
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='AddAgremeentFile'
                        className='col-sm-2 control-labels'
                      >
                        Upload Agreement:
                      </label>
                      <input
                        type='file'
                        id='addAgremeentFile'
                        className=' col-md-9'
                        name='addAgremeentFile'
                        onChange={this.onChangeaddUpload}
                        style={{ paddingLeft: 0 }}
                        accept='.pdf'
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-danger'
                    onClick={() => $('#add_agreement').hide()}
                  >
                    Cancel
                  </button>
                  <button type='submit' className='btn btn-success'>
                    Save{' '}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default AdminUserAgreementArchive;
