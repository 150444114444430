import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import axios from 'axios';
import notie from 'notie';
import $ from 'jquery';
import InputMask from 'react-input-mask';
import BaseSelect from 'react-select';
import FixRequiredSelect from '../../../components/FixRequiredSelect';

const { SearchBar } = Search;

const Select = props => (
  <FixRequiredSelect {...props} SelectComponent={BaseSelect} />
);

class ManageUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      Disable_Button: '',

      addCompName: '',
      addTitle: '',
      addFirstName: '',
      addEmail: '',
      addPhoneNo: '',
      addAddress: '',
      addStatus: 1,
      addService: '',

      editCompName: '',
      editFirstName: '',
      editTitle: '',
      editPassword: '',
      editEmail: '',
      editPhoneNo: '',
      editAddress: '',
      editStatus: 0,
      editService: '',
      User_ID: '',
      users: [],
      service: [
        {
          label: 'Postpay',
          value: 'Postpay'
        },
        {
          label: 'Prepay',
          value: 'Prepay'
        }
      ],
      loading: true,
      columns: [
        {
          dataField: 'user_id',
          text: 'Account Number',
          sort: true,
          headerAttrs: { width: 135 }
        },
        {
          dataField: 'comp_name',
          text: 'Company Name',
          sort: true
        },
        {
          dataField: 'first_name',
          text: 'Full Name',
          sort: true
        },
        {
          dataField: 'email',
          text: 'Email',
          sort: true
        },
        {
          dataField: 'phone_number',
          text: 'Contact Number',
          sort: true,
          headerAttrs: { width: 135 }
        },
        {
          dataField: 'status',
          text: 'Verified',
          sort: true,
          formatter: this.viewActiveFormatter,
          headerAttrs: { width: 95, style: { textAlign: 'center' } },
          attrs: {
            style: { textAlign: 'center' }
          }
        },
        {
          dataField: 'view',
          text: 'Active Agreement',
          sort: false,
          formatter: this.viewFormatter,
          headerAttrs: { width: 50, style: { textAlign: 'center' } }
        },
        {
          dataField: 'viewArchive',
          text: 'Archived Agreement',
          sort: false,
          formatter: this.viewArchiveFormatter,
          headerAttrs: { width: 50, style: { textAlign: 'center' } }
        },
        {
          dataField: 'credit',
          text: 'Credit',
          sort: false,
          formatter: this.viewCreditFormFormatter,
          headerAttrs: { width: 50, style: { textAlign: 'center' } }
        },
        {
          dataField: 'order',
          text: 'Order',
          sort: false,
          formatter: this.viewOrderFormFormatter,
          headerAttrs: { width: 50, style: { textAlign: 'center' } }
        },
        {
          dataField: 'edit',
          text: 'Edit',
          sort: false,
          formatter: this.userFormatter,
          headerAttrs: { width: 30, style: { textAlign: 'center' } }
        }
        // ,{
        //   dataField: 'delete',
        //   text: 'Delete',
        //   sort: false,
        //   formatter: this.deleteUserFormatter,
        //   headerAttrs: { width: 30, style: { textAlign: 'center' } }
        // }
      ]
    };

    this.handleAdd = this.handleAdd.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.userFormatter = this.userFormatter.bind(this);
    this.deleteUserFormatter = this.deleteUserFormatter.bind(this);

    this.getUserData = this.getUserData.bind(this);
  }

  GetAllUsers() {
    const api = `${process.env.REACT_APP_BASE_URL}users/api/Agent/`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_agent_token')}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = '/tcagent');
        }
        if (res.data.success === false) {
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        this.setState({
          users: res.data,
          loading: false
        });
      });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleCheckClick = () => {
    this.setState({ checked: !this.state.checked });
  };

  handleAdd(e) {
    e.preventDefault();
    this.setState({ Disable_Button: 'disabled' });
    try {
      let objUser = {
        comp_name: this.state.addCompName,
        title: this.state.addTitle,
        first_name: this.state.addFirstName,
        email: this.state.addEmail,
        phone_number: this.state.addPhoneNo,
        address: this.state.addAddress,
        service: this.state.addService.value
      };
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}users/api/agent`,
        data: objUser,

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('tcc_agent_token')}`
        }
      })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/tcagent');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          if (res.data.success === true) {
            notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 5, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            $('#add_new_user').hide();

            this.GetAllUsers();

            this.setState({
              addCompName: '',
              addTitle: '',
              addFirstName: '',
              addEmail: '',
              addPhoneNo: '',
              addAddress: '',
              addService: ''
            });
          } else {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
    this.setState({ Disable_Button: '' });
  }

  userFormatter = (cell, row) => {
    return row.isverified === 'Yes' ? (
      ''
    ) : (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <span
          className='fa fa-edit'
          onClick={() => this.getUserData(row.user_id)}
        />
      </div>
    );
  };

  deleteUserFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <span
          className='fa fa-trash'
          onClick={() =>
            notie.confirm({
              text: 'Warning: DO YOU WANT TO DELETE THIS ACCOUNT ?',
              submitText: 'Yes', // optional, default = 'Yes'
              cancelText: 'No', // optional, default = 'Cancel'
              position: 'top', // optional, default = 'top', enum: ['top', 'bottom']
              submitCallback: () => this.deleteUserData(row.user_id) // optional
            })
          }
        />
      </div>
    );
  };

  viewFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <span
          className='fa fa-file'
          onClick={() =>
            this.props.history.push({
              pathname: '/agent_client_agreements',
              state: {
                User_ID: row.user_id,
                User_FullName: row.first_name,
                Comp_Name: row.comp_name
              }
            })
          }
        />
      </div>
    );
  };

  viewArchiveFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <span
          className='fa fa-archive'
          onClick={() =>
            this.props.history.push({
              pathname: '/agent_client_agreement_archive',
              state: {
                User_ID: row.user_id,
                User_FullName: row.first_name,
                Comp_Name: row.comp_name
              }
            })
          }
        />
      </div>
    );
  };

  viewCreditFormFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <span
          className='fa fa-file'
          onClick={() =>
            this.props.history.push({
              pathname: '/agent_client_credit_form',
              state: {
                User_ID: row.user_id,
                User_FullName: row.first_name,
                Comp_Name: row.comp_name
              }
            })
          }
        />
      </div>
    );
  };

  viewOrderFormFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <span
          className='fa fa-file'
          onClick={() =>
            this.props.history.push({
              pathname: '/agent_client_order_form',
              state: {
                User_ID: row.user_id,
                User_FullName: row.first_name,
                Comp_Name: row.comp_name
              }
            })
          }
        />
      </div>
    );
  };

  viewActiveFormatter = (cell, row) => {
    return row.isverified === 'Yes' ? (
      'Yes'
    ) : (
      <div
        style={{
          textAlign: 'left',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <a
          onClick={() =>
            notie.confirm({
              text: 'Are you sure you want to RESEND the verification email?',
              submitText: 'Yes', // optional, default = 'Yes'
              cancelText: 'No', // optional, default = 'Cancel'
              position: 'top', // optional, default = 'top', enum: ['top', 'bottom']
              submitCallback: () =>
                this.resendVerifyEmail(
                  row.user_id,
                  row.email,
                  row.first_name,
                  row.title,
                  row.comp_name
                ) // optional
            })
          }
        >
          Resend <span className='fa fa-envelope' />
        </a>
      </div>
    );
  };

  handleEdit(e) {
    e.preventDefault();

    try {
      this.setState({ Disable_Button: 'disabled' });
      let objUser = {
        comp_name: this.state.editCompName,
        //password: this.state.editPassword,
        title: this.state.editTitle,
        first_name: this.state.editFirstName,
        phone_number: this.state.editPhoneNo,
        address: this.state.editAddress,
        status: this.state.editStatus ? 1 : 0,
        service: this.state.editService.value,
        user_id: this.state.User_ID
      };

      let headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_agent_token')}`
        }
      };

      let URL = `${process.env.REACT_APP_BASE_URL}users/api/agent/`;
      axios
        .put(URL, objUser, headers)
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/tcagent');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          } else if (res.data.success === true) {
            notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            this.setState({
              editCompName: '',
              editTitle: '',
              editFirstName: '',
              editPassword: '',
              editEmail: '',
              editPhoneNo: '',
              editAddress: '',
              editStatus: 0,
              editService: '',
              Disable_Button: ''
            });

            $('#edit_user').hide();

            this.GetAllUsers();
          } else {
            this.setState({ Disable_Button: '' });
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        })
        .catch(function(response) {
          //handle error
          this.setState({ Disable_Button: '' });
          notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      this.setState({ Disable_Button: '' });
      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  getUserData(_User_ID) {
    try {
      this.setState({
        editCompName: '',
        editTitle: '',
        editFirstName: '',
        editPassword: '',
        editEmail: '',
        editPhoneNo: '',
        editAddress: '',
        editService: '',
        editStatus: 0
      });
      this.setState({ User_ID: _User_ID });
      const api = `${process.env.REACT_APP_BASE_URL}users/api/getAgentUser?user_id=${_User_ID}`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_agent_token')}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/tcagent');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          $('#edit_user').show();
          this.setState({
            editCompName: res.data[0].comp_name,
            editTitle: res.data[0].title,
            editFirstName: res.data[0].first_name,
            editEmail: res.data[0].email,
            editPhoneNo: res.data[0].phone_number,
            editAddress: res.data[0].address,
            editStatus: res.data[0].status,
            editService:
              res.data[0].service !== null
                ? { value: res.data[0].service, label: res.data[0].service }
                : null
          });
        });
    } catch (error) {}
  }

  deleteUserData(_User_ID) {
    try {
      const api = `${process.env.REACT_APP_BASE_URL}users/api/agent/?user_id=${_User_ID}`;
      axios
        .delete(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_agent_token')}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/tcagent');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          } else if (res.data.success === true) {
            notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            this.GetAllUsers();
          } else {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: 'Some problem in deleting record.',
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        });
    } catch (error) {}
  }

  resendVerifyEmail(
    _User_ID,
    _User_Email,
    _User_Full_Name,
    _User_Title,
    _User_Comp_Name
  ) {
    try {
      let objUser = {
        user_id: _User_ID,
        email: _User_Email,
        full_name: _User_Full_Name,
        title: _User_Title,
        comp_name: _User_Comp_Name
      };

      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}users/api/adminResendVerifiedEmail`,
        data: objUser,

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('tcc_agent_token')}`
        }
      }).then(res => {
        if (res.data.logout === true) {
          return (window.location = '/tcagent');
        }
        if (res.data.success === false) {
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        } else {
          notie.alert({
            type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
      });
    } catch (error) {}
  }

  addServiceSetValues = selectedOption => {
    this.setState({ addService: selectedOption }, () => {
      console.log('selectedOption', selectedOption);
    });
  };

  editServiceSetValues = selectedOption => {
    this.setState({ editService: selectedOption }, () => {
      console.log('selectedOption', selectedOption);
    });
  };

  componentWillMount = () => {
    //console.log('ManageUsers will mount');
  };

  componentDidMount = () => {
    //console.log('ManageUsers mounted');
    this.GetAllUsers();
  };

  componentWillReceiveProps = nextProps => {
    //console.log('ManageUsers will receive props', nextProps);
  };

  componentWillUpdate = (nextProps, nextState) => {
    //console.log('ManageUsers will update', nextProps, nextState);
  };

  componentDidUpdate = () => {
    //console.log('ManageUsers did update');
  };

  componentWillUnmount = () => {
    //console.log('ManageUsers will unmount');
  };

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    const { loading } = this.state;
    return (
      <div>
        <div className='row'>
          <div className='col-md-12 card '>
            <div className='row'>
              <div className='col-md-9'>
                <h4 className='ml-3' id='heading'>
                  Manage Clients
                </h4>
              </div>
              <div className='col-md-3 col-sm-5 text-right text-sm-left '>
                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={() => $('#add_new_user').show()}
                >
                  Add New Client
                </button>
              </div>
            </div>
            <hr />
            <div className='container-fluid'>
              <div className='col-lg-12'>
                <div className='example-wrap'>
                  <div className='example'>
                    {loading ? (
                      <div style={{ textAlign: 'center' }}>
                        <img
                          src='assets/images/tcc_loading.gif'
                          style={{ width: '128px' }}
                        />
                      </div>
                    ) : (
                      <div>
                        <ToolkitProvider
                          keyField='user_id'
                          data={this.state.users}
                          columns={this.state.columns}
                          search
                        >
                          {props => (
                            <div>
                              <SearchBar
                                {...props.searchProps}
                                className='custome-search-field'
                                style={{ color: '#000' }}
                                delay={1000}
                                placeholder='Search ...'
                              />
                              <hr />
                              <BootstrapTable
                                {...props.baseProps}
                                filter={filterFactory()}
                                pagination={paginationFactory()}
                                striped
                                hover
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={this.handleAdd}>
          <div
            className='modal myModal AddUser'
            id='add_new_user'
            style={{
              display: 'none',
              paddingLeft: '15px',
              overflow: 'auto'
            }}
          >
            <div className='modal-dialog modal-dialog-centered'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h4 className='modal-title'>Add New Client</h4>
                  <button
                    type='button'
                    className='close'
                    onClick={() => $('#add_new_user').hide()}
                  >
                    ×
                  </button>
                </div>

                <div className='modal-body'>
                  <div className='container-fluid'>
                    <div className='form-group row'>
                      <label htmlFor='addService' className='col-md-3'>
                        Client Service:
                      </label>
                      <Select
                        id='addService'
                        style={{ width: '200px' }}
                        onChange={this.addServiceSetValues}
                        placeholder='Select Client Service'
                        options={this.state.service}
                        value={this.state.addService}
                        required
                      />
                    </div>
                    <div className='form-group row'>
                      <label htmlFor='addCompName' className='col-md-3'>
                        Company Name:
                      </label>
                      <input
                        type='text'
                        id='addCompName'
                        className='form-control col-md-7'
                        name='addCompName'
                        value={this.state.addCompName}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className='form-group row'>
                      <label htmlFor='addEmail' className='col-md-3'>
                        Email:
                      </label>
                      <input
                        type='email'
                        id='addEmail'
                        className='form-control col-md-7'
                        name='addEmail'
                        value={this.state.addEmail}
                        onChange={this.handleChange}
                        required
                      />
                    </div>

                    <div className='form-group row'>
                      <label className='col-md-3' htmlFor='addTitle'>
                        Title:
                      </label>
                      <input
                        type='text'
                        className='form-control col-md-7'
                        id='addTitle'
                        name='addTitle'
                        value={this.state.addTitle}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className='form-group row'>
                      <label htmlFor='addFirstName' className='col-md-3'>
                        Full Name:
                      </label>
                      <input
                        type='text'
                        id='addFirstName'
                        className='form-control col-md-7'
                        name='addFirstName'
                        value={this.state.addFirstName}
                        onChange={this.handleChange}
                        required
                      />
                    </div>

                    <div className='form-group row'>
                      <label htmlFor='addPhoneNo' className='col-md-3'>
                        Contact Number:
                      </label>

                      <InputMask
                        mask='99999999999'
                        pattern='.{10,}'
                        maskChar={null}
                        id='addPhoneNo'
                        className='form-control col-md-7'
                        name='addPhoneNo'
                        placeholder='Contact Number 2134567890'
                        title='Contact Number 2134567890'
                        value={this.state.addPhoneNo}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className='form-group row'>
                      <label htmlFor='addAddress' className='col-md-3'>
                        Address:
                      </label>
                      <textarea
                        id='addAddress'
                        className='form-control col-md-7'
                        placeholder='Service Address: City, State, Zip'
                        name='addAddress'
                        value={this.state.addAddress}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-warning'
                    onClick={() => $('#add_new_user').hide()}
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    className='btn btn-success'
                    disabled={this.state.Disable_Button}
                  >
                    Save{' '}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        {/** EDIT FORM */}
        <form onSubmit={this.handleEdit}>
          <div
            className='modal edit_user myModal'
            id='edit_user'
            style={{ display: 'none', paddingLeft: '15px', overflow: 'auto' }}
          >
            <div className='modal-dialog modal-dialog-centered'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h4 className='modal-title'>Edit Client</h4>
                  <button
                    type='button'
                    className='close'
                    onClick={() => $('#edit_user').hide()}
                  >
                    ×
                  </button>
                </div>

                <div className='modal-body'>
                  <div className='container-fluid'>
                    <div className='form-group row'>
                      <label htmlFor='editService' className='col-md-3'>
                        Client Service:
                      </label>
                      <Select
                        id='editService'
                        style={{ width: '200px' }}
                        onChange={this.editServiceSetValues}
                        options={this.state.service}
                        value={this.state.editService}
                        required
                      />
                    </div>
                    <div className='form-group row'>
                      <label htmlFor='editCompName' className='col-md-3'>
                        Company Name:
                      </label>
                      <input
                        type='text'
                        id='editCompName'
                        className='form-control col-md-7'
                        name='editCompName'
                        value={this.state.editCompName}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className='form-group row'>
                      <label htmlFor='editEmail' className='col-md-3'>
                        Email Address:
                      </label>
                      <input
                        type='email'
                        id='editEmail'
                        className='form-control col-md-7'
                        name='editEmail'
                        value={this.state.editEmail}
                        onChange={this.handleChange}
                        readOnly
                      />
                    </div>
                    <div className='form-group row'>
                      <label htmlFor='editTitle' className='col-md-3'>
                        Title:
                      </label>
                      <input
                        type='text'
                        id='editTitle'
                        className='form-control col-md-7'
                        name='editTitle'
                        value={this.state.editTitle}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className='form-group row'>
                      <label htmlFor='editFirstName' className='col-md-3'>
                        Full Name:
                      </label>
                      <input
                        type='text'
                        id='editFirstName'
                        className='form-control col-md-7'
                        name='editFirstName'
                        value={this.state.editFirstName}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    {/** 
                    <div className='form-group row'>
                      <label htmlFor='editPassword' className='col-md-3'>
                        Password:
                      </label>
                      <input
                        type='password'
                        className='form-control col-md-7'
                        id='editPassword'
                        name='editPassword'
                        placeholder='Password'
                        pattern='(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}'
                        title='Min 8 Chars must contain 1 Number, 1 Lowercase & 1 Uppercase'
                        onChange={this.handleChange}
                        value={this.state.editPassword}
                      />
                      <span
                        style={{
                          fontSize: '10px',
                          marginLeft: '155px'
                        }}
                      >
                        8+ characters. Mixed Case, Numbers and Symbols
                      </span>
                    </div>
                    */}
                    <div className='form-group row'>
                      <label htmlFor='editPhoneNo' className='col-md-3'>
                        Contact Number:
                      </label>

                      <InputMask
                        mask='99999999999'
                        pattern='.{10,}'
                        maskChar={null}
                        id='editPhoneNo'
                        className='form-control col-md-7'
                        name='editPhoneNo'
                        placeholder='Contact Number 2134567890'
                        title='Contact Number 2134567890'
                        value={this.state.editPhoneNo}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className='form-group row'>
                      <label htmlFor='editAddress' className='col-md-3'>
                        Address:
                      </label>
                      <textarea
                        id='editAddress'
                        className='form-control col-md-7'
                        name='editAddress'
                        value={this.state.editAddress}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className='form-group row'>
                      <label htmlFor='editStatus' className='col-md-3'>
                        Active:
                      </label>
                      <input
                        type='checkbox'
                        id='editStatus'
                        name='editStatus'
                        className='checkbox-custom'
                        checked={this.state.editStatus}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-warning'
                    onClick={() => $('#edit_user').hide()}
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    className='btn btn-success'
                    disabled={this.state.Disable_Button}
                  >
                    Save{' '}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default ManageUsers;
