import React, { Component } from 'react';
import axios from 'axios';
import notie from 'notie';
import $ from 'jquery';
import { replaceAgreementContent } from '../../helper/util';

class SignUpAgreements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      hasError: false,
      agreements: [],
      previewAgreement: ''
    };
    this.viewAgreementData = this.viewAgreementData.bind(this);
    this.GetSignUpAgreements = this.GetSignUpAgreements.bind(this);
  }

  GetSignUpAgreements() {
    try {
      const api = `${process.env.REACT_APP_BASE_URL}agreements/api/getSignUpAgreement`;
      axios.get(api).then(res => {
        if (res.data.length > 0) {
          this.setState({
            agreements: res.data
          });
        }
      });
    } catch (error) {
      console.log('GetSignUpAgreements', error);
    }
  }

  viewAgreementData(_Agreement_ID) {
    try {
      this.setState({
        previewAgreement: '',
        loading: true
      });
      $('#preview_agreement').show();

      const api = `${process.env.REACT_APP_BASE_URL}agreements/api/getSignUpAgreement`;
      let objAgreement = {
        agreement_id: _Agreement_ID
      };
      axios({
        method: 'post',
        url: api,
        data: objAgreement,

        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        let objUser = {
          client_title: '',
          client_name: '',
          client_comp_name: '',
          client_email: '',
          client_address: '',
          client_phone_no: '',
          client_fax: '',
          vendor_signor_name: res.data[0].vendor_signor_name,
          vendor_signor_comp_name: res.data[0].vendor_signor_comp_name,
          vendor_signor_title: res.data[0].vendor_signor_title,
          vendor_signor_address: res.data[0].vendor_signor_address,
          vendor_signor_email: res.data[0].vendor_signor_email,
          vendor_signor_phone_no: res.data[0].vendor_signor_phone_no,
          vendor_signor_fax: res.data[0].vendor_signor_fax,
          user_comp_name: res.data[0].user_comp_name,
          user_comp_name_short: res.data[0].user_comp_name_short,
          user_address: res.data[0].user_address
        };

        this.setState({
          previewAgreement: replaceAgreementContent(
            res.data[0].agreement_content,
            objUser
          ),
          loading: false
        });
      });
    } catch (error) {
      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  componentDidMount = () => {
    this.GetSignUpAgreements();
  };

  componentWillUnmount() {}

  render() {
    const { loading } = this.state;
    return (
      <div>
        <div className='form-group clearfix'>
          <div className='checkbox-custom checkbox-inline checkbox-primary float-left'>
            <input type='checkbox' id='chkAccept' name='chkAccept' required />
            <label htmlFor='chkAccept' className='chkAccept'>
              I agree to the{' '}
              {this.state.agreements.map((agreement, index) => (
                <a
                  key={index}
                  href='javascript:void(0)'
                  onClick={() => this.viewAgreementData(agreement.agreement_id)}
                  style={{ float: 'right' }}
                >
                  {agreement.agreement_title}
                </a>
              ))}
            </label>
          </div>
        </div>
        {/** PREWVIEW AGREEMENT*/}

        <div
          className='modal preview_agreement'
          id='preview_agreement'
          style={{ display: 'none' }}
        >
          <div className='modal-dialog modal-dialog-centered modal-lg'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h4 className='modal-title'>Agreement Preview</h4>
                <button
                  type='button'
                  className='close'
                  onClick={() => $('#preview_agreement').hide()}
                >
                  ×
                </button>
              </div>
              {loading ? (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src='../assets/images/tcc_loading.gif'
                    style={{ width: '128px' }}
                    alt='...'
                  />
                </div>
              ) : (
                <div className='modal-body'>
                  <div className='container-fluid'>
                    <div className='form-group row'>
                      <div
                        style={{
                          width: '100%',
                          height: 'auto',
                          overflowY: 'auto',
                          maxHeight: 'calc(100vh - 100px)'
                        }}
                        dangerouslySetInnerHTML={{
                          __html: this.state.previewAgreement
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SignUpAgreements;
