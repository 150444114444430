import React from 'react';
import notie from 'notie';
import axios from 'axios';
import $ from 'jquery';

const AuthContext = React.createContext();

class AuthProvider extends React.Component {
  constructor() {
    super();

    this.state = {
      isAuth: false,
      isAuthUser: false,
      User_ID: '',
      User_Name: '',
      User_Email: '',
      User_Title: '',
      Comp_Name: '',
      Comp_Logo: '',
      Comp_Logo_Dashboard: '',
      Client_Marketing_Content: '',
      Dashboard_Content: '',
      Service: '',

      Meta_Tags: '',
      Comp_Phone_No: '',
      Comp_Address: '',
      isMandatoryCompeleted: 0,
      User_Agreement_ID: 0,
      Support_Email: ''
    };
    const api = `${process.env.REACT_APP_BASE_URL}settings/api/`;
    axios
      .get(api, {
        config: { headers: { 'Content-Type': 'application/json' } }
      })
      .then(res => {
        this.setState({
          Comp_Name: res.data[0].setting_comp_name,
          Support_Email: res.data[0].setting_support_email,
          Comp_Phone_No: res.data[0].setting_comp_phone_no,
          Comp_Address: res.data[0].setting_comp_address,
          Comp_Logo: res.data[0].setting_comp_small_logo,
          Comp_Logo_Dashboard: res.data[0].setting_comp_large_logo,
          Meta_Tags: res.data[0].setting_meta_tags
        });
      })
      .catch(function(response) {
        //handle error
      });

    const apiContent = `${process.env.REACT_APP_BASE_URL}cms/api/getCMS?cms_id=4`;
    axios
      .get(apiContent, {
        config: { headers: { 'Content-Type': 'application/json' } }
      })
      .then(res => {
        this.setState({
          Client_Marketing_Content: res.data[0].cms_template
        });
      })
      .catch(function(response) {
        //handle error
      });

    //GET SETTING DATA
    if (
      localStorage.getItem('tcc_token') !== null &&
      localStorage.getItem('tcc_user_id') !== null
    ) {
      this.login(null, '', '', true);
    }

    if (
      localStorage.getItem('tcc_user_token') !== null &&
      localStorage.getItem('tcc_user_id') !== null
    ) {
      var pathName = window.location.pathname;
      var pageName = pathName.split('/').pop();
      console.log('pageName', pageName);
      if (
        pageName !== 'complete_signup_process' &&
        pageName !== 'reset_password'
      ) {
        this.userlogin(null, '', '', true);
      }
    }

    this.login = this.login.bind(this);
    this.userlogin = this.userlogin.bind(this);
    this.logout = this.logout.bind(this);
    this.Complete_Signup = this.Complete_Signup.bind(this);
    this.Reset_Password = this.Reset_Password.bind(this);
    this.Verify_User = this.Verify_User.bind(this);
  }

  setisMandatoryCompeleted = isMandatoryCompeleted => {
    this.setState({ isMandatoryCompeleted });
  };

  login = async (e, User_Name, User_Password, isAuto) => {
    if (isAuto) {
      $('#root').css('visibility', 'hidden');
      const api = `${process.env.REACT_APP_BASE_URL}users/api/adminlogin`;

      await axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
          }
        })
        .then(res => {
          //console.log(res.data);

          if (res.data.success) {
            this.setState({
              isAuth: true,
              User_ID: localStorage.getItem('tcc_user_id')
            });
          }
        });
    } else {
      $('#root').css('visibility', '');
      e.preventDefault();
      let userCredentails = {
        username: User_Name,
        password: User_Password
      };
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}users/api/adminlogin`,
        data: userCredentails,
        config: { headers: { 'Content-Type': 'application/json' } }
      })
        .then(data => {
          let _data = data.data;
          if (_data.success) {
            localStorage.setItem('tcc_token', _data.token);
            localStorage.setItem('tcc_user_id', _data.user[0].user_id);
            localStorage.setItem('tcc_user_email', _data.user[0].email);
            this.setState({ isAuth: true, User_ID: _data.user[0].user_id });
          } else {
            localStorage.removeItem('tcc_token');
            localStorage.removeItem('tcc_user_id');

            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: _data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    }
    $('#root').css('visibility', '');
  };

  userlogin = async (e, User_Email, User_Password, isAuto) => {
    if (isAuto) {
      $('#root').css('visibility', 'hidden');
      const api = `${process.env.REACT_APP_BASE_URL}users/api/login`;

      await axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_user_token')}`
          }
        })
        .then(res => {
          if (res.data.success) {
            this.setState({
              isAuthUser: true,
              User_ID: res.data.user.user_id,
              User_Name: res.data.user.first_name,
              User_Email: res.data.user.email,
              User_Title: res.data.user.title,
              isMandatoryCompeleted: res.data.user.isMandatoryCompeleted,
              User_Agreement_ID: res.data.user.User_Agreement_ID,
              Service: res.data.user.service
            });
          }
          $('#root').css('visibility', '');
        });
    } else {
      $('#root').css('visibility', '');
      e.preventDefault();
      let userCredentails = {
        email: User_Email,
        password: User_Password
      };
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}users/api/login`,
        data: userCredentails,
        config: { headers: { 'Content-Type': 'application/json' } }
      })
        .then(data => {
          let _data = data.data;
          if (_data.success) {
            localStorage.setItem('tcc_user_token', _data.token);
            localStorage.setItem('tcc_user_id', _data.user[0].user_id);
            localStorage.setItem('tcc_user_email', _data.user[0].email);

            this.setState({
              isAuthUser: true,
              User_ID: _data.user[0].user_id,
              User_Name: _data.user[0].first_name,
              User_Email: _data.user[0].email,
              User_Title: _data.user[0].title,
              isMandatoryCompeleted: _data.user[0].isMandatoryCompeleted,
              User_Agreement_ID: _data.user[0].User_Agreement_ID,
              Service: _data.user[0].service
            });
            $('#root').css('visibility', '');
          } else {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: _data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    }
    $('#root').css('visibility', '');
  };

  Complete_Signup(event, User_Password, User_Confirm_Password, Token) {
    event.preventDefault();
    try {
      if (User_Password !== User_Confirm_Password) {
        return notie.alert({
          type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: 'Password and Confirm Password not Same.',
          stay: false, // optional, default = false
          time: 3, // optional, default = 3, minimum = 1,
          position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
        });
      }
      let objUser = {
        password: User_Password
      };
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}users/api/complete_signup`,
        data: objUser,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Token}`
        }
      }).then(res => {
        if (res.data.success === false) {
          notie.alert({
            type: 2,
            text: res.data.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top'
          });
        } else {
          let _data = res.data;
          localStorage.setItem('tcc_user_token', _data.token);
          localStorage.setItem('tcc_user_id', _data.user[0].user_id);
          localStorage.setItem('tcc_user_email', _data.user[0].email);
          this.setState({
            isAuthUser: true,
            User_ID: _data.user[0].user_id,
            User_Email: _data.user[0].email,
            User_Name: _data.user[0].first_name,
            User_Title: _data.user[0].title,
            isMandatoryCompeleted: _data.user[0].isMandatoryCompeleted,
            User_Agreement_ID: _data.user[0].User_Agreement_ID
          });
        }
      });
    } catch (error) {}
  }

  Reset_Password(event, User_Password, User_Confirm_Password, Token) {
    event.preventDefault();
    try {
      if (User_Password !== User_Confirm_Password) {
        return notie.alert({
          type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: 'Password and Confirm Password not Same.',
          stay: false, // optional, default = false
          time: 3, // optional, default = 3, minimum = 1,
          position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
        });
      }
      let objUser = {
        password: User_Password
      };
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}users/api/reset_password`,
        data: objUser,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Token}`
        }
      }).then(res => {
        if (res.data.success === false) {
          notie.alert({
            type: 2,
            text: res.data.message,
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top'
          });
        } else if (res.data.success === 'reload') {
          document.location.reload(true);
        } else {
          let _data = res.data;
          localStorage.setItem('tcc_user_token', _data.token);
          localStorage.setItem('tcc_user_id', _data.user[0].user_id);
          localStorage.setItem('tcc_user_email', _data.user[0].email);
          this.setState({
            isAuthUser: true,
            User_ID: _data.user[0].user_id,
            User_Email: _data.user[0].email,
            User_Name: _data.user[0].first_name,
            User_Title: _data.user[0].title,
            isMandatoryCompeleted: _data.user[0].isMandatoryCompeleted,
            User_Agreement_ID: _data.user[0].User_Agreement_ID
          });
        }
      });
    } catch (error) {}
  }

  Verify_User(token) {
    try {
      console.log(`${process.env.REACT_APP_BASE_URL}users/api/verify_account`);
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}users/api/verify_account`,
        data: '',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }).then(res => {
        if (res.data.success === false) {
          notie.force({
            type: 2, // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            buttonText: 'Click Here To Login',
            text: res.data.message,
            stay: false, // optional, default = false
            time: 15, // optional, default = 3, minimum = 1,
            position: 'top',
            callback: function() {
              window.location = 'client_login';
            }
          });
        } else {
          let _data = res.data;
          localStorage.setItem('tcc_user_token', _data.token);
          localStorage.setItem('tcc_user_id', _data.user[0].user_id);
          localStorage.setItem('tcc_user_email', _data.user[0].email);
          this.setState({
            isAuthUser: true,
            User_ID: _data.user[0].user_id,
            User_Email: _data.user[0].email,
            User_Name: _data.user[0].first_name,
            User_Title: _data.user[0].title,
            isMandatoryCompeleted: _data.user[0].isMandatoryCompeleted,
            User_Agreement_ID: _data.user[0].User_Agreement_ID
          });
        }
      });
    } catch (error) {}
  }

  logout() {
    this.setState({ isAuth: false, isAuthUser: false });
    localStorage.removeItem('tcc_token');
    localStorage.removeItem('tcc_user_token');
    localStorage.removeItem('tcc_user_id');
    localStorage.removeItem('tcc_user_email');

    var arr = []; // Array to hold the keys
    // Iterate over localStorage and insert the keys that meet the condition into arr
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).substring(0, 10) === 'lastKnown_') {
        arr.push(localStorage.key(i));
      }
    }

    // Iterate over arr and remove the items by key
    for (let i = 0; i < arr.length; i++) {
      localStorage.removeItem(arr[i]);
    }
  }

  render() {
    return (
      <AuthContext.Provider
        value={{
          isAuth: this.state.isAuth,
          isAuthUser: this.state.isAuthUser,
          User_ID: this.state.User_ID,
          User_Name: this.state.User_Name,
          User_Email: this.state.User_Email,
          User_Title: this.state.User_Title,
          Service: this.state.Service,
          login: this.login,
          userlogin: this.userlogin,
          Complete_Signup: this.Complete_Signup,
          Reset_Password: this.Reset_Password,
          Verify_User: this.Verify_User,
          logout: this.logout,
          Comp_Name: this.state.Comp_Name,
          Support_Email: this.state.Support_Email,
          Comp_Phone_No: this.state.Comp_Phone_No,
          Comp_Address: this.state.Comp_Address,
          Comp_Logo: this.state.Comp_Logo,
          Comp_Logo_Dashboard: this.state.Comp_Logo_Dashboard,
          Client_Marketing_Content: this.state.Client_Marketing_Content,
          Dashboard_Content: this.state.Dashboard_Content,
          Meta_Tags: this.state.Meta_Tags,
          isMandatoryCompeleted: this.state.isMandatoryCompeleted,
          setisMandatoryCompeleted: this.setisMandatoryCompeleted,
          User_Agreement_ID: this.state.User_Agreement_ID
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer };
