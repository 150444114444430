import React, { Component } from 'react';

class AgentDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      User_ID: '',
      User_Agreement_ID: '',
      User_FullName: '',
      previewAgreement: '',
      agreements: [],
      loading: false
    };
  }

  componentWillMount = () => {
    //console.log('UserAgreements will mount');
  };

  componentDidMount = () => {};

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    const { loading } = this.state;
    return (
      <div>
        <div className='row'>
          <div className='col-md-12 card '>
            <div className='container-fluid'>
              <div className='col-md-9'>
                <h4 className='ml-3' id='heading'>
                  Agent Dashboard
                </h4>
              </div>
              <hr />
              <div className='col-lg-12'>
                <div className='example-wrap'>
                  <div className='example'>
                    {loading ? (
                      <div style={{ textAlign: 'center' }}>
                        <img
                          src='../assets/images/tcc_loading.gif'
                          style={{ width: '128px' }}
                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AgentDashboard;
