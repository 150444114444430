import React from "react";
import notie from "notie";
import axios from "axios";
import Select from "react-select";
import moment from "moment";
import "./UserOrderForm.css";
import $ from "jquery";
class UserOrderForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      Comp_Name: this.props.Comp_Name,
      Comp_Phone_No: this.props.Comp_Phone_No,
      Comp_Address: this.props.Comp_Address,
      Current_Date: moment().format("MM-DD-YYYY"),
      Order_Form_Instruction: "",
      Products: [],

      contact_company_name: "",
      contact_company_telephone: "",
      contact_accounting_address: "",
      contact_billing_contact: "",
      contact_provisoning_contact: "",
      contact_network_ip_activation_support: "",
      contact_network_tdm_activation_support: "",
      contact_trouble_reporting_contact: "",
      contact_trouble_reporting_alternate: "",
      contact_trouble_reporting_email: "",
      contact_email_sale_engineer: "",

      customer_company_name: "",
      customer_title: "",
      customer_full_name: "",
      customer_contact_number: "",
      customer_address: "",
      transport_order_form_company_name: "",
      transport_order_form_carrier_type: "",
      transport_order_form_requested_due_date: "",
      transport_order_form_requested_contact_number: "",
      transport_order_form_requested_order_type: "",
      transport_order_form_requested_contact_email: "",
      ip_tof_switch_type_interconnection: "",
      ip_tof_other: "",
      ip_tof_services_ip_ranges: "",
      ip_tof_capacity_sessions: "",
      ip_tof_estimated_mou: "",
      ip_tof_lnp_dips: "",
      ip_tof_lrn_route: "",
      ip_tof_max_ptime: "",
      ip_tof_fax_preference: "",
      ip_tof_num_plan: "",
      ip_tof_sil_sup_required: "",
      ip_tof_ip_route_protocol: "",
      ip_tof_bgp_ip_ranges: "",
      ip_tof_sip_rtp_ranges: "",
      ip_tof_ven_cus_require: "",
      ip_tof_reject_code: "",
      ip_tof_cdr_rep_interval: "",
      ip_tof_call_rejects: "",
      ip_tof_dtmf_handling: "",
      netblock_ip_address: "",
      customer_ip_address1: "",
      requested_ports_ses_count1: "",
      requested_call_per_sec1: "",
      default_port_number1: "",
      preferred_stat_routing1: "",
      rtp_paketization_time1: "",
      description1: "",
      product1: "",
      customer_ip_address2: "",
      requested_ports_ses_count2: "",
      requested_call_per_sec2: "",
      default_port_number2: "",
      preferred_stat_routing2: "",
      rtp_paketization_time2: "",
      description2: "",
      product2: "",
      customer_ip_address3: "",
      requested_ports_ses_count3: "",
      requested_call_per_sec3: "",
      default_port_number3: "",
      preferred_stat_routing3: "",
      rtp_paketization_time3: "",
      description3: "",
      product3: "",
      customer_ip_address4: "",
      requested_ports_ses_count4: "",
      requested_call_per_sec4: "",
      default_port_number4: "",
      preferred_stat_routing4: "",
      rtp_paketization_time4: "",
      description4: "",
      product4: "",
      hdproduct_1: "",
      hdproduct_title_1: "",
      addProduct_1: "",
      addProduct_1_Selected: "",

      hdproduct_2: "",
      hdproduct_title_2: "",
      addProduct_2: "",
      addProduct_2_Selected: "",

      hdproduct_3: "",
      hdproduct_title_3: "",
      addProduct_3: "",
      addProduct_3_Selected: "",

      hdproduct_4: "",
      hdproduct_title_4: "",
      addProduct_4: "",
      addProduct_4_Selected: "",

      hdCalled: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.GetOrderFormData = this.GetOrderFormData.bind(this);
    this.GetUserData = this.GetUserData.bind(this);
    this.GetAllProducts = this.GetAllProducts.bind(this);
    this.GetOrderFormInstruction = this.GetOrderFormInstruction.bind(this);
    this.addProduct1SetValues = this.addProduct1SetValues.bind(this);
    this.addProduct2SetValues = this.addProduct2SetValues.bind(this);
    this.addProduct3SetValues = this.addProduct3SetValues.bind(this);
    this.addProduct4SetValues = this.addProduct4SetValues.bind(this);
  }

  GetAllProducts() {
    const api = `${process.env.REACT_APP_BASE_URL}products/api/DDProduct`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tcc_user_token")}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = "/client_login");
        }
        if (res.data.success === false) {
          return notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        //console.log(res.data);

        this.setState({
          Products: res.data
        });
        this.GetOrderFormInstruction();
        this.GetOrderFormData();
      });
  }

  GetUserData() {
    Object.keys(localStorage).forEach(function(key) {
      if (!/^tcc_/.test(key)) {
        localStorage.removeItem(key);
      }
    });
    const api = `${process.env.REACT_APP_BASE_URL}users/api/getUserByUserID`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tcc_user_token")}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = "/client_login");
        }
        if (res.data.success === false) {
          return notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        if (res.data[0] !== undefined) {
          $("#customer_company_name").val(res.data[0].comp_name);
          $("#customer_title").val(res.data[0].title);
          $("#customer_full_name").val(res.data[0].first_name);
          $("#customer_contact_number").val(res.data[0].phone_number);
          $("#customer_address").val(res.data[0].address);
        }

        this.setState({
          loading: false
        });
      });
  }

  GetOrderFormData() {
    const api = `${process.env.REACT_APP_BASE_URL}order_form/api`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tcc_user_token")}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = "/client_login");
        }
        if (res.data.success === false) {
          return notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        if (res.data[0] !== undefined) {
          //console.log(res.data[0].contact_company_name);

          let _addProduct_1_Selected =
            res.data[0].product_title_1 !== ""
              ? JSON.parse(
                  `{"label" : "${res.data[0].product_title_1}","value":"${res.data[0].product_selected_1}"}`
                )
              : "";

          let _addProduct_2_Selected =
            res.data[0].product_title_2 !== ""
              ? JSON.parse(
                  `{"label" : "${res.data[0].product_title_2}","value":"${res.data[0].product_selected_2}"}`
                )
              : "";

          let _addProduct_3_Selected =
            res.data[0].product_title_3 !== ""
              ? JSON.parse(
                  `{"label" : "${res.data[0].product_title_3}","value":"${res.data[0].product_selected_3}"}`
                )
              : "";

          let _addProduct_4_Selected =
            res.data[0].product_title_4 !== ""
              ? JSON.parse(
                  `{"label" : "${res.data[0].product_title_4}","value":"${res.data[0].product_selected_4}"}`
                )
              : "";

          this.setState({
            loading: true,
            hdproduct_title_1: res.data[0].product_title_1,
            hdproduct_1: res.data[0].product_selected_1,
            addProduct_1_Selected: _addProduct_1_Selected,

            hdproduct_title_2: res.data[0].product_title_2,
            hdproduct_2: res.data[0].product_selected_2,
            addProduct_2_Selected: _addProduct_2_Selected,

            hdproduct_title_3: res.data[0].product_title_3,
            hdproduct_3: res.data[0].product_selected_3,
            addProduct_3_Selected: _addProduct_3_Selected,

            hdproduct_title_4: res.data[0].product_title_4,
            hdproduct_4: res.data[0].product_selected_4,
            addProduct_4_Selected: _addProduct_4_Selected
          });

          $("#contact_company_name").val(res.data[0].contact_company_name);
          $("#contact_company_telephone").val(
            res.data[0].contact_company_telephone
          );
          $("#contact_accounting_address").val(
            res.data[0].contact_accounting_address
          );
          $("#contact_billing_contact").val(
            res.data[0].contact_billing_contact
          );
          $("#contact_provisoning_contact").val(
            res.data[0].contact_provisoning_contact
          );
          $("#contact_network_ip_activation_support").val(
            res.data[0].contact_network_ip_activation_support
          );
          $("#contact_network_tdm_activation_support").val(
            res.data[0].contact_network_tdm_activation_support
          );
          $("#contact_trouble_reporting_contact").val(
            res.data[0].contact_trouble_reporting_contact
          );
          $("#contact_trouble_reporting_alternate").val(
            res.data[0].contact_trouble_reporting_alternate
          );
          $("#contact_trouble_reporting_email").val(
            res.data[0].contact_trouble_reporting_email
          );
          $("#contact_email_sale_engineer").val(
            res.data[0].contact_email_sale_engineer
          );
          $("#customer_company_name").val(res.data[0].customer_company_name);
          $("#customer_title").val(res.data[0].customer_title);
          $("#customer_full_name").val(res.data[0].customer_full_name);
          $("#customer_contact_number").val(
            res.data[0].customer_contact_number
          );
          $("#customer_address").val(res.data[0].customer_address);

          $("#transport_order_form_company_name").val(
            res.data[0].transport_order_form_company_name
          );

          $("#transport_order_form_carrier_type").val(
            res.data[0].transport_order_form_carrier_type
          );
          // $('#transport_order_form_requested_due_date').val(
          //   res.data[0].transport_order_form_requested_due_date
          // );
          $("#transport_order_form_requested_contact_number").val(
            res.data[0].transport_order_form_requested_contact_number
          );
          $("#transport_order_form_requested_order_type").val(
            res.data[0].transport_order_form_requested_order_type
          );
          $("#transport_order_form_requested_contact_email").val(
            res.data[0].transport_order_form_requested_contact_email
          );
          $("#ip_tof_switch_type_interconnection").val(
            res.data[0].ip_tof_switch_type_interconnection
          );
          $("#ip_tof_other").val(res.data[0].ip_tof_other);

          $("#ip_tof_services_ip_ranges").val(
            res.data[0].ip_tof_services_ip_ranges
          );
          $("#ip_tof_capacity_sessions").val(
            res.data[0].ip_tof_capacity_sessions
          );
          $("#ip_tof_estimated_mou").val(res.data[0].ip_tof_estimated_mou);
          $("#ip_tof_lnp_dips").val(res.data[0].ip_tof_lnp_dips);
          $("#ip_tof_lrn_route").val(res.data[0].ip_tof_lrn_route);

          $("#ip_tof_max_ptime").val(res.data[0].ip_tof_max_ptime);
          $("#ip_tof_fax_preference").val(res.data[0].ip_tof_fax_preference);
          $("#ip_tof_num_plan").val(res.data[0].ip_tof_num_plan);
          $("#ip_tof_sil_sup_required").val(
            res.data[0].ip_tof_sil_sup_required
          );
          $("#ip_tof_ip_route_protocol").val(
            res.data[0].ip_tof_ip_route_protocol
          );
          $("#ip_tof_bgp_ip_ranges").val(res.data[0].ip_tof_bgp_ip_ranges);
          $("#ip_tof_sip_rtp_ranges").val(res.data[0].ip_tof_sip_rtp_ranges);
          $("#ip_tof_ven_cus_require").val(res.data[0].ip_tof_ven_cus_require);
          $("#ip_tof_reject_code").val(res.data[0].ip_tof_reject_code);
          $("#ip_tof_cdr_rep_interval").val(
            res.data[0].ip_tof_cdr_rep_interval
          );
          $("#ip_tof_call_rejects").val(res.data[0].ip_tof_call_rejects);
          $("#ip_tof_dtmf_handling").val(res.data[0].ip_tof_dtmf_handling);
          $("#netblock_ip_address").val(res.data[0].netblock_ip_address);

          $("#customer_ip_address1").val(res.data[0].customer_ip_address1);
          $("#requested_ports_ses_count1").val(
            res.data[0].requested_ports_ses_count1
          );
          $("#requested_call_per_sec1").val(
            res.data[0].requested_call_per_sec1
          );
          $("#default_port_number1").val(res.data[0].default_port_number1);
          $("#preferred_stat_routing1").val(
            res.data[0].preferred_stat_routing1
          );
          $("#rtp_paketization_time1").val(res.data[0].rtp_paketization_time1);
          $("#description1").val(res.data[0].description1);

          //$('#product1').val(res.data[0].product1);

          $("#customer_ip_address2").val(res.data[0].customer_ip_address2);
          $("#requested_ports_ses_count2").val(
            res.data[0].requested_ports_ses_count2
          );
          $("#requested_call_per_sec2").val(
            res.data[0].requested_call_per_sec2
          );
          $("#default_port_number2").val(res.data[0].default_port_number2);
          $("#preferred_stat_routing2").val(
            res.data[0].preferred_stat_routing2
          );
          $("#rtp_paketization_time2").val(res.data[0].rtp_paketization_time2);
          $("#description2").val(res.data[0].description2);

          //$('#product2').val(res.data[0].product2);

          $("#customer_ip_address3").val(res.data[0].customer_ip_address3);
          $("#requested_ports_ses_count3").val(
            res.data[0].requested_ports_ses_count3
          );
          $("#requested_call_per_sec3").val(
            res.data[0].requested_call_per_sec3
          );
          $("#default_port_number3").val(res.data[0].default_port_number3);
          $("#preferred_stat_routing3").val(
            res.data[0].preferred_stat_routing3
          );
          $("#rtp_paketization_time3").val(res.data[0].rtp_paketization_time3);
          $("#description3").val(res.data[0].description3);

          //$('#product3').val(res.data[0].product3);

          $("#customer_ip_address4").val(res.data[0].customer_ip_address4);
          $("#requested_ports_ses_count4").val(
            res.data[0].requested_ports_ses_count4
          );
          $("#requested_call_per_sec4").val(
            res.data[0].requested_call_per_sec4
          );
          $("#default_port_number4").val(res.data[0].default_port_number4);
          $("#preferred_stat_routing4").val(
            res.data[0].preferred_stat_routing4
          );
          $("#rtp_paketization_time4").val(res.data[0].rtp_paketization_time4);
          $("#description4").val(res.data[0].description4);

          //$('#product4').val(res.data[0].product4);
        }

        this.setState({
          loading: false
        });
      });
  }

  GetOrderFormInstruction() {
    const api = `${process.env.REACT_APP_BASE_URL}cms/api/getCMS?cms_id=2`;
    axios.get(api).then(res => {
      if (res.data.logout === true) {
        return (window.location = "/client_login");
      }
      if (res.data.success === false) {
        return notie.alert({
          type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: res.data.message,
          stay: true, // optional, default = false
          position: "top" // optional, default = 'top', enum: ['top', 'bottom']
        });
      }

      this.setState({
        Order_Form_Instruction: res.data[0].cms_template
      });
    });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    localStorage.setItem(target.name, value);
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  hideAndShowA = () => {
    $("#link_1").hide();
    $("#two").show();
  };

  hideAndShowB = () => {
    $("#link_2").hide();
    $("#three").show();
  };

  hideAndShowC = () => {
    $("#link_3").hide();
    $("#four").show();
  };

  hideAndShowD = () => {
    $("#link_4").hide();
    $("#five").show();
  };

  hideAndShowE = () => {
    $("#link_5").hide();
    $("#six").show();
  };

  hideAndShowF = () => {
    $("#link_6").hide();
    $("#seven").show();
  };

  hideIPShowDIv1 = () => {
    $("#link_first").hide();
    $("#div_first").show();
  };

  hideIPShowDIv2 = () => {
    $("#link_sec").hide();
    $("#div_sec").show();
  };

  hideIPShowDIv3 = () => {
    $("#link_third").hide();
    $("#div_third").show();
  };

  handleOptionChange = changeEvent => {
    const target = changeEvent.target;

    const value = target.type === "radio" ? target.value : target.checked;

    const name = target.name;
    console.log("target.name", target.name, "target.value", value);
    this.setState({
      [name]: value
    });
  };

  addProduct1SetValues = selectedOption => {
    let flag = true;
    $("input[class='ddlProduct']").map(function() {
      if (
        "hdproduct_title_1" !== $(this).attr("id") &&
        selectedOption.label === $(this).val()
      ) {
        // alert('Select Different Value');
        flag = false;
        return;
      }
    });
    if (flag) {
      this.setState({
        addProduct_1_Selected: selectedOption,
        addProduct_1: selectedOption.value,
        hdproduct_1: selectedOption.value,
        hdproduct_title_1: selectedOption.label
      });

      localStorage.setItem(
        "addProduct_1_Selected",
        JSON.stringify(selectedOption)
      );

      localStorage.setItem("addProduct_1", selectedOption.value);
      localStorage.setItem("hdproduct_1", selectedOption.value);
      localStorage.setItem("hdproduct_title_1", selectedOption.label);
    }
  };

  addProduct2SetValues = selectedOption => {
    let flag = true;
    $("input[class='ddlProduct']").map(function() {
      if (
        "hdproduct_title_2" !== $(this).attr("id") &&
        selectedOption.label === $(this).val()
      ) {
        // alert('Select Different Value');
        flag = false;
        return;
      }
    });
    if (flag) {
      this.setState({
        addProduct_2_Selected: selectedOption,
        addProduct_2: selectedOption.value,
        hdproduct_2: selectedOption.value,
        hdproduct_title_2: selectedOption.label
      });

      localStorage.setItem(
        "addProduct_2_Selected",
        JSON.stringify(selectedOption)
      );

      localStorage.setItem("addProduct_2", selectedOption.value);
      localStorage.setItem("hdproduct_2", selectedOption.value);
      localStorage.setItem("hdproduct_title_2", selectedOption.label);
    }
  };

  addProduct3SetValues = selectedOption => {
    let flag = true;
    $("input[class='ddlProduct']").map(function() {
      if (
        "hdproduct_title_3" !== $(this).attr("id") &&
        selectedOption.label === $(this).val()
      ) {
        // alert('Select Different Value');
        flag = false;
        return;
      }
    });
    if (flag) {
      this.setState({
        addProduct_3_Selected: selectedOption,
        addProduct_3: selectedOption.value,
        hdproduct_3: selectedOption.value,
        hdproduct_title_3: selectedOption.label
      });

      localStorage.setItem(
        "addProduct_3_Selected",
        JSON.stringify(selectedOption)
      );

      localStorage.setItem("addProduct_3", selectedOption.value);
      localStorage.setItem("hdproduct_3", selectedOption.value);
      localStorage.setItem("hdproduct_title_3", selectedOption.label);
    }
  };

  addProduct4SetValues = selectedOption => {
    let flag = true;
    $("input[class='ddlProduct']").map(function() {
      if (
        "hdproduct_title_4" !== $(this).attr("id") &&
        selectedOption.label === $(this).val()
      ) {
        // alert('Select Different Value');
        flag = false;
        return;
      }
    });
    if (flag) {
      this.setState({
        addProduct_4_Selected: selectedOption,
        addProduct_4: selectedOption.value,
        hdproduct_4: selectedOption.value,
        hdproduct_title_4: selectedOption.label
      });

      localStorage.setItem(
        "addProduct_4_Selected",
        JSON.stringify(selectedOption)
      );

      localStorage.setItem("addProduct_4", selectedOption.value);
      localStorage.setItem("hdproduct_4", selectedOption.value);
      localStorage.setItem("hdproduct_title_4", selectedOption.label);
    }
  };

  componentWillMount = () => {
    //console.log('UserCreditForm will mount');
  };

  componentDidMount = () => {
    let script = document.createElement("script");
    script.src = "assets/examples/js/forms/wizard_order.js";
    document.body.appendChild(script);

    $(".active_left_menu").removeClass("active_left_menu");
    $("#lnkorder").addClass("active_left_menu");

    document.body.appendChild(script);
    this.GetUserData();
    this.GetAllProducts();
  };

  componentWillReceiveProps = nextProps => {
    //console.log('UserCreditForm will receive props', nextProps);
  };

  componentWillUpdate = (nextProps, nextState) => {
    //console.log('UserCreditForm will update', nextProps, nextState);
  };

  componentDidUpdate = () => {
    //console.log('UserCreditForm did update');
  };

  componentWillUnmount = () => {
    //console.log('UserCreditForm will unmount');
  };

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return (
      <div>
        <div className="loadingDiv" style={{ display: "none" }} />
        <div className="page-header">
          <h1 className="page-title">CLIENT ORDER FORM</h1>
        </div>
        <input
          type="hidden"
          id="hdCalled"
          name="hdCalled"
          onChange={this.handleChange}
        />
        <div className="page-content">
          <div className="panel" id="exampleWizardForm">
            <div className="panel-body">
              <div
                className="steps steps-sm row "
                data-plugin="matchHeight"
                data-by-row="true"
                role="tablist"
              >
                <div
                  className="step col-lg-4 current"
                  data-target="#customer"
                  role="tab"
                >
                  <span className="step-number">1</span>
                  <div className="step-desc">
                    <span className="step-title">Instructions</span>
                  </div>
                </div>

                <div className="step col-lg-4" data-target="#bank" role="tab">
                  <span className="step-number">2</span>
                  <div className="step-desc">
                    <span className="step-title">Contact</span>
                  </div>
                </div>
                <div
                  className="step col-lg-4"
                  data-target="#Guarantee"
                  role="tab"
                >
                  <span className="step-number">3</span>
                  <div className="step-desc">
                    <span className="step-title">Public IP</span>
                  </div>
                </div>
              </div>

              <div className="wizard-content">
                <div
                  className="wizard-pane active"
                  id="customer"
                  role="tabpanel"
                >
                  <form id="customerform">
                    <div className="col-lg-12">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.Order_Form_Instruction
                        }}
                      />
                    </div>
                  </form>
                </div>

                <div className="wizard-pane" id="bank" role="tabpanel">
                  <h4>Contact Information</h4>
                  <form id="bankform">
                    <div className="col-lg-12">
                      <div className="mb-30">
                        <div
                          className="panel-group"
                          id="exampleWizardAccordion"
                          aria-multiselectable="true"
                          role="tablist"
                        >
                          <div className="panel">
                            <div
                              className="panel-heading"
                              id="exampleHeading1"
                              role="tab"
                            >
                              <a
                                className="panel-title"
                                data-toggle="collapse"
                                href="#exampleCollapse1"
                                data-parent="#exampleWizardAccordion"
                                aria-expanded="true"
                                aria-controls="exampleCollapse1"
                              >
                                <h5>Virtual Voice Link Services information</h5>
                              </a>
                            </div>
                            <div
                              className="panel-collapse collapse show"
                              id="exampleCollapse1"
                              aria-labelledby="exampleHeading1"
                              role="tabpanel"
                            >
                              <div className="panel-body">
                                <div className="row">
                                  <div className="form-group col-md-6">
                                    <label
                                      className="form-control-label"
                                      htmlFor="contact_company_name"
                                    >
                                      Company Name:
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="contact_company_name"
                                      onChange={this.handleChange}
                                      name="contact_company_name"
                                      value="Virtual Voice Link"
                                      readOnly
                                    />
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label
                                      className="form-control-label"
                                      htmlFor="contact_company_telephone"
                                    >
                                      Primary Company Telephone:
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="contact_company_telephone"
                                      onChange={this.handleChange}
                                      name="contact_company_telephone"
                                      value="123-456-7890"
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="form-group col-md-12">
                                    <label
                                      className="form-control-label"
                                      htmlFor="contact_accounting_address"
                                    >
                                      Accounting Address:
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="contact_accounting_address"
                                      onChange={this.handleChange}
                                      name="contact_accounting_address"
                                      value="Lancaster, TX  75146 "
                                      readOnly
                                    />
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="form-group col-md-6">
                                    <label
                                      className="form-control-label"
                                      htmlFor="contact_billing_contact"
                                    >
                                      Billing Contact:
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleChange}
                                      id="contact_billing_contact"
                                      name="contact_billing_contact"
                                      value="billing@vvl.com"
                                      readOnly
                                    />
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label
                                      className="form-control-label"
                                      htmlFor="contact_provisoning_contact"
                                    >
                                      Provisioning Contact:
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleChange}
                                      id="contact_provisoning_contact"
                                      name="contact_provisoning_contact"
                                      value="ip@vvl.com"
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <h5>
                                  <u>Testing and Turn-up Contacts</u>
                                </h5>
                                <div className="row">
                                  <div className="form-group col-md-6">
                                    <label
                                      className="form-control-label"
                                      htmlFor="contact_network_ip_activation_support"
                                    >
                                      Network IP Activation Support:
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleChange}
                                      id="contact_network_ip_activation_support"
                                      name="contact_network_ip_activation_support"
                                      value="123-456-7890"
                                      readOnly
                                    />
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label
                                      className="form-control-label"
                                      htmlFor="contact_network_tdm_activation_support"
                                    >
                                      Network TDM Activation Support:
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleChange}
                                      id="contact_network_tdm_activation_support"
                                      name="contact_network_tdm_activation_support"
                                      value="123-456-7890"
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="form-group col-md-6">
                                    <label
                                      className="form-control-label"
                                      htmlFor="contact_trouble_reporting_contact"
                                    >
                                      Trouble Reporting Contact:
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleChange}
                                      id="contact_trouble_reporting_contact"
                                      name="contact_trouble_reporting_contact"
                                      value="123-456-7890"
                                      readOnly
                                    />
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label
                                      className="form-control-label"
                                      htmlFor="contact_trouble_reporting_alternate"
                                    >
                                      Trouble Reporting Alternate:
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleChange}
                                      id="contact_trouble_reporting_alternate"
                                      name="contact_trouble_reporting_alternate"
                                      value="123-456-7890"
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="form-group col-md-6">
                                    <label
                                      className="form-control-label"
                                      htmlFor="contact_trouble_reporting_email"
                                    >
                                      Trouble Reporting Email:
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleChange}
                                      id="contact_trouble_reporting_email"
                                      name="contact_trouble_reporting_email"
                                      value="NOC@vvl.com"
                                      readOnly
                                    />
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label
                                      className="form-control-label"
                                      htmlFor="contact_email_sale_engineer"
                                    >
                                      Email:
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleChange}
                                      id="contact_email_sale_engineer"
                                      name="contact_email_sale_engineer"
                                      value="eng@vvl.com"
                                      readOnly
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="panel">
                            <div
                              className="panel-heading"
                              id="exampleHeading2"
                              role="tab"
                            >
                              <a
                                className="panel-title collapsed"
                                data-toggle="collapse"
                                href="#exampleCollapse2"
                                data-parent="#exampleWizardAccordion"
                                aria-expanded="false"
                                aria-controls="exampleCollapse2"
                              >
                                <h5>Customer information</h5>
                              </a>
                            </div>
                            <div
                              className="panel-collapse collapse"
                              id="exampleCollapse2"
                              aria-labelledby="exampleHeading2"
                              role="tabpanel"
                            >
                              <div className="panel-body">
                                <div className="row">
                                  <div className="form-group col-md-6">
                                    <label
                                      className="form-control-label"
                                      htmlFor="customer_company_name"
                                    >
                                      Company Name:
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleChange}
                                      id="customer_company_name"
                                      name="customer_company_name"
                                    />
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label
                                      className="form-control-label"
                                      htmlFor="customer_title"
                                    >
                                      Title:
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleChange}
                                      id="customer_title"
                                      name="customer_title"
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="form-group col-md-6">
                                    <label
                                      className="form-control-label"
                                      htmlFor="customer_full_name"
                                    >
                                      Full Name:
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleChange}
                                      id="customer_full_name"
                                      name="customer_full_name"
                                    />
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label
                                      className="form-control-label"
                                      htmlFor="customer_contact_number"
                                    >
                                      Contact Number:
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleChange}
                                      id="customer_contact_number"
                                      name="customer_contact_number"
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="form-group col-md-12">
                                    <label
                                      className="form-control-label"
                                      htmlFor="customer_address"
                                    >
                                      Address:
                                    </label>
                                    <textarea
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleChange}
                                      id="customer_address"
                                      name="customer_address"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <div
                  className="wizard-pane active"
                  id="Guarantee"
                  role="tabpanel"
                >
                  <h4>IP Transport Order Form</h4>
                  <form id="Guaranteeform">
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label
                          className="form-control-label"
                          htmlFor="transport_order_form_company_name"
                        >
                          Customer or Vendor Order:
                        </label>
                        <select
                          className="form-control"
                          onChange={this.handleChange}
                          name="transport_order_form_company_name"
                          id="transport_order_form_company_name"
                        >
                          <option>Choose one.</option>
                          <option>Customer</option>
                          <option>Vendor</option>
                        </select>
                      </div>
                      <div className="form-group col-md-6">
                        <label
                          className="form-control-label"
                          htmlFor="transport_order_form_carrier_type"
                        >
                          Carrier Type:
                        </label>
                        <select
                          className="form-control"
                          onChange={this.handleChange}
                          name="transport_order_form_carrier_type"
                          id="transport_order_form_carrier_type"
                        >
                          <option>Choose one.</option>
                          <option>CLEC</option>
                          <option>ILEC</option>
                          <option>IXC</option>
                          <option>RLEC</option>
                          <option>Signalling</option>
                          <option>VoIP</option>
                          <option>Wireless Carrier</option>
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label
                          className="form-control-label"
                          htmlFor="transport_order_form_requested_due_date"
                        >
                          Requested Due Date:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={this.handleChange}
                          id="transport_order_form_requested_due_date"
                          name="transport_order_form_requested_due_date"
                          value={this.state.Current_Date}
                          readOnly
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label
                          className="form-control-label"
                          htmlFor="transport_order_form_requested_contact_number"
                        >
                          Contact Phone Number:
                        </label>
                        <input
                          className="form-control"
                          onChange={this.handleChange}
                          name="transport_order_form_requested_contact_number"
                          id="transport_order_form_requested_contact_number"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label
                          className="form-control-label"
                          htmlFor="transport_order_form_requested_order_type"
                        >
                          Order Type:
                        </label>
                        <select
                          className="form-control"
                          onChange={this.handleChange}
                          name="transport_order_form_requested_order_type"
                          id="transport_order_form_requested_order_type"
                        >
                          <option>Choose one.</option>
                          <option>New</option>
                          <option>Change</option>
                          <option>Disconnect</option>
                          <option>Record Change</option>
                          <option>Augment</option>
                        </select>
                      </div>
                      <div className="form-group col-md-6">
                        <label
                          className="form-control-label"
                          htmlFor="transport_order_form_requested_contact_email"
                        >
                          Customer Contact Email:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={this.handleChange}
                          id="transport_order_form_requested_contact_email"
                          name="transport_order_form_requested_contact_email"
                        />
                      </div>
                    </div>
                    <p>
                      "Note: Provisioning orders cannot be processed without a
                      signed contract."
                    </p>

                    <div className="mb-30">
                      <div
                        className="panel-group"
                        id="exampleWizardAccordion3"
                        aria-multiselectable="true"
                        role="tablist"
                      >
                        <div className="panel">
                          <div
                            className="panel-heading"
                            id="exampleHeading23"
                            role="tab"
                          >
                            <a
                              className="panel-title"
                              data-toggle="collapse"
                              href="#exampleCollapse23"
                              data-parent="#exampleWizardAccordion3"
                              aria-expanded="true"
                              aria-controls="exampleCollapse23"
                            >
                              <h5>General information</h5>
                            </a>
                          </div>
                          <div
                            className="panel-collapse collapse show"
                            id="exampleCollapse23"
                            aria-labelledby="exampleHeading23"
                            role="tabpanel"
                          >
                            <div className="panel-body">
                              <div className="row">
                                <div className="form-group col-md-6">
                                  <label
                                    className="form-control-label"
                                    htmlFor="ip_tof_switch_type_interconnection"
                                  >
                                    Switch Type for interconnection:
                                  </label>
                                  <input
                                    className="form-control"
                                    onChange={this.handleChange}
                                    name="ip_tof_switch_type_interconnection"
                                    id="ip_tof_switch_type_interconnection"
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <label
                                    className="form-control-label"
                                    htmlFor="ip_tof_other"
                                  >
                                    Other:
                                  </label>
                                  <input
                                    className="form-control"
                                    onChange={this.handleChange}
                                    name="ip_tof_other"
                                    id="ip_tof_other"
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-md-12">
                                  <label
                                    className="form-control-label"
                                    htmlFor="ip_tof_services_ip_ranges"
                                  >
                                    Services to Apply these IP ranges:
                                  </label>
                                  <textarea
                                    className="form-control"
                                    onChange={this.handleChange}
                                    name="ip_tof_services_ip_ranges"
                                    id="ip_tof_services_ip_ranges"
                                  />
                                </div>
                              </div>

                              <div className="row">
                                <div className="form-group col-md-6">
                                  <label
                                    className="form-control-label"
                                    htmlFor="ip_tof_capacity_sessions"
                                  >
                                    Capacity (# of sessions):
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    id="ip_tof_capacity_sessions"
                                    name="ip_tof_capacity_sessions"
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <label
                                    className="form-control-label"
                                    htmlFor="ip_tof_estimated_mou"
                                  >
                                    Estimated Minutes of Use (MOU):
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    id="ip_tof_estimated_mou"
                                    name="ip_tof_estimated_mou"
                                  />
                                </div>
                              </div>

                              <div className="row">
                                <div className="form-group col-md-6">
                                  <label
                                    className="form-control-label"
                                    htmlFor="ip_tof_lnp_dips"
                                  >
                                    Will you perform LNP dips?
                                  </label>
                                  <select
                                    className="form-control"
                                    onChange={this.handleChange}
                                    name="ip_tof_lnp_dips"
                                    id="ip_tof_lnp_dips"
                                  >
                                    <option>Choose one.</option>
                                    <option>Yes</option>
                                    <option>No</option>
                                  </select>
                                </div>
                                <div className="form-group col-md-6">
                                  <label
                                    className="form-control-label"
                                    htmlFor="ip_tof_lrn_route"
                                  >
                                    Do you route by LRN?
                                  </label>
                                  <select
                                    type="text"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    id="ip_tof_lrn_route"
                                    name="ip_tof_lrn_route"
                                  >
                                    <option>Choose one.</option>
                                    <option>Yes</option>
                                    <option>No</option>
                                  </select>
                                </div>
                              </div>

                              <div className="row">
                                <div className="form-group col-md-6">
                                  <label
                                    className="form-control-label"
                                    htmlFor="ip_tof_max_ptime"
                                  >
                                    Max Ptime:
                                  </label>
                                  <select
                                    className="form-control"
                                    onChange={this.handleChange}
                                    name="ip_tof_max_ptime"
                                    id="ip_tof_max_ptime"
                                  >
                                    <option>Choose one.</option>
                                    <option>20ms</option>
                                    <option>40ms</option>
                                    <option>any</option>
                                  </select>
                                </div>
                                <div className="form-group col-md-6">
                                  <label
                                    className="form-control-label"
                                    htmlFor="ip_tof_fax_preference"
                                  >
                                    Fax preference:
                                  </label>
                                  <select
                                    className="form-control"
                                    onChange={this.handleChange}
                                    name="ip_tof_fax_preference"
                                    id="ip_tof_fax_preference"
                                  >
                                    <option>Choose one.</option>
                                    <option>Inband</option>
                                    <option>T.38</option>
                                  </select>
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-md-6">
                                  <label
                                    className="form-control-label"
                                    htmlFor="ip_tof_num_plan"
                                  >
                                    Numbering Plan:
                                  </label>
                                  <select
                                    className="form-control"
                                    onChange={this.handleChange}
                                    name="ip_tof_num_plan"
                                    id="ip_tof_num_plan"
                                  >
                                    <option>Choose one.</option>
                                    <option>E.164</option>
                                    <option>10-digit National Number</option>
                                    <option>
                                      Tech-prefix before numbering
                                    </option>
                                  </select>
                                </div>
                                <div className="form-group col-md-6">
                                  <label
                                    className="form-control-label"
                                    htmlFor="ip_tof_sil_sup_required"
                                  >
                                    Silence suppression required?:
                                  </label>
                                  <select
                                    className="form-control"
                                    onChange={this.handleChange}
                                    name="ip_tof_sil_sup_required"
                                    id="ip_tof_sil_sup_required"
                                  >
                                    <option>Choose one.</option>
                                    <option>Yes</option>
                                    <option>No</option>
                                  </select>
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-md-6">
                                  <label
                                    className="form-control-label"
                                    htmlFor="ip_tof_ip_route_protocol"
                                  >
                                    IP Routing Protocol:
                                  </label>
                                  <select
                                    className="form-control"
                                    onChange={this.handleChange}
                                    name="ip_tof_ip_route_protocol"
                                    id="ip_tof_ip_route_protocol"
                                  >
                                    <option>Choose one.</option>
                                    <option>Static</option>
                                    <option>BGP</option>
                                  </select>
                                </div>
                                <div className="form-group col-md-6">
                                  <label
                                    className="form-control-label"
                                    htmlFor="ip_tof_bgp_ip_ranges"
                                  >
                                    If BGP, what IP ranges/subnet to be
                                    advertised (as number).
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    id="ip_tof_bgp_ip_ranges"
                                    name="ip_tof_bgp_ip_ranges"
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-md-6">
                                  <label
                                    className="form-control-label"
                                    htmlFor="ip_tof_sip_rtp_ranges"
                                  >
                                    SIP and RTP Port Ranges:
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    id="ip_tof_sip_rtp_ranges"
                                    name="ip_tof_sip_rtp_ranges"
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <label
                                    className="form-control-label"
                                    htmlFor="ip_tof_ven_cus_require"
                                  >
                                    Does Vendor/Customer require PRACK:
                                  </label>
                                  <select
                                    className="form-control"
                                    onChange={this.handleChange}
                                    name="ip_tof_ven_cus_require"
                                    id="ip_tof_ven_cus_require"
                                  >
                                    <option>Choose one.</option>
                                    <option>Yes</option>
                                    <option>No</option>
                                  </select>
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-md-6">
                                  <label
                                    className="form-control-label"
                                    htmlFor="ip_tof_reject_code"
                                  >
                                    Ten-digit NATNUM reject code:
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    id="ip_tof_reject_code"
                                    name="ip_tof_reject_code"
                                    value="502/31"
                                    readOnly
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <label
                                    className="form-control-label"
                                    htmlFor="ip_tof_cdr_rep_interval"
                                  >
                                    CDR Reporting Interval:
                                  </label>
                                  <select
                                    className="form-control"
                                    onChange={this.handleChange}
                                    name="ip_tof_cdr_rep_interval"
                                    id="ip_tof_cdr_rep_interval"
                                  >
                                    <option>Choose one.</option>
                                    <option>Daily</option>
                                    <option>Weekly</option>
                                    <option>Monthly</option>
                                  </select>
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-md-6">
                                  <label
                                    className="form-control-label"
                                    htmlFor="ip_tof_call_rejects"
                                  >
                                    Call rejects (no route available) reject
                                    code:
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    id="ip_tof_call_rejects"
                                    name="ip_tof_call_rejects"
                                    value="503/34"
                                    readOnly
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <label
                                    className="form-control-label"
                                    htmlFor="ip_tof_dtmf_handling"
                                  >
                                    DTMF Handling:
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    id="ip_tof_dtmf_handling"
                                    name="ip_tof_dtmf_handling"
                                    value="RFC2833"
                                    readOnly
                                  />
                                </div>
                              </div>
                              <p className="text-muted">
                                If the protocol is SIP, Faxes must be G.711
                                Pass-Through.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="panel">
                          <div
                            className="panel-heading"
                            id="exampleHeading24"
                            role="tab"
                          >
                            <a
                              className="panel-title collapsed"
                              data-toggle="collapse"
                              href="#exampleCollapse24"
                              data-parent="#exampleWizardAccordion3"
                              aria-expanded="false"
                              aria-controls="exampleCollapse24"
                            >
                              <h5>IP Addresses</h5>
                            </a>
                          </div>
                          <div
                            className="panel-collapse collapse"
                            id="exampleCollapse24"
                            aria-labelledby="exampleHeading24"
                            role="tabpanel"
                          >
                            <div className="panel-body">
                              <div className="row">
                                <div className="form-group col-md-6">
                                  <label
                                    className="form-control-label"
                                    htmlFor="netblock_ip_address"
                                  >
                                    NetBlock IP Address:
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    id="netblock_ip_address"
                                    name="netblock_ip_address"
                                  />
                                  <p>(nnn.nnn.nnn.nnn/nn)</p>
                                </div>
                              </div>

                              <div id="div_1">
                                <div className="row">
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="customer_ip_address1"
                                    >
                                      Customer IP Address(es)
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleChange}
                                      id="customer_ip_address1"
                                      name="customer_ip_address1"
                                    />
                                  </div>
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="requested_ports_ses_count1"
                                    >
                                      Requested Ports/Session Count
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleChange}
                                      id="requested_ports_ses_count1"
                                      name="requested_ports_ses_count1"
                                    />
                                  </div>
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="requested_call_per_sec1"
                                    >
                                      Requested Calls per Second
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleChange}
                                      id="requested_call_per_sec1"
                                      name="requested_call_per_sec1"
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="default_port_number1"
                                    >
                                      Default port number
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleChange}
                                      id="default_port_number1"
                                      name="default_port_number1"
                                      value="5060 (Term)"
                                      readOnly
                                    />
                                  </div>
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="preferred_stat_routing1"
                                    >
                                      Preferred strategy for routing
                                    </label>
                                    <select
                                      className="form-control"
                                      name="preferred_stat_routing1"
                                      onChange={this.handleChange}
                                      id="preferred_stat_routing1"
                                    >
                                      <option>Choose one.</option>
                                      <option>Primary-Secondary</option>
                                      <option>Round Robin</option>
                                    </select>
                                  </div>
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="rtp_paketization_time1"
                                    >
                                      RTP Packetization Time
                                    </label>
                                    <select
                                      className="form-control"
                                      onChange={this.handleChange}
                                      name="rtp_paketization_time1"
                                      id="rtp_paketization_time1"
                                    >
                                      <option>Choose one.</option>
                                      <option>10ms</option>
                                      <option>20ms</option>
                                      <option>30ms</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="description1"
                                    >
                                      Description
                                    </label>
                                    <select
                                      className="form-control"
                                      onChange={this.handleChange}
                                      name="description1"
                                      id="description1"
                                    >
                                      <option>Choose one.</option>
                                      <option>SIP (/32)</option>
                                      <option>SIP/RTP (/32)</option>
                                      <option>RTP (/32)</option>
                                      <option>
                                        Tunnel Source/Destination/32
                                      </option>
                                    </select>
                                  </div>
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="product1"
                                    >
                                      Product
                                    </label>

                                    <Select
                                      required
                                      aria-required="true"
                                      id="product1"
                                      onChange={this.addProduct1SetValues}
                                      placeholder=""
                                      options={this.state.Products}
                                      value={this.state.addProduct_1_Selected}
                                    />
                                    <input
                                      type="hidden"
                                      id="hdproduct_1"
                                      name="hdproduct_1"
                                      value={this.state.hdproduct_1}
                                      onChange={this.handleChange}
                                    />
                                    <input
                                      type="hidden"
                                      id="hdproduct_title_1"
                                      name="hdproduct_title_1"
                                      value={this.state.hdproduct_title_1}
                                      onChange={this.handleChange}
                                      className="ddlProduct"
                                    />
                                  </div>
                                </div>

                                <hr />
                                <div
                                  id="link_first"
                                  style={{
                                    color: "#214c7f",
                                    cursor: "pointer"
                                  }}
                                  onClick={this.hideIPShowDIv1}
                                >
                                  Add more Customer IP
                                </div>
                              </div>

                              <div id="div_first" style={{ display: "none" }}>
                                <h5>IP Address 2</h5>
                                <div className="row">
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="customer_ip_address2"
                                    >
                                      Customer IP Address(es)
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleChange}
                                      id="customer_ip_address2"
                                      name="customer_ip_address2"
                                    />
                                  </div>
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="requested_ports_ses_count2"
                                    >
                                      Requested Ports/Session Count
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleChange}
                                      id="requested_ports_ses_count2"
                                      name="requested_ports_ses_count2"
                                    />
                                  </div>
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="requested_call_per_sec2"
                                    >
                                      Requested Calls per Second
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleChange}
                                      id="requested_call_per_sec2"
                                      name="requested_call_per_sec2"
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="default_port_number2"
                                    >
                                      Default port number
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleChange}
                                      id="default_port_number2"
                                      name="default_port_number2"
                                    />
                                  </div>
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="preferred_stat_routing2"
                                    >
                                      Preferred strategy for routing
                                    </label>
                                    <select
                                      className="form-control"
                                      name="preferred_stat_routing2"
                                      onChange={this.handleChange}
                                      id="preferred_stat_routing2"
                                    >
                                      <option>Choose one.</option>
                                      <option>Primary-Secondary</option>
                                      <option>Round Robin</option>
                                    </select>
                                  </div>
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="rtp_paketization_time2"
                                    >
                                      RTP Packetization Time
                                    </label>
                                    <select
                                      className="form-control"
                                      name="rtp_paketization_time2"
                                      onChange={this.handleChange}
                                      id="rtp_paketization_time2"
                                    >
                                      <option>Choose one.</option>
                                      <option>10ms</option>
                                      <option>20ms</option>
                                      <option>30ms</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="description2"
                                    >
                                      Description
                                    </label>
                                    <select
                                      className="form-control"
                                      name="description2"
                                      onChange={this.handleChange}
                                      id="description2"
                                    >
                                      <option>Choose one.</option>
                                      <option>SIP (/32)</option>
                                      <option>SIP/RTP (/32)</option>
                                      <option>RTP (/32)</option>
                                      <option>
                                        Tunnel Source/Destination/32
                                      </option>
                                    </select>
                                  </div>
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="product2"
                                    >
                                      Product
                                    </label>
                                    <Select
                                      required
                                      aria-required="true"
                                      id="product2"
                                      onChange={this.addProduct2SetValues}
                                      placeholder=""
                                      options={this.state.Products}
                                      value={this.state.addProduct_2_Selected}
                                    />
                                    <input
                                      type="hidden"
                                      id="hdproduct_2"
                                      name="hdproduct_2"
                                      value={this.state.hdproduct_2}
                                      onChange={this.handleChange}
                                    />
                                    <input
                                      type="hidden"
                                      id="hdproduct_title_2"
                                      name="hdproduct_title_2"
                                      value={this.state.hdproduct_title_2}
                                      onChange={this.handleChange}
                                      className="ddlProduct"
                                    />
                                  </div>
                                </div>

                                <hr />
                                <div
                                  id="link_sec"
                                  style={{
                                    color: "#214c7f",
                                    cursor: "pointer"
                                  }}
                                  onClick={this.hideIPShowDIv2}
                                >
                                  Add more Customer IP
                                </div>
                              </div>

                              <div id="div_sec" style={{ display: "none" }}>
                                <h5>IP Address 3</h5>
                                <div className="row">
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="customer_ip_address3"
                                    >
                                      Customer IP Address(es)
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleChange}
                                      id="customer_ip_address3"
                                      name="customer_ip_address3"
                                    />
                                  </div>
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="requested_ports_ses_count3"
                                    >
                                      Requested Ports/Session Count
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleChange}
                                      id="requested_ports_ses_count3"
                                      name="requested_ports_ses_count3"
                                    />
                                  </div>
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="requested_call_per_sec3"
                                    >
                                      Requested Calls per Second
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleChange}
                                      id="requested_call_per_sec3"
                                      name="requested_call_per_sec3"
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="default_port_number3"
                                    >
                                      Default port number
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleChange}
                                      id="default_port_number3"
                                      name="default_port_number3"
                                    />
                                  </div>
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="preferred_stat_routing3"
                                    >
                                      Preferred strategy for routing
                                    </label>
                                    <select
                                      className="form-control"
                                      name="preferred_stat_routing3"
                                      onChange={this.handleChange}
                                      id="preferred_stat_routing3"
                                    >
                                      <option>Choose one.</option>
                                      <option>Primary-Secondary</option>
                                      <option>Round Robin</option>
                                    </select>
                                  </div>
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="rtp_paketization_time3"
                                    >
                                      RTP Packetization Time
                                    </label>
                                    <select
                                      className="form-control"
                                      onChange={this.handleChange}
                                      name="rtp_paketization_time3"
                                      id="rtp_paketization_time3"
                                    >
                                      <option>Choose one.</option>
                                      <option>10ms</option>
                                      <option>20ms</option>
                                      <option>30ms</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="description3"
                                    >
                                      Description
                                    </label>
                                    <select
                                      className="form-control"
                                      onChange={this.handleChange}
                                      name="description3"
                                      id="description3"
                                    >
                                      <option>Choose one.</option>
                                      <option>SIP (/32)</option>
                                      <option>SIP/RTP (/32)</option>
                                      <option>RTP (/32)</option>
                                      <option>
                                        Tunnel Source/Destination/32
                                      </option>
                                    </select>
                                  </div>
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="product3"
                                    >
                                      Product
                                    </label>
                                    <Select
                                      required
                                      aria-required="true"
                                      id="product3"
                                      onChange={this.addProduct3SetValues}
                                      placeholder=""
                                      options={this.state.Products}
                                      value={this.state.addProduct_3_Selected}
                                    />
                                    <input
                                      type="hidden"
                                      id="hdproduct_3"
                                      name="hdproduct_3"
                                      value={this.state.hdproduct_3}
                                      onChange={this.handleChange}
                                    />
                                    <input
                                      type="hidden"
                                      id="hdproduct_title_3"
                                      name="hdproduct_title_3"
                                      value={this.state.hdproduct_title_3}
                                      onChange={this.handleChange}
                                      className="ddlProduct"
                                    />
                                  </div>
                                </div>

                                <hr />
                                <div
                                  id="link_third"
                                  style={{
                                    color: "#214c7f",
                                    cursor: "pointer"
                                  }}
                                  onClick={this.hideIPShowDIv3}
                                >
                                  Add more Customer IP
                                </div>
                              </div>

                              <div id="div_third" style={{ display: "none" }}>
                                <h5>IP Address 4</h5>
                                <div className="row">
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="customer_ip_address4"
                                    >
                                      Customer IP Address(es)
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleChange}
                                      id="customer_ip_address4"
                                      name="customer_ip_address4"
                                    />
                                  </div>
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="requested_ports_ses_count4"
                                    >
                                      Requested Ports/Session Count
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleChange}
                                      id="requested_ports_ses_count4"
                                      name="requested_ports_ses_count4"
                                    />
                                  </div>
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="requested_call_per_sec4"
                                    >
                                      Requested Calls per Second
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleChange}
                                      id="requested_call_per_sec4"
                                      name="requested_call_per_sec4"
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="default_port_number4"
                                    >
                                      Default port number
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleChange}
                                      id="default_port_number4"
                                      name="default_port_number4"
                                    />
                                  </div>
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="preferred_stat_routing4"
                                    >
                                      Preferred strategy for routing
                                    </label>
                                    <select
                                      className="form-control"
                                      name="preferred_stat_routing4"
                                      onChange={this.handleChange}
                                      id="preferred_stat_routing4"
                                    >
                                      <option>Choose one.</option>
                                      <option>Primary-Secondary</option>
                                      <option>Round Robin</option>
                                    </select>
                                  </div>
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="rtp_paketization_time4"
                                    >
                                      RTP Packetization Time
                                    </label>
                                    <select
                                      className="form-control"
                                      onChange={this.handleChange}
                                      name="rtp_paketization_time4"
                                      id="rtp_paketization_time4"
                                    >
                                      <option>Choose one.</option>
                                      <option>10ms</option>
                                      <option>20ms</option>
                                      <option>30ms</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="description4"
                                    >
                                      Description
                                    </label>
                                    <select
                                      className="form-control"
                                      onChange={this.handleChange}
                                      name="description4"
                                      id="description4"
                                    >
                                      <option>Choose one.</option>
                                      <option>SIP (/32)</option>
                                      <option>SIP/RTP (/32)</option>
                                      <option>RTP (/32)</option>
                                      <option>
                                        Tunnel Source/Destination/32
                                      </option>
                                    </select>
                                  </div>
                                  <div className="form-group col-md-4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="product4"
                                    >
                                      Product
                                    </label>
                                    <Select
                                      required
                                      aria-required="true"
                                      id="product4"
                                      onChange={this.addProduct4SetValues}
                                      placeholder=""
                                      options={this.state.Products}
                                      value={this.state.addProduct_4_Selected}
                                    />
                                    <input
                                      type="hidden"
                                      id="hdproduct_4"
                                      name="hdproduct_4"
                                      value={this.state.hdproduct_4}
                                      onChange={this.handleChange}
                                    />
                                    <input
                                      type="hidden"
                                      id="hdproduct_title_4"
                                      name="hdproduct_title_4"
                                      value={this.state.hdproduct_title_4}
                                      onChange={this.handleChange}
                                      className="ddlProduct"
                                    />
                                  </div>
                                </div>

                                <hr />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <input
                      type="button"
                      id="btnSuccess"
                      name="btnSuccess"
                      onClick={() => {
                        notie.alert({
                          type: "info",
                          text: $("#hdCalled").val(),
                          time: 3,
                          position: "top" // optional, default = 'top', enum: ['top', 'bottom']
                        });
                        this.props.history.push(`/client_dashboard`);
                      }}
                      style={{ visibility: "hidden" }}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserOrderForm;
