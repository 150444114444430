import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
/** Layouts **/
import AdminLoginLayoutRoute from './components/Layouts/AdminLoginLayout';
import AdminMainLayoutRoute from './components/Layouts/AdminMainLayout';

import AgentLoginLayoutRoute from './components/Layouts/AgentLoginLayout';
import AgentMainLayoutRoute from './components/Layouts/AgentMainLayout';

import UserLoginLayoutRoute from './components/Layouts/UserLoginLayout';
import UserMainLayoutRoute from './components/Layouts/UserMainLayout';

import EmailLayoutRoute from './components/Layouts/EmailLayout';

/** ADMIN Components **/
import AdminDashboard from './pages/AdminDashboard';
import AdminLogin from './pages/AdminLogin';

import { AuthProvider } from './context/AuthContext';
import { AuthProviderAgent } from './context/AuthContextAgent';
import { AuthProviderEmail } from './context/AuthContextEmail';

import ManageUsers from './pages/ManageUsers';
import ManageAgreements from './pages/ManageAgreements';
import ManageProducts from './pages/ManageProducts';
import ManageVendorSignor from './pages/ManageVendorSignor';
import ManageVendorContact from './pages/ManageVendorContact';
import ManageAgent from './pages/ManageAgents';
import AdminUserAgreements from './pages/AdminUserAgreements';
import AdminUserAgreementArchive from './pages/AdminUserAgreementArchive';
import AdminUserCreditForms from './pages/AdminUserCreditForms';
import AdminUserOrderForms from './pages/AdminUserOrderForms';
import ManageSettings from './pages/ManageSettings';
import ManageCannedEmail from './pages/ManageCannedEmail';
import ManageCMS from './pages/ManageCMS';

/** AGENT Components **/
import AgentDashboard from './pages/agent_module/AgentDashboard';
import AgentLogin from './pages/agent_module/AgentLogin';
import CompleteAgentSignup from './pages/agent_module/CompleteAgentSignup';
import AgentResetPassword from './pages/agent_module/AgentResetPassword';
import AgentManageUsers from './pages/agent_module/AgentManageUsers';
import AgentUserAgreements from './pages/agent_module/AgentUserAgreements';
import AgentUserCreditForms from './pages/agent_module/AgentUserCreditForms';
import AgentUserOrderForms from './pages/agent_module/AgentUserOrderForms';
import AgentUserAgreementArchive from './pages/agent_module/AgentUserAgreementArchive';

/** USER Components **/
import UserLogin from './pages/UserLogin';
import UserDashboard from './pages/UserDashboard';
import UserAgreements from './pages/UserAgreements';
import UserProducts from './pages/UserProducts';
import UserCreditForm from './pages/UserCreditForm';
import UserOrderForm from './pages/UserOrderForm';
import VerifyUser from './pages/VerifyUser';
import CompleteUserSignup from './pages/CompleteUserSignup';
import ResetPassword from './pages/ResetPassword';
import SigneeSignature from './pages/SigneeSignature';
import Page404 from './pages/Page404';

import AdminApproveByEmail from './pages/AdminApproveByEmail';
import AdminDeclineByEmail from './pages/AdminDeclineByEmail';

import SignaturePad from './pages/Test/SignaturePad';

class App extends Component {
  render() {
    return (
      <Router>
        <AuthProvider>
          <Switch>
            <Route path='/SignaturePad' component={SignaturePad} />
            <UserLoginLayoutRoute exact path='/' component={UserLogin} />
            <UserLoginLayoutRoute
              exact
              path='/client_login'
              component={UserLogin}
            />
            <AdminLoginLayoutRoute
              exact
              path='/tcaccess'
              component={AdminLogin}
            />
            <AdminMainLayoutRoute
              exact
              path='/dashboard'
              component={AdminDashboard}
            />
            <AdminMainLayoutRoute
              exact
              path='/clients'
              component={ManageUsers}
            />
            <AdminMainLayoutRoute
              exact
              path='/agreements'
              component={ManageAgreements}
            />
            <AdminMainLayoutRoute
              exact
              path='/products'
              component={ManageProducts}
            />
            <AdminMainLayoutRoute
              exact
              path='/admin_client_agreements'
              component={AdminUserAgreements}
            />
            <AdminMainLayoutRoute
              exact
              path='/admin_client_agreement_archive'
              component={AdminUserAgreementArchive}
            />
            <AdminMainLayoutRoute
              exact
              path='/admin_client_order_form'
              component={AdminUserOrderForms}
            />
            <AdminMainLayoutRoute
              exact
              path='/admin_client_credit_form'
              component={AdminUserCreditForms}
            />
            <AdminMainLayoutRoute
              exact
              path='/admin_vendor_signor'
              component={ManageVendorSignor}
            />
            <AdminMainLayoutRoute
              exact
              path='/admin_vendor_contact'
              component={ManageVendorContact}
            />
            <AdminMainLayoutRoute
              exact
              path='/agents'
              component={ManageAgent}
            />
            <AdminMainLayoutRoute
              exact
              path='/admin_settings'
              component={ManageSettings}
            />
            <AdminMainLayoutRoute
              exact
              path='/admin_canned_email'
              component={ManageCannedEmail}
            />
            <AdminMainLayoutRoute
              exact
              path='/admin_cms'
              component={ManageCMS}
            />

            <UserLoginLayoutRoute
              exact
              path='/complete_signup_process'
              component={CompleteUserSignup}
            />
            <UserLoginLayoutRoute
              exact
              path='/verify_account'
              component={VerifyUser}
            />
            <UserLoginLayoutRoute
              exact
              path='/reset_password'
              component={ResetPassword}
            />

            <UserMainLayoutRoute
              exact
              path='/client_dashboard'
              component={UserDashboard}
            />
            <UserMainLayoutRoute
              exact
              path='/client_agreements'
              component={UserAgreements}
            />
            <UserMainLayoutRoute
              exact
              path='/client_services'
              component={UserProducts}
            />
            <UserMainLayoutRoute
              exact
              path='/client_credit'
              component={UserCreditForm}
            />
            <UserMainLayoutRoute
              exact
              path='/client_order'
              component={UserOrderForm}
            />

            <Route exact path='/signee_agreement' component={SigneeSignature} />

            <AuthProviderEmail>
              <Switch>
                <EmailLayoutRoute
                  exact
                  path='/admin_approve_agreement'
                  component={AdminApproveByEmail}
                />
                <EmailLayoutRoute
                  exact
                  path='/admin_decline_agreement'
                  component={AdminDeclineByEmail}
                />
                <AuthProviderAgent>
                  <Switch>
                    <AgentLoginLayoutRoute
                      exact
                      path='/tcagent'
                      component={AgentLogin}
                    />
                    <AgentLoginLayoutRoute
                      exact
                      path='/agent_complete_signup_process'
                      component={CompleteAgentSignup}
                    />
                    <AgentLoginLayoutRoute
                      exact
                      path='/agent_reset_password'
                      component={AgentResetPassword}
                    />
                    <AgentMainLayoutRoute
                      exact
                      path='/agent_dashboard'
                      component={AgentDashboard}
                    />
                    <AgentMainLayoutRoute
                      exact
                      path='/agent_clients'
                      component={AgentManageUsers}
                    />
                    <AgentMainLayoutRoute
                      exact
                      path='/agent_client_agreements'
                      component={AgentUserAgreements}
                    />
                    <AgentMainLayoutRoute
                      exact
                      path='/agent_client_credit_form'
                      component={AgentUserCreditForms}
                    />
                    <AgentMainLayoutRoute
                      exact
                      path='/agent_client_order_form'
                      component={AgentUserOrderForms}
                    />
                    <AgentMainLayoutRoute
                      exact
                      path='/agent_client_agreement_archive'
                      component={AgentUserAgreementArchive}
                    />

                    <Route path='*' component={Page404} />
                  </Switch>
                </AuthProviderAgent>
              </Switch>
            </AuthProviderEmail>
          </Switch>
        </AuthProvider>
      </Router>
    );
  }
}

export default App;
