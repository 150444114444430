import React, { Component } from 'react';
import notie from 'notie';
import $ from 'jquery';
import axios from 'axios';

class ProductFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      loading: true,
      Select_Product_Files: [],
      Product_ID: props.Product_ID
    };
    this.getProductFiles = this.getProductFiles.bind(this);
  }

  getProductFiles(_Product_ID) {
    try {
      this.setState({
        Product_ID: _Product_ID
      });
      const api = `${process.env.REACT_APP_BASE_URL}products/api/getProductFilesUser?product_id=${_Product_ID}`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_user_token')}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/client_login');
          }
          if (res.data.success === false) {
             this.setState({
               loading: false
             });
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          this.setState({
            Select_Product_Files: res.data,
            loading: false
          });
        });
    } catch (error) {}
  }
  componentWillMount() {
    this.getProductFiles(this.state.Product_ID);
  }

  render() {
    const { loading } = this.state;
    return (
      <React.Fragment>
        {loading ? (
          <div style={{ textAlign: 'center' }}>
            <img
              src='../assets/images/tcc_loading.gif'
              alt='...'
              style={{ width: '40px' }}
            />
          </div>
        ) : (
          <React.Fragment>
            {this.state.Select_Product_Files === [] ? (
              ''
            ) : (
              <React.Fragment>
                {this.state.Select_Product_Files.map((data, index) => (
                  <a
                    key={data.product_file_id}
                    className='fa fa-file'
                    style={{ textDecoration: 'none' }}
                    target='_blank'
                    title={data.product_file_name}
                    href={
                      process.env.REACT_APP_BASE_URL + data.product_file_path
                    }
                  />
                ))}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default ProductFiles;
