import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import axios from "axios";
import $ from "jquery";
import notie from "notie";
import { replaceAgreementContent } from "../../helper/util";
import CKEditor from "ckeditor4-react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const { SearchBar } = Search;
const CKEconfig = {
  doctype: "html",
  fullPage: true,
  allowedContent: true,
  toolbarGroups: [
    { name: "document", groups: ["mode", "document", "doctools"] },
    { name: "clipboard", groups: ["clipboard", "undo"] },
    {
      name: "editing",
      groups: ["find", "selection", "spellchecker", "editing"]
    },
    { name: "forms", groups: ["forms"] },
    { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
    {
      name: "paragraph",
      groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"]
    },
    { name: "links", groups: ["links"] },
    { name: "insert", groups: ["insert"] },
    { name: "styles", groups: ["styles"] },
    { name: "colors", groups: ["colors"] },
    { name: "tools", groups: ["tools"] },
    { name: "others", groups: ["others"] },
    { name: "about", groups: ["about"] },
    { name: "font", groups: ["font"] },
    "/",
    "/"
  ]
  //,

  // removeButtons:
  //   'Find,Replace,Scayt,SelectAll,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,Blockquote,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,BidiLtr,BidiRtl,Language,Anchor,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,FontSize,Font,TextColor,BGColor,About,Styles'
};
class AdminUserAgreements extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      Disable_Button: "",
      User_ID: this.props.location.state.User_ID,
      User_Agreement_ID: "",
      User_FullName: this.props.location.state.User_FullName,
      Comp_Name: this.props.location.state.Comp_Name,
      addAgreementID: "",
      addAgreementTitle: "",
      addAgreementContent: "",
      editUserAgreementID: "",
      editSigneeName: "",
      editSigneeTitle: "",
      editSignatureDate: "",
      previewAgreement: "",
      agreements: [],
      UnAssigned_Agreement: [],
      agreementsVendorPush: [],
      loading: true,
      columns: [
        {
          dataField: "serial_number",
          text: "Sr #",
          sort: true,
          headerAttrs: { width: 50 }
        },
        {
          dataField: "agreement_title",
          text: "Agreement Title",
          sort: true,
          headerAttrs: { width: 300 }
        },
        {
          dataField: "signee_name",
          text: "Signee Name",
          sort: true,
          headerAttrs: { width: 120 }
        },
        {
          dataField: "signee_title",
          text: "Signee Title",
          sort: true,
          headerAttrs: { width: 100 }
        },
        {
          dataField: "agreement_status",
          text: "Status",
          sort: true,
          headerAttrs: { width: 60 }
        },
        {
          dataField: "signature_date",
          text: "Signature Date",
          sort: true,
          headerAttrs: { width: 110 }
        },

        {
          dataField: "agreement_PDF",
          text: "View",
          sort: false,
          formatter: this.signagreementPreviewFormatter,
          headerAttrs: { width: 35, style: { textAlign: "center" } }
        },

        {
          dataField: "ispushtovendor",
          text: "Send to Vendor",
          sort: true,
          headerAttrs: { width: 100, style: { textAlign: "center" } },
          attrs: { width: 75, style: { textAlign: "center" } }
        },
        {
          dataField: "signed",
          text: "Upload Agreement",
          sort: false,
          formatter: this.uploadSignedAgreementPreviewFormatter,
          headerAttrs: { width: 100, style: { textAlign: "center" } }
        },
        {
          dataField: "approve",
          text: "Approve",
          sort: false,
          formatter: this.agreementApproveFormatter,
          headerAttrs: { width: 35 }
        },
        {
          dataField: "decline",
          text: "Reject",
          sort: false,
          formatter: this.agreementDeclineFormatter,
          headerAttrs: { width: 35 }
        },
        {
          dataField: "delete",
          text: "Delete",
          sort: false,
          formatter: this.agreementDeleteFormatter,
          headerAttrs: { width: 75, style: { textAlign: "center" } },
          attrs: { width: 75, style: { textAlign: "center" } }
        }
      ],
      columnsVendorPush: [
        {
          dataField: "serial_number",
          text: "Sr #",
          sort: true
        },
        {
          dataField: "agreement_title",
          text: "Agreement Title",
          sort: true
        },
        {
          dataField: "signee_name",
          text: "Signee Name",
          sort: true
        },
        {
          dataField: "signee_title",
          text: "Signee Title",
          sort: true
        },
        {
          dataField: "signature_date",
          text: "Signature Date",
          sort: true
        }
      ]
    };

    this.handleChange = this.handleChange.bind(this);
    this.agreementPreviewFormatter = this.agreementPreviewFormatter.bind(this);
    this.signagreementPreviewFormatter = this.signagreementPreviewFormatter.bind(
      this
    );

    this.viewAgreementData = this.viewAgreementData.bind(this);

    this.viewAgreementData = this.viewAgreementData.bind(this);
    this.GetAdminUserAgreements = this.GetAdminUserAgreements.bind(this);
    this.GetUnAssignedAgreements = this.GetUnAssignedAgreements.bind(this);
    this.onChangeAdd = this.onChangeAdd.bind(this);
    this.addAgreementSetValues = this.addAgreementSetValues.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleEditAgreement = this.handleEditAgreement.bind(this);

    this.GetVendorPushAgreements = this.GetVendorPushAgreements.bind(this);
    this.handlePushVendor = this.handlePushVendor.bind(this);
    this.onRowSelect = this.onRowSelect.bind(this);
    this.onSelectAll = this.onSelectAll.bind(this);

    this.handleSignatureDate = this.handleSignatureDate.bind(this);
  }

  GetAdminUserAgreements() {
    const api = `${process.env.REACT_APP_BASE_URL}user_agreements/api/Admin?user_id=${this.state.User_ID}`;
    this.setState({ loading: true });
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = "/tcaccess");
        }
        if (res.data.success === false) {
          this.setState({ loading: false });
          return notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        this.setState({
          agreements: res.data,
          loading: false
        });
      });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSignatureDate(date) {
    console.log("date", date);
    this.setState({
      editSignatureDate: date
    });
  }

  onChangeeditUploadAgreement = event => {
    const types = ["application/pdf"];
    var files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      // get item
      let file = files[i];
      let err = "";
      if (types.every(type => file.type !== type)) {
        // create error message and assign to container
        err += file.type + " is Not a Supported Format.";

        notie.alert({
          type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: err,
          stay: false, // optional, default = false
          time: 10, // optional, default = 3, minimum = 1,
          position: "top" // optional, default = 'top', enum: ['top', 'bottom']
        });
        return false;
      }
    }
    this.setState({
      editSignedAgreementFile: event.target.files[0]
    });

    //console.log('this.state.editSettingSmallLogo', event.target.files[0]);
  };

  agreementPreviewFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal"
        }}
      >
        {row.issigned === "No" && row.issigneesigned === "No" ? (
          <span
            className="fa fa-edit"
            onClick={() => this.viewAgreementData(row.user_agreement_id)}
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  signagreementPreviewFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal"
        }}
      >
        {row.issigned === "Yes" || row.issigneesigned === "Yes" ? (
          <a
            className="fa fa-eye"
            target="_blank"
            href={process.env.REACT_APP_BASE_URL + row.agreement_PDF}
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  uploadSignedAgreementPreviewFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal"
        }}
      >
        <span
          className="fa fa-pen"
          onClick={() => {
            this.setState({
              editUserAgreementID: row.user_agreement_id,
              editSigneeName: row.signee_name,
              editSigneeTitle: row.signee_title
            });
            $("#upload_signature").show();
          }}
        />
      </div>
    );
  };

  viewAgreementData(_User_Agreement_ID) {
    try {
      const api = `${process.env.REACT_APP_BASE_URL}user_agreements/api/getAgreement?user_agreement_id=${_User_Agreement_ID}&user_id=${this.state.User_ID}`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = "/tcaccess");
          }
          if (res.data.success === false) {
            return notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          this.setState({
            previewAgreement: ""
          });
          var objUser = {
            name: res.data[0].fullname,
            comp_name: res.data[0].comp_name,
            address: res.data[0].address,
            title: res.data[0].title
          };
          this.setState({
            previewAgreement: replaceAgreementContent(
              res.data[0].agreement_content,
              objUser
            ),
            User_Agreement_ID: res.data[0].user_agreement_id,
            User_FullName: res.data[0].fullname
          });

          $("#preview_agreement").show();
        });
    } catch (error) {}
  }

  GetUnAssignedAgreements() {
    this.setState({
      UnAssigned_Agreement: [],
      loading: true
    });
    const api = `${process.env.REACT_APP_BASE_URL}agreements/api/getUnAssignedAgreements/?user_id=${this.state.User_ID}`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = "/tcaccess");
        }
        if (res.data.success === false) {
          this.setState({ loading: false });
          return notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        //console.log(res.data);
        this.setState({
          UnAssigned_Agreement: res.data,
          loading: false
        });
      });
  }

  addAgreementSetValues = selectedOption => {
    this.setState({ addAgreementID: selectedOption }, () => {
      console.log(this.state.addAgreementID, "addAgreementID");
    });
    if (selectedOption.value !== "") {
      let _Value = selectedOption.value.split("||");

      this.setState({
        addAgreementTitle: _Value[1],
        addAgreementContent: _Value[2]
      });
    }
  };

  handleAdd(e) {
    e.preventDefault();

    try {
      if (
        this.state.addAgreementID === "" ||
        this.state.addAgreementID === undefined
      ) {
        $("#ddladdAgreement").focus();
        return false;
      }
      this.setState({
        Disable_Button: "disabled"
      });
      let objAgreement = {
        user_id: this.state.User_ID,
        agreement_id: this.state.addAgreementID.value.split("||")[0],
        agreement_title: this.state.addAgreementTitle,
        agreement_content: this.state.addAgreementContent,
        isactive: 1,
        isnotified: 1
      };
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}user_agreements/api/addCustomAgreement`,
        data: objAgreement,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
        }
      })
        .then(res => {
          if (res.data.logout === true) {
            return this.props.history.push({
              pathname: "/tcaccess"
            });
          }
          if (res.data.success === false) {
            this.setState({
              Disable_Button: ""
            });
            return notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          let _data = res.data;
          if (_data.affectedRows === 1) {
            notie.alert({
              type: "success", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: "Agreement Added Successfully",
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
            this.setState({
              addAgreementID: "",
              addAgreementTitle: "",
              addAgreementContent: "",
              UnAssigned_Agreement: []
            });

            $(".css-1uccc91-singleValue").html("Select Agreement");
            $("#add_new_agreement").hide();

            this.GetAdminUserAgreements();
            this.GetUnAssignedAgreements();
            this.setState({
              Disable_Button: ""
            });
          } else {
            this.setState({
              Disable_Button: ""
            });
            notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: _data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        })
        .catch(function(response) {
          this.setState({
            Disable_Button: ""
          });
          //handle error
          notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      this.setState({
        Disable_Button: ""
      });
      notie.alert({
        type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: "top" // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }
  handleEditAgreement(e) {
    e.preventDefault();
    try {
      var body = new FormData();
      body.append("user_agreement_id", this.state.editUserAgreementID);
      body.append("signee_name", this.state.editSigneeName);
      body.append("signee_title", this.state.editSigneeTitle);
      body.append("signature_date", this.state.editSignatureDate);
      body.append("signed_agreement_file", this.state.editSignedAgreementFile);

      axios({
        method: "put",
        url: `${process.env.REACT_APP_BASE_URL}user_agreements/api/AdminUploadApprovedAgreement`,
        data: body,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
        }
      })
        .then(res => {
          if (res.data.success === false) {
            notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
            return false;
          }

          notie.alert({
            type: "success", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
          $("#upload_signature").hide();

          this.GetAdminUserAgreements();

          this.setState({
            editUserAgreementID: "",
            editSigneeName: "",
            editSigneeTitle: "",
            editSignatureDate: "",
            editSignatureUploadFile: ""
          });

          $("#editSignedAgreementFile").val("");
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      notie.alert({
        type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: "top" // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  onChangeAdd(evt) {
    //console.log('onChange fired with event info: ', evt);
    var newContent = evt.editor.getData();
    this.setState({
      addAgreementContent: newContent
    });
  }

  agreementApproveFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal"
        }}
      >
        {(row.issigned === "Yes" || row.issigneesigned === "Yes") &&
        row.agreement_status === "Pending" ? (
          <span
            className="fa fa-check"
            style={{ color: "green" }}
            onClick={() =>
              notie.confirm({
                text: "Warning: DO YOU WANT TO APPROVE THIS AGREEMENT ?",
                submitText: "Yes", // optional, default = 'Yes'
                cancelText: "No", // optional, default = 'Cancel'
                position: "top", // optional, default = 'top', enum: ['top', 'bottom']
                submitCallback: () =>
                  this.approveAgreement(
                    row.user_agreement_id,
                    row.agreement_title,
                    row.first_name,
                    row.email
                  ) // optional
              })
            }
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  agreementDeclineFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal"
        }}
      >
        {(row.issigned === "Yes" || row.issigneesigned === "Yes") &&
        row.agreement_status === "Pending" ? (
          <span
            className="fa fa-times"
            style={{ color: "red" }}
            onClick={() =>
              notie.input({
                text: "DO YOU WANT TO REJECT THIS AGREEMENT ?",
                submitText: "Reject", // optional, default = 'Submit'
                cancelText: "Cancel", // optional, default = 'Cancel'
                position: "top", // optional, default = 'top', enum: ['top', 'bottom']
                submitCallback: value => {
                  this.declineAgreement(
                    row.agreement_id,
                    row.user_agreement_id,
                    row.agreement_title,
                    row.first_name,
                    row.email,
                    value
                  );
                }, // optional
                placeholder: "Enter Rejection Reason", // default: ''
                autofocus: "true", // default: 'true'
                max: "10000", // default: ''
                maxlength: "100", // default: ''
                min: "10", // default: ''
                minlength: "10", // default: ''
                type: "text"
              })
            }
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  approveAgreement(
    _User_Agreement_ID,
    _Agreement_Title,
    _Client_Name,
    _Client_Email
  ) {
    try {
      this.setState({
        Disable_Button: "disabled"
      });
      const api = `${process.env.REACT_APP_BASE_URL}user_agreements/api/ApproveAgreement`;

      let objUserAgreemnet = {
        user_agreement_id: _User_Agreement_ID,
        agreement_title: _Agreement_Title,
        client_name: _Client_Name,
        client_email: _Client_Email
      };

      let headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
        }
      };
      axios.put(api, objUserAgreemnet, headers).then(res => {
        if (res.data.logout === true) {
          return (window.location = "/tcaccess");
        }
        if (res.data.success === false) {
          this.setState({
            Disable_Button: ""
          });
          return notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        }

        if (res.data.success === true) {
          notie.alert({
            type: "success", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
          this.GetAdminUserAgreements();
        } else {
          notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: "Some problem in approving agreement.",
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        this.setState({
          Disable_Button: "disabled"
        });
      });
    } catch (error) {}
    this.setState({
      Disable_Button: ""
    });
  }

  declineAgreement(
    _Agreement_ID,
    _User_Agreement_ID,
    _Agreement_Title,
    _Client_Name,
    _Client_Email,
    _Decline_Reason
  ) {
    try {
      this.setState({
        Disable_Button: "disabled"
      });
      if (_Decline_Reason === "") {
        return notie.alert({
          type: "warning", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: "Rejection Reason is Required.",
          position: "top" // optional, default = 'top', enum: ['top', 'bottom']
        });
      }
      const api = `${process.env.REACT_APP_BASE_URL}user_agreements/api/DeclineAgreement`;

      let objUserAgreemnet = {
        agreement_id: _Agreement_ID,
        user_agreement_id: _User_Agreement_ID,
        agreement_title: _Agreement_Title,
        client_name: _Client_Name,
        client_email: _Client_Email,
        decline_reason: _Decline_Reason
      };

      let headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
        }
      };
      axios.put(api, objUserAgreemnet, headers).then(res => {
        if (res.data.logout === true) {
          return (window.location = "/tcaccess");
        }
        if (res.data.success === false) {
          this.setState({
            Disable_Button: ""
          });
          return notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        }

        if (res.data.success === true) {
          notie.alert({
            type: "success", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
          this.GetAdminUserAgreements();
        } else {
          notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: "Some problem in approving agreement.",
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        this.setState({
          Disable_Button: ""
        });
      });
    } catch (error) {}
    this.setState({
      Disable_Button: ""
    });
  }

  agreementDeleteFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal"
        }}
      >
        {row.issigned === "No" &&
        row.agreement_status !== "Accepted" &&
        row.isnda !== 1 ? (
          <span
            className="fa fa-trash"
            onClick={() =>
              notie.confirm({
                text: "Are you sure to this Agreement ?",
                submitText: "Yes", // optional, default = 'Yes'
                cancelText: "No", // optional, default = 'Cancel'
                position: "top", // optional, default = 'top', enum: ['top', 'bottom']
                submitCallback: () =>
                  this.deleteAgreementData(row.user_agreement_id) // optional
              })
            }
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  deleteAgreementData(_User_Agreement_ID) {
    try {
      const api = `${process.env.REACT_APP_BASE_URL}user_agreements/api/Admin/?user_agreement_id=${_User_Agreement_ID}`;
      axios
        .delete(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = "/tcaccess");
          }
          if (res.data.success === false) {
            return notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          } else if (res.data.success === true) {
            notie.alert({
              type: "success", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
            this.GetAdminUserAgreements();
            this.GetUnAssignedAgreements();
          } else {
            notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: "Some problem in deleting record.",
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        });
    } catch (error) {}
  }

  GetVendorPushAgreements() {
    const api = `${process.env.REACT_APP_BASE_URL}user_agreements/api/AdminPushToVendor/?user_id=${this.state.User_ID}`;
    this.setState({
      loading: true,
      Disable_Button: "disabled"
    });
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = "/tcaccess");
        }
        if (res.data.success === false) {
          this.setState({
            loading: false,
            Disable_Button: ""
          });
          return notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        }

        this.setState({
          selectedAgreements: [],
          agreementsVendorPush: res.data,
          loading: false,
          Disable_Button: ""
        });
        $("#push_to_vendor").show();
      });
  }

  onRowSelect(row, isSelected, e) {
    let newArray = isSelected // if isChecked is true
      ? [...this.state.selectedAgreements, row.user_agreement_id] // add element
      : [...this.state.selectedAgreements].filter(
          e => e !== row.user_agreement_id
        ); // remove the elements that are equal to newStatus
    this.setState({ selectedAgreements: newArray });
    //console.log('newArray', newArray);
  }

  onSelectAll(isSelected, rows) {
    let newArray = [];
    if (isSelected) {
      for (let i = 0; i < rows.length; i++) {
        newArray.push(rows[i].user_agreement_id);
      }
    }

    this.setState({ selectedAgreements: newArray });
    //console.log('newArray', newArray);
  }

  handlePushVendor(e) {
    e.preventDefault();
    this.setState({
      Disable_Button: "disabled"
    });
    try {
      if (this.state.selectedAgreements.length === 0) {
        notie.alert({
          type: "warning", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: "Please Select Agreement to Send to Vendor",
          stay: false, // optional, default = false
          time: 3, // optional, default = 3, minimum = 1,
          position: "top" // optional, default = 'top', enum: ['top', 'bottom']
        });
        return false;
      }

      let objAgreement = {
        agreements: this.state.selectedAgreements,
        user_id: this.state.User_ID
      };
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}user_agreements/api/AdminPushToVendor`,
        data: objAgreement,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
        }
      })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = "/tcaccess");
          }
          if (res.data.success === false) {
            this.setState({
              Disable_Button: ""
            });
            return notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          }

          if (res.data.success === true) {
            notie.alert({
              type: "success", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
            $("#push_to_vendor").hide();

            this.GetAdminUserAgreements();

            this.setState({
              selectedAgreements: []
            });
          } else {
            notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      notie.alert({
        type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: "top" // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
    this.setState({
      Disable_Button: ""
    });
  }

  copyToClipboard(element) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($(element).text()).select();
    document.execCommand("copy");
    $temp.remove();
    return false;
  }
  componentWillMount = () => {
    //console.log('AdminUserAgreements will mount');
  };

  componentDidMount = () => {
    this.GetAdminUserAgreements();
    this.GetUnAssignedAgreements();
  };

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    const { loading } = this.state;

    const selectRowProp = {
      mode: "checkbox",
      clickToSelect: true, // enable click to select
      columnWidth: "20px",
      bgColor: "lightgray",
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll
    };
    return (
      <div>
        <div className="row">
          <div className="col-md-12 card ">
            <div className="row">
              <div className="col-md-6">
                <h4 className="ml-3" id="heading">
                  {this.state.Comp_Name} Agreements
                </h4>
              </div>
              <div className="col-md-6 col-sm-6 text-right text-sm-left ">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => $("#add_new_agreement").show()}
                  disabled={this.state.Disable_Button}
                >
                  Add New Agreement
                </button>
                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={() => this.GetVendorPushAgreements()}
                  disabled={this.state.Disable_Button}
                >
                  Send to Vendor
                </button>
              </div>
            </div>

            <hr />
            <div className="container-fluid">
              <div className="col-lg-12">
                <div className="example-wrap">
                  <div className="example">
                    {loading ? (
                      <div style={{ textAlign: "center" }}>
                        <img
                          src="assets/images/tcc_loading.gif"
                          style={{ width: "128px" }}
                        />
                      </div>
                    ) : (
                      <div>
                        <ToolkitProvider
                          keyField="user_agreement_id"
                          data={this.state.agreements}
                          columns={this.state.columns}
                          search
                        >
                          {props => (
                            <div>
                              <SearchBar
                                {...props.searchProps}
                                className="custome-search-field"
                                style={{ color: "#000" }}
                                delay={1000}
                                placeholder="Search ..."
                              />
                              <hr />
                              <BootstrapTable
                                {...props.baseProps}
                                filter={filterFactory()}
                                pagination={paginationFactory()}
                                striped
                                hover
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal myModal AddAgreement"
          id="add_new_agreement"
          style={{ display: "none", paddingLeft: "15px", overflow: "auto" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <form
              id="frmAdd"
              onSubmit={this.handleAdd}
              style={{ width: "100%" }}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Add New Agreement</h4>
                  <button
                    type="button"
                    className="close"
                    onClick={() => $("#add_new_agreement").hide()}
                  >
                    ×
                  </button>
                </div>

                <div className="modal-body">
                  <div className="container-fluid">
                    <div className="form-group row">
                      <label
                        htmlFor="ddladdAgreement"
                        className="col-sm-2 control-labels"
                      >
                        Select Agreement:
                      </label>
                      <Select
                        id="ddladdAgreement"
                        style={{ width: "200px" }}
                        onChange={this.addAgreementSetValues}
                        placeholder="Select Agreement"
                        options={this.state.UnAssigned_Agreement}
                        value={this.state.addAgreementID}
                      />
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="addAgreementTitle"
                        className="col-sm-2 control-labels"
                      >
                        Agreement Title:
                      </label>
                      <input
                        type="text"
                        id="addAgreementTitle"
                        className="form-control col-md-3"
                        name="addAgreementTitle"
                        onChange={this.handleChange}
                        value={this.state.addAgreementTitle}
                        required
                      />
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="addAgreementContent"
                        className="col-sm-2 control-labels"
                      >
                        Agreement Content:
                      </label>
                      <div className="col-md-9" style={{ paddingLeft: 0 }}>
                        <CKEditor
                          id="addAgreementContent"
                          data={this.state.addAgreementContent}
                          onChange={this.onChangeAdd}
                          config={CKEconfig}
                          onBeforeLoad={CKEDITOR =>
                            CKEDITOR !== null
                              ? (CKEDITOR.disableAutoInline = true)
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 control-labels">
                        Client Place Holders:
                      </label>
                      <button
                        type="button"
                        id="CLIENT_SIGNOR_TITLE"
                        onClick={() =>
                          this.copyToClipboard("#CLIENT_SIGNOR_TITLE")
                        }
                      >
                        [[CLIENT_SIGNOR_TITLE]]
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        id="CLIENT_SIGNOR_NAME"
                        onClick={() =>
                          this.copyToClipboard("#CLIENT_SIGNOR_NAME")
                        }
                      >
                        [[CLIENT_SIGNOR_NAME]]
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        id="CLIENT_COMPANY_NAME"
                        onClick={() =>
                          this.copyToClipboard("#CLIENT_COMPANY_NAME")
                        }
                      >
                        [[CLIENT_COMPANY_NAME]]
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        id="CLIENT_EMAIL"
                        onClick={() => this.copyToClipboard("#CLIENT_EMAIL")}
                      >
                        [[CLIENT_EMAIL]]
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        id="CLIENT_ADDRESS"
                        onClick={() => this.copyToClipboard("#CLIENT_ADDRESS")}
                      >
                        [[CLIENT_ADDRESS]]
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        id="CONTRACT_DATE"
                        onClick={() => this.copyToClipboard("#CONTRACT_DATE")}
                      >
                        [[CONTRACT_DATE]]
                      </button>
                      &nbsp;{" "}
                      <button
                        type="button"
                        id="CLIENT_SIGN"
                        onClick={() => this.copyToClipboard("#CLIENT_SIGN")}
                      >
                        [[CLIENT_SIGN]]
                      </button>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 control-labels">
                        Vendor Place Holders:
                      </label>
                      <button
                        type="button"
                        id="VENDOR_SIGNOR_TITLE"
                        onClick={() =>
                          this.copyToClipboard("#VENDOR_SIGNOR_TITLE")
                        }
                      >
                        [[VENDOR_SIGNOR_TITLE]]
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        id="VENDOR_SIGNOR_NAME"
                        onClick={() =>
                          this.copyToClipboard("#VENDOR_SIGNOR_NAME")
                        }
                      >
                        [[VENDOR_SIGNOR_NAME]]
                      </button>
                      &nbsp;{" "}
                      <button
                        type="button"
                        id="VENDOR_SIGNOR_ADDRESS"
                        onClick={() =>
                          this.copyToClipboard("#VENDOR_SIGNOR_ADDRESS")
                        }
                      >
                        [[VENDOR_SIGNOR_ADDRESS]]
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        id="VENDOR_SIGNOR_SIGN"
                        onClick={() =>
                          this.copyToClipboard("#VENDOR_SIGNOR_SIGN")
                        }
                      >
                        [[VENDOR_SIGNOR_SIGN]]
                      </button>
                      &nbsp;{" "}
                      <button
                        type="button"
                        id="VENDOR_SIGNOR_COMPANY_NAME"
                        onClick={() =>
                          this.copyToClipboard("#VENDOR_SIGNOR_COMPANY_NAME")
                        }
                      >
                        [[VENDOR_SIGNOR_COMPANY_NAME]]
                      </button>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 control-labels"></label>
                      <button
                        type="button"
                        id="VENDOR_CONTRACT_DATE"
                        onClick={() =>
                          this.copyToClipboard("#VENDOR_CONTRACT_DATE")
                        }
                      >
                        [[VENDOR_CONTRACT_DATE]]
                      </button>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 control-labels">
                        User Place Holders:
                      </label>
                      <button
                        type="button"
                        id="USER_COMPANY_NAME"
                        onClick={() =>
                          this.copyToClipboard("#USER_COMPANY_NAME")
                        }
                      >
                        [[USER_COMPANY_NAME]]
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        id="USER_COMPANY_NAME_SHORT"
                        onClick={() =>
                          this.copyToClipboard("#USER_COMPANY_NAME_SHORT")
                        }
                      >
                        [[USER_COMPANY_NAME_SHORT]]
                      </button>
                      &nbsp;{" "}
                      <button
                        type="button"
                        id="USER_ADDRESS"
                        onClick={() => this.copyToClipboard("#USER_ADDRESS")}
                      >
                        [[USER_ADDRESS]]
                      </button>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={() => $("#add_new_agreement").hide()}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    id="addAgreement"
                    className="btn btn-success"
                    disabled={this.state.Disable_Button}
                  >
                    Save{" "}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div
          className="modal myModal PushtoVendor "
          id="push_to_vendor"
          style={{ display: "none", paddingLeft: "15px", overflow: "auto" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <form
              id="frmVendorPush"
              onSubmit={this.handlePushVendor}
              style={{ width: "100%" }}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Send to Vendor</h4>
                  <button
                    type="button"
                    className="close"
                    onClick={() => $("#push_to_vendor").hide()}
                  >
                    ×
                  </button>
                </div>

                <div className="modal-body">
                  <div className="container-fluid">
                    <div className="col-lg-12">
                      <div className="example-wrap">
                        <div className="example">
                          {loading ? (
                            <div style={{ textAlign: "center" }}>
                              <img
                                src="../assets/images/tcc_loading.gif"
                                style={{ width: "128px" }}
                                alt="..."
                              />
                            </div>
                          ) : (
                            <div>
                              <ToolkitProvider
                                keyField="user_agreement_id"
                                data={this.state.agreementsVendorPush}
                                columns={this.state.columnsVendorPush}
                                search
                                columnToggle
                              >
                                {props => (
                                  <div>
                                    {/*<ToggleList {...props.columnToggleProps} />
                              <hr />**/}

                                    <SearchBar
                                      {...props.searchProps}
                                      className="custome-search-field"
                                      style={{ color: "#000" }}
                                      delay={1000}
                                      placeholder="Search ..."
                                    />
                                    <hr />
                                    <BootstrapTable
                                      selectRow={selectRowProp}
                                      wrapperClasses="table-responsive"
                                      {...props.baseProps}
                                      filter={filterFactory()}
                                      pagination={paginationFactory({
                                        sizePerPage: 5,
                                        nextPageText: "Next",
                                        prePageText: "Previous",
                                        sizePerPageList: [
                                          {
                                            text: "5 Per Page",
                                            value: 5
                                          },
                                          {
                                            text: "10 Per Page",
                                            value: 10
                                          },
                                          {
                                            text: "20 Per Page",
                                            value: 20
                                          },
                                          {
                                            text: "All Record",
                                            value: 100
                                          }
                                        ]
                                      })}
                                      striped
                                      hover
                                    />
                                  </div>
                                )}
                              </ToolkitProvider>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={() => $("#push_to_vendor").hide()}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    id="btnSendToVendor"
                    className="btn btn-success"
                    disabled={this.state.Disable_Button}
                  >
                    Send
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/** UPLOAD SIGNATURE FORM */}
        <form onSubmit={this.handleEditAgreement}>
          <div
            className="modal  myModal"
            id="upload_signature"
            style={{ display: "none", paddingLeft: "15px", overflow: "auto" }}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Upload Signed Agreement</h4>
                  <button
                    type="button"
                    className="close"
                    onClick={() => $("#upload_signature").hide()}
                  >
                    ×
                  </button>
                </div>

                <div className="modal-body">
                  <div className="container-fluid">
                    <div className="form-group row">
                      <label htmlFor="editSigneeName" className="col-md-3">
                        Signee Name:
                      </label>
                      <input
                        type="text"
                        id="editSigneeName"
                        className="form-control col-md-7"
                        name="editSigneeName"
                        value={this.state.editSigneeName}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label htmlFor="editSigneeTitle" className="col-md-3">
                        Signee Title:
                      </label>
                      <input
                        type="text"
                        id="editSigneeTitle"
                        className="form-control col-md-7"
                        name="editSigneeTitle"
                        value={this.state.editSigneeTitle}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label htmlFor="editTitle" className="col-md-3">
                        Signature Date:
                      </label>
                      <DatePicker
                        placeholderText="Click to select a date"
                        id="editSignatureDate"
                        name="editSignatureDate"
                        className="form-control"
                        selected={this.state.editSignatureDate}
                        onChange={this.handleSignatureDate}
                        value={this.state.editSignatureDate}
                        maxDate={new Date()}
                        showYearDropdown
                        showMonthDropdown
                        dateFormat="MMM dd yyyy"
                        todayButton="Select Current Date"
                        isClearable
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="editSignedAgreementFile"
                        className="col-md-3"
                      >
                        Select Signed Agreement:
                      </label>
                      <input
                        type="file"
                        id="editSignedAgreementFile"
                        className=" col-md-9"
                        name="editSignedAgreementFile"
                        onChange={this.onChangeeditUploadAgreement}
                        style={{ paddingLeft: 0 }}
                        accept=".pdf"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={() => $("#upload_signature").hide()}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={this.state.Disable_Button}
                  >
                    Save{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default AdminUserAgreements;
