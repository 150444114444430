import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import axios from 'axios';
import notie from 'notie';
import $ from 'jquery';
import './UserProducts.css';
const { SearchBar } = Search;

class UserProducts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      addProductFile: [],
      Product_ID: '',
      Products: [],
      Select_Product_Files: [],
      loading: true,
      preview_More_Info: '',
      preview_More_Info_Title: '',
      columns: [
        {
          dataField: 'serial_number',
          text: 'Sr #',
          sort: true
        },
        {
          dataField: 'product_title',
          text: 'Product Title',
          sort: true
        },
        {
          dataField: 'more_info',
          text: 'More Info',
          sort: false,
          formatter: this.productMoreInfoFormatter,
          headerAttrs: {
            width: 100,
            style: {
              textAlign: 'center'
            }
          },
          attrs: { style: { textAlign: 'center' } }
        },

        {
          dataField: 'show',
          text: 'View',
          sort: false,
          formatter: this.productFileFormatter,
          headerAttrs: { width: 100, style: { textAlign: 'center' } },
          attrs: { width: 100, style: { textAlign: 'center' } }
        }
      ],
      columnsProductFiles: [
        {
          dataField: 'serial_number',
          text: 'Sr #',
          sort: true
        },
        {
          dataField: 'product_file_name',
          text: 'File Name',
          sort: true
        },
        {
          dataField: 'view_file',
          text: '',
          sort: false,
          formatter: this.productFileViewFormatter,
          headerAttrs: { width: 50 },
          attrs: { width: 50, className: '' }
        }
      ]
    };

    this.getProductFiles = this.getProductFiles.bind(this);
  }

  GetAllProducts() {
    const api = `${process.env.REACT_APP_BASE_URL}products/api/getClientProducts`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_user_token')}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = '/client_login');
        }
        if (res.data.success === false) {
           this.setState({
             loading: false
           });
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        this.setState({
          Products: res.data,
          loading: false
        });
      });
  }

  productFileFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <span
          className='fa fa-eye'
          onClick={() => this.getProductFiles(row.product_id)}
        />
      </div>
    );
  };

  productFileViewFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <a
          className='fa fa-eye'
          style={{ textDecoration: 'none' }}
          target='_blank'
          href={process.env.REACT_APP_BASE_URL + row.product_file_path}
        />
      </div>
    );
  };

  getProductFiles(_Product_ID) {
    try {
      this.setState({
        loading: true,
        Product_ID: _Product_ID
      });
      const api = `${process.env.REACT_APP_BASE_URL}products/api/getProductFilesUser?product_id=${_Product_ID}`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_user_token')}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/client_login');
          }
          if (res.data.success === false) {
            this.setState({
              loading: false
            });
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          this.setState({
            Select_Product_Files: res.data,
            loading: false
          });
          $('#product_files').show();
        });
    } catch (error) {}
  }

  productMoreInfoFormatter = (cell, row) => {
    return (
      <a
        href='javascript:void'
        onClick={() => {
          this.setState({
            preview_More_Info: row.product_desc,
            preview_More_Info_Title: row.product_title
          });
          $('#preview_more_info').show();
        }}
      >
        More Info
      </a>
    );
  };

  componentWillMount = () => {};

  componentDidMount = () => {
    $('.active_left_menu').removeClass('active_left_menu');
    $('#lnkservice').addClass('active_left_menu');
    this.GetAllProducts();
  };

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    const { loading } = this.state;
    return (
      <div>
        <div>
          <div className='page-header'>
            <h1 className='page-title'>SERVICES</h1>
          </div>

          <div className='page-content'>
            <div className='panel-body'>
              <div className='row'>
                <div className='col-md-12 card'>
                  <div className='container-fluid' style={{ padding: 0 }}>
                    <div className='col-lg-12' style={{ padding: 0 }}>
                      <div className='example-wrap'>
                        <div className='example'>
                          {loading ? (
                            <div style={{ textAlign: 'center' }}>
                              <img
                                src='../assets/images/tcc_loading.gif'
                                style={{ width: '128px' }}
                              />
                            </div>
                          ) : (
                            <div>
                              <ToolkitProvider
                                keyField='product_id'
                                data={this.state.Products}
                                columns={this.state.columns}
                                search
                              >
                                {props => (
                                  <div>
                                    <SearchBar
                                      {...props.searchProps}
                                      className='custome-search-field'
                                      style={{ color: '#000' }}
                                      delay={1000}
                                      placeholder='Search ...'
                                    />
                                    <hr />
                                    <BootstrapTable
                                      {...props.baseProps}
                                      filter={filterFactory()}
                                      pagination={paginationFactory()}
                                      striped
                                      hover
                                    />
                                  </div>
                                )}
                              </ToolkitProvider>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className='modal myModal ProductFiles '
            id='product_files'
            style={{ display: 'none', paddingLeft: '15px', overflow: 'auto' }}
          >
            <form id='frmProductFiles'>
              <div className='modal-dialog modal-dialog-centered  modal-lg'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h4 className='modal-title'>SERVICE FILES</h4>
                    <button
                      type='button'
                      className='close'
                      onClick={() => $('#product_files').hide()}
                    >
                      ×
                    </button>
                  </div>

                  <div className='modal-body'>
                    <div className='container-fluid'>
                      <div className='col-lg-12'>
                        <div className='example-wrap'>
                          <div className='example'>
                            {loading ? (
                              <div style={{ textAlign: 'center' }}>
                                <img
                                  src='../assets/images/tcc_loading.gif'
                                  style={{ width: '128px' }}
                                  alt='...'
                                />
                              </div>
                            ) : (
                              <div>
                                <ToolkitProvider
                                  keyField='product_file_id'
                                  data={this.state.Select_Product_Files}
                                  columns={this.state.columnsProductFiles}
                                  search
                                  columnToggle
                                >
                                  {props => (
                                    <div>
                                      {/*<ToggleList {...props.columnToggleProps} />
                              <hr />**/}

                                      <SearchBar
                                        {...props.searchProps}
                                        className='custome-search-field'
                                        style={{ color: '#000' }}
                                        delay={1000}
                                        placeholder='Search ...'
                                      />
                                      <hr />
                                      <BootstrapTable
                                        wrapperClasses='table-responsive'
                                        {...props.baseProps}
                                        filter={filterFactory()}
                                        pagination={paginationFactory({
                                          sizePerPage: 10,
                                          nextPageText: 'Next',
                                          prePageText: 'Previous',
                                          sizePerPageList: [
                                            {
                                              text: '10 Per Page',
                                              value: 10
                                            },
                                            {
                                              text: '20 Per Page',
                                              value: 20
                                            },
                                            {
                                              text: 'All Record',
                                              value: 100
                                            }
                                          ]
                                        })}
                                        striped
                                        hover
                                      />
                                    </div>
                                  )}
                                </ToolkitProvider>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/** PREWVIEW MORE INFO*/}

        <div
          className='modal myModal'
          id='preview_more_info'
          style={{ display: 'none', paddingLeft: '15px', overflow: 'auto' }}
        >
          <div className='modal-dialog modal-dialog-centered modal-lg'>
            <div className='modal-content'>
              <div
                className='modal-body'
                style={{
                  padding: 0,
                  overflowY: 'auto',
                  maxHeight: 'calc(100vh - 100px)'
                }}
              >
                <div className='panel'>
                  <div className='container-fluid'>
                    <div className='example-wrap wrap-1'>
                      <div className='example example-well well-1'>
                        <div className='page-header'>
                          <h2 className='page-title text-white text-uppercase title-1'>
                            {this.state.preview_More_Info_Title}
                          </h2>
                          <button
                            type='button'
                            className='btn btn-outline btn-primary my-btn'
                            onClick={() => $('#preview_more_info').hide()}
                          >
                            X
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-12'>
                        <div
                          className='ml-20 mr-md-50'
                          dangerouslySetInnerHTML={{
                            __html: this.state.preview_More_Info
                          }}
                        />
                      </div>
                      <div className='col-md-3' style={{ display: 'none' }}>
                        <ul className='list-unstyled'>
                          <li>
                            <button
                              type='button'
                              className='btn btn-block btn-danger mb-10'
                            >
                              Download Rate Deck
                            </button>
                          </li>
                          <li>
                            <button
                              type='button'
                              className='btn btn-block btn-success  mb-10'
                            >
                              Sign Agreement
                            </button>
                          </li>
                          <li>
                            <button
                              type='button'
                              className='btn btn-block btn-info mb-10'
                            >
                              Credit Application
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserProducts;
