import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import lifecycle from 'react-pure-lifecycle';
import { AuthConsumer } from '../../context/AuthContextEmail';

const methods = {
  componentDidMount(props) {
    document.body.classList.add('page-login-v2', 'layout-full', 'page-dark');
    document.body.classList.remove('site-navbar-small');
  }
};

const EmailLayout = ({ children, ...rest }) => {
  return (
    <AuthConsumer>
      {({ Comp_Logo_Dashboard, Client_Marketing_Content }) => (
        <React.Fragment>
          <div
            className='page'
            data-animsition-in='fade-in'
            data-animsition-out='fade-out'
          >
            <div className='page-content'>
              <div className='page-brand-info'>
                <div className='brand'>
                  {Comp_Logo_Dashboard === '' ? (
                    ''
                  ) : (
                    <img
                      className='brand-img'
                      src={Comp_Logo_Dashboard}
                      alt='...'
                    />
                  )}
                </div>
                <div className='brand'>
                  <p className='text-white'>{Client_Marketing_Content}</p>
                </div>
              </div>

              <div className='page-login-main animation-slide-right animation-duration-1'>
                {children}
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </AuthConsumer>
  );
};

const EmailLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <AuthConsumer>
      {({ Comp_Name }) => (
        <Route
          render={matchProps => (
            <EmailLayout Comp_Name={Comp_Name}>
              <Component {...matchProps} />
            </EmailLayout>
          )}
          {...rest}
        />
      )}
    </AuthConsumer>
  );
};

export default lifecycle(methods)(EmailLayoutRoute);
