import React, { Component } from 'react';
import { AuthConsumer } from '../../context/AuthContext';
import queryString from 'query-string';
import axios from 'axios';

class AdminDeclineByEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      isValid: true,
      token: '',
      PageLoad: false,
      ResponseMessage: '',
      addDeclineReason: '',
      Request_Complete: false
    };

    this.AdminDeclineByEmail = this.AdminDeclineByEmail.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  verifyLink(_Token) {
    const api = `${
      process.env.REACT_APP_BASE_URL
    }user_agreements/api/AdminDeclineByEmail`;
    try {
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${_Token}`
          }
        })
        .then(res => {
          //console.log(res.data);

          if (res.data.success) {
            this.setState({
              isValid: true
            });
          } else {
            this.setState({
              isValid: false
            });
          }
          this.setState({
            PageLoad: true,
            ResponseMessage: res.data.message
          });
        });
    } catch (error) {}
  }

  AdminDeclineByEmail(e) {
    e.preventDefault();
    const api = `${
      process.env.REACT_APP_BASE_URL
    }user_agreements/api/AdminDeclineByEmail`;
    try {
      let objUserAgremeent = {
        decline_reason: this.state.addDeclineReason
      };
      axios({
        method: 'put',
        url: api,
        data: objUserAgremeent,

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.state.token}`
        }
      }).then(res => {
        //console.log(res.data);

        if (res.data.success) {
          this.setState({
            isValid: true
          });
        } else {
          this.setState({
            isValid: false
          });
        }
        this.setState({
          PageLoad: true,
          ResponseMessage: res.data.message,
          Request_Complete: true
        });
      });
    } catch (error) {}
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }
  componentWillMount = () => {};

  componentDidMount = () => {
    const values = queryString.parse(this.props.location.search);

    if (values.token !== '' && values.token !== undefined) {
      this.setState({ token: values.token });
      this.verifyLink(values.token);
    } else {
      this.setState({
        isValid: false,
        PageLoad: true
      });
    }
  };

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return (
      <div>
        {this.state.PageLoad === true ? (
          <React.Fragment>
            <div className='col-md-12'>
              <div className='example-wrap'>
                <div className='example'>
                  <div
                    className='nav-tabs-horizontal nav-tabs-inverse'
                    data-plugin='tabs'
                  >
                    <ul className='nav nav-tabs nav-tabs-solid' role='tablist'>
                      <li className='nav-item' role='presentation'>
                        <a
                          className='nav-link active show'
                          data-toggle='tab'
                          href='#exampleIconifiedTabsOne'
                          aria-controls='exampleIconifiedTabsOne'
                          role='tab'
                          aria-selected='true'
                        >
                          <i className='icon fas fa-file' aria-hidden='true' />
                          Reject Agreement
                        </a>
                      </li>
                    </ul>
                    <div className='tab-content pt-15'>
                      <div
                        className='tab-pane active show'
                        id='exampleIconifiedTabsOne'
                        role='tabpanel'
                      >
                        {this.state.isValid === true ? (
                          <form onSubmit={this.AdminDeclineByEmail}>
                            {this.state.Request_Complete === false ? (
                              <React.Fragment>
                                <div className='form-group'>
                                  Please enter agreement reject reason below.
                                </div>
                                <div className='form-group'>
                                  <label
                                    className='sr-only'
                                    htmlFor='addDeclineReason'
                                  >
                                    Reject Reason
                                  </label>
                                  <textarea
                                    className='form-control'
                                    id='addDeclineReason'
                                    name='addDeclineReason'
                                    placeholder='Decline Reason'
                                    maxLength='500'
                                    required
                                    onChange={this.handleChange}
                                    value={this.state.addDeclineReason}
                                    rows='15'
                                  />
                                </div>

                                <button
                                  type='submit'
                                  className='btn btn-danger btn-block'
                                >
                                  Reject
                                </button>
                              </React.Fragment>
                            ) : (
                              <div className='form-group'>
                                {this.state.ResponseMessage}
                              </div>
                            )}
                          </form>
                        ) : (
                          <form>
                            <div className='form-group'>
                              {this.state.ResponseMessage}
                            </div>
                          </form>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-12'>
              <div style={{ textAlign: 'center' }}>
                All Rights Reserved. Copyright. Telecom Carrier 2019.
              </div>
            </div>
          </React.Fragment>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default AdminDeclineByEmail;
