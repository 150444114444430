import React from 'react';
import notie from 'notie';
import axios from 'axios';
import $ from 'jquery';

const AuthContext = React.createContext();

class AuthProviderAgent extends React.Component {
  constructor() {
    super();

    this.state = {
      isAuthAgent: false,
      User_ID: '',
      Agent_ID: '',
      Agent_Name: '',
      Agent_Email: '',
      Agent_Title: '',
      Comp_Name: '',
      Comp_Logo: '',
      Comp_Logo_Dashboard: '',

      Meta_Tags: '',
      Comp_Phone_No: '',
      Comp_Address: '',
      Support_Email: ''
    };
    const api = `${process.env.REACT_APP_BASE_URL}settings/api/`;
    axios
      .get(api, {
        config: { headers: { 'Content-Type': 'application/json' } }
      })
      .then(res => {
        this.setState({
          Comp_Name: res.data[0].setting_comp_name,
          Support_Email: res.data[0].setting_support_email,
          Comp_Phone_No: res.data[0].setting_comp_phone_no,
          Comp_Address: res.data[0].setting_comp_address,
          Comp_Logo: res.data[0].setting_comp_small_logo,
          Comp_Logo_Dashboard: res.data[0].setting_comp_large_logo,
          Meta_Tags: res.data[0].setting_meta_tags
        });
      })
      .catch(function(response) {
        //handle error
      });

    //GET SETTING DATA

    if (
      localStorage.getItem('tcc_agent_token') !== null &&
      localStorage.getItem('tcc_agent_id') !== null
    ) {
      var pathName = window.location.pathname;
      var pageName = pathName.split('/').pop();
      console.log('pageName', pageName);
      if (
        pageName !== 'agent_complete_signup_process' &&
        pageName !== 'agent_reset_password'
      ) {
        this.agentLogin(null, '', '', true);
      }
    }

    this.agentLogin = this.agentLogin.bind(this);
    this.logout = this.logout.bind(this);
    this.Reset_Password = this.Reset_Password.bind(this);
    this.Complete_Signup = this.Complete_Signup.bind(this);
  }

  agentLogin = async (e, Agent_Email, Agent_Password, isAuto) => {
    if (isAuto) {
      $('#root').css('visibility', 'hidden');
      const api = `${process.env.REACT_APP_BASE_URL}agents/api/agentlogin`;

      await axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_agent_token')}`
          }
        })
        .then(res => {
          if (res.data.success) {
            this.setState({
              isAuthAgent: true,
              User_ID: res.data.agent.user_id,
              Agent_ID: res.data.agent.agent_id,
              Agent_Name: res.data.agent.agent_name,
              Agent_Email: res.data.agent.agent_email,
              Agent_Title: res.data.agent.agent_title
            });
          }
          $('#root').css('visibility', '');
        });
    } else {
      $('#root').css('visibility', '');
      e.preventDefault();
      let agentCredentails = {
        agent_email: Agent_Email,
        agent_password: Agent_Password
      };
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}agents/api/agentlogin`,
        data: agentCredentails,
        config: { headers: { 'Content-Type': 'application/json' } }
      })
        .then(data => {
          let _data = data.data;
          if (_data.success) {
            localStorage.setItem('tcc_agent_token', _data.token);
            localStorage.setItem('tcc_agent_id', _data.user[0].agent_id);
            localStorage.setItem('tcc_agent_email', _data.user[0].agent_email);

            this.setState({
              isAuthAgent: true,
              User_ID: _data.user[0].user_id,
              Agent_ID: _data.user[0].agent_id,
              Agent_Name: _data.user[0].agent_name,
              Agent_Email: _data.user[0].agent_email,
              Agent_Title: _data.user[0].agent_title
            });
            $('#root').css('visibility', '');
          } else {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: _data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    }
    $('#root').css('visibility', '');
  };

  Complete_Signup(event, User_Password, User_Confirm_Password, Token) {
    event.preventDefault();
    try {
      if (User_Password !== User_Confirm_Password) {
        return notie.alert({
          type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: 'Password and Confirm Password not Same.',
          stay: false, // optional, default = false
          time: 3, // optional, default = 3, minimum = 1,
          position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
        });
      }
      let objUser = {
        agent_password: User_Password
      };
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}agents/api/complete_signup`,
        data: objUser,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Token}`
        }
      }).then(res => {
        if (res.data.success === false) {
          notie.alert({
            type: 2,
            text: res.data.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top'
          });
        } else {
          let _data = res.data;
          localStorage.setItem('tcc_agent_token', _data.token);
          localStorage.setItem('tcc_agent_id', _data.agent[0].agent_id);
          localStorage.setItem('tcc_agent_email', _data.agent[0].agent_email);
          this.setState({
            isAuthAgent: true,
            User_ID: _data.agent[0].user_id,
            Agent_ID: _data.agent[0].agent_id,
            Agent_Name: _data.agent[0].agent_name,
            Agent_Email: _data.agent[0].agent_email,
            Agent_Title: _data.agent[0].agent_title
          });
        }
      });
    } catch (error) {}
  }

  Reset_Password(event, Agent_Password, Agent_Confirm_Password, Token) {
    event.preventDefault();
    try {
      if (Agent_Password !== Agent_Confirm_Password) {
        return notie.alert({
          type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: 'Password and Confirm Password not Same.',
          stay: false, // optional, default = false
          time: 3, // optional, default = 3, minimum = 1,
          position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
        });
      }
      let objAgent = {
        agent_password: Agent_Password
      };
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}agents/api/reset_password`,
        data: objAgent,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Token}`
        }
      }).then(res => {
        if (res.data.success === false) {
          notie.alert({
            type: 2,
            text: res.data.message,
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top'
          });
        } else if (res.data.success === 'reload') {
          document.location.reload(true);
        } else {
          let _data = res.data;
          localStorage.setItem('tcc_agent_token', _data.token);
          localStorage.setItem('tcc_agent_id', _data.user[0].agent_id);
          localStorage.setItem('tcc_agent_email', _data.user[0].agent_email);
          this.setState({
            isAuthAgent: true,
            User_ID: _data.user[0].user_id,
            Agent_ID: _data.user[0].agent_id,
            Agent_Email: _data.user[0].agent_email,
            Agent_Name: _data.user[0].agent_name,
            Agent_Title: _data.user[0].agent_title
          });
        }
      });
    } catch (error) {}
  }

  Verify_User(token) {
    try {
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}agents/api/verify_account`,
        data: '',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }).then(res => {
        if (res.data.success === false) {
          notie.force({
            type: 2, // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            buttonText: 'Click Here To Login',
            text: res.data.message,
            stay: false, // optional, default = false
            time: 15, // optional, default = 3, minimum = 1,
            position: 'top',
            callback: function() {
              window.location = 'tcagent';
            }
          });
        } else {
          let _data = res.data;
          localStorage.setItem('tcc_agent_token', _data.token);
          localStorage.setItem('tcc_agent_id', _data.user[0].agent_id);
          localStorage.setItem('tcc_agent_email', _data.user[0].agent_email);
          this.setState({
            isAuthAgent: true,
            Agent_ID: _data.user[0].agent_id,
            Agent_Email: _data.user[0].agent_email,
            Agent_Name: _data.user[0].agent_name,
            Agent_Title: _data.user[0].agent_title
          });
        }
      });
    } catch (error) {}
  }

  logout() {
    this.setState({ isAuthAgent: false });
    localStorage.removeItem('tcc_agent_token');
    localStorage.removeItem('tcc_agent_id');
    localStorage.removeItem('tcc_agent_email');

    var arr = []; // Array to hold the keys
    // Iterate over localStorage and insert the keys that meet the condition into arr
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).substring(0, 10) === 'lastKnown_') {
        arr.push(localStorage.key(i));
      }
    }

    // Iterate over arr and remove the items by key
    for (let i = 0; i < arr.length; i++) {
      localStorage.removeItem(arr[i]);
    }
  }

  render() {
    return (
      <AuthContext.Provider
        value={{
          isAuthAgent: this.state.isAuthAgent,
          Agent_ID: this.state.Agent_ID,
          Agent_Name: this.state.Agent_Name,
          Agent_Email: this.state.Agent_Email,
          Agent_Title: this.state.Agent_Title,
          agentLogin: this.agentLogin,
          Complete_Signup: this.Complete_Signup,
          Reset_Password: this.Reset_Password,
          logout: this.logout,
          Comp_Name: this.state.Comp_Name,
          Support_Email: this.state.Support_Email,
          Comp_Phone_No: this.state.Comp_Phone_No,
          Comp_Address: this.state.Comp_Address,
          Comp_Logo: this.state.Comp_Logo,
          Meta_Tags: this.state.Meta_Tags,
          Comp_Logo_Dashboard: this.state.Comp_Logo_Dashboard
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

const AuthConsumer = AuthContext.Consumer;

export { AuthProviderAgent, AuthConsumer };
