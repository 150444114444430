import React from "react";
import $ from "jquery";
import axios from "axios";
import notie from "notie";
import InputMask from "react-input-mask";
import SignatureCanvas from "react-signature-canvas";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardTitle,
  CardText,
  Row,
  Col
} from "reactstrap";
import classnames from "classnames";
import Select from "react-select";
import htmlToImage from "html-to-image";
import styles from "../styles.module.css";

class EditUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      User_ID: props.User_ID,
      editCompName: "",
      editFirstName: "",
      editPhoneNo: "",
      editEmail: "",
      editTitle: "",
      editAddress: "",
      editSignature: "",
      editSignatureFile: "",
      editSignatureFileDisplay: "",
      editSign: "",
      editSignCheck: false,
      loading: false,
      activeTab: "1",
      FontName: "Mistral",
      SelectedFontName: {
        value: "Mistral",
        label: props.User_Name
      },
      signOptions: [
        {
          value: "Mistral",
          label: props.User_Name
        },
        {
          value: "Rage",
          label: props.User_Name
        },
        {
          value: "SignatureOne",
          label: props.User_Name
        },
        {
          value: "SignatureTwo",
          label: props.User_Name
        },
        {
          value: "SignatureThree",
          label: props.User_Name
        },
        {
          value: "SignatureFour",
          label: props.User_Name
        },
        {
          value: "SignatureFive",
          label: props.User_Name
        },
        {
          value: "SignatureSix",
          label: props.User_Name
        },
        {
          value: "SignatureSeven",
          label: props.User_Name
        },
        {
          value: "SignatureEight",
          label: props.User_Name
        }
      ]
    };

    this.handleEdit = this.handleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeeditUploadSign = this.onChangeeditUploadSign.bind(this);
    this.handleeditUpload = this.handleeditUpload.bind(this);
    this.getUserData = this.getUserData.bind(this);
  }

  componentWillMount() {
    this.getUserData(this.props.User_ID);
  }

  componentWillUnmount() {}

  getUserData(_User_ID) {
    try {
      this.setState({ loading: true });
      const api = `${process.env.REACT_APP_BASE_URL}users/api/getUserByUserID`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("tcc_user_token")}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = "/client_login");
          }
          if (res.data.success === false) {
            this.setState({ loading: false });
            return notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          }

          this.setState({
            editCompName: res.data[0].comp_name,
            editFirstName: res.data[0].first_name,
            editPhoneNo: res.data[0].phone_number,
            editEmail: res.data[0].email,
            editTitle: res.data[0].title,
            editAddress: res.data[0].address,
            editSignatureFileDisplay: res.data[0].user_signature_file
          });
          $("#edit_user").show();
          this.setState({ loading: false });
        });
    } catch (error) {}
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleEditClear(e) {
    e.preventDefault();
    this.editSignaturePad.clear();
    this.setState({ editSign: "" });
  }

  handleeditUpload = () => {
    this.setState({ editSignCheck: !this.state.editSignCheck });
    if (this.state.editSignCheck) {
      $("#diveditUploadSign").show();
      $(".diveditSign").hide();
    } else {
      $("#diveditUploadSign").hide();
      $(".diveditSign").show();
    }
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 1);
  };

  onChangeeditUploadSign = event => {
    //getting file object
    let file = event.target.files[0];

    const types = ["image/png", "image/jpeg"];
    let imgSize = 70000;
    // let imgWidth = 200;
    // let imgHeight = 200;

    let err = "";
    // list allow mime type
    if (file === undefined) {
      return false;
    }
    if (types.every(type => file.type !== type)) {
      // create error message and assign to container
      err += file.type + " is Not a Supported Format. Please Try PNG or JGPEG ";

      notie.alert({
        type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: err,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: "top" // optional, default = 'top', enum: ['top', 'bottom']
      });
    }

    if (err !== "") {
      // if message not same old that mean has error
      event.target.value = null; // discard selected file
      console.log(err);
      return false;
    }

    if (file.size > imgSize) {
      err += file.type + " is Too Large. Please Pick a MAX 70KB File";
      notie.alert({
        type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: err,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: "top" // optional, default = 'top', enum: ['top', 'bottom']
      });
    }

    if (err !== "") {
      event.target.value = null;
      console.log(err);
      return false;
    }

    this.setState({
      editSignatureFile: event.target.files[0]
    });

    console.log(this.state.editSignatureFile);
  };

  async handleEdit(e) {
    e.preventDefault();
    try {
      this.setState({ loading: true });

      var body = new FormData();

      body.append("comp_name", this.state.editCompName);
      body.append("first_name", this.state.editFirstName);
      body.append("phone_number", this.state.editPhoneNo);
      body.append("title", this.state.editTitle);
      body.append("address", this.state.editAddress);
      body.append("siganture_type", this.state.editSignCheck);
      if (this.state.activeTab === "1") {
        body.append(
          "user_signature",
          this.editSignaturePad.toDataURL("image/png")
        );
      } else {
        var node = document.getElementById("divSignature");
        await htmlToImage
          .toPng(node)
          .then(function(dataUrl) {
            console.log("dataUrl", dataUrl);
            body.append("user_signature", dataUrl);
          })
          .catch(function(error) {
            console.error("oops, something went wrong!", error);
          });
      }

      body.append("user_signature_file", this.state.editSignatureFile);

      axios({
        method: "put",
        url: `${process.env.REACT_APP_BASE_URL}users/api/`,
        data: body,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tcc_user_token")}`
        }
      })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = "/client_login");
          }
          if (res.data.success === false) {
            this.setState({ loading: false });
            return notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          let _data = res.data;
          if (res.data.success === true) {
            notie.alert({
              type: "success", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: _data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });

            this.props.hideEditForm();
          } else {
            notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: _data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          this.setState({ loading: false });
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      notie.alert({
        type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: "top" // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 1);
  };

  onChangeSignStyle = selectedSignOption => {
    this.setState({ FontName: selectedSignOption.value });
    this.setState({ SelectedFontName: selectedSignOption });
    console.log("this.state.FontName", this.state.FontName);
  };
  handleSignatureClear(e) {
    e.preventDefault();
    this.editSignaturePad.clear();
  }
  render() {
    const signStyles = {
      control: styles => ({
        ...styles,
        fontFamily: this.state.FontName,
        fontSize: "x-large",
        width: "275px",
        heigth: "150px"
      }),
      option: (styles, { data, isSelected }) => {
        return {
          ...styles,
          fontFamily: data.value,
          fontSize: "x-large"
        };
      }
    };
    const { loading } = this.state;
    return (
      <React.Fragment>
        {loading ? <div className="loadingDiv" /> : ""}

        {/** EDIT FORM */}

        <form onSubmit={this.handleEdit}>
          <div
            className="modal edit_user myModal"
            id="edit_user"
            style={{
              display: "none",
              paddingLeft: "15px",
              overflow: "auto"
            }}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Edit Profile</h4>
                  <button
                    type="button"
                    className="close"
                    onClick={this.props.hideEditForm}
                  >
                    ×
                  </button>
                </div>

                <div className="modal-body">
                  <div className="container-fluid">
                    <div className="form-group row">
                      <label htmlFor="editEmail" className="col-md-3">
                        Company Email:
                      </label>
                      <input
                        type="text"
                        id="editEmail"
                        className="form-control col-md-7"
                        name="editEmail"
                        value={this.state.editEmail}
                        disabled
                      />
                    </div>
                    <div className="form-group row">
                      <label htmlFor="editCompName" className="col-md-3">
                        Company Name:
                      </label>
                      <input
                        type="text"
                        id="editCompName"
                        className="form-control col-md-7"
                        name="editCompName"
                        value={this.state.editCompName}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label htmlFor="editTitle" className="col-md-3">
                        Title:
                      </label>
                      <input
                        type="text"
                        id="editTitle"
                        className="form-control col-md-7"
                        name="editTitle"
                        value={this.state.editTitle}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label htmlFor="editFirstName" className="col-md-3">
                        Full Name:
                      </label>
                      <input
                        type="text"
                        id="editFirstName"
                        className="form-control col-md-7"
                        name="editFirstName"
                        value={this.state.editFirstName}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label htmlFor="editPhoneNo" className="col-md-3">
                        Contact Number:
                      </label>

                      <InputMask
                        mask="99999999999"
                        pattern=".{10,}"
                        maskChar={null}
                        id="editPhoneNo"
                        className="form-control col-md-7"
                        name="editPhoneNo"
                        placeholder="Contact Number 2134567890"
                        title="Contact Number 2134567890"
                        value={this.state.editPhoneNo}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label htmlFor="editAddress" className="col-md-3">
                        Address:
                      </label>
                      <textarea
                        id="editAddress"
                        className="form-control col-md-7"
                        name="editAddress"
                        placeholder="Service Address: City, State, Zip"
                        value={this.state.editAddress}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3">Signature:</label>
                      {this.state.editSignatureFileDisplay === null ? (
                        "No Signature"
                      ) : (
                        <img
                          style={{ height: "50px" }}
                          alt="Signature"
                          src={`${process.env.REACT_APP_BASE_URL}${this.state.editSignatureFileDisplay}`}
                        />
                      )}
                    </div>
                    <div className="form-group row">
                      <label className="col-md-4" htmlFor="chkeditUpload">
                        Manual Signature: (check box to add signature)
                      </label>
                      <div className="isnotified:0,">
                        <input
                          type="checkbox"
                          id="chkeditUpload"
                          checked={this.state.editSignCheck}
                          onChange={this.handleeditUpload}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-4">or</label>
                    </div>
                    <div id="diveditUploadSign" className="form-group row">
                      <label htmlFor="editSignatureFile" className="col-md-4">
                        Upload Signature:
                      </label>
                      <input
                        type="file"
                        id="editSignatureFile"
                        className="col-md-6"
                        name="editSignatureFile"
                        onChange={this.onChangeeditUploadSign}
                        style={{ paddingLeft: 0 }}
                        accept="image/*"
                      />
                    </div>
                    <div
                      className="diveditSign"
                      style={{
                        display: "none"
                      }}
                    >
                      <Nav tabs className="col-md-12">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "1"
                            })}
                            onClick={() => {
                              this.toggle("1");
                            }}
                          >
                            DRAW
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "2"
                            })}
                            onClick={() => {
                              this.toggle("2");
                            }}
                          >
                            CHOOSE
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                          <Row>
                            <Col sm="12">
                              <div
                                className="row"
                                style={{
                                  padding: "10px 10px 10px 10px",
                                  height: "200px"
                                }}
                              >
                                <label htmlFor="">Sign Here:</label>

                                <div className={styles.container}>
                                  <div className={styles.sigContainer}>
                                    <SignatureCanvas
                                      clearOnResize={true}
                                      canvasProps={{ className: styles.sigPad }}
                                      velocityFilterWeight={0.7}
                                      throttle={50}
                                      ref={ref => {
                                        this.editSignaturePad = ref;
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="2">
                          <Row>
                            <Col lg="12">
                              <Card body>
                                <CardTitle></CardTitle>
                                <CardText>
                                  <Select
                                    id="ddlSignatureStyle"
                                    onChange={this.onChangeSignStyle}
                                    options={this.state.signOptions}
                                    value={this.state.SelectedFontName}
                                    styles={signStyles}
                                  />
                                </CardText>
                              </Card>
                            </Col>
                            <Col lg="12">
                              <Card body>
                                <CardTitle></CardTitle>

                                <CardText>
                                  <div
                                    id="divSignature"
                                    style={{
                                      color: "black",
                                      backgroundColor: "white",
                                      fontFamily: this.state.FontName,
                                      fontSize: "80px",
                                      width: "500px",
                                      height: "150px",
                                      lineHeight: "150px"
                                    }}
                                  >
                                    {this.state.editFirstName}
                                  </div>
                                </CardText>
                              </Card>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-warning"
                    onClick={this.handleSignatureClear.bind(this)}
                  >
                    Clear Signature
                  </button>
                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={this.props.hideEditForm}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-success">
                    Save{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default EditUserForm;
