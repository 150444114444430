import React, { Component } from 'react';
import { AuthConsumer } from '../../context/AuthContext';
import './Admin.css';
class AdminLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    let target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(e) {
    return true;
  }
  render() {
    return (
      <AuthConsumer>
        {({ isAuth, login, logout, Comp_Name }) => (
          <div>
            {isAuth === false ? (
              <div>
                <h3 className='font-size-24'>Admin Sign In</h3>
                <form
                  onSubmit={e =>
                    login(
                      e,
                      document.getElementById('inputUserName').value,
                      document.getElementById('inputPassword').value,
                      false
                    )
                  }
                >
                  <div className='form-group'>
                    <label className='sr-only' htmlFor='inputUserName'>
                      User Name
                    </label>
                    <input
                      className='form-control'
                      id='inputUserName'
                      name='inputUserName'
                      placeholder='User Name'
                      onChange={this.handleChange}
                      value={this.state.value}
                      required
                    />
                  </div>
                  <div className='form-group'>
                    <label className='sr-only' htmlFor='inputPassword'>
                      Password
                    </label>
                    <input
                      type='password'
                      className='form-control'
                      id='inputPassword'
                      name='inputPassword'
                      placeholder='Password'
                      onChange={this.handleChange}
                      value={this.state.value}
                      required
                    />
                  </div>
                  <div className='form-group clearfix'>
                    {/* <div className='checkbox-custom checkbox-inline checkbox-primary float-left'>
                  <input type='checkbox' id='rememberMe' name='rememberMe' />
                  <label htmlFor='rememberMe'>Remember Me</label>
        </div>
                <a
                  className='float-right'
                  data-toggle='modal'
                  data-target='.myMode'
                >
                  Forgot Password?
                </a>**/}
                  </div>
                  <button
                    type='submit'
                    data-time='1.5'
                    className='btn btn-primary btn-block'
                  >
                    Sign in
                  </button>
                </form>
              </div>
            ) : (
              ''
            )}
          </div>
        )}
      </AuthConsumer>
    );
  }
}

export default AdminLogin;
