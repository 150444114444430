import React, { PureComponent } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import notie from 'notie';
import styles from './styles.module.css';

class SignaturePad extends PureComponent {
  state = { trimmedDataURL: null };
  signaturePad = {};
  clear = () => {
    this.signaturePad.clear();
  };

  onEvent = () => {
    console.log('onEvent');
    this.signaturePad.on();
  };

  trim = () => {
    this.setState({
      trimmedDataURL: this.signaturePad
        .getTrimmedCanvas()
        .toDataURL('image/png')
    });
  };
  constructor(props) {
    super(props);

    this.state = {
      hasError: false
    };
    this.onChangeeditUploadSign = this.onChangeeditUploadSign.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  onChangeeditUploadSign = event => {
    //getting file object
    let file = event.target.files[0];

    const types = ['image/png', 'image/jpeg'];
    let imgSize = 70000;
    // let imgWidth = 200;
    // let imgHeight = 200;

    let err = '';
    // list allow mime type
    if (file === undefined) {
      return false;
    }
    if (types.every(type => file.type !== type)) {
      // create error message and assign to container
      err += file.type + ' is Not a Supported Format. Please Try PNG or JGPEG ';

      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: err,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }

    if (err !== '') {
      // if message not same old that mean has error
      event.target.value = null; // discard selected file
      console.log(err);
      return false;
    }

    if (file.size > imgSize) {
      err += file.type + ' is Too Large. Please Pick a MAX 70KB File';
      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: err,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }

    if (err !== '') {
      event.target.value = null;
      console.log(err);
      return false;
    }

    this.setState({
      editSignatureFile: event.target.files[0]
    });

    console.log(this.state.editSignatureFile);
  };

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  componentWillMount = () => {
    console.log('SignaturePad will mount');
  };

  componentDidMount = () => {
    console.log('SignaturePad mounted');
  };

  componentWillReceiveProps = nextProps => {
    console.log('SignaturePad will receive props', nextProps);
  };

  componentWillUpdate = (nextProps, nextState) => {
    console.log('SignaturePad will update', nextProps, nextState);
  };

  componentDidUpdate = () => {
    console.log('SignaturePad did update');
  };

  componentWillUnmount = () => {
    console.log('SignaturePad will unmount');
  };

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    const { trimmedDataURL } = this.state;
    return (
      <div className='SignaturePadWrapper'>
        <div style={{ height: 'auto', width: '100%' }}>
          <div className='container-fluid'>
            <div id='diveditUploadSign' className='form-group row'>
              <label htmlFor='editSignatureFile' className='col-md-4'>
                Upload Signature:
              </label>
              <input
                type='file'
                id='editSignatureFile'
                className='col-md-6'
                name='editSignatureFile'
                onChange={this.onChangeeditUploadSign}
                style={{ paddingLeft: 0 }}
                accept='image/*'
              />
            </div>
            <div
              className='row'
              style={{ padding: '10px', height: '200px', width: '100%' }}
            >
              <label htmlFor='' className='col-md-3'>
                Sign Here:
              </label>
              <div className={styles.container}>
                <div className={styles.sigContainer}>
                  <SignatureCanvas
                    clearOnResize={true}
                    canvasProps={{ className: styles.sigPad }}
                    ref={ref => {
                      this.signaturePad = ref;
                    }}
                    on={() => this.onEvent()}
                  />
                </div>
                <div>
                  <button className={styles.buttons} onClick={this.clear}>
                    Clear
                  </button>
                  <button
                    className={styles.buttons}
                    onClick={this.trim}
                    style={{ display: 'none' }}
                  >
                    Trim
                  </button>
                </div>
                {trimmedDataURL ? (
                  <img
                    alt=''
                    className={styles.sigImage}
                    src={trimmedDataURL}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SignaturePad;
